const obj = {
  title: '篩選器',
  mobile: {
    title: '篩選清單'
  },
  fields: {
    totalArea: {
      label: '總面積',
      placeholderFrom: '從',
      placeholderTo: '之前'
    },
    projectProfitYearRate: {
      label: '年收益'
    },
    discountFromPriceMarketMin: {
      label: '折扣來自'
    },
    price: {
      label_both: '成本',
      label_pay_extra: '額外付費',
      label_cash: '獲取現金',
      placeholderFrom: '從',
      placeholderTo: '之前',
      errorText: '最低價格必須低於最高價格',
    },
    constructionYear: {
      label: '建造年份',
      placeholderFrom: '從',
      placeholderTo: '之前'
    },
    ageDays: {
      label: '在市場上'
    },
    payExtraGetCash: {
      label: 'Pay extra / get cash',
      options: {
        pay_extra: 'Pay extra',
        both: 'Both',
        cash: 'Get cash',
      }
    },
    repairRating: {
      label: 'Repair level not less',
    },
    roomsCount: {
      label: 'Rooms count',
      placeholderFrom: 'from',
      placeholderTo: 'to'
    },
    buildingRating: {
      label: 'Building rating from',
    },
    isHasCommentChecks: {
      label: 'Property is checked'
    }
  },
  btn: {
    apply: '應用',
    reset: '重啟'
  },
  message: {
    not_found: '在此區域未找到任何對象'
  },
  showMore: {
    expand: 'Show filters',
    collapse: 'Hide filters'
  }
};

export default obj;
