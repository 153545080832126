const obj = {
  chart: {
    priceRadioGroupTitle: 'Prices for',
    saleRadioGroupTitle: 'Sale for',
    lot: 'home',
  },
  noCompetitors: 'No competitors',
  currentProperty: 'Current apartment',
  btnList: 'Show details'
};

export default obj;
