<template>
  <ul class="params-list" v-if="transactionAt || transactionNumber || countExpositionDays">
    <li v-if="transactionAt">
      <span>{{ $t('property.metaInfo.transactionAt') }}</span>
      <span>{{ $d(new Date(transactionAt), 'short') }}</span>
    </li>
    <li v-if="transactionNumber">
      <span>{{ $t('property.metaInfo.transactionNumber') }}</span>
      <span>{{ transactionNumber }}</span>
    </li>
    <li v-if="countExpositionDays">
      <span>{{ $t('property.countExpositionDays') }}</span>
      <span>{{ $tc('timePeriod.day', countExpositionDays) }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SalesInformation',
  props: {
    transactionAt: {
      type: String,
      default: ''
    },
    transactionNumber: {
      type: String,
      default: ''
    },
    countExpositionDays: {
      type: Number,
      default: 0
    },
  },
};
</script>

<style lang="scss" scoped>
.params-list {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 206px;
  li {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D2D2D2;
    padding: 10px 0;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    &:first-child {
      border-top: none;
    }
    > span {
      &:first-child {
        color: #999;
      }
    }
  }
}
</style>
