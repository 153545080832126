<template>
  <div class="company-about">
    <h2 class="mb-1">{{ title }}</h2>
    <div class="two-columns">
      <div class="main-col">
        <h4 class="mb-2">
          {{ $t('modal.companyAbout.subTitle') }}
        </h4>
        <div v-html="$t('modal.companyAbout.htmlMainDescription', {
                  'link': `<a href='${urlProjarka}' target='_blank'>${urlProjarka}</a>`})">
        </div>
        <h3>{{ $t('modal.companyAbout.media.title') }}</h3>
        <div class="media-about">
          <a v-for="post in posts"
             v-bind:key="post.title"
             v-bind:href="post.url"
             target="_blank"
             class="media-about-item">
            <div class="img-wrap">
              <img v-bind:src="post.img">
            </div>
            <p>{{ getPostTitle(post.title) }}</p>
          </a>
        </div>
      </div>
      <aside class="sidebar">
        <dl class="sidebar-list"
             v-html="$t('modal.companyAbout.htmlSidebarDescription', {
                  'link': `<a href='${realisteUrl}' target='_blank'>${realisteUrl}</a>`})">
        </dl>
      </aside>
    </div>
  </div>
</template>

<script>
import constants from '@/components/constants';

const posts = [
  {
    img: require('@/assets/images/media-logo/rbc.png'),
    title: 'rbc',
    url: 'https://trends.rbc.ru/trends/industry/5fd9c8d19a79478053e43d07?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/pierwy-kanal.jpg'),
    title: 'pierwyKanal',
    url: 'https://www.1tv.ru/shows/dobroe-utro/reportazh/apartamenty-protiv-kvartiry-dobroe-utro-fragment-vypuska-ot-11-02-2021?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/hazeta.png'),
    title: 'hazeta',
    url: 'https://www.gazeta.ru/business/2020/11/10/13355785.shtml?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/rt.png'),
    title: 'rt',
    url: 'https://russian.rt.com/business/article/777075-udalyonka-nedvizhimost-ceny?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/viedomosti.png'),
    title: 'viedomosti',
    url: 'https://www.vedomosti.ru/realty/blogs/2021/02/16/858088-investori-ispolzuyut?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/rbc.png'),
    title: 'rbc_2',
    url: 'https://realty.rbc.ru/news/5f9be6199a7947686150de7a?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/the-village.png'),
    title: 'theVillage',
    url: 'https://www.the-village.ru/business/personal-finance/6-kvartir?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/rg.png'),
    title: 'rg',
    url: 'https://rg.ru/2020/11/11/na-vtorichnoe-zhile-vyrosli-i-spros-i-ceny.html?roistat_visit=102933'
  },
  {
    img: require('@/assets/images/media-logo/forbes.png'),
    title: 'forbes',
    url: 'https://www.forbes.ru/newsroom/finansy-i-investicii/409611-zhile-na-vtorichnom-rynke-v-moskve-podorozhalo-s-nachala-goda'
  },
];

export default {
  props: {
    title: String,
  },
  computed: {
    posts() {
      return posts;
    },
    urlProjarka() {
      return constants.PROJARKA;
    },
    realisteUrl() {
      return process.env.VUE_APP_REALISTE_URL;
    }
  },
  methods: {
    openSite() {
      this.$emit('closeModal');
    },
    getPostTitle(name) {
      return this.$t(`modal.companyAbout.media.list.${name}`);
    }
  },
};
</script>

<style lang="scss" scoped>
.bubble-inner {
  padding: 2rem 4.5rem 1rem 2.5rem;
  color: #000;
}

@include media-breakpoint-down(sm) {
  .bubble-inner{
    padding-right: 2.5rem;
    padding-left: 1.5rem;
  }
}
</style>
