<template>
  <div class="d-inline-flex align-items-baseline"
       v-if="roomsCount !== null">
    <span v-bind:class="`divider hidden ${sizeClass}`"></span>
    {{ rooms }}{{ $t('property.numberRoomsShort') }}
  </div>
</template>

<script>
export default {
  props: ['roomsCount', 'sizeClass'],
  computed: {
    rooms() {
      return this.roomsCount == 0 ? 1 : this.roomsCount;
    }
  }
};
</script>
