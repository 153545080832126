const obj = {
  chart: {
    priceRadioGroupTitle: '價格',
    saleRadioGroupTitle: '出售給',
    lot: '很多',
  },
  noCompetitors: '沒有競爭對手',
  currentProperty: 'Current apartment',
  btnList: '顯示詳細資料'
};

export default obj;
