<template>
  <div class="d-inline-flex align-items-baseline">
    <span v-bind:class="`divider ${sizeClass}`"></span>
    <span class="text-md">{{ expositionDays }} </span>
    <span class="text-base">{{ getExpositionDays }}</span>
  </div>
</template>

<script>
export default {
  props: ['sizeClass', 'expositionDays'],
  computed: {
    getExpositionDays() {
      return `${this.$tc('timePeriod.dayWithoutVal', this.expositionDays)} на рынке`;
    },
  }
};
</script>
