<template>
  <div v-if="repairRating">
    <p class="text-muted m-0">
      {{ $t('property.repairQuality') }}
    </p>
    <p class="value m-0">
      {{ repairRating }}/10
      <span class="text-warning">★</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ['repairRating'],
};
</script>
