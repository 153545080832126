import Vue from 'vue';
import Vuelidate from 'vuelidate';
import apolloProvider from '@/lib/apollo';
import '@/lib/realiste_storage';

import formatNumberMixin from '@/mixins/formatNumberMixin';
import routingTo from '@/mixins/routingTo';

import vueScrollBehavior from 'vue-scroll-behavior';
import TextareaAutosize from 'vue-textarea-autosize';

import getParameterByName from '@/lib/get_param_by_name';
import detectIframe from '@/lib/detect_iframe';

import VueScrollTo from 'vue-scrollto';
import * as GmapVue from 'gmap-vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import cookies from 'vue-cookies';
import VuexRouterSync from 'vuex-router-sync';

import Empty from '@/layouts/EmptyLayout.vue';
import Default from '@/layouts/DefaultLayout.vue';
import Deals from '@/layouts/DealsLayout.vue';

import TopHeader from '@/components/common/TopHeader.vue';
import SvgIcon from '@/components/common/SvgIcon.vue';
import ButtonBack from '@/components/common/ButtonBack.vue';
import Loader from '@/components/common/Loader.vue';
import InfoPanel from '@/components/common/InfoPanel.vue';
import AmountCurrency from '@/components/common/AmountCurrency.vue';
import PropertyInfo from '@/components/common/PropertyInfo.vue';
import BottomBar from '@/components/common/BottomBar.vue';

import router from './router';
import store from './store';

import App from './App.vue';

import '@/lib/sentry';
import '@/lib/replace_window_location';
import '@/lib/add_global_instance_properties';
import '@/lib/change_theme';
import { i18n } from './plugins/i18n';
// eslint-disable-next-line import/extensions
import './directives.js';

VuexRouterSync.sync(store, router);

Vue.use(VueAxios, Axios);
Vue.use(cookies);
Vue.use(VueScrollTo);

Vue.use(vueScrollBehavior, { router });
Vue.use(TextareaAutosize);

Vue.config.productionTip = false;

const lng = getParameterByName('locale') || i18n.locale;

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
    language: lng,
  },
  installComponents: true
});

Vue.use(Vuelidate);

const detectIframeTimeout = setTimeout(detectIframe, 1000);

Vue.component('svg-icon', SvgIcon);
Vue.component('button-back', ButtonBack);
Vue.component('data-loader', Loader);
Vue.component('info-panel', InfoPanel);
Vue.component('amount-currency', AmountCurrency);
Vue.component('property-info', PropertyInfo);
Vue.component('bottom-bar', BottomBar);
Vue.component('top-header', TopHeader);
Vue.component('empty-layout', Empty);
Vue.component('default-layout', Default);
Vue.component('deals-layout', Deals);

Vue.mixin(formatNumberMixin);
Vue.mixin(routingTo);

new Vue({
  store,
  router,
  apolloProvider,
  i18n,
  render: h => h(App)
}).$mount('#app');
