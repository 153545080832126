const poi = {
  priceEstimation: {
    sidebar: {
      title: 'Next to the house',
      total: 'Points of interest',
      notFound: 'Not found',
      swiperHint: 'Scroll right to view other factors'
    },
    dataFoundPoi: 'Found factors affecting the attractiveness of the location',
    showPoiBtn: {
      collapse: 'Show factors',
      expand: 'Hide factors',
    }
  }
};

export default poi;
