import transliterate from '@/lib/transliterate';

export default {
  methods: {
    formatTags(tags) {
      const result = tags.map(i => `#${i}`);
      return this.transliterateInner(result.join(' '));
    },
    transliterateInner(text) {
      return this.$i18n.locale !== 'ru' ? transliterate(text) : text;
    }
  }
};
