import Vue from 'vue';
import constants from '@/components/constants';
import dataFactory from '@/services/DataFactory';
import partnerData from '@/lib/partner_data';
import { EventBus } from '@/patterns/event-bus';

Vue.prototype.$domainConfig = partnerData;

const availableCities = dataFactory.getAvailableCities();
const currencies = dataFactory.getCurrencies();

Promise.all([availableCities, currencies]).then(values => {
  Vue.prototype.$constants = {
    ...constants,
    AVAILABLE_CITIES: values[0],
    CURRENCIES: values[1]
  };

  EventBus.$emit('constantsAreLoaded', true);
});
