export default {
  namespaced: true,
  state: {
    isPanelVisible: false,
    content: {
      code: '',
      category: '',
      subCategory: '',
      title: '',
      customComponent: '',
      objectId: null,
      zIndex: null,
      htmlDescription: '',
      coordinate: null
    },
    modalIsVisible: false
  },
  mutations: {
    changePanelStatus(state, status) {
      state.isPanelVisible = status;
    },
    updateContent(state, data) {
      state.content.code = data.code || '';
      state.content.category = data.category || '';
      state.content.subCategory = data.subCategory || '';
      state.content.title = data.title || '';
      state.content.customComponent = data.customComponent || '';
      state.content.objectId = data.objectId || null;
      state.content.zIndex = data.zIndex || null;
      state.content.htmlDescription = data.htmlDescription || '';
      state.content.coordinate = data.coordinate || '';
    },
    showModal(state, isVisible) {
      state.modalIsVisible = isVisible;
    },
  },
  actions: {
  },
  getters: {
    isPanelVisible(state) {
      return state.isPanelVisible;
    },
    content(state) {
      return state.content;
    },
    isModalVisible(state) {
      return state.modalIsVisible;
    },
  },
};
