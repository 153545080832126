import constants from '@/components/constants';

const arrInSessionStorage = ['clientToken', 'id', '_et'];

class SaveGetParamsInStorage {
  addItemToStorage(args) {
    if (args.to.params[args.el] == null && args.to.query[args.el] == null) {
      if (args.from.params[args.el] != null) {
        args.storage.setItem(args.el, args.from.params[args.el], args.storageType);

        const newTo = {
          ...args.to
        };

        newTo.params[args.el] = args.from.params[args.el];
        args.next(newTo);

        return;
      }
      args.storage.setItem(args.el, '', args.storageType);
    } else if (args.to.params[args.el] != null) {
      args.storage.setItem(args.el, args.to.params[args.el], args.storageType);
    } else if (args.to.query[args.el] != null) {
      args.storage.setItem(args.el, args.to.query[args.el], args.storageType);
    }
  }

  beforeEach(to, from, next) {
    arrInSessionStorage.forEach(el => this.addItemToStorage({
      el, to, from, next, storage: window.__realisteStorage, storageType: 'sessionStorage'
    }));
    constants.GLOBAL_GET_PARAMS.forEach(el => this.addItemToStorage({
      el, to, from, next, storage: window.__realisteStorage
    }));
  }
}

const saveGetParamsInStorage = new SaveGetParamsInStorage();

export default saveGetParamsInStorage;
