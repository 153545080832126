const getUrlParameters = (parameter) => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(parameter);
};

window.__parentOrigin = getUrlParameters('parentOrigin');

window.__realisteStorage = {
  mode: undefined, // localStorage | parentFrame
  currentStore: undefined,
  supportsStorage: () => {
    return navigator.cookieEnabled && typeof window.localStorage != undefined;
  },
  init: () => {
    if (window.top === window) {
      window.__realisteStorage.mode = 'localStorage';
    } else {
      window.__realisteStorage.mode = 'parentFrame';
      window.addEventListener('message', window.__realisteStorage.listener);
      window.top.postMessage(JSON.stringify({ operation: 'get_settings' }),
        window.__parentOrigin);
      window.__realisteStorage.currentStore = {};
      if (window.location.hash) {
        try {
          window.__realisteStorage.currentStore = JSON.parse(window.location.hash);
        } catch {
          console.log('error');
        }
      }
    }
  },
  listener: (event) => {
    if (event.origin !== window.__parentOrigin) return;
    const data = JSON.parse(event.data);
    if (data.operation == 'update_settings') {
      window.__realisteStorage.currentStore = data.value;
    }
  },
  getAllKeys: (storage = 'localStorage') => {
    let keys = null;

    if (window.__realisteStorage.mode == 'localStorage') {
      keys = Object.keys(localStorage);
    }
    if (window.__realisteStorage.mode == 'parentFrame') {
      keys = Object.keys(window.__realisteStorage.currentStore);
    }
    return keys;
  },
  getItem: (key, storage = 'localStorage') => {
    let value = null;

    if (window.__realisteStorage.mode == 'localStorage') {
      value = window[storage].getItem(key);
    }
    if (window.__realisteStorage.mode == 'parentFrame') {
      value = window.__realisteStorage.currentStore[key];
    }

    if (value === 'null') {
      value = null;
    }
    if (value === 'undefined') {
      value = undefined;
    }
    return value;
  },
  setItem: (key, value, storage = 'localStorage') => {
    if (window.__realisteStorage.mode == 'localStorage') {
      if (value == undefined) {
        window[storage].removeItem(key);
        return value;
      } else {
        return window[storage].setItem(key, value);
      }
    }
    if (window.__realisteStorage.mode == 'parentFrame') {
      if (value == undefined) {
        window.top.postMessage(JSON.stringify({ operation: 'remove', key: key }), window.__parentOrigin);
        return window.__realisteStorage.currentStore[key] = value;
      } else {
        window.top.postMessage(JSON.stringify({ operation: 'set', key: key, value: value }), window.__parentOrigin);
        return window.__realisteStorage.currentStore[key] = value;
      }
    }
  },
  removeItem: (key) => {
    if (window.__realisteStorage.mode == 'localStorage') return localStorage.removeItem(key);
    if (window.__realisteStorage.mode == 'parentFrame') {
      window.top.postMessage(JSON.stringify({ operation: 'remove', key: key }), window.__parentOrigin);
      delete window.__realisteStorage.currentStore[key];
    }
  }
};

window.__realisteStorage.init();
