import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, concat, split } from 'apollo-link';
import introspectionQueryResult from '@/data/schema.json';
import { RetryLink } from '@apollo/client/link/retry';

import ActionCable from 'actioncable';
import { ActionCableLink } from 'graphql-ruby-client';
import { getMainDefinition } from 'apollo-utilities';
import errorHandler from '@/lib/error_handler';

const wsClient = ActionCable.createConsumer(process.env.VUE_APP_SHIVA_ACTIONCABLE_URL);

Vue.use(VueApollo);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionQueryResult,
});

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_SHIVA_GRAPHQL_URL
});

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const newHeader = {};

  const res = {};

  const utms = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content'];

  utms.forEach(el => {
    if (window.__realisteStorage.getItem(el) == undefined) return;

    const v = Buffer.from(window.__realisteStorage.getItem(el));

    res[el] = v.toString('base64');
  });

  newHeader['X-Client-Token'] = window.__realisteStorage.getItem('clientToken', 'sessionStorage');
  newHeader['X-ENCRYPTED-TOKEN'] = window.__realisteStorage.getItem('_et', 'sessionStorage');

  newHeader['X-UTM-Medium'] = res.utm_medium;
  newHeader['X-UTM-Source'] = res.utm_source;
  newHeader['X-UTM-Campaign'] = res.utm_campaign;
  newHeader['X-UTM-Content'] = res.utm_content;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...newHeader
    }
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => errorHandler.handleError('[GraphQL error]', { message, locations, path }));
  }

  if (networkError) {
    errorHandler.handleError('[GraphQL Network error]', { networkError });
  }
});

const wsLink = new ActionCableLink({
  cable: wsClient,
  connectionParams: () => {
    return {
      xClientToken: window.__realisteStorage.getItem('clientToken', 'sessionStorage'),
      xEncryptedToken: window.__realisteStorage.getItem('_et', 'sessionStorage')
    };
  }
});
const retryLink = new RetryLink();

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
  errorLink,
  retryLink
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: concat(authLink, link),
  cache: new InMemoryCache({
    fragmentMatcher
  })
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    $mutate: {
      errorPolicy: 'all',
    },
  }
});

export default apolloProvider;
