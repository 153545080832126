const pointIsInsidePolygon = (point, polygon) => {
  /* eslint-disable */
  const n = polygon.length;
  let isIn = false;
  const x = point[0];
  const y = point[1];
  let x1;
  let x2;
  let y1;
  let y2;

  for (let i = 0; i < n - 1; ++i) {
    x1 = polygon[i][0];
    x2 = polygon[i + 1][0];
    y1 = polygon[i][1];
    y2 = polygon[i + 1][1];

    if (y < y1 != y < y2 && x < (x2 - x1) * (y - y1) / (y2 - y1) + x1) {
      isIn = !isIn;
    }
  }

  return isIn;
};

export default pointIsInsidePolygon;
