import gql from 'graphql-tag';

const COMMON_FIELDS = `
  comment
  projectProfitYearRate(asPercent: true, roundingAccuracy: 0)
  netProfit
  duration {
    duration
    unit
    unitLabel
  }
  price
  priceMarket(roundingAccuracy: 0, absolute: true)
  investmentAmount(roundingAccuracy: 0)
  improvementAmount(roundingAccuracy: 0, absolute: true)
  id
  externalUrl
  currency
  estimateBuyFields: estimateFields(group: buy) {
    label
    value(roundingAccuracy:0, absolute: true)
    code
  }
  estimateOwnFields: estimateFields(group: own) {
    label
    value(roundingAccuracy:0, absolute: true)
    code
  }
  estimateOwn2Fields: estimateFields(group: own2) {
    label
    value(roundingAccuracy:0, absolute: true)
    code
  }
  totalEstimateCost(roundingAccuracy:0, absolute: true)
  totalCostInvestments(roundingAccuracy:0, absolute: true)
  pricePurchase(roundingAccuracy:0, absolute: true)
  coordinate {lat, lng}
  discountFromPriceMarket(asPercent: true, roundingAccuracy: 2)
`;

const INVESTMENT_OBJECT_FIELDS = `{
  ${COMMON_FIELDS}
  potentialVariants {
    id
    changes
  }
}`;

const INVESTMENT_OBJECT_CALC_CLAIM_RESULTS_FIELDS = `{
  __typename
  ... on CalcClaim {
    ${COMMON_FIELDS}
  }
  ... on CalcClaimError {
    message
  }
}`;

export default function buildInvestmentObjectQuery(type) {
  if (type === 'CalcClaim') {
    return gql`query app($id: ID!) {
      userInfo {
        auth,
        isInternal,
        isPublic
      }
      investmentObjectCalcClaimResult(id: $id) ${INVESTMENT_OBJECT_CALC_CLAIM_RESULTS_FIELDS}
    }`;
  }
  return gql`query app($id: ID!) {
    userInfo {
      auth,
      isInternal,
      isPublic
    }
    investmentObject(id: $id) ${INVESTMENT_OBJECT_FIELDS}
  }`;
}

export function investmentObjectPotentialWithEstimate() {
  return gql`query investmentObjectPotential(
    $id: ID!,
    $variantId: ID!) {
    investmentObject(id: $id) {
      id
      potential(variantId: $variantId) {
        variantInfo {
          id
          changes
        }
        ${COMMON_FIELDS}
      }
    }
  }`;
}
