const table = {
  thead: {
    sellToRealiste: '賣Realiste',
    sellByYourOwn: '自己賣'
  },
  saleTerm: '銷售期天數',
  serviceFee: '服務佣金3％',
  urgentSaleCommission: '緊急銷售佣金9％',
  bargain: '討價還價2.5％',
  rent: '租金0.2％',
  instantAmountOfMoney: '現在的金額',
  amountOfMoneyIn120days: '120天后手頭上的金額',
  totalPrice: '總價',
  in3Days: '（三天后）',
  in90Days: '（90 天內）',
  aboutRealiste: '關於 Realiste'
};

export default table;
