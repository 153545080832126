<template>
  <div v-if="!$route.meta.emptyLayout && !$route.meta.dealsLayout">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'Default'
};
</script>
