const obj = {
  btn: {
    cancel: 'Отмена',
    submit: 'Отправить',
    close: 'Закрыть'
  },
  analysisError: {
    title: 'Ошибка в анализе?',
    telegramLink: 'Перейти в группу техподдержки Telegram',
  },
  propertyValuation: {
    title: {
      default: 'Оценка квартиры и подбор за 10 секунд. Шаг 1 из 2',
      lenstroytrest: 'Оценка квартиры и подбор за 10 секунд. Шаг 1 из 2'
    },
    subtitle: 'Узнайте, сколько стоит квартира при сроке продажи за 1 неделю, 3 месяца или полгода.',
    claimsCounter: 'Уже оценили {counter} раза с 1 июня 2021 года.',
    claimFeatures: {
      title: 'За 10 секунд узнаете',
      list: [
        'Рыночную стоимость квартиры и что на нее влияет',
        'Ежемесячный платеж по ипотеке',
        'Какой кредит могут дать банки под залог квартиры'
      ]
    },
    fillOutClaimNote: 'Цель заполнения заявки:',
    btn: {
      submit: {
        default: 'Показать расчет',
        nextStep: 'Следующий шаг',
        pending: 'Данные отправляются',
        verifyPhone: {
          default: 'Показать оценку квартиры',
          tradeUp: 'Показать предложения'
        },
        smsCodeVerification: {
          default: 'Подтвердить',
          tradeUp: 'Подтвердить',
        },
        resendPhone: {
          prependText: 'Не пришла СМС?',
          hasInterval: 'Повторно отправить СМС через {count} сек.',
          default: 'Повторно отправить СМС'
        }
      },
      showThirdStep: 'Оценить',
      showFourthStep: 'Еще'
    },
    fillAddressNote: 'Введите адрес, чтобы перейти к следующему шагу',
    canCalcForPoint: {
      errorLabel: 'К сожалению, мы пока не можем сделать расчет для этой локации.</br>Попробуйте ввести другой адрес или {telegramLink}, чтобы быть в курсе, когда мы сможем произвести  расчет',
      linkText: 'вступите в наше сообщество'
    },
    error: {
      manyCalcRequestsForAddress: 'К сожалению, мы не можем выполнить расчёт. Возможно, из вашей сети приходит слишком много запросов. <br>Пожалуйста, попробуйте позже или измените параметры запроса.',
      default: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте позже.'
    },
    prefilledDataMessage: 'Проверьте предзаполненные данные, пожалуйста',
    showPrice: 'Показать цену'
  },
  offerForm: {
    offerTitle: ['Мы купим данный объект за', 'за 3 дня'],
    formTitle: 'Подтвердите контактные данные:',
    note: ['Если вы считаете цену нерыночной, пожалуйста, ', 'ознакомьтесь с конкурентным анализом'],
    btn: {
      getOffer: 'Получить предложение о покупке',
      notInterested: 'Неинтересно',
      submit: {
        pending: 'Данные отправляются',
        error: 'Данные отправить не удалось',
        success: 'Данные успешно отправлены'
      }
    }
  },
  categoryTitle: {
    tellAboutApartment: 'Расскажите о квартире',
    mainObjectParameters: 'Основные параметры жилья',
    location: 'Введите адрес дома',
    objectAbout: 'О квартире',
    square: 'Площадь',
    floor: 'Этажность',
    balconies: 'Кол-во балконов',
    bathrooms: 'Кол-во санузлов',
    elevator: 'Кол-во лифтов',
    contactInfo: 'Контактная информация'
  },
  inputGroup: {
    appendLabel: {
      squareMeter: {
        code: 'm2',
        html: 'м<sup>2</sup>'
      }
    }
  },
  fields: {
    problemDescription: {
      placeholder: 'Опишите проблему',
      note: 'Вы можете запросить детальный расчет стоимости квартиры, где точность расчетов AI проверят эксперты Реалист и пришлют Вам свой вывод.'
    },
    desiredPrice: {
      label: 'Желаемая цена продажи (во сколько вы сами оцениваете)',
      note: 'Введите желаемую цену или оставьте пустой для автоматической оценки'
    },
    burden: {
      label: 'Есть обременение',
      note: 'Установите галочку, если есть обременение (залог, суди т.д.)'
    },
    objectType: {
      emptyText: '-- Выберите --',
      label: 'Тип жилья',
      options: [
        {
          code: 'flat',
          label: 'Квартира'
        },
        {
          code: 'apartments',
          label: 'Апартаменты'
        }
      ]
    },
    buildingPropertyType: {
      emptyText: '-- Выберите значение --',
      options: [
        {
          code: 'condo',
          label: 'Квартира'
        }
      ]
    },
    roomsCount: {
      label: 'Количество комнат',
      studio: 'студия'
    },
    square: {
      label: 'Площадь',
      note: {
        sqm: 'Минимум 8 м.',
        sqft: 'Минимум 300 футов'
      }
    },
    repairRating: {
      title: 'Уровень ремонта',
      label: 'Как вы оцениваете качество ремонта своей квартиры',
      error: 'Пожалуйста, укажите уровень ремонта'
    },
    city: {
      label: 'Область или город'
    },
    address: {
      label: 'Регион {city} и область',
      placeholder: 'Введите адрес дома без номера квартиры',
      note: {
        gmap: 'Пожалуйста, введите полностью слова "корпус", "строение" и т.д.',
        dadata: 'Номер квартиры указывать не нужно'
      },
      error: 'Укажите улицу и точный адрес до номера дома',
      warning: 'Проверьте, пожалуйста, что указан дом',
      canChooseAddressLabel: {
        showSelect: 'Изменить',
        hideSelect: 'Скрыть'
      }
    },
    roomNumber: {
      label: 'Номер квартиры'
    },
    buildingBuildYear: {
      label: 'Год постройки'
    },
    timeWaltToMetro: {
      label: 'Пешком до метро'
    },
    kadNumber: {
      label: 'Кадастровый номер'
    },
    buildingMaterialType: {
      emptyText: '-- Выберите тип --',
      label: 'Тип дома',
      options: [
        {
          code: 'brick',
          label: 'Кирпичный'
        },
        {
          code: 'monolith',
          label: 'Монолитный'
        },
        {
          code: 'panel',
          label: 'Панельный'
        },
        {
          code: 'block',
          label: 'Блочный'
        },
        {
          code: 'wood',
          label: 'Деревянный'
        },
        {
          code: 'stalin',
          label: 'Сталинский'
        },
        {
          code: 'monolithBrick',
          label: 'Кирпично-монолитный'
        }
      ]
    },
    redevelopment: {
      label: 'Есть ли неузаконенная перепланировка?',
      options: [
        {
          code: true,
          label: 'Да'
        },
        {
          code: false,
          label: 'Нет'
        }
      ]
    },
    viewFromWindow: {
      label: 'Вид из окон',
      options: ['На парк', 'На лес', 'На шоссе', 'Во двор']
    },
    roomsLayout: {
      emptyText: '-- Выберите значение --',
      label: 'Планировка комнат',
      options: [
        {
          code: 'adjoining',
          label: 'Смежная'
        },
        {
          code: 'isolated',
          label: 'Изолированная'
        },
        {
          code: 'adjoiningIsolated',
          label: 'Смежно-изолированная'
        }
      ]
    },
    ceilingHeight: {
      label: 'Высота потолков'
    },
    squareLiving: {
      label: 'Жилая'
    },
    squareKitchen: {
      label: 'Кухня'
    },
    floorNumber: {
      label: 'Этаж'
    },
    buildingFloorsCount: {
      label: 'Этажность'
    },
    balconiesCount: {
      label: 'Балконов'
    },
    loggiasCount: {
      label: 'Лоджий'
    },
    bathroomShared: {
      label: 'Смежные'
    },
    bathroomCombined: {
      label: 'Раздельные'
    },
    parkingType: {
      emptyText: '-- Выберите значение --',
      label: 'Парковка',
      options: [
        {
          code: 'in_yard',
          label: 'Во дворе'
        },
        {
          code: 'underground',
          label: 'Подземная'
        },
        {
          code: 'chaos',
          label: 'Хаотичная'
        },
        {
          code: 'barrier_used',
          label: 'За шлагбаумом'
        }
      ]
    },
    additionalOptions: {
      label: 'Дополнительно',
      options: ['Детская площадка', 'Консьерж', 'Охраняемая территория']
    },
    elevatorFreightsCount: {
      label: 'Грузовые'
    },
    elevatorPassengersCount: {
      label: 'Пассажирские'
    },
    contactName: {
      label: 'Ваше имя'
    },
    contactPhone: {
      label: 'Телефон для связи',
      placeholder: 'Введите номер телефона',
      error: {
        validateNumber: 'Пожалуйста, проверьте номер телефона',
        verificateNumber: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте позже.'
      }
    },
    contactMailOrTelegram: {
      label: 'Электронная почта или Telegram'
    },
    contactSmsCode: {
      label: 'Мы отправили смс-код подтверждения на ваш телефон. <br> Пожалуйста, введите код подтверждения.',
      placeholder: 'Код подтверждения',
      error: 'К сожалению, вы ввели неверный код или произошла ошибка. Пожалуйста, проверьте код или запросите новый.'
    },
    externalUrl: {
      label: 'Ссылка на другой сайт (если есть)',
      error: 'Данных по данному объекту не найдено',
      btnText: 'Заполнить данными'
    },
    correctPrice: {
      label: 'Корректная цена'
    },
    apartmentNumber: {
      label: 'Дом'
    },
    comment: {
      label: 'Комментарий (необязателен)'
    }
  },
  messages: {
    error: {
      part1: 'К сожалению, мы пока не можем рассчитать стоимость квартиры по Вашему адресу и характеристикам. Возможно, вы где-то указали неверные данные. <br>В любом случае мы получили Вашу заявку и начали работать над возможностью произвести расчет.<br>Пока же приглашаем следить за обновлениями',
      part2: 'в нашем Telegram канале.'
    },
    bugReport: {
      success: 'Спасибо за ваш отзыв. Мы ответим как можно скорее.',
      error: 'К сожалению, произошла ошибка. Попробуйте отправить замечание позже или напишите в <a href="https://t.me/joinchat/D9E-5xbj0w0nUgieILwaQg" target="_blank">группу техподдержки Telegram</a>',
      searchForErrors: 'За каждые 30 найденных ошибок и неточностей в данных или в оценке мы даем 1 мес использования PRO-версии бесплатно. Детальная информация на <a href="{link}" target="_blank">сайте</a>.'
    },
    success: {
      default: 'Спасибо, мы с вами свяжемся как можно скорее',
    }
  },
  showMore: {
    title: {
      part1: 'Рассказать больше о квартире',
      part2: 'и сделать оценку более точной за 10 секунд'
    }
  },
  purposes: {
    wanna_sell: {
      label: 'Продать',
      labelBtnCta: 'Продать эту квартиру',
      labelModal: 'Оставьте заявку и получите от нас предложение по покупке этой квартиры за&nbsp;1&nbsp;день',
      subTitleModal: 'Срок продажи составит 10 дней или раньше если у вас есть необходимый пакет документов для продажи',
      labelModalButton: 'Продать'
    },
    wanna_buy: {
      label: 'Купить',
      labelBtnCta: 'Купить вместе с нами',
      labelModal: 'Купить вместе с нами',
      subTitleModal: '',
      labelModalButton: 'Купить'
    },
    get_apt_details: {
      label: 'Получить информацию',
      labelBtnCta: 'Получить информацию',
      labelModal: 'Оставьте заявку и получите подробности об этой квартире',
      subTitleModal: '',
      labelModalButton: 'Получить информацию'
    },
    collateral_evaluation: {
      label: 'Оценка залога',
      labelBtnCta: 'Кредит под залог квартиры',
      labelModal: 'Кредит под залог квартиры',
      subTitleModal: '',
      labelModalButton: 'Оставить заявку'
    },
    trade_in: {
      label: 'Обменять',
      labelBtnCta: 'Обменять эту&nbsp;квартиру',
      labelModal: 'Обменяйте квартиру за 7 дней',
      subTitleModal: '',
      labelModalButton: 'Обменять'
    },
    trade_up_map: {
      label: 'Подобрать новую квартиру'
    },
    wanna_view: {
      label: 'Записаться на просмотр'
    }
  },
  alert: {
    checkIfAllFieldsAreFilled: 'Проверьте, все ли поля заполнены'
  },
  canCalculateNo: 'Расчет данного типа недвижимости пока не поддерживается',
};

export default obj;
