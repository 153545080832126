const obj = {
  ai: {
    title: '視頻，培訓和說明',
    btn: '網絡研討會。事件和指示',
    list: {
      title: '在AI地圖上，它突出顯示了在莫斯科買賣公寓最有利可圖的交易：',
      options: [
        '折扣高達5％的公寓',
        '折扣高達9.99％的公寓',
        '享有10％折扣的公寓'
      ],
      optionsPublic: [
        '折扣高達6.99％的公寓',
        '折扣7％起的公寓',
      ]
    },
    content: `<p>如何使用此信息：</p>
                <ol class="list-ol">
                  <li>您可以以折扣價購買住房。</li>
                  <li>
                  您可以打折購買公寓并快速轉售。因此，您每年最多可以賺15％。
                  </li>
                  <li>
                    AI選擇的最佳交易可以投資於Realistic旗艦基金，並每年賺取高達30％的收益。細節
                    {link}
                  </li>
                </ol>`,
    linkMore: '這裡。'
  },
  chart: {
    title: '競爭對手圖表',
    subTitle: '有兩種在地圖上顯示對象列表的模式：',
    partnerSubTitle: 'Based on',
    modes: [
      '相對於當前對象的市場價格。當前對象的市場價格顯示為綠線；',
      '相對於每個對象的市場價格。'
    ],
    legend: {
      title: '圖表上的符號列表：',
      list: [
        '出售對象;',
        '當前對象；',
        '該對象高於市場價格；',
        '以市場價格為對象；',
        '低於市場價格的對象；',
        '系統計算的快速轉售期；',
        '當前選擇的快速轉售期。'
      ]
    },
    help: {
      title: '如何閱讀圖表'
    }
  },
  userAgreement: {
    content: `<div class="text-agreement">
                <p>
                  OOO “Giglob-Moskva”（以下簡稱“許可方”）將本協議發送給簽訂本協議的任何個人或法人實體（以下稱被許可人），完全無條件地同意本文檔中指定的所有條款。
                </p>
                <p>
                  該文件是公開要約（公開要約），公開要約的接受（接受條件）是根據協議規定執行的行動（俄羅斯聯邦民法典第437條第2款）。
                </p>
                <p>
                  如果被許可人不同意該協議的任何條款，則該協議未締結，並且被許可人無權使用知識產權對象。
                </p>
                <ol>
                  <li>
                    <h4>基本條款</h4>
                    <ol>
                      <li>
                        <strong>許可人</strong> – OOO “Giglob-Moskva”, 123112, Moskva, Presnenskaja naberezhnaja, 8號屋 1號樓, 24樓 客房245m 3室.
                      </li>
                      <li>
                        <strong>被許可人</strong> –根據俄羅斯聯邦現行法律和本協議的要求，有權為自己的利益使用本程序的任何個人或法人實體。
                      </li>
                      <li>
                        <strong>驗收</strong> -通過執行第3.1條中指定的行動，完全無條件地接受要約。協議。
                      </li>
                      <li>
                        <strong>內容</strong> -網站上所有可用的對象，包括設計元素，文本，圖形，插圖，視頻，計算機程序，數據庫和其他對象。
                      </li>
                      <li>
                        <strong>個人專區</strong> -網站的一部分，禁止公眾訪問，被許可人的訪問通過帳戶進行。
                      </li>
                      <li>
                        <strong>提供</strong> -本文檔發佈在Internet上：{link}
                      </li>
                      <li>
                        <strong>網站</strong> -在以下地址（包括子域）可以在Internet上獲得的自動化信息系統：{link}
                      </li>
                      <li>
                        <strong>帳戶</strong> -有關被許可方的一組信息數據，以數字格式記錄，是識別被許可方並提供對其個人數據，設置和內容的訪問所必需的。
                      </li>
                      <li>
                        <strong>技術支援</strong> -許可方在其為確保程序的功能而確定的限制和數量之內進行的活動，包括被許可人對程序使用的信息和諮詢支持。
                      </li>
                      <li>
                        <strong>執照類型</strong> -被許可方根據許可方價格表選擇的關稅計劃，該計劃已在Internet上的以下地址發布： {link}
                      </li>
                      <li>
                        <strong>API (Application Programming Interface)</strong> -通過使用許可方發布的公共方法，系統與第三方應用程序交互的界面。
                      </li>
                      <li>
                        <strong>程序</strong> -計算機程序“ Magic.Realiste”（作為一個整體及其組成部分），它是客觀呈現的一組數據和命令，包括許可方在指定計算機程序中包含的源文本，數據庫和視聽作品以及有關其使用的任何文檔。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>協議的主題</h4>
                    <ol>
                      <li>
                      該協議定義了在以下位置使用本網站的程序和條件： {link} 協議的主題包括網站的所有功能和服務，以及明確可用的任何新功能的開發或增加。
                      </li>
                      <li>
                        許可人保證他是第2.1條中指定的站點專有權的所有者。協議。本網站的所有專有權均歸許可人所有。
                      </li>
                      <li>
                        本協議在使用本程序之前或開始時立即締結，並且在被許可方合法使用的整個期限內，在其版權期限內有效，前提是被許可方正確遵守本協議的條款。
                      </li>
                      <li>
                        許可方授予被許可方使用程序的權利，不受俄羅斯聯邦現行法律，本協議和本協議規定的方式和條件的限制。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>版權和商標</h4>
                    <ol>
                      <li>
                        該程序是智力活動的結果，是版權的對象（計算機程序），受俄羅斯聯邦有關知識產權的立法和國際法準則的規範和保護。
                      </li>
                      <li>
                        本程序的算法及其源代碼（包括其部分）是許可方的商業秘密。違反本協議條款的任何使用或使用程序的行為均被視為違反許可方的權利，並且是剝奪用戶根據本協議授予的權利的充分理由。
                      </li>
                      <li>
                        許可方保證它具有將程序授予被許可方的所有必要權利範圍，包括程序的文檔。
                      </li>
                      <li>
                        侵犯版權的責任是根據俄羅斯聯邦的現行法律進行的。
                      </li>
                      <li>
                        本協議不授予被許可人使用許可方和/或其合作夥伴的商標和服務標誌的任何權利。
                      </li>
                      <li>
                        在任何情況下，被許可方都不得刪除或模糊本程序中指定的有關版權，商標權或專利的信息。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>軟件使用條款和限制</h4>
                    <ol>
                      <li>
                        根據本許可協議，被許可人被授予註冊權。
                      </li>
                      <li>
                        註冊是由被許可方通過創建並指定其帳戶獨立進行的。
                      </li>
                      <li>
                        如果不違反本許可協議和俄羅斯聯邦的法規，被許可人有權在本許可的框架內並根據所選的許可類型，將屬於他的數據放入帳戶中。
                      </li>
                      <li>
                        僅在俄羅斯聯邦版權法規定的情況下，被許可方才有權更改，添加或刪除程序文件。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>權利的轉讓（轉讓）</h4>
                    <ol>
                      <li>
                        除本協議確定的情況外，被許可人有權將本協議項下的權利和義務完全轉讓給另一位授權用戶。
                      </li>
                      <li>
                        權利和義務的轉讓（轉讓）僅在新被許可方完全同意且無條件同意本協議和本協議的所有條款和條件的情況下進行。
                      </li>
                      <li>
                        被許可方有義務向許可方提供有關新被許可方的完整數據，以便根據本協議向其重新註冊本程序。
                      </li>
                      <li>
                        本協議項下的權利轉讓（轉讓）不能間接或通過任何第三方進行。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>許可類型</h4>
                    <ol>
                      <li>
                        被許可人有權獨立選擇適當的許可類型，其列表發佈在Internet上：{link}
                      </li>
                      <li>
                        根據註冊時有效的條件，被許可人有權在有限的時間內使用本程序，而無需支付審查許可（試用版）的費用。
                      </li>
                      <li>
                        被許可人有權在其整個有效期內更改許可的類型，同時考慮到新許可的資費成本，購買的許可的有效期限將延長為已付費但未使用的當前許可的期限。
                      </li>
                      <li>
                        許可證有效期的開始是創建帳戶和/或帳戶的日期（註冊之日）。
                      </li>
                      <li>
                        如果相關許可證過期且被許可方未在15（十五）個日曆日內從6.1節中指定的列表中獲取新許可證。根據本協議，被許可方可以自行決定限制被許可方對程序的進一步使用。
                      </li>
                      <li>
                        許可人有權通過在Internet上發布新的許可列表，單方面更改許可的類型。 {link} 不遲於此類更改的生效日期之前的十四（14）天。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>程序使用順序</h4>
                    <ol>
                      <li>
                        <h5>註冊</h5>
                        <ol>
                          <li>
                            為了使用本程序，被許可方必須經過註冊程序，因此將為被許可方創建一個唯一的帳戶和帳戶。要添加授權用戶，被許可方必須使用本程序為他們獨立創建帳戶，並授予他們訪問帳戶的權限。一個帳戶中的授權用戶數取決於許可證的類型。
                          </li>
                          <li>
                            對於註冊，被許可方承諾提供有關他本人和授權用戶的準確和完整的信息，有關註冊表單中提出的問題，並保持此信息為最新。如果被許可人提供的信息不正確或許可方有理由相信其提供的信息不完整或不准確，則許可方有權自行決定阻止或刪除被許可方的帳戶，並禁止使用本程序。
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          登錄和密碼以訪問被許可人或授權用戶的帳戶。
                        </h5>
                        <ol>
                          <li>
                            註冊時，被許可方獨立選擇一個登錄名（被許可方或授權用戶的帳戶的唯一符號名）和用於訪問該帳戶的密碼。許可人有權禁止使用某些登錄名，並規定登錄名和密碼的要求（長度，允許的字符等）。
                          </li>
                          <li>
                            被許可人或授權用戶對自己選擇的密碼的安全性（拒絕猜測）負全部責任，並且還獨立確保其密碼的機密性。被許可方對在其帳戶下或使用本程序的程序內的所有行為/不作為（及其後果）承擔全部責任，包括在任何情況下（包括合同或協議）。 同時，被許可方在被許可方帳戶內或使用本程序進行的所有操作均被視為已執行，除非在許可方收到被許可方通知後，以本協議或任何其他方式規定的方式在被許可方帳戶下未經授權使用本程序的通知違反（懷疑違反）您的密碼的機密性。
                          </li>
                          <li>
                            如果有任何使用其帳戶未經授權訪問本程序的情況和/或任何違反（懷疑違反）其密碼機密性的情況，被許可人有義務立即將其告知許可人。出於安全原因，被許可方有義務在每次使用本程序的工作結束時，以其帳戶（“退出”按鈕）獨立進行安全關閉。許可方不對可能的數據丟失以及由於被許可方違反本協議本部分規定而可能導致的任何其他性質的後果負責。
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          刪除帳戶。在違反本協議條款的情況下，許可方有權阻止和刪除被許可方的帳戶，包括所有內容，而無需給出任何理由。從這一刻起，將無法恢復該帳戶，與其相關的任何信息以及使用該帳戶訪問程序。
                        </h5>
                      </li>
                      <li>
                        <h5>
                          限制對API的訪問。所有方法總共每秒不能超過1個API請求。在通過API進行可疑活動的情況下，許可方有權阻止被許可人帳戶訪問API。
                        </h5>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>使用限制</h4>
                    <ol>
                      <li>
                        被許可方和/或授權用戶無權採取可能導致以下行為的行動：a）中斷許可方設備和網絡的運行； b）破壞本程序或限制其他用戶使用本程序的能力； c）未經授權訪問程序以及許可方的信息，計算和網絡資源； d）造成或威脅損害第三方，包括發布信息和網絡資源鏈接，其內容與俄羅斯聯邦現行法律相抵觸。
                      </li>
                      <li>
                        沒有向被許可人提供修改程序的機會和權利。
                      </li>
                      <li>
                        被許可方獨立確保滿足使用本程序和訪問Internet的技術要求的設備的可用性。
                      </li>
                      <li>
                        被許可方保證他對與程序使用相關的所有數據，計算機程序或服務擁有所有必要的權利，並且此類行為不會侵犯第三方的權利。
                      </li>
                      <li>
                        除非用戶已獲得許可方的許可，否則被許可方無權以本協議中指定的方式以外的任何其他方式使用本程序，以及復制，出售和轉售本程序或對其進行訪問。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>技術支援</h4>
                    <ol>
                      <li>
                        許可方向被許可方提供技術支持，包括與程序功能有關的問題，所支持的操作系統，郵件和其他系統的標準配置上的操作功能，其方式和技術文檔中指定的條款。
                      </li>
                      <li>
                        被許可方有權聯繫許可方的技術支持服務，而無需支付額外費用。
                      </li>
                      <li>
                        為了提供技術支持，許可人有權要求被許可人提供有關帳戶數據，設備技術特徵的信息以及提供技術支持所需的其他信息。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>有限擔保和責任</h4>
                    <ol>
                      <li>
                        本程序按“原樣”提供，並且許可方不保證其所有功能都可以滿足被許可方的期望，並且可以將其用於特定目的。
                      </li>
                      <li>
                        許可人在使用本程序的過程中不發起或控制被許可人對任何信息的放置，不影響其內容和完整性，並且在發布此信息時不知道並且不知道其是否違反了第三方的受法律保護的權益，國際條約以及俄羅斯聯邦的現行法律。
                      </li>
                      <li>
                        對於與使用或無法使用本程序有關的任何損害，任何收入損失，利潤，信息或儲蓄，包括在被許可人事先通知此類損害的可能性或第三方提出的任何索賠中，許可方不對被許可人承擔責任。 ...
                      </li>
                      <li>
                        如果在使用本程序時發現錯誤，則許可方將採取措施盡快糾正它們。雙方同意，由於本程序與第三方開發人員的其他計算機程序，被許可方計算機的操作系統和硬件資源緊密交互，因此無法建立消除錯誤的確切時間定義，並且消除問題的可操作性和時間並不完全取決於許可方。
                      </li>
                      <li>
                        如果被許可人採取本協議規定禁止的行動，則許可人有權採取措施識別和防止這些違規行為，而無需向被許可人提供任何理由或任何通知。
                      </li>
                      <li>
                        如果違反本協議的條款，被許可人應根據俄羅斯聯邦法律承擔責任。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>個人數據的處理和使用條款</h4>
                    <ol>
                      <li>
                        接受本協議的條款，被許可人根據2006年7月27日第152-FZ號“關於個人數據”的聯邦法律，以及2012年11月1日俄羅斯聯邦政府第1119號決議“關於批准在個人數據信息系統”，在特定時刻生效的其他監管法律行為-出於自己的自由意志並出於他的利益自由採取行動，表示他同意：提供其個人數據，包括姓氏，姓名，監護人，電子郵件地址，聯繫電話電話號碼，出生日期，地區，城市，組織，由許可方處理的位置。
                      </li>
                      <li>
                        許可人承諾採取一切必要措施保護上述個人數據免遭未經授權的訪問或洩露。
                      </li>
                      <li>
                        許可人針對個人數據的處理採取以下行動：收集，記錄，系統化，累積，存儲，澄清（更新，更改），提取，使用，去個性化，阻止，刪除，銷毀。
                      </li>
                      <li>
                        該許可在許可協議的整個期限內以及被許可人對程序的使用均有效。被許可人理解並同意，如果撤回此同意書，他將被剝奪使用本程序的部分或全部服務的機會。
                      </li>
                      <li>
                        被許可人可以通過與許可方的技術支持聯繫，以撤回對處理個人數據的同意。 {email}.
                      </li>
                      <li>
                        被許可人同意通過電子郵件接收其在註冊時指明的地址，有關許可人及其合作夥伴的產品和服務的廣告和信息消息。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>協議的有效性，修改和終止</h4>
                    <ol>
                      <li>
                        對於不受本協議約束的所有問題，雙方均以俄羅斯聯邦現行法律為指導。
                      </li>
                      <li>
                        許可人有權通過將修改後的文本發佈到Internet上來單方面更改本協議的條款。 {link}
                      </li>
                      <li>
                        如果被許可人在使用程序時違反了本協議的條款，則許可方有權單方面終止本協議。
                      </li>
                      <li>
                        任何一方以任何理由終止本協議後，被許可方和授權用戶必須完全停止使用本程序。
                      </li>
                      <li>
                        如果主管法院宣布本協議的任何條款無效，則本協議在本協議的其餘部分繼續有效。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>爭議解決</h4>
                    <ol>
                      <li>
                        審前解決爭端和協議的索賠程序是強制性的。
                      </li>
                      <li>
                        要求書由雙方以掛號信的形式寄出，並在回執中註明已送達該方所在地的地址。
                      </li>
                      <li>
                        雙方以不同於第13.2條規定的方式發送索賠書。不允許協議。
                      </li>
                      <li>
                        自索賠人收到索賠書之日起，其考慮期限為30個工作日。
                      </li>
                      <li>
                        本協議下的爭議將依法在法院解決。
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>其他規定</h4>
                    <ol>
                      <li>
                        禁止被許可人使用本程序將未經信息接收者同意（未要求）的商業，廣告或其他性質的批量電子消息發送給電子郵件或電話會議組（“垃圾郵件”）。未經收件人同意發送信息不被視為“垃圾郵件”，並且可能會取消訂閱。
                      </li>
                      <li>
                        被許可方必須僅出於合法目的和合法方式，並考慮到俄羅斯聯邦，歐洲聯盟和美國的法律，才能使用該程序。
                      </li>
                      <li>
                        被許可人直接負責由被許可人創建和維護的帳戶內容（程序副本）。許可人不對被許可人發布和/或分發的信息的內容進行初步控制，但是，當此類信息的放置和分發違反法律時，許可人有權在不發出警告的情況下封鎖或刪除相應的帳戶和數據。
                      </li>
                      <li>
                        許可人不向被許可人提供通信服務，沒有為他組織訪問信息和電信網絡（包括Internet）的信息系統的可能性，也沒有進行接收，處理，存儲，傳輸，傳遞電信消息的活動。
                      </li>
                    </ol>v
                  </li>
                  <li>
                    <h4>許可人聯繫信息</h4>
                    <p>
                      通過電子郵件接受有關本協議和技術支持條款的查詢 {email}.
                    </p>
                  </li>
                </ol>
                <h4>執行器</h4>
                <p>
                  合法地址: 123112, Moskva, Presnenskaja naberezhnaja, 8號屋 1號樓, 24樓 客房245m 3室 <br />
                  支票賬戶 No 40702810002370001790 v AO “Al'fa-bank”, Moskva <br />
                  代理賬戶 No 3010181020000000593 <br />
                  BIK: 044525593 <br />
                  TIN: 7736282685 <br />
                  CEO - Galtsev Alexey Aleksandrovich
                </p>
              </div>`,
  },
  selectCity: {
    title: '選擇城市',
    note: '您可以通過轉到帶有過濾器的部分來更改所選城市。',
    btnTitle: '選擇'
  },
  externalUrl: {
    title: '您正在使用Magic的免費版本',
    btnPrimaryTitle: '轉到另一個站點',
    mainText: '數據每7天更新一次。如果該鏈接下沒有任何對象，則本周有90％的可能性被出售。',
    note: {
      proText: '僅查看最新數據',
      linkText: '升級到PRO計劃'
    }
  },
  companyAbout: {
    title: 'Realiste',
    subTitle: '關於公司',
    partnerSubTitle: {
      default: 'Based on',
      domclick: 'Based on'
    },
    htmlMainDescription: `
      <p>Realiste 是一家在線評估和購買房地產的技術公司。</p>
      <p>Realiste技術幫助在10秒內了解房地產的市場價值，考慮所有因素（位置，位置的負面和正面因素，房地產參數，交易歷史，競爭和需求）的購買，銷售和抵押房地產。</p>
    `,
    media: {
      title: '關於 Realiste 的媒體',
      list: {
        rbc: '人工智能如何幫助在房地產上賺錢#Realiste',
        pierwyKanal: '公寓是 2021 年最熱門的產品。接受第一頻道採訪時說',
        hazeta: '用頭腦無法理解俄羅斯：神經網絡在莫斯科環路外找到了有前景的房地產#Realiste',
        rt: '俄羅斯人向遠程工作的過渡如何影響該國房地產的價值#Realiste',
        viedomosti: '投資者利用優惠抵押貸款購買公寓出租',
        rbc_2: '在第二波 COVID #Realiste 中房地產會發生什麼',
        theVillage: '人們不相信盧布，但相信米#Realiste',
        rg: '由於“偏遠”而命名的公寓價格將上漲的區域#Realiste',
        forbes: '在交通不便的地區，二手房價格將繼續上漲#Realiste'
      }
    },
    htmlSidebarDescription: `
      <dl class="sidebar-list">
        <dt>網站</dt>
        <dd>
          <a href="{link}" target="_blank">{link}</a>
        </dd>
        <dt>股份公司 Realiste</dt>
        <dt>PSRN</dt>
        <dd>1207700420311</dd>
        <dt>TIN</dt>
        <dd>9703021240</dd>
        <dt></dt>
        <dd>Unit OT 16-36 , Level 16, Central Park Offices, Dubai International Financial Centre, Dubai, UAE</dd>
      </dl>
    `,
  },
  jkRating: {
    rating: '評分',
    distinctive_features: '顯著特點',
    photo: '照片'
  },
  propertyValuation: {
    title: '物業估值 Realiste',
    htmlDescription: `
      <p>Realiste 不是估值公司，Realiste 估值是根據用戶輸入的數據通過算法計算得出的近似市場價值。</p>
      <p>它應該僅用於信息目的。</p>
    `
  },
  contactPhone: {
    title: {
      default: 'Sign up',
      verifySmsCode: 'Enter sms-code',
      tradeUp: 'Sign up',
      register: 'Register'
    },
    subTitle: {
      tradeUp: 'We have evaluated your apartment and selected options for moving.',
      ru: 'Экспресс-отчет со сравнительным анализом: 1500руб - бесплатно за первые 3 отчета',
      en: 'Express report with comparative analysis: 15$ - free of charge for the first 3 reports',
      gb: 'Express report with comparative analysis: 15£ - free of charge for the first 3 reports'
    },
    phoneLabel: '<strong>The cost of an apartment and competitors</strong> - after a free phone confirmation by SMS',
    noSpam: 'We do not send spam. We would love to hear your feedback.',
    fields: {
      privacyPolicy: {
        label: '我同意處理 ',
        link: '個人資料'
      }
    },
    btn: {
      sendRequest: '繼續訂單計算',
      startCalc: '我們已經為您開始計算',
      backToTheForm: '返回表格'
    },
  },
  tradeUpHowItWorks: {
    htmlContent: `
      <p>使用 Realiste，您可以搬到另一間公寓或將其換成新建築。為此，您無需為您的公寓尋找買家。</p>
      <p>Realiste 只為您選擇具有公平市場價格的優惠報價，並組織公寓交換，包括您搬到新公寓、獲得抵押貸款。</p>
    `,
    steps: {
      title: '它是如何工作的：',
      list: [
        '你指明你的公寓',
        'Realiste 為您計算最有利的搬遷方案的最佳組合，包括抵押貸款的選擇',
        '您選擇您喜歡的選項並向 Realiste 經理提出請求',
        '經理會組織一切，您無需浪費數月的努力和期望',
        'Realiste 將購買您的公寓並安排您一次購買一套新公寓。 15 天，您將擁有一套新公寓'
      ]
    }
  },
  wannaSell: {
    subTitle: [
      '從我們這裡獲得購買價格為',
      '前'
    ],
    messages: {
      success: '申請已成功發送 <br> 我們會盡快與您聯繫',
      error: '無法發送請求。再試一次'
    }
  },
  disclaimer: {
    title: '免責聲明',
    htmlContent: `
      <p>所提供的信息在任何情況下都不構成個人投資建議，包括在外部符合其對個人投資建議的監管法律要求的情況下。所提供的信息與個人投資建議的任何相似性純屬巧合。任何這些金融工具或交易都可能不適合您的投資狀況。提交的消息中提到的操作和（或）金融工具在任何情況下都不能保證您可能指望的收入，前提是所提供的信息用於做出投資決策。</p>
      <p>公司不對投資者在所提供信息中提及的金融工具進行交易或投資時可能造成的損失負責。在所有情況下，投資者的工作是確定金融工具或交易是否適合投資目標、投資期限和風險承受能力。</p>
      <p>投資股份的價值可增可減，過去的投資結果不決定未來收益，投資收益無保障。 JSC Realiste TIN 9703021240 作為封閉式共同投資基金的投資份額所有者提供有關房地產對象、經濟指標以及任何分析和信息數據的信息。假設包含在 JSC Realiste TIN 9703021240 的營銷和其他材料中的所有信息，包括 CEREUIF（封閉式房地產單位投資基金）單位的未來收益率。為了更準確的評估，在做出購買投資股的決定之前，我們強烈建議您獨立研究投資對像或聯繫財務顧問。所有財務計算和模型僅是預測，JSC Realiste TIN 9703021240 對其準確性不承擔任何責任。</p>
      <p>在準備所呈現的材料時，所使用的信息來自公司專家認為值得信賴的來源。儘管本公司的專家對材料的準備作出了謹慎的反應，但本公司不對此處包含的信息的準確性和完整性作出任何保證。本網站頁面上的信息不構成達成協議的要約/要約。</p>
    `
  },
  externalUrlClaim: {
    fillOutClaimPurchaseType: {
      title: '購買 / 有趣',
      options: {
        as_investment: '作為投資',
        on_mortgage: '在抵押貸款',
        no_mortgage: '沒有抵押貸款',
        for_information: '有趣的'
      }
    },
    verifyPhone: {
      title: '我們要求您輸入您的電話號碼並說明填寫表格的目的，以確保您不是機器人。'
    },
    formFields: {
      comment: 'Comment'
    }
  },
  modalPromo: {
    slide1: {
      title: '大折扣公寓',
      description: 'PRO 賬戶可享受 7% 至 12% 折扣的投資公寓'
    },
    slide2: {
      title: '2X 更新率',
      description: '從市場更新信息的速度 - 不超過 1 小時 + 根據報價的新鮮度過濾'
    },
    slide3: {
      title: '維修預測和估算',
      description: '維修估算和維修後價格預測的計算'
    },
    slide4: {
      title: '所有國家，所有城市',
      description: '即將訪問 20 個城市、5 個國家和更多國家'
    },
    getRealistPRO: '獲得現實主義 PRO',
    moreAboutPRO: '更多關於 PRO',
    form: {
      title: '獲得現實主義 PRO',
      description: '留下訪問系統所有功能的請求',
      btn: '提交您的申請',
      privacyPolicyText: '點擊按鈕，即表示您同意我們的',
      privacyPolicyLink: '政治',
      warningText: '抱歉，發生錯誤，請稍後再試'
    },
    success: {
      title: '已發送申請',
      description: '我們會盡快與您聯繫',
      btn: '關'
    }
  }
};

export default obj;
