import { investmentObjectWithCompetitors } from '@/models/investmentObjectWithCompetitors';
import { investmentObjectPotentialWithEstimate } from '@/models/investmentObjectWithEstimate';
import errorHandler from '@/lib/error_handler';
import { apolloClient } from './apollo';

export const investmentObjectPotential = async (id, variantId, routeName) => {
  const q = routeName == 'ProfitForecast' ? investmentObjectPotentialWithEstimate() : investmentObjectWithCompetitors();

  const x = await apolloClient.query({
    query: q,
    variables: {
      id,
      variantId
    },
    fetchPolicy: 'no-cache',
  })
    .then((data, loading) => {
      if (!loading) {
        return data.data.investmentObject.potential;
      }
    })
    .catch((error) => {
      errorHandler.handleError(error, { id, variantId });
      return error;
    });

  return x;
};
