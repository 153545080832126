const detectIframe = () => {
  if (window.top != window.self) {
    document.querySelector('body').classList.add('iframe-style');

    if (window.location.hostname == 'afi-development.realiste.ai') {
      const locationOrigin = 'https://afi-park.art3d.ru';

      const sendMessage = () => {
        window.top.postMessage(JSON.stringify({ action: 'disable_mouse_event' }), locationOrigin);
      };

      'mousewheel wheel DOMMouseScroll'.split(' ').forEach(el => window.addEventListener(el, sendMessage, false));
    }
  }

  return window.top != window.self;
};

export default detectIframe;
