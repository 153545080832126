<template>
  <transition name="fade">
    <div class="panel-wrap"
         v-bind:class="panelClass"
         v-show="isPanelVisibleInner"
         v-bind:style="{ 'z-index': panelZIndex }">
      <div class="panel">
        <a class="back-wrap static"
           v-on:click.prevent="closeInfoPanel">
          <span class="back icon-in-circle">
            <svg-icon name="chevron-left" class="text-white"></svg-icon>
          </span>
          <span class="back-text text ml-2">{{ $t('btn.back') }}</span>
        </a>
        <div class="panel-body static-text">
          <h2 class="title" v-if="title">{{ title }}</h2>
          <p v-if="description">{{ description }}</p>
          <div v-if="htmlDescription"
               v-html="htmlDescription"></div>
          <div v-if="customComponent">
            <component v-bind:is="customComponent"
                       v-bind:coordinate="coordinate"></component>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UserAgreement from '@/components/common/info_modal/UserAgreement.vue';
import AIHowItWorks from '@/components/common/info_modal/AIHowItWorks.vue';
import TradeUpHowItWorks from '@/components/common/info_modal/TradeUpHowItWorks.vue';
import JKRating from '@/components/common/info_modal/JKRating.vue';
import CompanyAbout from '@/components/common/info_modal/CompanyAbout.vue';
import ChartDescription from '@/components/common/info_modal/ChartDescription.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'InfoPanel',
  props: {
    code: String,
    category: String,
    subCategory: String,
    title: String,
    customComponent: String,
    objectId: [Number, String],
    zIndex: [Number, String],
    htmlDescription: String,
    coordinate: [Object, String]
  },
  components: {
    UserAgreement,
    AIHowItWorks,
    JKRating,
    ChartDescription,
    TradeUpHowItWorks,
    CompanyAbout
  },
  computed: {
    ...mapGetters({
      isPanelVisible: 'modal/isPanelVisible'
    }),
    isPanelVisibleInner() {
      return this.isPanelVisible;
    },
    panelClass() {
      return {
        visible: this.isPanelVisibleInner
      };
    },
    panelZIndex() {
      return this.zIndex || 999;
    },
    description() {
      if (this.subCategory) {
        return this.code ? this.$i18n.messages[this.$i18n.locale].costs[this.category][this.subCategory][this.code].description : '';
      }
      return this.code ? this.$i18n.messages[this.$i18n.locale].costs[this.category][this.code].description : '';
    }
  },
  methods: {
    ...mapMutations({
      changePanelStatus: 'modal/changePanelStatus'
    }),
    showInfoPanel() {
      this.changePanelStatus(true);
      document.querySelector('body').classList.add('info-panel-visible');
    },
    closeInfoPanel() {
      this.changePanelStatus(false);
      document.querySelector('body').classList.remove('info-panel-visible');
    }
  }
};
</script>
