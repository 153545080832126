<template>
  <div class="repair-quality"
       v-if="repairRating">
    <small class="text-muted mr-2">{{ $t('property.repairQuality') }}</small>
    <vue-stars bind:active-color="`#ffdd00`"
               v-bind:inactive-color="`#999999`"
               v-bind:hover-color="`#dddd00`"
               v-bind:max="10"
               v-bind:readonly="true"
               v-bind:char="`★`"
               v-bind:value=repairRating
               v-bind:class="`size-sm ${ratingStarsClass}`"
               v-bind:name="ratingName" />
  </div>
</template>

<script>
import { VueStars } from 'vue-stars';

export default {
  components: {
    VueStars
  },
  props: ['repairRating', 'ratingStarsClass', 'ratingName'],
};
</script>

<style lang="scss" scoped>
.repair-quality {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
