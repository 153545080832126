const tradeUp = {
  object: '{count} 平坦的 | {count} 公寓',
  form: {
    title: {
      default: 'A new way to buy a home',
      lenstroytrest: 'A new way to buy a home'
    },
    subtitle: 'Estimate the value of your home in seconds. </br>See the price difference between your current home and your future home.',
    ownApartment: {
      title: '輸入您的公寓詳細信息'
    },
    exchangeApartment: {
      title: '您想搬到哪裡：'
    },
    searchDistrictsWithin: {
      title: '在我的區域內搜索'
    }
  },
  options: {
    spacious_apartment: '房屋更寬敞',
    new_apartment: '新建築',
    modest_apartment: '住房更普通'
  },
  optionsTitle: 'Apartment characteristics',
  showObjectsBtn: '在地圖上顯示選項',
  shortInfo: {
    yourApartment: '當前對象',
    payExtra: '支付額外費用',
    mortgage: 'Mortgage from',
    getPriceDifference: '搬家後，您將收到',
    sendRequest: 'Trade Up'
  },
  countObjects: {
    default: 'Found {count}',
    more250: 'Found more than {count}',
  },
  actionModal: {
    title: '1分钟拿到offer',
    btnText: '获得报价'
  },
  propertySlide: {
    analysis: '分析'
  },
  btn: {
    howItWorks: 'Trade-Up',
    sendRequest: 'Trade Up',
    wannaView: 'Wanna view'
  },
  slider: {
    title: 'Exchange options'
  },
  modal: {
    wannaView: {
      title: 'Wanna view',
      btnText: 'Send'
    }
  }
};

export default tradeUp;
