<script>
import routingTo from '@/mixins/routingTo';
import noBreakingSpacesMixin from '@/mixins/noBreakingSpacesMixin';
import formatTagsMixin from '@/mixins/formatTagsMixin';
import RepairSwitcher from '@/components/common/RepairSwitcher.vue';
import { investmentObjectPotential } from '@/lib/investmentObjectPotential';
import { mapGetters } from 'vuex';

const REPAIR_TYPE_DEFAULT_VALUE = 'no_repair';

export default {
  mixins: [routingTo, noBreakingSpacesMixin, formatTagsMixin],
  props: {
    id: [Number, String],
    tradeUpObjectId: [Number, String],
  },
  components: {
    RepairSwitcher
  },
  data() {
    return {
      objectId: this.id,
      repair_type: REPAIR_TYPE_DEFAULT_VALUE,
      initialData: null,
      repairSwitcher: [],
    };
  },
  computed: {
    propertyValuationResultPage() {
      return this.$route.name === 'ObjectEvaluationResult' || this.$route.name === 'TradeUpObjectEvaluationResult';
    },
    tradeUpPage() {
      return this.$route.name === 'TradeUpCard' || this.$route.name === 'TradeUpObjectEvaluationResult';
    },
    isTradeUpMap() {
      return this.$route.name === 'TradeUpMap';
    },
    ...mapGetters({
      isPro: 'isPro',
    }),
    routeQuery() {
      return {
        ...this.$route.query,
        repairType: this.repair_type
      };
    }
  },
  methods: {
    getObjectData(data, isSubscription) {
      if (!data) return;
      if (data.hasOwnProperty('investmentObject')) return data.investmentObject;

      if (data.hasOwnProperty('investmentObjectCalcClaimResult')) {
        if (isSubscription) {
          return data.investmentObjectCalcClaimDone.investmentObject;
        } else {
          if (data.investmentObjectCalcClaimResult != null && data.investmentObjectCalcClaimResult.hasOwnProperty('claimResult')) {
            return {
              ...data.investmentObjectCalcClaimResult.claimResult,
              parentTypename: data.investmentObjectCalcClaimResult.__typename
            };
          }
          return data.investmentObjectCalcClaimResult;
        }
      }
    },
    openPotentialObject(potentialId, routeName) {
      investmentObjectPotential(this.objectId, potentialId, routeName).then((res) => {
        if (res) {
          this.object = Object.assign({}, res);
          if (this.$route.name == 'ProfitForecast') {
            this.updatePrice(this.object);
          }
        }
      });
    },
    updateQuery(obj) {
      const query = { ...obj };

      this.$router.replace({ query }).catch(() => {});
    },
    switchRepairType(el) {
      this.repair_type = el.val;

      this.updateQuery(this.routeQuery);

      if (this.objectId != el.id) {
        this.openPotentialObject(el.id, this.$route.name);
      } else {
        this.object = this.initialData;
      }
    },
    getData(data) {
      this.initialData = this.getObjectData(data).hasOwnProperty('potentials') && this.getObjectData(data).potentials.length != 0 && this.$route.meta.hasPotentials ? this.getObjectData(data).potentials[0] : this.getObjectData(data);

      if (this.initialData && this.initialData.potentialVariants != null) {
        const hasRepairTypeChanges = this.initialData.potentialVariants.some(el => el.changes.hasOwnProperty('repair_type'));

        this.repairSwitcher = hasRepairTypeChanges ? [
          {
            id: this.object.id,
            changes: {
              repair_type: 'no_repair'
            }
          },
          ...this.object.potentialVariants] : [];
      }

      if (this.repairSwitcher.length == 0 && this.$route.name == 'ProfitForecast') {
        this.updatePrice(this.initialData);
      }

      if (this.repair_type == 'no_repair' || !this.repair_type) {
        this.object = this.initialData;
        if (this.$route.name == 'ProfitForecast') this.updatePrice(this.object);
      } else {
        const repairType = this.repairSwitcher.filter(el => el.changes.repair_type == this.repair_type);
        const currentId = repairType.length > 0 ? repairType[0].id : null;
        if (currentId) this.openPotentialObject(currentId, this.$route.name);
      }
    },
    updatePrice(obj) {
      this.valuePriceBuy = obj.pricePurchase;
      this.valuePriceSell = obj.priceMarket;
      this.valueProjectDuration = obj.duration.duration;
      this.valueImprovementsAmount = obj.improvementAmount;
    },
    updateRepairType() {
      if (this.$route.path.includes('object/')) {
        const repairType = this.$route.query.repairType || this.repair_type || REPAIR_TYPE_DEFAULT_VALUE;

        this.repair_type = repairType;

        this.$router.replace({
          query: this.routeQuery
        }).catch(() => {});
      }
    }
  },
  mounted() {
    this.repair_type = this.$route.query.repairType;

    this.updateRepairType();
  },
  watch: {
    isPro(newVal, oldVal) {
      if (newVal) this.updateRepairType();
    }
  },
};
</script>
