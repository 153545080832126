const obj = {
  title: 'Deals',
  fundSize: 'Размер фонда',
  amountDividents: 'Размер дивидендов',
  currentProfit: 'Текущая доходность',
  paymentDate: 'Дата выплат',
  activeDeals: 'Активные сделки',
  completedDeals: 'Завершенные сделки',
  commonStatistic: {
    boughtTotal: 'Куплено всего',
    totalDeals: 'Сделок итого',
    operationProfit: 'Операционная прибыль',
    netProfitPaidToInvestors: 'Выплаченная чистая прибыль инвесторам',
    notPaidNetProfitToInvestors: 'Насчитанная, но не выплаченная чистая прибыль инвесторам',
    soldTotal: 'Продано на',
    IRR: 'Годовая доходность инвесторов ( IRR )',
    portfolioAnnualReturn: 'Годовая доходность портфеля',
    countProjects: 'Всего проектов',
    countSuccesfulProjects: 'Успешных проектов',
    countUnprofitableProjects: 'Убыточных сделок',
    countActiveProjects: 'Активные проекты',
    averageProjectDuration: 'Средний срок проектов',
    portfolioVolalityRatio: 'Коэффициент волатильности портфеля',
    countHighYieldDeals: 'Процент сделок с доходностью выше безрисковой доходности',
    modifiedSharpeRatio: 'Модифицированный Коэффициент Шарпа (Modified Sharpe ratio)',
    averageRiskFreeReturnOnDeposits: 'Средняя безрисковая доходность депозитов / облигаций с 2018 года',
  },
  apartmentLegalCheck: {
    mainData: {
      title: 'Основные выводы',
      A1_1: 'В отношении квартиры существенные риски',
      A1_2: 'Проведение сделки с Квартирой',
    },
    primaryRisks: {
      title: 'Первичные риски со слов продавца',
      A2_1: 'Неузаконенная перепланировка',
      A2_2: 'Собственники дети есть',
      A2_3: 'Прописанные дети есть',
      A2_4: 'Прописанные, которые не выпишутся до сделки',
      A2_5: 'Полная цена в ДКП?',
      A2_6: 'Площадь в документах и в анализе расходится?'
    },
    objData: {
      title: 'Данные о квартире',
      A3_1: 'В соответствии с расширенной выпиской из Единого государственного реестра недвижимости о переходе прав на объект недвижимости (далее – Выписка из ЕГРН), собственник владеет недвижимостью с',
      A3_2: 'Продавец на дату составления Отчета является единоличным собственником Квартиры?',
      A3_3: 'Согласно Выписке из домовой книги по состоянию на момент составления отчета в Квартире есть лица имеющих постоянную или временную регистрацию?',
      A3_4: 'Недееспособные лица, инвалиды, ветераны, защищаемые государством лица прописанные в квартире есть?',
      A3_5: 'Ограничения (обременения) прав на Квартиру?',
      A3_6: 'Проведена ли сверка плана кваритры с фактом ( через 3D модель, выезд ) ? Есть ли расхождения в планировке квартиры?',
      A3_7: 'Существует ли существенная ( более 5 тыс. рублей ) задолженность по коммунальным услугам, кварплате?',
    },
    lossRightsRisks: {
      title: 'Риски потери права',
      A4_1: 'Нахождение Продавца в Едином федеральном реестре сведений о банкротстве',
      A4_2: 'Нахождение Продавца в Базе данных по розыску должников Федеральной службы судебных приставов',
      A4_3: 'Участие Продавца в судебных производствах арбитражных судов',
      A4_4: 'Участие Продавца в судебных производствах судов общей юрисдикции',
      A4_5: 'В отношении Продавца выявлена задолженность перед третьими лицами?',
      A4_6: 'Есть данные или опасения по продавцу, что он недееспособен или совершенная им сделка может быть признана ничтожной?',
      A4_7: 'Является ли продавец учредителем в Организациях, в отношении которых выявлены задолженности перед третьими лицами?',
      A4_8: 'Продавец относится к числу лиц, причастных к экстремизму.',
      A4_9: 'Квартира является адресом массовой регистрации юридических лиц, согласно сведениям, предоставленным Федеральной налоговой службой РФ.'
    }
  },
  landLegalCheck: {
    mainData: {
      title: 'Основные выводы',
      A1_1: 'В отношении участка существенные риски',
      A1_2: 'Проведение сделки с участком',
    },
    primaryRisks: {
      title: 'Первичные риски со слов продавца и при осмотре',
      A2_1: 'Подъезд к участку',
      A2_2: 'Адрес не совпал',
      A2_3: 'Критичная неровность участка',
      A2_4: 'Не возможно построить дом',
      A2_5: 'НЕ полная цена в ДКП?',
      A2_6: 'Площадь в документах и в анализе расходится?',
      A2_7: 'Проблемы с документами'
    },
    objData: {
      title: 'Данные об участке',
      A3_1: 'Сведения о регистрации прав и существующих (зарегистрированных) ограничениях:',
      A3_2: 'Кадастровая стоимость',
      A3_3: 'Возникновение налога с учетом кадастровй стоимости и цены продажи',
      A3_4: 'Категория земель',
      A3_5: 'Разрешенное использование:',
      A3_6: 'Сведения о документах территориального планирования:',
      A3_7: 'СВЕДЕНИЯ О ДОКУМЕНТАХ ГРАДОСТРОИТЕЛЬНОГО ЗОНИРОВАНИЯ (ПЗЗ):',
      A3_8: 'СВЕДЕНИЯ О НАХОЖДЕНИИ УЧАСТКА В ЗОНАХ С ОСОБЫМИ УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ ТЕРРИТОРИИ (Место допустимого размещения объектов капитального строительства):',
    },
    lossRightsRisks: {
      title: 'Риски потери права',
      A4_1: 'Нахождение Продавца в Едином федеральном реестре сведений о банкротстве',
      A4_2: 'Нахождение Продавца в Базе данных Федеральной службы судебных приставов',
      A4_3: 'Нахождение Продавца в Базе данных по розыску должников Федеральной службы судебных приставов',
      A4_4: 'Участие Продавца в судебных производствах арбитражных судов',
      A4_5: 'Участие Продавца в судебных производствах судов общей юрисдикции',
      A4_6: 'Участие Продавца в судебных производствах мировых судов',
      A4_7: 'В отношении Продавца выявлена задолженность перед третьими лицами? ',
      A4_8: 'Есть данные или опасения по продавцу, что он недееспособен или совершенная им сделка может быть признана недействительной?',
      A4_9: 'Является ли продавец учредителем в Организациях, в отношении которых выявлены задолженности перед третьими лицами?',
      A4_10: 'Продавец относится к числу лиц, причастных к экстремизму.',
      A4_11: 'У Продавца имеются блокировки счетов со стороны Федеральной налоговой службы',
      A4_12: 'Паспорт Продавца является недействительным согласно сведений о проверке действительности паспорта',
    }
  },
  apartmentProjectSteps: {
    B1: 'Оформление покупки - приемка квартиры',
    B2: 'Выставлен в рекламу',
    B3: 'Начало стейнджинга / ремонта',
    B4: 'Первые заинтересанты - покупатели',
    B5: 'Закончен стейджинг',
    B6: 'Оцифрованы все документы, сделана оценка недвижимости',
    B7: 'Получено предложение о покупке',
    B8: 'Назначена дата сделки',
    B9: 'Выход на сделку'
  },
  landProjectSteps: {
    B1: 'Сделка прошла',
    B2: 'Выставлен в рекламу',
    B3: 'Старт стейджинга',
    B4: 'Сбор документов / подготовка межевого дела',
    B5: 'Закончен стейджинг',
    B6: 'Оцифрованы все документы, сделана оценка недвижимости, проведен раздел',
    B7: 'Получено предложение о покупке',
    B8: 'Назначена дата сделки',
    B9: 'Выход на сделку Участка № 1',
    B10: 'Выход на сделку Участка № 2',
    B11: 'Выход на сделку Участка № 3',
    B12: 'Выход на сделку Участка № 4',
    B13: 'Выход на сделку Участка № 5',
    B14: 'Выход на сделку Участка № 6',
    B15: 'Выход на сделку Участка № 7',
    B16: 'Выход на сделку Участка № 8',
    B17: 'Выход на сделку Участка № 9',
    B18: 'Выход на сделку Участка № 10',
  },
  datePurchase: 'Дата покупки',
  dateSale: 'Дата продажи',
  annualReturnRate: 'Годовая доходность',
  cadNumber: 'Кадастровый номер',
  aiRecommendations: 'Рекомендация ИИ',
  targetReturn: 'Целевая доходность',
  netProfitPortfolio: 'Полученная прибыль',
  pricePortfolio: 'Продано за',
  dealTerm: 'Срок сделки',
  netProfit: 'Чистая прибыль',
  dealsInformation: {
    title: 'Информация по сделкам'
  },
  commonInformation: {
    title: 'Общая информация'
  },
  btnPurchaseTitle: {
    sellUrgently: 'Продавать срочно',
    sell: 'Продавать',
    sellLeisurely: 'Продавать не спеша',
    keep: 'Держать'
  },
  dateSaleDescription: 'Предполагаемая дата продажи, основанная на аналитике продаж, динамике спроса и данных.',
  shareBtn: 'Расскажи о своих сделках с Реалист',
  noData: 'Данные в процессе разработки',
  noDealsData: 'Данных по сделкам пока нет. Перейдите по <a href="{link}" target="_blank">ссылке</a> чтобы ознакомиться с существующими фондами',
  noDealsDetailData: 'Подробных данных пока нет',
  realtorType: {
    funds: 'Фонды',
    flippers: 'Компании',
    persons: 'Частные лица'
  },
  additionalInvestments: {
    title: 'Дополнительные вложения',
    purchasePrice: 'Цена покупки',
    sellingPrice: 'Цена продажи'
  },
  dealForecast: {
    title: 'Ожидаемый прогноз'
  },
  demand: {
    title: 'Спрос',
    nWeek: 'неделя.',
    countAdViews: 'Просмотров рекламы',
    countCalls: 'Звонков',
    countAdRotated: 'Показов',
    total: 'ВСЕГО'
  },
  legalReview: {
    title: 'Юридическая проверка',
    status: {
      notIdentified: 'не выявлены',
      recommended: 'рекомендуется'
    }
  },
  projectStages: {
    title: 'Этапы проекта',
    estimatedFinishDate: 'План финиша',
    actualFinishDate: 'Факт финиша',
    countCompletedStages: 'Завершено этапов'
  },
  apartment: 'Квартира',
  landPlot: 'Земельный участок',
  fund: {
    moreInformation: ['Официальная информация о фонде доступна по ', 'ссылке']
  },
  all: 'Все'
};

export default obj;
