const obj = {
  rating_name: 'Рейтинг',
  room_category_name: 'Количество комнат',
  ads_count: 'Количество объявлений',
  ads_percent: 'Процент объявлений',
  median_price: 'Средняя цена лота',
  median_sqm_price: {
    sqm: 'Средняя цена кв.м.',
    sqft: 'Средняя цена кв.фут'
  },
  heat: 'Индекс спроса',
  rating_stats_title: 'Данные по рейтингам',
  rooms_stats_title: 'Данные по количеству комнат',
  exposition_days_median: 'Средний срок экспозиции',
  choose_layer: 'Выбрать слой',
  poi_moscow: 'Локации Москвы',
  poi_st_petersburg: 'Локации Санкт-Петербурга',
  category_name: 'Категория',
  factor_score: {
    title: 'Влияние факторов на стоимость недвижимости',
    good: 'позитивное',
    bad: 'негативное'
  },
  btn: {
    apply: 'Применить изменения',
  },
  changeFor: 'Изменение за',
  month: 'мес.',
  per: 'за',
  for: 'на',
  growth: 'История',
  forecast: 'Прогноз',
  year: 'год',
  years: 'года',
  rent_roi_median: 'Доходность аренды',
  gov_sold_count_median: 'Продаж по госреестру за год',
  chartModalTitle: 'Использованные данные',
  btnShowModalChart: 'Показать данные',
  rent_price_median: 'Средняя цена аренды',
  betaModalLine1: 'На сайте представлена бета-версия инструмента «Индекс».',
  betaModalLine2: 'Информация, предоставляемая при помощи инструмента, не является индивидуальной инвестиционной рекомендацией, и информационные данные, предоставляемые с ее помощью, могут не соответствовать Вашим инвестиционным целям (ожиданиям). Realisteне несет ответственности за возможные убытки в случае совершения инвестиционных операций с недвижимостью с использованием инструмента «Индекс».',
  betaModalLine3: 'Инфографика в виде размеченной карты является визуализацией инвестиционной привлекательности объектов недвижимости. Указанный показатель не гарантирует доходности в будущем, результаты инвестирования и не определяют доходы от инвестиций в будущем.',
  betaModalLine4: 'Использование  инструмента «Индекс» не гарантирует совершения инвестором  сделки с недвижимостью по заранее определенной цене.',
  betaModalLine5: 'Инфографика инструмента «Индекс» не является рекламой.',
  betaModalLine6: 'Числовые показатели рассчитаны при помощи инструмента «Индекс» на основании публичных данных об объектах недвижимости в заданных территориальных границах, за достоверность которых ответственность несут третьи лица, в том числе юридические лица, разместившие информацию на своих официальных сайтах.',
  betaModalLine7: 'Realisteне несет ответственности за возможные убытки инвестора в случае принятия инвестором  решения по совершению сделки с недвижимостью.',
  showBlock: 'Показать больше...',
  hideBlock: 'Скрыть',
  bookingBtn: 'Выбрать квартиру',
  bookingFormTitle: 'Пожалуйста, введите номер телефона для бронирования',
  bookingFormTitle2: 'Пожалуйста, введите номер телефона',
  bookingFormTitleSuccess: 'Спасибо! В ближайшее время мы с Вами свяжемся',
  viewApartments: 'Посмотреть квартиры',
  bookingBtnSend: 'Подтвердить',
  notAnInvestmentRecommendation1: 'Не является инвестиционной рекомендацией, данные предоставлены в качестве информации',
  notAnInvestmentRecommendation2: 'Пользовательское соглашение',
  refundableDeposit: 'Возвратный Депозит',
  bookingBtnForm: 'Забронировать',
  bookingBtnFormUnit: 'Получить каталог',
  freeCancellation1: 'Бесплатная отмена брони.',
  freeCancellation2: 'Депозит вернется на ваш счет',
  chooseApartment: 'Выберите квартиру из списка',
  bedrooms: 'к',
  textSubmitFormUnit: 'Каталог объектов доступен по запросу',
  unitTypes: 'Тип',
  state: {
    available: 'Доступен',
    bookingInProgress: 'Бронируется',
    booked: 'Забронирован',
  },
  bookable: 'Бронируется',
  btnLayout: 'Планировка',
  btnVideo: 'Видео',
  btnPhoto: 'Фото',
  rentRoi: 'ROI аренда/год',
  buyRoi: 'ROI продажа/3 года',
  priceName: 'Цена',
  filter: {
    states: 'Статус',
    price: 'Цена',
    bedrooms: 'Комнаты',
    floorNumber: 'Этаж',
    areaChargeable: 'Площадь',
    clear: 'Очистить',
    apply: 'Применить',
  },
  bedroomsFilter: {
    studio: 'Студия',
    b1_0: '1 спальня',
    b1_5: '1.5 спальни',
    b2_0: '2 спальни',
    b2_5: '2.5 спальни',
    b3_0: '3 спальни',
    b3_5: '3.5 спальни',
    b4_0: '4 спальни',
    b4_5: '5 спальни',
    more: '5+ спален',
  },
  filterUnitNot: 'Ничего не найдено.',
  filterUnitNot2: 'Попробуйте изменить\nили убрать все фильтры.',
  btnResetFilter: 'Очистить все фильтры',
  commission: 'комиссия',
  guarantee: 'Обязательства Realiste',
  from: 'От',
  before: 'До',
  commissionHintBroker: 'Комиссия брокера или риелтора\nпосле закрытия сделки.\nРазмер комиссии может быть изменен\n по решению застройщика.',
  commissionHintGuarantee: 'Обязательства Realiste после закрытия сделки.\nРазмер обязательств в редких случаях может быть изменен до заключения сделки.',
  commissionLink: 'Подробнее',
  commissionTitleBroker: 'Шаги к получению комиссии застройщика.',
  commissionHtmlBroker: '<p>1. Выбрать и забронировать квартиру, используя свой номер телефона.</p><p>2. Дождаться звонка менеджера. Он поможет подписать договор и пройти все шаги по сделке.</p><p>3. Получить комиссию на свой расчетный счет в течение 5 дней после получения оплаты застройщиком.</p>',
  objectChartCaption: 'Рост стоимости',
  commissionTitleGuarantee: 'Обязательства Realiste',
  commissionHtmlGuarantee: '<p>Прогнозы Realiste основаны на расчетах искусственного интеллекта (AI). Realiste доверяет им и в случае, если рост цены приобретенной инвестором квартиры с Realiste по прошествии срока (12 или 24 или 36 мес.) окажется меньше прогнозного, компания обязуется возместить разницу в пределах суммы возможной компенсации. В случае, если рост цены окажется больше прогнозного, инвестор получает доход выше ожидаемого.</p><p>Размер возможной компенсации (% от стоимости приобретаемой квартиры) указан в карточке объекта.</p><p>О подробностях узнавайте у экспертов компании: ',
  tooltipForecast: 'Прогноз роста',
  tooltipForecastHint: 'Отношение среднегодовой цены за\nпоследний год к среднегодовой\nцене года прогноза',
  updateIosMsg: 'Чтобы просмотреть график, обновите IOS до последней версии',
  updateIosClose: 'Закрыть',
  unitsStockUpdatedAt: 'Обновлено',
  median: 'Медиана',
  toMedian: 'к медиане',
  tooltipForecastMedian: 'Изменение цены в % рассчитывается по формуле: разница цен между текущими ценами к средней прогнозной цене за 2023 года, 2024 года и 2025 год (берется медианная цена за будущий год, так как цены могут быть выше и ниже в течение года в разные месяца ввиду сезонности). Методологию прогноза роста цены на будущее узнавайте у Экспертов Реалиста.',
  depositDisclaimerHeader: 'О депозите',
  depositDisclaimerText: 'Оплата указанной суммы подтверждает намерение забронировать данный лот и начало процедуры бронирования лота у застройщика. Платёж 10 000 рублей возвращается Вам на карту в течение 3 дней вне зависимости от принятого на стороне застройщика решения.',
  comissionDisclaimer: '*Размер комиссии может быть изменен по решению застройщика',
  successfulSendingCrypto: 'Заявка на бронирование<br> отправлена. Мы свяжемся с<br> вами в ближайшее время.',
  successfulSendingCryptoBtn: 'Продолжить',
  calculatorName: 'Калькулятор доходности',
  bookingBtnSoldOut: 'Запросить информацию',
  successfulRequestInformation: 'Заявка отправлена.<br>Мы свяжемся с вами в ближайшее время.',
  impact: 'влияние',
  upcomingEventsTitle: 'Будущие события',
  aboutTitle: 'О проекте',
  upcomingEventFuture: 'БУДУЩЕЕ',
  upcomingEventPast: 'ПРОШЕДШЕЕ',
  upcomingEventQuarter: 'КВ',
};

export default obj;
