const obj = {
  title: 'Filters',
  mobile: {
    title: 'Filter list'
  },
  fields: {
    totalArea: {
      label: 'Area',
      placeholderFrom: 'from',
      placeholderTo: 'to'
    },
    projectProfitYearRate: {
      label: 'Profit year rate from'
    },
    discountFromPriceMarketMin: {
      label: 'Discount from the market price'
    },
    price: {
      label_both: 'Price',
      label_pay_extra: 'Pay extra',
      label_cash: 'Get cash',
      placeholderFrom: 'from',
      placeholderTo: 'to',
      errorText: 'Min price must be less than max price',
    },
    constructionYear: {
      label: 'Construction/completion year',
      placeholderFrom: 'from',
      placeholderTo: 'to'
    },
    ageDays: {
      label: 'On market'
    },
    payExtraGetCash: {
      label: 'Pay extra / get cash',
      options: {
        pay_extra: 'Pay extra',
        both: 'Both',
        cash: 'Get cash',
      }
    },
    repairRating: {
      label: 'Repair level not less',
    },
    roomsCount: {
      label: 'Rooms count',
      placeholderFrom: 'from',
      placeholderTo: 'to'
    },
    buildingRating: {
      label: 'Building rating from',
    },
    isHasCommentChecks: {
      label: 'Property is checked'
    }
  },
  btn: {
    apply: 'Apply',
    reset: 'Reset'
  },
  message: {
    not_found: 'There are no objects corresponding to filters on this location. Please try to search elsewhere'
  },
  showMore: {
    expand: 'More filters',
    collapse: 'Hide filters'
  }
};

export default obj;
