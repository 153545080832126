import Vue from 'vue';

function validate(binding) {
  if (typeof binding.value !== 'function') {
    console.warn(
      `[Vue-click-outside:] provided expression ${binding.expression} is not a function.`
    );
    return false;
  }

  return true;
}

function isPopup(popupItem, elements) {
  if (!popupItem || !elements) return false;

  for (let i = 0, len = elements.length; i < len; i++) {
    try {
      if (popupItem.contains(elements[i])) {
        return true;
      }
      if (elements[i].contains(popupItem)) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return false;
}

function isServer(vNode) {
  return (
    typeof vNode.componentInstance !== 'undefined'
    && vNode.componentInstance.$isServer
  );
}

// Since IE11 doesn't support e.composedPath()
function composedPath(el) {
  const path = [];
  while (el) {
    path.push(el);
    if (el.tagName === 'HTML') {
      path.push(document);
      path.push(window);
      return path;
    }
    el = el.parentNode;
  }
}

Vue.directive('click-outside', {
  bind: function (el, binding, vNode) {
    if (!validate(binding)) return;

    // Define Handler and cache it on the element
    function handler(e) {
      if (!vNode.context) return;

      // some components may have related popup item, on which we shall prevent the click outside event handler.
      const elements = e.path
        || (e.composedPath && e.composedPath())
        || composedPath(e.target);
      elements && elements.length > 0 && elements.unshift(e.target);

      if (el.contains(e.target) || isPopup(vNode.context.popupItem, elements)) { return; }

      el.__vueClickOutside__.callback(e);
    }

    // add Event Listeners
    el.__vueClickOutside__ = {
      handler: handler,
      callback: binding.value,
      modifiers: Object.keys(binding.modifiers)
    };
    if (!isServer(vNode)) {
      if (el.__vueClickOutside__.modifiers.length) {
        for (let index = 0; index < el.__vueClickOutside__.modifiers.length; index++) {
          const eventName = el.__vueClickOutside__.modifiers[index];
          document.addEventListener(eventName, handler);
        }
      } else {
        document.addEventListener('click', handler);
      }
    }
  },

  update: function (el, binding) {
    if (validate(binding)) el.__vueClickOutside__.callback = binding.value;
  },

  unbind: function (el, binding, vNode) {
    // Remove Event Listeners
    if (!isServer(vNode)) {
      if (el.__vueClickOutside__.modifiers.length) {
        for (let index = 0; index < el.__vueClickOutside__.modifiers.length; index++) {
          const eventName = el.__vueClickOutside__.modifiers[index];
          document.removeEventListener(
            eventName,
            el.__vueClickOutside__.handler
          );
        }
      } else {
        document.removeEventListener('click', el.__vueClickOutside__.handler);
      }
    }
    delete el.__vueClickOutside__;
  }
});
