export default Object.freeze({
  MOBILE_RESOLUTION: 767.98,
  COOKIES: {
    BUBBLE_ABOUT_IS_VISIBLE: 'bubble_about_is_visible',
    BUBBLE_PURCHASE_OFFER_IS_VISIBLE: 'bubble_purchase_offer_is_visible'
  },
  FORM: {
    SUBMIT_STATUS: {
      ERROR: 'ERROR',
      PENDING: 'PENDING',
      OK: 'OK'
    },
    AVAILABLE_ROOMS_VARIANTS: [
      { label: '1', code: 1 },
      { label: '2', code: 2 },
      { label: '3', code: 3 },
      { label: '4', code: 4 },
      { label: '5+', code: 5 }
    ],
    PROFIT_YEAR_RATE_INITIAL_VAL: 20,
    DISCOUNT_FROM_PRICE_MARKET_INITIAL_VAL: 3,
    DISCOUNT_FROM_PRICE_MARKET_INITIAL_VAL_PRO: 5,
    DISCOUNT_FROM_PRICE_MARKET_MAX_VAL: 25,
    DISCOUNT_FROM_PRICE_MARKET_MAX_VAL_TRADE_UP: 7,
  },
  CHART: {
    BAR_WIDTH: 18,
    CHART_GAP: 4,
    STROKE_WIDTH: 2,
    CHART_HEIGHT: 153,
    CHART_AXIS_Y_WIDTH: 100,
    CHART_GAP_BETWEEN_AXIS_Y_AND_COLUMNS: 15,
    COLOR_GREY: '#7f7f7f',
    COLOR_SUCCESS: '#26C435',
    HEIGHT_BASE: 20,
    MOBILE_RESOLUTION: 767.98,
    CIRCLE_RAD: 8,
    TEXT_INDENT: 10,
    SWITCHER_STATE: {
      DEFAULT: 'isPriceMarketLine',
      CHECKED: 'isPriceMarketCurve'
    },
    TEXT_LINE_HEIGHT: 5
  },
  FORECAST: {
    PRICE_BUY_RANGE_BORDER: 2000000,
    PRICE_SELL_RANGE_BORDER: 2000000,
    PROJECT_DURATION_INTERVAL: 0.5,
    PROJECT_DURATION_MIN: 1,
    PROJECT_DURATION_MAX: 12
  },
  SHARE_OPTIONS: {
    DEFAULT: {
      IMG_COMMON: 'https://magic.realiste.ai/og-img-share-default-2x.jpg',
      IMG_VK: 'https://magic.realiste.ai/og-img-share-vk-default-2x.jpg'
    },
    FORM: {
      IMG_COMMON: 'https://magic.realiste.ai/og-img-share-form-2x.jpg',
      IMG_VK: 'https://magic.realiste.ai/og-img-share-vk-form-2x.jpg'
    },
    IMG_COMMON_WIDTH: 1054,
    IMG_VK_WIDTH: 1020,
    IMG_COMMON_HEIGHT: 548,
    IMG_VK_HEIGHT: 454
  },
  TRACK_RECORD_URL: `https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}/values/%D0%A2%D1%80%D0%B5%D0%BA%20%D1%80%D0%B5%D0%BA%D0%BE%D1%80%D0%B4%20Realiste?majorDimension=ROWS&valueRenderOption=UNFORMATTED_VALUE&dateTimeRenderOption=FORMATTED_STRING&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`,
  TRACK_RECORD_URL_RENDER_FORMULA: `https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}/values/%D0%A2%D1%80%D0%B5%D0%BA%20%D1%80%D0%B5%D0%BA%D0%BE%D1%80%D0%B4%20Realiste?majorDimension=ROWS&valueRenderOption=FORMULA&dateTimeRenderOption=FORMATTED_STRING&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`,
  SPREADSHEETS_URL: `https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}?fields=sheets.properties.title&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`,
  TRACK_RECORD_SHEET_NAME_REALTORS: '*ПЕРЕВОДЫ Риелторы',
  TRACK_RECORD_SHEET_NAME_SHEET_TRANSLATIONS: '*ПЕРЕВОДЫ интерфейс',
  BASEPATH: process.env.VUE_APP_DOMAIN,
  PROURL: `${process.env.VUE_APP_REALISTE_URL}/promagic`,
  PROENTERPLANURL: `${process.env.VUE_APP_REALISTE_URL}/service_brokeridge`,
  MAGICURL: `${process.env.VUE_APP_DOMAIN}`,
  SECONDHOME: `${process.env.VUE_APP_REALISTE_URL}/vtoroy_dom`,
  PROJARKA: `${process.env.VUE_APP_REALISTE_URL}/rus/ai-projarka`,
  REPAIR_TYPE: ['killed', 'cosmetics', 'euro', 'super_euro'],
  REPAIR_TYPE_2: ['no', 'cosmetic', 'euro', 'design'],
  TRADE_UP_OPTIONS: ['spacious_apartment', 'new_apartment', 'modest_apartment'],
  TRADE_UP_OPTIONS_USD: ['spacious_apartment', 'similar', 'modest_apartment'],
  PAY_EXTRA_GET_CASH: ['pay_extra', 'both', 'cash'],
  FILL_OUT_CLAIM_PURCHASE_TYPE: ['as_investment', 'on_mortgage', 'no_mortgage', 'for_information'],
  SUPPORTED_LANGUAGES: ['ru', 'en', 'zh'],
  POI_OBJECTS_LIMIT: 100,
  POI_OBJECTS_ORDER: 'factorScoreAbsDesc',
  DEAL_SALE_BADGE_VALUES: ['продан', 'аванс', 'задаток', 'купили'],
  DEAL_BUY_BADGE_VALUES: ['в продаже', 'покупаем'],
  loaderBg: {
    propertyEstimate: {
      ru: [require('@/assets/images/property-estimate-blured-lg-ru.png'), require('@/assets/images/property-estimate-blured-sm-ru.png')],
      en: [require('@/assets/images/property-estimate-blured-lg-us.png'), require('@/assets/images/property-estimate-blured-sm-us.png')]
    },
    tradeUp: {
      ru: [require('@/assets/images/trade-up-blured-lg-ru.png'), require('@/assets/images/trade-up-blured-sm-ru.png')],
      en: [require('@/assets/images/trade-up-blured-lg-us.png'), require('@/assets/images/trade-up-blured-sm-us.png')]
    }
  },
  MEDIA_ABOUT_REALISTE_URL: 'https://dzen.ru/news/search?issue_tld=ru&text=Realiste',
  PROPERTY_DEFAULT_VALUE: {
    price: null,
    encumbrance: false,
    objectType: '',
    buildingPropertyType: 'condo',
    roomsCount: null,
    square: null,
    squareLiving: null,
    squareKitchen: null,
    repairRating: 0,
    city: '',
    address: '',
    buildingBuildYear: null,
    redevelopment: false,
    viewFromWindows: [],
    roomsLayout: null,
    ceilingHeight: 2.65,
    floorNumber: null,
    buildingFloorsCount: null,
    balconiesCount: 0,
    loggiasCount: 0,
    bathroomShared: 0,
    bathroomCombined: 0,
    parkingType: null,
    buildingMaterialType: null,
    additionalOptions: [],
    elevatorFreightsCount: 0,
    elevatorPassengersCount: 0,
    contactPhone: '',
    lat: null,
    lng: null
  },
  LOADER_DELAY: 4000,
  GLOBAL_GET_PARAMS: ['Iid', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'parentInfo'],
  OBJECT_GET_PARAMS: ['tradeUpSelectedOption', '_et', 'repairType'],
  UTMS: ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content'],
  BUILDING_RATINGS: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'E+', 'E', 'E-', 'FFF'],
  CALC_CLAIM_TIMER: 10,
  CALC_CLAIM_TIMER_MAX_VALUE: 60 * 4,
  ONBOARDING: [
    {
      key: 'propertyValuation',
      image: {
        375: require('@/assets/images/onboarding/property-valuation-375.png'),
        320: require('@/assets/images/onboarding/property-valuation-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/property-valuation-375@2x.png'),
        320: require('@/assets/images/onboarding/property-valuation-320@2x.png')
      },
    },
    {
      key: 'map',
      image: {
        375: require('@/assets/images/onboarding/map-375.png'),
        320: require('@/assets/images/onboarding/map-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/map-375@2x.png'),
        320: require('@/assets/images/onboarding/map-320@2x.png')
      },
    },
    {
      key: 'poi',
      image: {
        375: require('@/assets/images/onboarding/poi-375.png'),
        320: require('@/assets/images/onboarding/poi-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/poi-375@2x.png'),
        320: require('@/assets/images/onboarding/poi-320@2x.png')
      },
    },
    {
      key: 'competitors',
      image: {
        375: require('@/assets/images/onboarding/competitors-375.png'),
        320: require('@/assets/images/onboarding/competitors-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/competitors-375@2x.png'),
        320: require('@/assets/images/onboarding/competitors-320@2x.png')
      },
    },
    {
      key: 'tradeUp',
      image: {
        375: require('@/assets/images/onboarding/trade-up-375.png'),
        320: require('@/assets/images/onboarding/trade-up-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/trade-up-375@2x.png'),
        320: require('@/assets/images/onboarding/trade-up-320@2x.png')
      },
    },
    {
      key: 'investment',
      image: {
        375: require('@/assets/images/onboarding/investments-375.png'),
        320: require('@/assets/images/onboarding/investments-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/investments-375@2x.png'),
        320: require('@/assets/images/onboarding/investments-320@2x.png')
      },
    },
    {
      key: 'mapAnalytics',
      image: {
        375: require('@/assets/images/onboarding/analytics-375.png'),
        320: require('@/assets/images/onboarding/analytics-320.png')
      },
      image_2x: {
        375: require('@/assets/images/onboarding/analytics-375@2x.png'),
        320: require('@/assets/images/onboarding/analytics-320@2x.png')
      },
    }
  ],
  REALISTE_LOGO: {
    ru: {
      black: 'realiste-logo-black-ru.svg',
      white: 'realiste-logo-white-ru.svg',
      light: 'realiste-logo-white-color-icon-ru.svg',
      dark: 'realiste-logo-black-color-icon-ru.svg'
    },
    en: {
      black: 'realiste-logo-black-en.svg',
      white: 'realiste-logo-white-en.svg',
      light: 'realiste-logo-white-color-icon-en.svg',
      dark: 'realiste-logo-black-color-icon-en.svg'
    }
  },
  FILTERS: {
    COMMENT_CHECKS_OPTIONS: [
      {
        value: false,
        label: 'Нет'
      },
      {
        value: true,
        label: 'Да'
      }
    ]
  }
});
