import costs from '@/langs/forecast/ru';
import form from '@/langs/form_fields/ru';
import modal from '@/langs/modal/ru';
import filters from '@/langs/filters/ru';
import deals from '@/langs/deals/ru';
import propertyTable from '@/langs/property_table/ru';
import analytics from '@/langs/analytics/ru';
import tradeUp from '@/langs/trade_up/ru';
import competitors from '@/langs/competitors/ru';
import funds from '@/langs/funds/ru';
import poi from '@/langs/poi/ru';
import feedback from '@/langs/feedback/ru';
import privacyPolicy from '@/langs/privacy_policy/ru';
import rent from '@/langs/rent/ru';
import favorites from '@/langs/favorites/ru';

const obj = {
  timePeriod: {
    year: '{count} год | {count} года | {count} лет',
    yearNoCount: 'год',
    month: '{count} месяц | {count} месяца | {count} месяцев',
    monthNoCount: 'месяц',
    day: '{count} день | {count} дня | {count} дней',
    dayWithoutVal: 'день | дня | дней',
    monthShort: 'мес.',
    weekShort: 'нед.',
    minShort: 'мин'
  },
  amount: {
    thousandShort: 'тыс',
    millionShort: 'млн'
  },
  units: {
    meter: 'м'
  },
  btn: {
    education: 'Обучение',
    mediaAboutRealiste: 'Сми о Реалист',
    '30PerAnnum': '30% годовых',
    proLink: 'Пассивные инвестиции',
    analysisError: 'Неверная оценка?',
    invest: 'Оценить свою квартиру за 10 секунд',
    investRequest: '{count}, если продать за 3 дня и инвестировать с нами под&nbsp;15% годовых',
    telegramSubscribe: 'Подписаться на канал',
    back: 'Назад',
    reset: 'Сбросить',
    backToList: 'К списку',
    openTheMap: 'Карта',
    realisteLink: 'Перейти на страницу Фонда',
    cianLink: 'Посмотреть на стороннем сайте',
    sell: 'Продать за 7 дней',
    complaintReceived: 'Поступила жалоба',
    getMoreFeatures: 'Детальная аналитика доступна клиентам Реалист',
    slideCompetitorsMap: 'Показать конкурентов на карте',
    showClosestCompetitors: 'Только прямые аналоги',
    showAllCompetitors: 'Все конкуренты',
    onlyClosestCompetitors: 'Только прямые аналоги',
    howItWasCalculated: 'Как считали',
    getOffer: 'Получить предложение о покупке',
    competitorsList: 'Список',
    more: 'Еще',
    areaInfrastructure: 'Инфраструктура района',
    analyticsAndForecast: 'Статистика и&nbsp;прогноз'
  },
  property: {
    RUB: '₽',
    USD: '$',
    numberRoomsShort: '-к кв.',
    sqm: 'м<sup>2</sup>',
    sqft: 'фут<sup>2</sup>',
    floor: 'этаж',
    discount: {
      textBefore: 'на',
      label: {
        undervalued: 'дешевле рын. цены',
        overvalued: 'дороже рын. цены'
      },
      badgeOptions: {
        fairPrice: 'В рынке',
        overValued: 'Выше рынка',
        goodPrice: 'Выгодная цена',
        greatPrice: 'Отличная цена',
        bestPrice: 'Топ 0.1%'
      }
    },
    discountText: 'Выгода',
    profitText: 'Годовых',
    applicationSent: 'Заявка отправлена',
    discountShortV: 'Дисконт',
    externalUrl: {
      pro: 'Перейти на другой сайт',
      default: 'Записаться на просмотр',
      unpublished: 'Посмотреть полный анализ со ссылками на объявления',
      defaultDetailed: 'Получить больше информации'
    },
    actualDate: {
      default: ['Рассчитано', ''],
      notPro: ['Данные обновляются бесплатно раз в 7 дней. Для обновления раз в день', 'перейдите на PRO'],
    },
    repairQuality: 'Ремонт',
    priceMarketDiscount: 'Ниже рын. цены',
    priceMarketDiscountResultsPage: 'Дисконт при продаже за 2-10 дней',
    netProfit: 'Прибыль',
    projectProfitYearRate: 'Годовая доходность проекта',
    projectProfitYearRateNote: ', если перепродать за',
    duration: 'Срок проекта',
    priceDetail: 'Цена предложения',
    priceMarket: 'Цена через 3 мес.',
    pricePurchase: 'Рекомендованная цена покупки',
    improvementAmount: 'Доп. вложения для продажи за',
    profitPageLink: 'Расчеты и смета',
    competitorsPageLink: 'Сравнить с конкурентами',
    price: 'Цена',
    square: 'Площадь',
    pricePer: {
      sqm: 'За м<sup>2</sup>',
      sqft: 'За фут<sup>2</sup>',
    },
    discountMoreThanPriceMarket: 'Выше рын. цены',
    potentialBenefit: 'Возможная выгода при перепродаже',
    statedPrice: 'Заявленная цена',
    marketPrice: 'Рыночная цена',
    marketPriceResultsPage: 'Цена при продаже за {count}',
    investInRenovation: 'Нужно вложить чтобы продать за {count}',
    unitLabel: {
      month: '{count} мес.',
      day: '{count} день | {count} дня | {count} дней',
    },
    buildingRating: 'рейтинг',
    noiseLevel: 'Уровень шума',
    repairType: {
      killed: 'без ремонта',
      cosmetics: 'косметика',
      euro: 'евро',
      super_euro: 'дизайн'
    },
    repairType2: {
      no: 'Без ремонта',
      cosmetic: 'Косметика',
      euro: 'Евро',
      design: 'Дизайн'
    },
    repairTypeChange: {
      label: 'Показать расчет',
      noRepair: 'без ремонта',
      renovated: 'с ремонтом {repair}'
    },
    sold: 'Продано',
    not_sold: 'Сняли, не продали',
    unlisted: 'Сдано',
    demand: 'Спрос',
    betaValuationAlert: 'Оценка в данной локации находится на стадии улучшения и, вероятно, весьма неточна. Вы можете помочь нам сделать сервис лучше, отправив обратную связь по этой кнопке:',
    valuationAlert: {
      default: 'Оценка произведена алгоритмом Shiva AI автоматически. В данном районе Shiva AI точно оценивает цену при продаже за 3 мес у 95 квартир из 100. Если вы считаете оценку неадекватной, пожалуйста, сообщите нам. Мы проведем дополнительное исследование и сообщим вам о результатах.',
    },
    seelerContactsMessage: 'Оставьте номер телефона, чтобы получить больше информации',
    exposition: {
      default: 'Дней на рынке',
      unpublished: 'Сдано за {count}',
      competitorsMapObject: '{count} на рынке',
      sold: 'Продано за {count}'
    },
    metaInfo: {
      transactionAt: 'Дата продажи',
      transactionNumber: 'DLD транзакция'
    },
    countExpositionDays: 'Срок экспозиции',
    tooltipTitle: 'Информация',
    priceShownObviousAd: 'Цена через 12 мес.',
    apartment: 'Квартира',
    building: 'Дом',
    flatInfoFloor: 'Этаж',
    noData: 'Нет данных',
    salePriceForPeriod: {
      title: {
        default: 'Цена продажи',
        tradeUpCard: 'Стоимость квартиры'
      },
      modalTitle: 'Цена продажи',
      period: {
        '12months': 'за 12 месяцев',
        '3months': 'за 3 месяца',
        dealTomorrow: 'сделка завтра'
      }
    },
    verifiedObject: {
      btnText: 'Отчет по квартире',
      badgeText: 'Проверено Реалист'
    }
  },
  district: {
    details: [
      {
        key: 'similarApartmentCount',
        text: ['<h3>{count}</h3> похожая квартира | <h3>{count}</h3> похожих квартиры | <h3>{count}</h3> похожих квартир', 'продается рядом | продаются рядом | продаются рядом'],
      },
      {
        key: 'soldApartmentsLastMonthsCount',
        text: ['<h3>{count}</h3> похожая квартира | <h3>{count}</h3> похожих квартиры | <h3>{count}</h3> похожих квартир', 'была продана за последние 3 месяца | было продано за последние 3 месяца | было продано за последние 3 месяца'],
      },
      {
        key: 'expositionDays',
        text: ['За <h3>{count}</h3> день | За <h3>{count}</h3> дня | За <h3>{count}</h3> дней', 'в среднем продается квартира в этом районе'],
      },
      {
        key: 'priceAreaMedian',
        text: ['currency', 'средняя цена продажи похожих квартир в этом районе'],
      },
      {
        key: 'discountForFastSell',
        text: ['<h3>{count}</h3>%', ' - необходимый дисконт для быстрой продажи']
      }
    ],
    showDetailsLabel: {
      text: 'Подробности – на нашей',
      textLink: 'карте индекса цен'
    }
  },
  chart: {
    legend: {
      priceMarket: ['Рыночная цена', 'для текущей квартиры'],
      priceMarketForEachObject: 'Рыночная цена по каждому объекту'
    },
  },
  loader: {
    text: 'Подождите, пожалуйста, данные загружаются',
    textCalcClaimTop: '<p class="mb-1">Подождите, пожалуйста, данные загружаются.</p>В районах с высокой конкуренцией расчет может занимать до 30 секунд',
    textCalcClaimTradeUpTop: '<p class="mb-1">Убираем все невыгодные предложения с экрана.</p>В районах с высокой конкуренцией расчет может занимать до 30 секунд',
    textCalcClaim: [
      'Выделяю локацию',
      'Анализирую факторы, влияющие на&nbsp;район',
      'Собираю данные о заключенных сделках за&nbsp;3&nbsp;месяца',
      'Определяю рыночную цену на каждую квартиру в&nbsp;районе',
      'Оцениваю текущую квартиру',
      'Почти готово',
      'Похоже, что в вашем районе продается много квартир. Анализ займет чуть больше времени - подождите'
    ],
    textCalcClaimTradeUp: [
      'Выделяю локацию',
      'Оцениваю текущую квартиру',
      'Определяю рыночную цену на каждую квартиру',
      'Отбираю предложения, цена которых рыночная или ниже',
      'Убираю невыгодные варианты с карты',
      'Анализирую какие плохие и хорошие факторы влияют на район',
      'Насчитываю разницу и возможные ипотечные платежи',
      'Почти готово',
      'Похоже, что в вашем районе продается много квартир. Анализ займет чуть больше времени - подождите'
    ],
    textShort: 'Подождите ...',
    counter: {
      default: 'С начала расчета прошло {count} сек.',
      moreThan60sec: 'К сожалению, мы не смогли оперативно рассчитать стоимость по вашим параметрам. Пожалуйста, попробуйте позже или напишите нам в чат.'
    },
    isReady: 'Готово'
  },
  costs,
  profitPage: {
    title: 'Прогноз доходности',
    pricePurchase: 'Цена покупки',
    priceSelling: 'Цена продажи',
    projectDuration: 'Срок проекта',
    improvements: 'Улучшения',
    projectProfitYearRate: 'Годовая доходность',
    netProfit: 'Чистая прибыль',
  },
  competitorsPage: {
    title: 'Лучшие конкуренты'
  },
  disclaimer: {
    info: 'Не является инвестиционной рекомендацией, данные предоставлены в качестве',
    linkText: 'информации'
  },
  userAgreement: {
    title: 'ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ С ПОЛЬЗОВАТЕЛЕМ САЙТА',
    linkTitle: 'Пользовательское соглашение'
  },
  bubble: {
    bubbleAbout: {
      title: 'Перейти на'
    }
  },
  share: {
    default: {
      title: 'Будь Реалистом! Получай выгоду от быстрой продажи и покупки квартиры.',
      description: 'Реалист (Realiste) - это компания, где основой для принятий решений является искусственный интеллект'
    },
    form: {
      title: 'AI прожарка квартир от Реалист ( Realiste )',
      description: 'Получи AI анализ недвижимости бесплатно. Узнай о недвижимости все прежде инвестировать и потом жалеть об ошибках.'
    }
  },
  form,
  bottomNav: {
    map: 'Квартиры',
    propertyValuation: {
      default: 'Оценка',
      isCalcClaim: {
        priceEstimation: 'Оценить другую квартиру',
        tradeUp: 'Обменять другую квартиру'
      }
    },
    tradeUp: {
      default: 'Обмен',
      tradeUp: 'Обменять другую квартиру',
      isCalcClaim: 'Подобрать новую квартиру'
    },
    deals: 'Сделки',
    shivaAi: 'Портфель',
    magicPro: 'Для инвесторов',
    mapAnalytics: 'Карта'
  },
  bottomNavProLabelTitle: 'Доступно в PRO версии',
  startScreen: {
    phoneVerification: {
      title: 'Чтобы смотреть квартиры с дисконтом, пожалуйста, введите номер телефона',
      btn: {
        submit: 'Подтвердить'
      }
    },
    smsCodeVerification: {
      btn: {
        submit: 'Подтвердить',
      },
      tradeUpBtn: {
        submit: 'Открыть карту с вариантами',
      }
    }
  },
  onboarding: {
    slides: {
      propertyValuation: 'Оцените вашу квартиру за&nbsp;10&nbsp;секунд',
      map: 'Отобранный топ 1% самых выгодных квартир по лучшим ценам',
      poi: 'Посмотрите, какие факторы влияют на стоимость квартир в районе',
      competitors: 'Узнайте, какие квартиры, похожие на вашу продаются&nbsp;рядом',
      tradeUp: 'Покажем варианты обмена без доплаты',
      investment: 'Владейте квартирами и получайте доход от&nbsp;10%&nbsp;до&nbsp;20% в год',
      mapAnalytics: 'Узнайте какие районы в&nbsp;вашем городе растут в&nbsp;цене, а какие нет'
    },
    buttons: {
      skip: 'Пропустить вводную часть',
      next: 'Перейти'
    }
  },
  modal,
  filters,
  mapTopMessage: 'ТОП 1000 квартир из 120 000 предложений',
  internalTokenMessage: 'Служебный токен',
  proText: 'Доступно акционерам и&nbsp;партнерам Realiste',
  deals,
  propertyTable,
  analytics,
  tradeUp,
  competitors,
  funds,
  poi,
  feedback,
  privacyPolicy,
  rent,
  favorites,
  roiDesk: 'Чистый ROI, включает в себя расходы на содержание помещения.',
  linkHowItWorks: '<strong>Как это работает?</strong><br>Узнайте что такое ИИ Реалист и как он проводит оценку недвижимости',
  sqftSup: 'фут<sup>2</sup>',
  sqmSup: 'м<sup>2</sup>',
  tokenExpired: 'Ссылка просрочена или заблокирована'
};

export default obj;
