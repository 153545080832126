const openCalcLogOnActualDate = (href, querySelector) => {
  let pressTimer;
  document.querySelector(querySelector).addEventListener('click', e => {
    if (e.detail === 5) {
      window.open(`https://parser.shiva-property.dev.realiste.ai/__developer?view_log=1$&autoopenmap=1&magic_or_ad_link=${encodeURIComponent(href)}`, '_blank');
    }
  });
};

const openCalcLog = (href) => {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('bottom-bar')) return;
    const autoopenmap = e.ctrlKey ? '&autoopenmap=1' : '';
    if (e.offsetX < 50 && e.detail === 5) {
      window.open(`https://parser.shiva-property.dev.realiste.ai/__developer?view_log=1${autoopenmap}&magic_or_ad_link=${encodeURIComponent(href)}`, '_blank');
    }
  });
};

export { openCalcLog, openCalcLogOnActualDate };
