<template>
  <ul v-bind:class="bottomBarClass"
      ref="bottomBar"
      v-on:click="openCalcLogInner"
      v-if="bottomBarIsVisible">
    <li v-for="(el, index) in navbarList" v-bind:key="el.key">
      <template v-if="$domainConfig.useSiteV2IndexMapAnalgeo && el.key === 'mapAnalytics'">
        <a :href="getV2IndexMapUrl()" target="_blank">
          <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
          <span class="title">{{ menuItemTitle(el.key) }}</span>
        </a>
      </template>

      <template v-else>
        <template v-if="el.pathName">
          <template v-if="el.showProLabel">
            <tooltip-base :position="proTooltipPosition(index, navbarList.length)" class="bottom-bar--item-inactive">
              <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
              <img v-bind:src="el.imgUrl" width="24" height="24" class="rounded" v-if="el.imgUrl" />
              <span class="title">{{ menuItemTitle(el.key) }}</span>

              <div class="bottom-bar--pro-label"><span>PRO</span></div>
              <div class="bottom-bar--pro-hint" slot="body"><p>{{ $t('bottomNavProLabelTitle') }}</p></div>
            </tooltip-base>
          </template>

          <template v-else>
          <a v-if="el.external" :href="el.url" :class="linkClass(el)">
            <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
            <img v-bind:src="el.imgUrl" width="24" height="24" class="rounded" v-if="el.imgUrl" />
            <span class="title">{{ menuItemTitle(el.key) }}</span>
          </a>
          <router-link v-else v-bind:to="routingTo(el)" v-bind:class="linkClass(el)">
            <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
            <img v-bind:src="el.imgUrl" width="24" height="24" class="rounded" v-if="el.imgUrl" />
            <span class="title">{{ menuItemTitle(el.key) }}</span>
          </router-link>
        </template>
        </template>

        <template v-else>
          <template v-if="el.showProLabel">
            <tooltip-base :position="proTooltipPosition(index, navbarList.length)" class="bottom-bar--item-inactive">
              <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
              <span class="title">{{ menuItemTitle(el.key) }}</span>

              <div class="bottom-bar--pro-label"><span>PRO</span></div>
              <div class="bottom-bar--pro-hint" slot="body"><p>{{ $t('bottomNavProLabelTitle') }}</p></div>
            </tooltip-base>
          </template>

          <a v-bind:href="el.url" v-else target="_blank" class="link-shiva-ai">
            <svg-icon v-bind:name="el.icon[$domainConfig.locale]" v-if="el.icon"></svg-icon>
            <span class="title">{{ menuItemTitle(el.key) }}</span>
          </a>
        </template>
      </template>
    </li>
  </ul>
</template>

<script>
import { openCalcLog } from '@/lib/open_calc_log';
import navbarButtons from '@/mixins/navbarButtons';
import TooltipBase from '@/components/common/TooltipBase.vue';
import isMobile from '@/mixins/isMobile';

export default {
  name: 'BottomBar',
  mixins: [navbarButtons, isMobile],
  props: {
    isDisabled: Boolean
  },
  components: {
    TooltipBase
  },
  computed: {
    bottomBarClass() {
      return [
        'bottom-bar',
        {
          disabled: this.isDisabled
        }
      ];
    },
    bottomBarIsVisible() {
      return this.$domainConfig.bottomBarButtons.length > 1;
    }
  },
  methods: {
    getV2IndexMapUrl() {
      let url = `${window.location.protocol}//map-${window.location.hostname}`;
      if (this.$route.params.clientToken) url += `/${this.$route.params.clientToken}/`;

      let params = `locale=${this.$i18n.locale}`;
      if (this.$route.query.selectCurrency) params += `&currency=${this.$route.query.selectCurrency}`;

      return `${url}?${params}`;
    },
    openCalcLogInner() {
      openCalcLog(window.location.href);
    },
    proTooltipPosition(index, length) {
      if (this.isMobile && index === (length - 1)) {
        return 'top';
      }
      return 'top center';
    },
    linkClass(el) {
      if (!el.pathName) return;

      return [
        el.class,
        {
          active: this.$route.name === el.pathName.default || this.$route.path.includes(el.path),
          beta: el.beta,
        }
      ];
    },
    routingTo(el) {
      if (el.url) return el.url;
      return {
        name: el.pathName.default
      };
    },
    menuItemTitle(key) {
      if (key != 'propertyValuation' && key != 'tradeUp') return this.$t(`bottomNav.${key}`);
      return this.$t(`bottomNav.${key}.default`);
    }
  }
};
</script>

<style lang="scss" scoped>
  .bottom-bar {
    opacity: 0;
    visibility: hidden;
  }
</style>
