const poi = {
  priceEstimation: {
    sidebar: {
      title: 'Рядом с домом',
      total: 'Всего факторов:',
      notFound: 'Не найдено',
      swiperHint: 'Листайте вправо чтоб просмотреть другие факторы'
    },
    dataFoundPoi: 'Найдены факторы, влияющие на&nbsp;привлекательность локации',
    showPoiBtn: {
      collapse: 'Смотреть факторы',
      expand: 'Скрыть факторы',
    }
  }
};

export default poi;
