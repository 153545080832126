import gql from 'graphql-tag';

export const userInfo = gql`
  query app {
    userInfo {
      auth,
      isInternal,
      isPublic
    }
    partnerInfo {
      isDeveloper
    }
  }
`;
