<template>
  <div v-bind:class="loaderWrapClass">
    <p v-bind:style="textStyle"
       v-html="calcWaitingSentences"
       class="loader-text"
       v-if="calcWaitingSentences"></p>
    <div v-if="calcWaitingMedia"
         class="media-loader-wrap">

      <div class="img-wrap"
           v-if="calcWaitingMedia.img_src">
        <img v-bind:src="calcWaitingMedia.img_src"
             v-bind:alt="calcWaitingMedia.code">
      </div>
      <h4 class="media-title">{{ calcWaitingMedia.title }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from '@/patterns/event-bus';

export default {
  props: {
    textColor: String,
  },
  data() {
    return {
      isFadeIn: false
    };
  },
  computed: {
    ...mapGetters({
      calcWaitingSentences: 'propertyForm/getCalcWaitingSentences',
      calcWaitingMedia: 'propertyForm/getCalcWaitingMedia',
      calcClaimCounter: 'propertyForm/getCalcClaimCounter',
    }),

    textStyle() {
      return { color: this.textColor };
    },

    loaderWrapClass() {
      return [
        'loader-container',
        {
          'fade-in': this.isFadeIn
        }
      ];
    }
  },
  methods: {
    animateLoaderText() {
      this.isFadeIn = true;

      const clearFadeIn = () => this.isFadeIn = false;

      setTimeout(clearFadeIn, 500);
    },
  },
  mounted() {
    EventBus.$on('animateLoaderText', this.animateLoaderText);
  }
};
</script>

<style lang="scss" scoped>

</style>
