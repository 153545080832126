import axios from 'axios';
import errorHandler from '@/lib/error_handler';

const baseUrl = process.env.VUE_APP_EXTERNAL_DATA_STATIC_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

/* eslint-disable */
class DataFactory {
  showError(fileName, error) {
    errorHandler.handleError(`Cannot get access to ${baseUrl}/${fileName}`, { error });
  }

  async getAvailableCities() {
    return apiClient.get('/available_cities_public.json')
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return this.showError('available_cities_public.json', error);
      });
  }

  async getCurrencies() {
    return apiClient.get('/currencies_public.json')
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return this.showError('currencies_public.json', error);
      });
  }

  async getCityRating(currentCity) {
    return apiClient.get('/city_ratings_public.json')
      .then(response => {
        const res = response.data.filter(el => el.cities.includes(currentCity));
        if (res.length == 0) return response.data.filter(el => el.cities.includes('default'));
        return res[0];
      })
      .catch(error => {
        return this.showError('city_ratings.json', error);
      });
  };
}

const dataFactory = new DataFactory();

export default dataFactory;
