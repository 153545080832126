export default {
  methods: {
    formatNumber(x, code) {
      const separator = code == 'USD' ? ',' : '\u00a0';

      if (x === null || x === undefined) {
        return '';
      }

      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator).toString().replace('.', ',');
    }
  },
};
