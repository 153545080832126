const obj = {
  title: 'Deals',
  fundSize: 'Fund Size',
  amountDividents: 'Amount of dividends',
  currentProfit: 'Current profit',
  paymentDate: 'Payment date',
  activeDeals: 'Active deals',
  completedDeals: 'Completed deals',
  commonStatistic: {
    boughtTotal: 'Number of objects purchased in total',
    totalDeals: 'Total deals',
    operationProfit: 'Operating profit',
    netProfitPaidToInvestors: 'Net profit paid to investors',
    notPaidNetProfitToInvestors: 'Not paid net profit to investors',
    soldTotal: 'Sold total',
    IRR: 'Internal rate of return ( IRR )',
    portfolioAnnualReturn: 'Portfolio annual return',
    countProjects: 'Number of projects',
    countSuccesfulProjects: 'Number of successful projects',
    countUnprofitableProjects: 'Number of unprofitable projects',
    countActiveProjects: 'Active projects',
    averageProjectDuration: 'Average project duration',
    portfolioVolalityRatio: 'Portfolio Volality Ratio',
    countHighYieldDeals: 'Count high yield deals',
    modifiedSharpeRatio: 'Modified sharpe ratio',
    averageRiskFreeReturnOnDeposits: 'Average risk-free returns on deposits/bonds since 2018',
  },
  apartmentLegalCheck: {
    mainData: {
      title: 'Main conclusions',
      A1_1: 'Significant risks in relation to the apartment',
      A1_2: 'Carrying out a transaction with an apartment',
    },
    primaryRisks: {
      title: 'Primary risks according to the seller',
      A2_1: 'Unauthorized redevelopment',
      A2_2: 'Owners have children',
      A2_3: 'There are registered children',
      A2_4: 'Prescribed, which will not be written out before the transaction',
      A2_5: 'Full price in contract of sale?',
      A2_6: 'Does the area in the documents and in the analysis diverge?'
    },
    objData: {
      title: 'Information about the apartment',
      A3_1: 'In accordance with the extended extract from the Unified State Register of Real Estate on the transfer of rights to the property (hereinafter referred to as the Extract from the Unified State Register of Real Estate), the owner owns the property with',
      A3_2: 'Is the Seller the sole owner of the Apartment as of the date of the Report?',
      A3_3: 'According to the Extract from the house book, as of the moment of the report preparation, are there persons with permanent or temporary registration in the Apartment?',
      A3_4: 'Are there incompetent persons, disabled people, veterans, state-protected persons registered in the apartment?',
      A3_5: 'Restrictions (encumbrances) of the rights to the apartment?',
      A3_6: 'Has the apartment plan been reconciled with the fact (through a 3D model, check out)? Are there any discrepancies in the layout of the apartment?',
      A3_7: 'Is there a significant (more than 5 thousand rubles) debt for utilities, rent?',
    },
    lossRightsRisks: {
      title: 'Risks of loss of rights',
      A4_1: 'Finding the Seller in the Unified Federal Register of Bankruptcy Information',
      A4_2: 'Finding the Seller in the database for tracing debtors of the Federal Bailiff Service',
      A4_3: 'Participation of the Seller in judicial proceedings of arbitration courts',
      A4_4: 'Participation of the Seller in court proceedings of courts of general jurisdiction',
      A4_5: 'Is the Seller indebted to third parties?',
      A4_6: 'Is there any evidence or concerns about the seller that he is incapacitated or the transaction made by him can be recognized as void?',
      A4_7: 'Is the seller a founder in Organizations in respect of which debts to third parties are revealed?',
      A4_8: 'The seller is one of the persons involved in extremism.',
      A4_9: 'The apartment is the address of mass registration of legal entities, according to information provided by the Federal Tax Service of the Russian Federation.'
    }
  },
  landLegalCheck: {
    mainData: {
      title: 'Main conclusions',
      A1_1: 'Significant risks in relation to the land plot',
      A1_2: 'Conducting a deal with a plot',
    },
    primaryRisks: {
      title: 'Primary risks according to the seller and upon inspection',
      A2_1: 'Entrance to the site',
      A2_2: 'Address did not match',
      A2_3: 'Critical roughness of the site',
      A2_4: 'Cannot build a house',
      A2_5: 'Not full price in contract of sale?',
      A2_6: 'Does the area in the documents and in the analysis diverge?',
      A2_7: 'Any problems with documents'
    },
    objData: {
      title: 'Plot data',
      A3_1: 'Information about registration of rights and existing (registered) restrictions:',
      A3_2: 'Cadastral value',
      A3_3: 'The occurrence of tax, taking into account the cadastral value and sale price',
      A3_4: 'Land category',
      A3_5: 'Permitted use:',
      A3_6: 'Information about territorial planning documents:',
      A3_7: 'INFORMATION ABOUT URBAN PLANNING ZONING DOCUMENTS:',
      A3_8: 'INFORMATION ON THE LOCATION OF THE PLOT IN ZONES WITH SPECIAL CONDITIONS FOR THE USE OF THE TERRITORY (Place of permissible placement of capital construction objects):',
    },
    lossRightsRisks: {
      A4_1: 'Finding the Seller in the Unified Federal Register of Bankruptcy Information',
      A4_2: 'Finding the Seller in the Database of the Federal Bailiff Service',
      A4_3: 'Finding the Seller in the database for tracing debtors of the Federal Bailiff Service',
      A4_4: 'Participation of the Seller in judicial proceedings of arbitration courts',
      A4_5: 'Participation of the Seller in court proceedings of courts of general jurisdiction',
      A4_6: 'Participation of the Seller in court proceedings of the world courts',
      A4_7: 'Is the Seller indebted to third parties?',
      A4_8: 'Is there any evidence or concerns about the seller that he is incapacitated or the transaction made by him may be invalidated?',
      A4_9: 'Is the seller a founder in Organizations in respect of which debts to third parties are revealed?',
      A4_10: 'The seller is one of the persons involved in extremism.',
      A4_11: 'The Seller has account blocking by the Federal Tax Service',
      A4_12: 'The Seller\'s passport is invalid according to the passport validation information',
    }
  },
  apartmentProjectSteps: {
    B1: 'Registration of the purchase - acceptance of the apartment',
    B2: 'Exposed to advertising',
    B3: 'Start of staging/repair',
    B4: 'The first interested buyers',
    B5: 'Finished staging',
    B6: 'All documents were digitized, real estate appraisal was made',
    B7: 'Purchase offer received',
    B8: 'Transaction date set',
    B9: 'Deal completed'
  },
  landProjectSteps: {
    B1: 'Deal has been completed successfully',
    B2: 'Exposed to advertising',
    B3: 'Staging start',
    B4: 'Collection of documents/preparation of land surveying',
    B5: 'Finished staging',
    B6: 'All documents were digitized, real estate appraisal was made',
    B7: 'Purchase offer received',
    B8: 'Transaction date set',
  },
  datePurchase: 'Purchase date',
  dateSale: 'Date of sale',
  annualReturnRate: 'Annual return',
  cadNumber: 'Cadastral number',
  aiRecommendations: 'AI Recommendations',
  targetReturn: 'Target return',
  netProfitPortfolio: 'Net profit',
  pricePortfolio: 'Selling price',
  dealTerm: 'Deal term',
  netProfit: 'Net profit',
  dealsInformation: {
    title: 'Transaction Information'
  },
  commonInformation: {
    title: 'Common information'
  },
  btnPurchaseTitle: {
    sellUrgently: 'Sell urgently',
    sell: 'Sell',
    sellLeisurely: 'Sell leisurely',
    keep: 'Keep'
  },
  dateSaleDescription: 'Estimated sale date based on sales analytics, demand dynamics and data.',
  shareBtn: 'Tell us about your deals with Realiste',
  noData: 'No data',
  noDealsData: 'Deals data is not yet available. Click on the <a href="{link}" target="_blank">link</a> to view existing funds',
  noDealsDetailData: 'No detailed data yet',
  realtorType: {
    funds: 'Funds',
    flippers: 'Flippers',
    persons: 'Private persons'
  },
  additionalInvestments: {
    title: 'Additional investments',
    purchasePrice: 'Purchase price',
    sellingPrice: 'Selling price'
  },
  dealForecast: {
    title: 'Expected Forecast'
  },
  demand: {
    title: 'Demand',
    nWeek: 'week',
    countAdViews: 'Ad views count',
    countCalls: 'Calls',
    countAdRotated: 'Ad rotated',
    total: 'Total'
  },
  legalReview: {
    title: 'Legal review',
    status: {
      notIdentified: 'not identified',
      recommended: 'recommended'
    }
  },
  projectStages: {
    title: 'Project stages',
    estimatedFinishDate: 'Estimated finish date',
    actualFinishDate: 'Actual finish date',
    countCompletedStages: 'Completed stages'
  },
  apartment: 'Apartment',
  landPlot: 'Land plot',
  fund: {
    moreInformation: ['Official information about the fund is available at the ', 'link']
  },
  all: 'All'
};

export default obj;
