const funds = {
  deals: {
    title: 'Deals',
    activeDeals: 'Active deals',
    completedDeals: 'Completed deals',
  },
  mainNavbar: {
    all: 'All',
    funds: 'Funds',
    flippers: 'Flippers',
    persons: 'Private persons'
  },
  dealsNavbar: {
    apartment: 'Apartments',
    land: 'Lands',
    all: 'All',
    allTheTime: 'During all the time'
  },
  chart: {
    actualDate: 'Trades report on',
    tradingProfit: 'Trading profit',
    irrAverage: 'Average Internal rate of return (IRR)',
    netProfit: 'Net profit',
    forecastNetProfit: 'Forecast net profit'
  }
};

export default funds;
