import costs from '@/langs/forecast/en';
import form from '@/langs/form_fields/en';
import modal from '@/langs/modal/en';
import filters from '@/langs/filters/en';
import deals from '@/langs/deals/en';
import propertyTable from '@/langs/property_table/en';
import analytics from '@/langs/analytics/en';
import tradeUp from '@/langs/trade_up/en';
import competitors from '@/langs/competitors/en';
import funds from '@/langs/funds/en';
import poi from '@/langs/poi/en';
import feedback from '@/langs/feedback/en';
import privacyPolicy from '@/langs/privacy_policy/en';
import rent from '@/langs/rent/en';
import favorites from '@/langs/favorites/en';

const obj = {
  timePeriod: {
    year: 'year {count}',
    yearNoCount: 'year',
    month: '{count} month | {count} months',
    monthNoCount: 'month | months',
    day: '{count} day | {count} days',
    dayWithoutVal: 'day | days',
    monthShort: 'mo | mos',
    weekShort: 'week',
    minShort: 'mins'
  },
  amount: {
    thousandShort: 'K',
    millionShort: 'M'
  },
  units: {
    meter: 'm'
  },
  btn: {
    education: 'Education',
    mediaAboutRealiste: 'Media about Realiste',
    '30PerAnnum': '30% per annum',
    proLink: 'Passive investment',
    analysisError: 'Analysis error?',
    invest: 'Price estimation in 10 sec',
    investRequest: '{count}, if you sell in 3 days and invest with us at 15% per annum',
    telegramSubscribe: 'Subscribe to a channel',
    back: 'Back',
    reset: 'Reset',
    backToList: 'Map',
    openTheMap: 'On the map',
    realisteLink: 'Go to Realiste',
    cianLink: 'Open external website',
    sell: 'Sell in 7 days',
    complaintReceived: 'Complaint received',
    getMoreFeatures: 'Detailed analytics are available to shareholders and partners of Realiste',
    slideCompetitorsMap: 'Show competitors on the map',
    showClosestCompetitors: 'Only closest competitors',
    showAllCompetitors: 'Show all competitors',
    onlyClosestCompetitors: 'Only closest competitors',
    howItWasCalculated: 'How it was calculated',
    getOffer: 'Get your free cash offer',
    competitorsList: 'List',
    more: 'More',
    areaInfrastructure: 'District infrastructure',
    analyticsAndForecast: 'Statistics and forecast'
  },
  property: {
    numberRoomsShort: 'bd',
    sqm: 'sqm',
    sqft: 'sqft',
    floor: 'floor',
    discount: {
      textBefore: '',
      label: {
        undervalued: 'cheaper',
        overvalued: 'more expensive'
      },
      badgeOptions: {
        fairPrice: 'fair price',
        overValued: 'over valued',
        goodPrice: 'good price',
        greatPrice: 'great price',
        bestPrice: 'best price'
      }
    },
    discountText: 'Discount',
    profitText: 'Annual',
    applicationSent: 'Application sent',
    discountShortV: 'Discount',
    externalUrl: {
      pro: 'External website',
      default: 'Request for viewing',
      unpublished: 'View full analysis with links to ads',
      defaultDetailed: 'Get more information'
    },
    actualDate: {
      default: ['Last update', ''],
      notPro: ['The data is updated free of charge every 7 days. To receive updates every day', 'upgrade your plan'],
    },
    repairQuality: 'Repair',
    priceMarketDiscount: 'Less than market price',
    priceMarketDiscountResultsPage: 'Discount when selling in 2-10 days',
    netProfit: 'Profit',
    projectProfitYearRate: 'Annual return',
    projectProfitYearRateNote: ', if resell for',
    duration: 'Project duration',
    priceDetail: 'Seller\'s price',
    priceMarket: 'Price after 3 months',
    pricePurchase: 'Recommended purchase price',
    improvementAmount: 'Additional investment for sale for',
    profitPageLink: 'Cost estimates',
    competitorsPageLink: 'Research alternatives',
    price: 'Price',
    square: 'Square',
    pricePer: {
      sqm: 'Per sqm',
      sqft: 'Per sqft',
    },
    discountMoreThanPriceMarket: 'More than market price',
    potentialBenefit: 'Potential resale benefits',
    statedPrice: 'Stated price',
    marketPrice: 'Market price',
    marketPriceResultsPage: 'Price when selling for {count}',
    investInRenovation: 'You need to invest to sell for {count}',
    unitLabel: {
      month: '{count} mo | {count} mos',
      day: '{count} day | {count} days | {count} days',
    },
    buildingRating: 'Building rating',
    noiseLevel: 'Noise level',
    repairType: {
      killed: 'no repairs',
      cosmetics: 'cosmetic',
      euro: 'euro',
      super_euro: 'design'
    },
    repairType2: {
      no: 'No repairs',
      cosmetic: 'Cosmetic',
      euro: 'Euro',
      design: 'Design'
    },
    repairTypeChange: {
      label: 'Show calculation',
      noRepair: 'Flip',
      renovated: 'Add value & Flip'
    },
    sold: 'Sold',
    not_sold: 'Not sold',
    unlisted: 'Rented',
    demand: 'Demand',
    betaValuationAlert: 'The assessment in this location is undergoing improvement and is probably quite inaccurate. You can help us improve the service by sending feedback on the button',
    valuationAlert: {
      default: 'The evaluation is made by the Shiva AI algorithm automatically. In this area, Shiva AI accurately estimates the price when selling for 3 months for 95 apartments out of 100. If you think the estimate is inadequate, please let us know. We will conduct additional research and inform you of the results.',
    },
    seelerContactsMessage: 'Leave your phone number to get more information',
    exposition: {
      default: 'Exposition',
      unpublished: 'Rented in {count}',
      competitorsMapObject: 'exposition {count}',
      sold: 'exposition {count}'
    },
    metaInfo: {
      transactionAt: 'Date of sale',
      transactionNumber: 'DLD transaction'
    },
    countExpositionDays: 'Exposure time',
    tooltipTitle: 'Information',
    priceShownObviousAd: 'Price after 12 mos.',
    apartment: 'Flat',
    building: 'Building',
    flatInfoFloor: 'Floor',
    noData: 'No data',
    salePriceForPeriod: {
      title: {
        default: 'Selling price for',
        tradeUpCard: 'Apartment price'
      },
      modalTitle: 'Selling price',
      period: {
        '12months': '12 months',
        '3months': '3 months',
        dealTomorrow: 'deal tomorrow'
      }
    },
    verifiedObject: {
      btnText: 'Apartment check results',
      badgeText: 'Verified by Realiste'
    }
  },
  district: {
    details: [
      {
        key: 'similarApartmentCount',
        text: ['<h3>{count}</h3> similar apartment | <h3>{count}</h3> similar apartments | <h3>{count}</h3> similar apartments', 'for sale'],
      },
      {
        key: 'soldApartmentsLastMonthsCount',
        text: ['<h3>{count}</h3> similar apartment | <h3>{count}</h3> similar apartments | <h3>{count}</h3> similar apartments', 'was sold for the last month | were sold for the last month | were sold for the last month'],
      },
      {
        key: 'expositionDays',
        text: ['<h3>{count}</h3> day | <h3>{count}</h3> days | <h3>{count}</h3> days', ' - average exposition days'],
      },
      {
        key: 'priceAreaMedian',
        text: ['currency', 'average selling price of similar apartments in the area'],
      },
      {
        key: 'discountForFastSell',
        text: ['<h3>{count}%</h3>', ' - necessary discount for a quick sale']
      }
    ],
    showDetailsLabel: {
      text: 'Details - on our',
      textLink: 'price index map'
    }
  },
  chart: {
    legend: {
      priceMarket: ['Market price', 'for current apartment'],
      priceMarketForEachObject: 'Market price for each property'
    },
  },
  loader: {
    text: 'Please wait, data is loading',
    textCalcClaimTop: '<p class="mb-1">Please wait, data is loading.</p>In highly competitive areas, claim calculation can take up to 30 seconds',
    textCalcClaimTradeUpTop: '<p class="mb-1">We remove all unfavorable offers from the screen.</p>In highly competitive areas, claim calculation can take up to 30 seconds',
    textCalcClaim: [
      'I highlight the location',
      'I analyze the factors affecting the&nbsp;area',
      'Collecting data on transactions made for&nbsp;3 months',
      'I determine the market price for each apartment in the &nbsp;area',
      'Appreciating the current apartment',
      'Almost ready',
      'It looks like there are a lot of apartments for sale in your area. The analysis will take a little longer'
    ],
    textCalcClaimTradeUp: [
      'I highlight the location',
      'Appreciating the current apartment',
      'I determine the market price for each apartment',
      'I select offers with a market price or lower',
      'I remove unprofitable options from the map',
      'I analyze what good and bad factors affect the area',
      'I calculate the difference and possible mortgage payments',
      'Almost ready',
      'It looks like there are a lot of apartments for sale in your area. The analysis will take a little longer'
    ],
    textShort: 'Please wait ...',
    counter: {
      default: 'The calculation takes <mark>{count}</mark> s',
      moreThan60sec: 'Unfortunately, we were unable to quickly calculate the cost according to your parameters. Please try again later or chat with us'
    },
    isReady: 'The calculation is ready'
  },
  costs,
  profitPage: {
    title: 'Profitability forecast',
    pricePurchase: 'Purchase price',
    priceSelling: 'Seller\'s price',
    projectDuration: 'Project duration',
    improvements: 'Improvements',
    projectProfitYearRate: 'Annual yield',
    netProfit: 'Net profit',
  },
  competitorsPage: {
    title: 'Top competitors'
  },
  disclaimer: {
    info: 'It is not an investment recommendation, the data is provided as',
    linkText: 'information'
  },
  userAgreement: {
    title: 'END USER LICENSE AGREEMENT',
    linkTitle: 'Terms and conditions'
  },
  bubble: {
    bubbleAbout: {
      title: 'Go to'
    }
  },
  share: {
    default: {
      title: 'Be a Realist! Get 50% + MORE from your investment.',
      description: 'Realiste - is an investment company where artificial intelligence (AI) serves as the basis for making real estate investment decisions'
    },
    form: {
      title: 'AI estimation',
      description: 'Get AI real estate analysis for free. Learn all about real estate before investing and then regret mistakes.'
    }
  },
  form,
  bottomNav: {
    map: 'Apartments',
    propertyValuation: {
      default: 'Estimation',
      isCalcClaim: {
        priceEstimation: 'Estimate another apartment',
        tradeUp: 'Estimate another apartment'
      }
    },
    tradeUp: {
      default: 'Trade Up',
      tradeUp: 'Trade Up',
      isCalcClaim: 'Trade Up'
    },
    deals: 'Deals',
    shivaAi: 'Portfolio',
    magicPro: 'For investors',
    mapAnalytics: 'Map'
  },
  bottomNavProLabelTitle: 'Available in PRO version',
  startScreen: {
    btn: {
      map: 'View properties at a good price',
      propertyValuation: 'Price estimation',
      tradeUp: 'Trade Up',
      deals: 'Investment history',
      shivaAi: 'Shiva AI',
      magicPro: 'Information for investors',
      mapAnalytics: 'View price index'
    },
    hint: 'All actions will be available later in the bottom menu',
    phoneVerification: {
      title: 'To view apartments with a discount, please enter your phone number',
      btn: {
        submit: 'Confirm'
      }
    },
    smsCodeVerification: {
      btn: {
        submit: 'Confirm'
      },
      tradeUpBtn: {
        submit: 'Open trade up options',
      }
    }
  },
  onboarding: {
    slides: {
      propertyValuation: 'Assessment of any apartment in 10 seconds',
      map: 'Selected top 1% of the most profitable apartments at the best prices',
      poi: 'See what factors affect the cost of apartments in the area',
      competitors: 'Find out what apartments similar to yours are for sale nearby',
      tradeUp: 'Find out the best options for exchanging your apartment for any new build',
      investment: 'Own apartments and earn income from&nbsp;10%&nbsp;to&nbsp;20% per year',
      mapAnalytics: 'Find out which areas in&nbsp;your city are growing in price and which are not'
    },
    buttons: {
      skip: 'Skip',
      next: 'Next'
    }
  },
  modal,
  filters,
  mapTopMessage: 'Realiste shows top 1% of 19540 condos and coops',
  internalTokenMessage: 'Internal token',
  proText: 'Available to PRO investors and partners',
  deals,
  propertyTable,
  analytics,
  tradeUp,
  competitors,
  funds,
  poi,
  feedback,
  privacyPolicy,
  rent,
  favorites,
  roiDesk: 'This is net ROI, it includes maintenance costs.',
  linkHowItWorks: '<strong>How it works?</strong><br>Learn what AI Realist is and how it conducts real estate evaluation',
  sqftSup: 'ft<sup>2</sup>',
  sqmSup: 'm<sup>2</sup>',
  tokenExpired: 'Link corrupted or expired'
};

export default obj;
