<template>
  <div>
    <a v-bind:href="linkPlatformKnowledge" target="_blank" class="btn btn-youtube d-inline-flex align-items-center mb-2">
      <svg-icon name="youtube" class="mr-2"></svg-icon>
      {{ $t('modal.ai.btn') }}
    </a>
    <p>
      {{ $t('modal.ai.list.title') }}
    </p>
    <ul class="list-unstyled mb-3" v-if="!isPublic">
      <li class="d-flex align-items-center">
        <span class="legend-icon bg-dark mr-2">
          <svg-icon name="star"></svg-icon>
        </span> {{ $t('modal.ai.list.options[0]') }}
      </li>
      <li class="d-flex align-items-center">
        <span class="legend-icon bg-success mr-2">
          <svg-icon name="star"></svg-icon>
        </span> {{ $t('modal.ai.list.options[1]') }}</li>
      <li class="d-flex align-items-center">
        <span class="legend-icon bg-special mr-2">
          <svg-icon name="star"></svg-icon>
        </span> {{ $t('modal.ai.list.options[2]') }}</li>
    </ul>
    <ul class="list-unstyled mb-3" v-if="isPublic">
      <li class="d-flex align-items-center">
        <span class="legend-icon bg-success mr-2">
          <svg-icon name="star"></svg-icon>
        </span> {{ $t('modal.ai.list.optionsPublic[0]') }}</li>
      <li class="d-flex align-items-center">
        <span class="legend-icon bg-special mr-2">
          <svg-icon name="star"></svg-icon>
        </span> {{ $t('modal.ai.list.optionsPublic[1]') }}</li>
    </ul>
    <div v-html="$t('modal.ai.content', {'link': `<a target='_blank' href=${linkDetails}>${$t('modal.ai.linkMore')}</a>`})">
    </div>
  </div>
</template>

<script>
import buildRealisteUrl from '@/lib/build_realiste_url';

export default {
  computed: {
    linkDetails() {
      return buildRealisteUrl('rus/first_flagma_nfund/?utm_medium=magic&utm_source=onboard');
    },
    linkPlatformKnowledge() {
      return buildRealisteUrl('platform/knowledge');
    },
    isPublic() {
      return this.$store.getters.isPro === false;
    }
  }
};
</script>

<style lang="scss" scoped>
.legend-icon {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  font-size: rem-calc(12);
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  .svg-icon {
    color: $white;
  }
}
</style>
