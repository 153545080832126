const replaceWindowLocation = () => {
  if (window.location.href.includes('#/')) {
    const match = window.location.href.split('#/');

    if (match.length == 1 || window.location.pathname != '/') return;

    const beforeParams = match[0].split('/?').length > 1 ? match[0].split('/?')[1] : '';
    const afterParams = match[1].split('?').length > 1 ? match[1].split('?')[1] : '';

    let res = window.location.href.replace('#/', '');

    if (beforeParams && afterParams) {
      res = `${res.replace(`?${beforeParams}`, '')}&${beforeParams}`;
    } else if (beforeParams && !afterParams) {
      res = `${res.replace(`?${beforeParams}`, '')}?${beforeParams}`;
    }

    window.location.replace(res);
  }
};

replaceWindowLocation();
