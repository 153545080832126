<template>
  <div class="chart-description">
    <p>{{ $t('modal.chart.subTitle') }}</p>
    <ul class="list-unstyled mb-4">
      <li class="d-flex align-items-center">
        <span class="legend-icon line mr-3">
        </span>
        {{ $t('modal.chart.modes[0]') }}
      </li>
      <li class="d-flex align-items-center">
        <span class="legend-icon mr-3">
          <svg-icon name="graph"></svg-icon>
        </span>
        {{ $t('modal.chart.modes[1]') }}
      </li>
    </ul>
    <h4>{{ $t('modal.chart.legend.title') }}</h4>
    <ul class="list-unstyled">
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="bar-legend sold">
            <span class="inner">7</span>
          </span>
        </span>
        {{ $t('modal.chart.legend.list[0]') }}
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="bar-legend special">
            <span class="bar-legend-demand"></span>
            <span class="inner">7</span>
          </span>
        </span>
        {{ $t('modal.chart.legend.list[1]') }}
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="bar-legend danger">
            <span class="bar-legend-demand"></span>
            <span class="inner">7</span>
          </span>
        </span>
        <span v-html="$t('modal.chart.legend.list[2]', { legend: `<br><span class='legend-square danger'></span>` } )"></span>
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="bar-legend warning">
            <span class="bar-legend-demand"></span>
            <span class="inner">7</span>
          </span>
        </span>
        <span v-html="$t('modal.chart.legend.list[3]', { legend: `<span class='legend-square warning'></span>` } )"></span>
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="bar-legend success">
            <span class="bar-legend-demand"></span>
            <span class="inner">7</span>
          </span>
        </span>
        <span v-html="$t('modal.chart.legend.list[4]', { legend: `<span class='legend-square success'></span>` } )"></span>
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="period-btn">1 {{ $t('timePeriod.weekShort') }}</span>
        </span>
        {{ $t('modal.chart.legend.list[5]') }}
      </li>
      <li class="d-flex align-items-center mb-3">
        <span class="legend-icon mr-3">
          <span class="period-btn active">3 {{ $tc('timePeriod.monthShort', 2) }}</span>
        </span>
        {{ $t('modal.chart.legend.list[6]') }}
      </li>
    </ul>
  </div>
</template>

<script>
import buildRealisteUrl from '@/lib/build_realiste_url';

export default {
  computed: {
    linkDetails() {
      return buildRealisteUrl('rus/first_flagma_nfund/?utm_medium=magic&utm_source=onboard');
    },
    linkPlatformKnowledge() {
      return buildRealisteUrl('platform/knowledge');
    }
  }
};
</script>
