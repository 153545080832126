const fbqTrack = (to, partnerName) => {
  if (partnerName !== 'M2') return;

  if (!to.query || (to.params.id && to.params.id.toString().length < 10)) {
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }

    return;
  }

  const fbqEvent = to.params.id && to.params.id.toString().length >= 10 ? 'Lead' : 'PageView';

  if (window.fbq) {
    window.fbq('track', fbqEvent);
  }
};

export default fbqTrack;
