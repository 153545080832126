const obj = {
  rating_name: '評分',
  room_category_name: '房間的數量',
  ads_count: '廣告數量',
  ads_percent: '廣告百分比',
  median_price: '平均手價',
  median_sqm_price: {
    sqm: 'Average price sq.m.',
    sqft: 'Average price sqft'
  },
  heat: '需求指數',
  rating_stats_title: '評分數據',
  rooms_stats_title: '房間數數據',
  exposition_days_median: '平均曝光時間',
  choose_layer: '選擇圖層',
  poi_moscow: 'poi - Moscow',
  poi_st_petersburg: 'poi - St. Petersburg',
  category_name: '類別',
  factor_score: {
    title: 'Factor score',
    good: 'good',
    bad: 'bad'
  },
  btn: {
    apply: '應用更改',
  },
  changeFor: '為。。改變',
  month: '月',
  per: '每',
  for: '在',
  growth: '故事',
  forecast: '預報',
  year: '年',
  years: '年',
  rent_roi_median: '租金收益',
  gov_sold_count_median: '根據年度國家登記冊的銷售額',
  chartModalTitle: '使用的數據',
  btnShowModalChart: '顯示數據',
  rent_price_median: '平均租金',
  betaModalLine1: '該站點提供了索引工具的測試版。',
  betaModalLine2: '通過該工具提供的信息不是個人投資建議，通過它提供的信息可能不符合您的投資目標（期望）。 Realiste 不對使用“指數”工具進行房地產投資交易時可能造成的損失負責。',
  betaModalLine3: '帶標籤地圖形式的信息圖表是房地產投資吸引力的可視化。 該指標不保證未來的回報、投資結果，也不決定未來的投資回報。',
  betaModalLine4: '使用“指數”工具並不能保証投資者會以預定價格完成房地產交易。',
  betaModalLine5: '索引工具信息圖不是廣告。',
  betaModalLine6: '數字指標是使用指數工具根據給定領土範圍內房地產對象的公共數據計算的，第三方（包括已在其官方網站上發布信息的法人實體）對其可靠性負責。',
  betaModalLine7: '如果投資者決定進行房地產交易，Realiste 不對投資者可能造成的損失負責。',
  showBlock: '顯示更多...',
  hideBlock: '隱藏',
  bookingBtn: '預訂',
  bookingFormTitle: '請輸入您的電話號碼進行預訂',
  bookingFormTitle2: '請輸入電話號碼',
  bookingFormTitleSuccess: '謝謝！ 我們會盡快與您聯繫',
  viewApartments: '查看公寓',
  bookingBtnSend: '確認',
  notAnInvestmentRecommendation1: '不是投資建議，數據僅供參考',
  notAnInvestmentRecommendation2: '使用條款',
  refundableDeposit: '退還押金',
  bookingBtnForm: '書',
  bookingBtnFormUnit: '獲取目錄',
  freeCancellation1: '免費取消',
  freeCancellation2: '押金將退還至您的賬戶',
  chooseApartment: '從列表中選擇一個公寓',
  bedrooms: '房間',
  textSubmitFormUnit: '可應要求提供對象目錄',
  unitTypes: '類型',
  state: {
    available: '可用的',
    bookingInProgress: '預訂中',
    booked: '預定',
  },
  bookable: '可預訂',
  btnLayout: '佈局',
  btnVideo: '視頻',
  btnPhoto: '照片',
  rentRoi: '租金回報率/年',
  buyRoi: 'ROI 銷售額/3 年',
  priceName: '價格',
  filter: {
    states: '狀態',
    price: '價格',
    bedrooms: '房間',
    floorNumber: '地面',
    areaChargeable: '正方形',
    clear: '清除',
    apply: '申請',
  },
  bedroomsFilter: {
    studio: 'Studio',
    b1_0: '1 bedroom',
    b1_5: '1.5 bedrooms',
    b2_0: '2 bedrooms',
    b2_5: '2.5 bedrooms',
    b3_0: '3 bedrooms',
    b3_5: '3.5 bedrooms',
    b4_0: '4 bedrooms',
    b4_5: '5 bedrooms',
    more: '5+ bedrooms',
  },
  filterUnitNot: '什麼都沒找到',
  filterUnitNot2: '嘗試更改或刪除所有過濾器。',
  btnResetFilter: '清除所有過濾器',
  commission: '委員會',
  guarantee: '對現實的承諾',
  from: '從',
  before: '前',
  commissionHintBroker: '交易結束後經紀人或房地產經紀人的佣金。\n佣金的大小可由開發人員自行決定更改。',
  commissionHintGuarantee: '交易結束後 Realiste 的義務。\n在極少數情況下，義務金額可以在交易結束前更改。',
  commissionLink: '更多的',
  commissionTitleBroker: '獲得開發商佣金的步驟.',
  commissionHtmlBroker: '<p>1. 使用您的電話號碼選擇和預訂公寓.</p><p>2. 等待經理的電話。 他將幫助您簽署合同並完成交易的所有步驟。</p><p>3. 收到開發商付款後5天內收到佣金到您的活期賬戶.</p>',
  objectChartCaption: '市場價格增長',
  commissionTitleGuarantee: '現實主義對沖',
  commissionHtmlGuarantee: '<p>Realiste 預測基於人工智能 (AI) 計算。 Realiste 信任他們，當投資者在一段時間（12 或 24 或 36 個月）後從 Realiste 購買的公寓價格漲幅低於預期時，公司承諾補償差額 在可能的賠償數額之內。 如果價格漲幅高於預期，投資者將獲得高於預期的收入。</p> <p>可能的補償金額（所購公寓成本的百分比）在對象卡中註明。</p> <p>向公司專家了解更多：',
  tooltipForecast: '增長預測',
  tooltipForecastHint: '上年年均價與預測年年均價之比',
  updateIosMsg: '要查看圖表，請將 IOS 更新到最新版本',
  updateIosClose: '關閉',
  unitsStockUpdatedAt: '更新',
  median: '中位數',
  toMedian: '到中位數',
  tooltipForecastMedian: '價格變化百分比根據以下公式計算：當前價格與 2023 年、2024 年和 2025 年平均預測價格之間的價格差異（採用下一年的中位價格，因為價格在這段時間內可能會更高或更低）由於季節性，一年在不同的月份）。 從現實主義專家那裡學習預測未來價格增長的方法。',
  depositDisclaimerHeader: '關於押金',
  depositDisclaimerText: '這筆付款是對意向的確認和與開發商的預訂流程的開始，無論開發商做出何種決定，全額押金（10 000 盧布）將在 3 天內退還。',
  comissionDisclaimer: '*佣金數額可由開發商自行決定更改',
  successfulSendingCrypto: '預訂請求已發送。<br>我們將盡快與您聯繫。',
  successfulSendingCryptoBtn: '繼續',
  calculatorName: '產量計算器',
  bookingBtnSoldOut: '請求信息',
  successfulRequestInformation: '申請已發送。<br>我們將盡快與您聯繫。',
  impact: '影響',
  upcomingEventsTitle: '即將舉行的活動',
  aboutTitle: '關於',
  upcomingEventFuture: '未來',
  upcomingEventPast: '過去的',
  upcomingEventQuarter: '四分之一',
};

export default obj;
