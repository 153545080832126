const tradeUp = {
  object: '{count} объект | {count} объекта | {count} объектов',
  form: {
    title: {
      default: 'Оценка квартиры и подбор за 10 секунд. Шаг 1 из 2',
      lenstroytrest: 'Оценка квартиры и подбор за 10 секунд. Шаг 1 из 2'
    },
    claimFeatures: [
      'за 10 секунд – оценка и подбор',
      'за 1 операцию возможна продажа старой квартиры и покупка новой',
      'оперативный выход на сделку',
      'подбор лучших предложений по ипотеке',
    ],
    ownApartment: {
      title: 'Введите данные по своей квартире'
    },
    exchangeApartment: {
      title: 'Куда бы вы хотели переехать:',
    },
    searchDistrictsWithin: {
      title: 'Искать в пределах моего района'
    }
  },
  options: {
    spacious_apartment: 'жилье просторнее',
    new_apartment: 'новостройка',
    modest_apartment: 'жилье скромнее'
  },
  optionsTitle: 'Характеристики объекта',
  showObjectsBtn: 'Показать варианты на карте',
  shortInfo: {
    yourApartment: 'Ваша квартира',
    payExtra: 'Доплатить',
    mortgage: 'Ипотека от',
    getPriceDifference: 'Переехав, вы получите',
    sendRequest: 'Получить предложение'
  },
  countObjects: {
    default: 'Найдено {count}',
    more250: 'Найдено более {count}',
  },
  actionModal: {
    title: 'Получить предложение за 1 минуту',
    btnText: 'Получить предложение'
  },
  propertySlide: {
    analysis: 'Анализ'
  },
  btn: {
    howItWorks: 'Как работает Trade-Up',
    sendRequest: 'Обменять эту&nbsp;квартиру',
    wannaView: 'Заказать осмотр'
  },
  slider: {
    title: 'На что обменять'
  },
  modal: {
    wannaView: {
      title: 'Заказать осмотр',
      btnText: 'Отправить'
    }
  }
};

export default tradeUp;
