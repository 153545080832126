<template>
  <div class="btn-group repair-switcher-inner"
       role="group">
    <small class="pr-2 text-muted">
      {{ $t('property.repairTypeChange.label') }}
    </small>
    <template v-for="el in group">
      <input type="radio"
             class="btn-check"
             v-bind:name="groupName"
             v-bind:id="`${groupName}_${el.id}`"
             v-bind:key="`${el.id}_input`"
             v-bind:checked="el.changes.repair_type == value"
             v-on:change="updateValue(el)" >
      <label class="btn btn-outline-secondary"
            v-bind:for="`${groupName}_${el.id}`"
            v-bind:key="`${el.id}_label`">
        {{ getLabelText(el) }}
      </label>
    </template>

  </div>
</template>

<script>
import constants from '@/components/constants';

export default {
  props: {
    group: Array,
    groupName: String,
    value: [String, Number],
  },
  methods: {
    getLabelText(el) {
      const currentRepairTypeArr = constants.REPAIR_TYPE.filter(elem => elem == el.changes.repair_type);
      const repairTypeIsExist = currentRepairTypeArr ? currentRepairTypeArr.length > 0 : false;
      return repairTypeIsExist ? `${this.$t('property.repairTypeChange.renovated', { repair: this.$t(`property.repairType.${currentRepairTypeArr[0]}`) })}` : this.$t('property.repairTypeChange.noRepair');
    },
    updateValue(el) {
      this.$emit('change', { val: el.changes.repair_type, id: el.id });
    },
  }
};
</script>
