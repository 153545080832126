export default {
  namespaced: true,
  state: {
    priceClassCategories: [],
    sliderHeight: null
  },
  mutations: {
    setPriceClassCategories(state, priceClassCategories) {
      state.priceClassCategories = priceClassCategories;
    },
    setSliderHeight(state, sliderHeight) {
      state.sliderHeight = sliderHeight;
    }
  },
  getters: {
    getPriceClassCategories: (state) => state.priceClassCategories,
    getSliderHeight: (state) => state.sliderHeight
  },
};
