const obj = {
  rating_name: 'Rating name',
  room_category_name: 'Room category name',
  ads_count: 'Ads count',
  ads_percent: 'Ads percent',
  median_price: 'Average price',
  median_sqm_price: {
    sqm: 'Average price sq.m.',
    sqft: 'Average price sqft'
  },
  heat: 'Demand index',
  rating_stats_title: 'Rating data',
  rooms_stats_title: 'Number or rooms data',
  exposition_days_median: 'Exposition days median',
  choose_layer: 'Choose map layer',
  poi_moscow: 'poi - Moscow',
  poi_st_petersburg: 'poi - St. Petersburg',
  category_name: 'Category',
  factor_score: {
    title: 'Factor score',
    good: 'good',
    bad: 'bad'
  },
  btn: {
    apply: 'Apply changes',
  },
  changeFor: 'Change in',
  month: 'm.',
  per: 'in',
  for: 'for',
  growth: 'History',
  forecast: 'Forecast',
  year: 'year',
  years: 'years',
  rent_roi_median: 'Rental yield',
  gov_sold_count_median: 'Yearly sales according to state register',
  chartModalTitle: 'Dataset',
  btnShowModalChart: 'View data',
  rent_price_median: 'Average rent price',
  betaModalLine1: 'This is a beta version of the “Index” tool.',
  betaModalLine2: 'The information provided with the tool is not individual investment advice, and the information provided through it may not meet your investment goals (expectations). Realisteshall not be liable for possible losses in the event of real estate investment transactions with the use the “Index” tool.',
  betaModalLine3: 'Infographics in the form of a labeled map is a visualization of the investment attractiveness of real estate. This indicator does not guarantee future returns, investment results and does not determine future investment returns.',
  betaModalLine4: 'The use of the “Index” tool does not guarantee that the investor will complete a real estate transaction at a predetermined price.',
  betaModalLine5: 'The “Index” tool infographic is not an advertisement.',
  betaModalLine6: '“Index” tool numerical indicators are calculated based on public data on real estate objects within given territorial boundaries, reliability of which shall be within the scope of responsibilityof third parties, including legal entities that have posted information on their official websites.',
  betaModalLine7: 'Realisteshall not be responsible for any possible losses of the investor in case the  investor decides to enter into a real estate transaction.',
  showBlock: 'Show more...',
  hideBlock: 'Hide',
  bookingBtn: 'Choose apartment',
  bookingFormTitle: 'Please enter your phone number for booking',
  bookingFormTitle2: 'Please enter your phone number',
  bookingFormTitleSuccess: 'Thank you! We will contact you soon',
  viewApartments: 'View apartments',
  bookingBtnSend: 'Confirm',
  notAnInvestmentRecommendation1: 'Not an investment recommendation, data provided as information',
  notAnInvestmentRecommendation2: 'Terms of use',
  refundableDeposit: 'Refundable Deposit',
  bookingBtnForm: 'Book now',
  bookingBtnFormUnit: 'Get catalog',
  freeCancellation1: 'Free cancellation.',
  freeCancellation2: 'The deposit will be returned to your account',
  chooseApartment: 'Choose an apartment from the list',
  bedrooms: 'r',
  textSubmitFormUnit: 'Unit catalog is available upon request',
  unitTypes: 'Type of',
  state: {
    available: 'Available',
    bookingInProgress: 'Booking in progress',
    booked: 'Booked',
  },
  bookable: 'Booking in process',
  btnLayout: 'Layout',
  btnVideo: 'Video',
  btnPhoto: 'Photo',
  rentRoi: 'ROI rent/year',
  buyRoi: 'ROI sale/3 years',
  priceName: 'Price',
  filter: {
    states: 'Status',
    price: 'Price',
    bedrooms: 'Rooms',
    floorNumber: 'Floor',
    areaChargeable: 'Area',
    clear: 'Clear',
    apply: 'Apply',
  },
  bedroomsFilter: {
    studio: 'Studio',
    b1_0: '1 bedroom',
    b1_5: '1.5 bedrooms',
    b2_0: '2 bedrooms',
    b2_5: '2.5 bedrooms',
    b3_0: '3 bedrooms',
    b3_5: '3.5 bedrooms',
    b4_0: '4 bedrooms',
    b4_5: '5 bedrooms',
    more: '5+ bedrooms',
  },
  filterUnitNot: 'Nothing found.',
  filterUnitNot2: 'Try changing or\nremoving all filters.',
  btnResetFilter: 'Clear all filters',
  commission: 'commission',
  guarantee: 'Realiste\'s commitments',
  from: 'From',
  before: 'To',
  commissionHintBroker: 'Broker comission\nafter closing the deal.\nThe commission\'s size may be altered at\n the discretion of the developer.',
  commissionHintGuarantee: 'Realiste\'s commitments after the deal’s closure.\nThe amount of liabilities may be changed before closing the deal in rare cases.',
  commissionLink: 'More',
  commissionTitleBroker: 'Steps to get a developer\'s commission.',
  commissionHtmlBroker: '<p>1. Choose and book an apartment using your phone number.</p><p>2. Wait for the manager\'s call. He will help you sign the contract and go through all the steps of the deal.</p><p>3. Receive a commission to your current account within 5 days after receiving payment from the developer.</p>',
  objectChartCaption: 'Market price growth',
  commissionTitleGuarantee: 'Realiste\'s commitments',
  commissionHtmlGuarantee: '<p>The Realiste forecasts are based on artificial intelligence (AI) calculations. The Realiste trusts them and when the increase in the price of an apartment purchased by an investor from the Realiste after a period of time (12 or 24 or 36 months) turns out to be less than predicted, the company undertakes to compensate for the difference within the amount of possible compensation. In case when the price increase turns out to be greater than the forecast, the investor receives an income higher than expected.</p><p>The amount of possible compensation (% of the cost of the purchased apartment) is indicated in the object card.</p><p>Learn more from the company\'s experts: ',
  tooltipForecast: 'Price projection',
  tooltipForecastHint: 'Based on average price of last year\nand average projected price\nof the year in future',
  updateIosMsg: 'To view the graph, update IOS to the latest version',
  updateIosClose: 'Close',
  unitsStockUpdatedAt: 'Updated at',
  median: 'Median',
  toMedian: 'to median',
  tooltipForecastMedian: 'The price change in % is calculated using the formula: the price difference between the current prices to the average forecast price for 2023, 2024 and 2025 (the median price for the next year is taken, since prices may be higher and lower during the year in different months due to seasonality). Learn the methodology of forecasting price growth for the future from Realist Experts.',
  depositDisclaimerHeader: 'About the deposit',
  depositDisclaimerText: 'This payment is a confirmation of intent and the start of a booking process with a developer, the full amount of the deposit (10 000 rubles) is refundable within 3 days regardless of the decision taken on the side of the developer.',
  comissionDisclaimer: '*The commission can be changed by developer',
  successfulSendingCrypto: 'The booking request has been sent.<br>We will contact you soon.',
  successfulSendingCryptoBtn: 'Continue',
  calculatorName: 'Profit calculator',
  bookingBtnSoldOut: 'Request information',
  successfulRequestInformation: 'Application has been sent.<br>We will contact you soon.',
  impact: 'impact',
  upcomingEventsTitle: 'Upcoming events',
  aboutTitle: 'About',
  upcomingEventFuture: 'FUTURE',
  upcomingEventPast: 'PAST',
  upcomingEventQuarter: 'Q',
};

export default obj;
