const obj = {
  title: 'Фильтры',
  mobile: {
    title: 'Список фильтров'
  },
  fields: {
    totalArea: {
      label: 'Общая площадь',
      placeholderFrom: 'от',
      placeholderTo: 'до'
    },
    projectProfitYearRate: {
      label: 'Доходность от'
    },
    discountFromPriceMarketMin: {
      label: 'Дисконт от рыночной цены'
    },
    price: {
      label_both: 'Стоимость',
      label_pay_extra: 'Сумма доплаты',
      label_cash: 'Сумма на руки',
      placeholderFrom: 'от',
      placeholderTo: 'до',
      errorText: 'Цена минимум должна быть меньше цены максимум',
    },
    constructionYear: {
      label: 'Год постройки/сдачи',
      placeholderFrom: 'от',
      placeholderTo: 'до'
    },
    ageDays: {
      label: 'Свежесть объектов'
    },
    payExtraGetCash: {
      label: 'Доплатить / получить деньги на руки',
      options: {
        pay_extra: 'Доплатить',
        both: 'Все',
        cash: 'Получить деньги',
      }
    },
    repairRating: {
      label: 'Уровень ремонта не ниже',
    },
    roomsCount: {
      label: 'Кол-во комнат',
      placeholderFrom: 'от',
      placeholderTo: 'до'
    },
    buildingRating: {
      label: 'Рейтинг дома не ниже',
    },
    isHasCommentChecks: {
      label: 'Объект проверен'
    }
  },
  btn: {
    apply: 'Применить',
    reset: 'Сбросить'
  },
  message: {
    not_found: 'Объектов, соответствующих фильтрам, в данной локации нет. Попробуйте посмотреть в другой локации'
  },
  showMore: {
    expand: 'Больше фильтров',
    collapse: 'Cкрыть фильтры'
  }
};

export default obj;
