<template>
   <div>{{ formatTags(tags) }}</div>
</template>

<script>
import formatTagsMixin from '@/mixins/formatTagsMixin';

export default {
  props: ['tags'],
  mixins: [formatTagsMixin]
};
</script>
