const NOT_PARTNER_DOMAINS = ['staging.magic.realiste.ai', 'magic.realiste.ai', 'magic.realiste.ai', 'app.realiste.ai', 'my1.realiste.ai', 'my.realiste.ai', 'localhost'];

/* eslint-disable */
class PartnerData {
  #partnerDomain = location.hostname;
  #domainConfig = null;

  get isSkip() {
    return NOT_PARTNER_DOMAINS.includes(this.#partnerDomain);
  }

  get domainConfig() {
    return this.#domainConfig;
  }

  get name() {
    return this.#domainConfig.name;
  }

  get isPartner() {
    return !this.isSkip;
  }

  get locale() {
    return this.#domainConfig.locale;
  }

  get bottomBarButtonsType() {
    return this.#domainConfig.bottomBarButtonsType;
  }

  get bottomBarButtons() {
    if (this.#domainConfig.bottomBarButtonsType == 'custom_value') {
      return this.#domainConfig.bottomBarButtons;
    }

    return this.#domainConfig.bottomBarButtonsDefault[this.#domainConfig.bottomBarButtonsType];
  }

  get onboardingLinks() {
    return this.#domainConfig.onboardingLinks;
  }

  get countryCode() {
    return this.#domainConfig.countryCode;
  }

  get canSwitchTheme() {
    return this.#domainConfig.canSwitchTheme;
  }

  get canSwitchLocale() {
    return this.#domainConfig.canSwitchLocale;
  }

  get initialPage() {
    return this.#domainConfig.initialPage;
  }

  get selectedCity() {
    return this.#domainConfig.selectedCity;
  }

  get realisteLandingUrl() {
    return this.#domainConfig.realisteLandingUrl;
  }

  get telegramChannelUrl() {
    return this.#domainConfig.telegramChannelUrl;
  }

  get realisteLogo() {
    return this.#domainConfig.realisteLogo;
  }

  get logo() {
    return this.#domainConfig.files.logoUrl;
  }

  get logoMarginBottom() {
    if (!this.#domainConfig.logo) return;

    return this.#domainConfig.logo.marginBottom;
  }

  get headerBg() {
    return this.#domainConfig.headerBg;
  }

  get ownTheme() {
    if (!this.#domainConfig.ownTheme) return { colorTheme: 'light' };

    return this.#domainConfig.ownTheme;
  }

  get availableCities() {
    return this.#domainConfig.availableCities;
  }

  get modalLogo() {
    return this.#domainConfig.files.logoBackgroundWhiteUrl;
  }

  get hasOnboarding() {
    return this.#domainConfig.hasOnboarding;
  }

  get partnerName() {
    return this.#domainConfig.name;
  }

  get privacyPolicy() {
    return this.#domainConfig.privacyPolicy;
  }

  get partnerUrl() {
    return this.#domainConfig.logo.link;
  }

  get bugReport() {
    return this.#domainConfig.bugReport;
  }

  get bugReport() {
    return this.#domainConfig.bugReport;
  }

  get yandexMetricaCounter() {
    return this.#domainConfig.yandexMetricaCounter;
  }

  get isRequiredPhoneVerification() {
    return this.#domainConfig.isRequiredPhoneVerification;
  }

  get isVisibleMediaInLoader() {
    return this.#domainConfig.isVisibleMediaInLoader;
  }

  get cenLat() {
    return this.#domainConfig.cenLat;
  }

  get cenLng() {
    return this.#domainConfig.cenLng;
  }

  get zoom() {
    return this.#domainConfig.zoom;
  }

  get calcClaimPrepareResultTimeout() {
    return this.#domainConfig.calcClaimPrepareResultTimeout;
  }

  get tradeUpExchangeOptionsAreVisible() {
    return this.#domainConfig.tradeUpExchangeOptionsAreVisible;
  }

  get canCloseSignUpForm() {
    return this.#domainConfig.canCloseSignUpForm || false;
  }

  get propertyEstimationFormHasOnboarding() {
    return this.#domainConfig.propertyEstimationFormHasOnboarding;
  }

  get numberOfSecondsToDisplayAccessRequestModal() {
    return this.#domainConfig.numberOfSecondsToDisplayAccessRequestModal;
  }

  get onboardingSlides() {
    if (!this.#domainConfig.onboardingSlides) return ['propertyValuation', 'poi', 'competitors', 'tradeUp'];
    return this.#domainConfig.onboardingSlides;
  }

  get calcClaimPrepareResultAnimation() {
    return this.#domainConfig.calcClaimPrepareResultAnimation;
  }

  get calcClaimObjectTargetAction() {
    return this.#domainConfig.calcClaimObjectTargetAction;
  }

  get publicObjectTargetAction() {
    return this.#domainConfig.publicObjectTargetAction;
  }

  get tradeUpPublicObjectTargetAction() {
    return this.#domainConfig.tradeUpPublicObjectTargetAction;
  }

  get proPlanOffer() {
    return this.#domainConfig.proPlanOffer;
  }

  get disctrictInformationIsVisible() {
    return this.#domainConfig.disctrictInformationIsVisible;
  }

  get pricePurchaseIsVisible() {
    return this.#domainConfig.pricePurchaseIsVisible;
  }

  get dadataApiUrl() {
    return this.#domainConfig.dadataApiUrl;
  }

  get filtersSetArr() {
    if (!this.#domainConfig.filtersSet) return [];
    return this.#domainConfig.filtersSet;
  }

  get showNotAvailableProdutsAsAvailableInPROVersion() {
    return this.#domainConfig.showNotAvailableProdutsAsAvailableInPROVersion;
  }

  get isSiteForBrokers() {
    return this.#domainConfig.isSiteForBrokers;
  }

  get useCryptoCurrencies() {
    return this.#domainConfig.useCryptoCurrencies;
  }

  get useSiteV2IndexMapAnalgeo() {
    return this.#domainConfig.useSiteV2IndexMapAnalgeo;
  }

  get showRealisteCommitmentsOrCommissions() {
    return this.#domainConfig.showRealisteCommitmentsOrCommissions;
  }

  get isSiteWhiteLabelNoRealisteMentions() {
    return this.#domainConfig.isSiteWhiteLabelNoRealisteMentions;
  }

  getDomainConfig() {
    if (!document.__partner) return;

    this.#domainConfig = document.__partner;
  }
}

const partnerData = new PartnerData();

partnerData.getDomainConfig();

export default partnerData;
