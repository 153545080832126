<template>
  <div>
    <div class="calc-claim-counter-text">
      <p v-html="counterText"></p>
      <div class="text-center pt-2"
           v-if="btnBackIsVisible">
        <button class="btn btn-outline-primary m-auto"
                type="button"
                v-on:click="backToTheForm">
          {{ $t('btn.back') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import constants from '@/components/constants';

export default {
  computed: {
    ...mapGetters({
      calcClaimCounter: 'propertyForm/getCalcClaimCounter',
      claimIsCalculated: 'propertyForm/claimIsCalculated',
    }),

    counterText() {
      if (this.claimIsCalculated) return;
      let v = 'default';
      if (this.calcClaimCounter >= constants.CALC_CLAIM_TIMER_MAX_VALUE) v = 'moreThan60sec';

      if (this.calcClaimCounter == null) return this.$t('loader.isReady');

      return this.$t(`loader.counter.${v}`, { count: this.calcClaimCounter });
    },

    isTradeUpMap() {
      return this.$route.name == 'TradeUpMap';
    },

    btnBackIsVisible() {
      return this.calcClaimCounter >= constants.CALC_CLAIM_TIMER_MAX_VALUE;
    }
  },
  methods: {
    backToTheForm() {
      const routeName = this.isTradeUpMap ? 'TradeUp' : 'ObjectEvaluation';

      this.$emit('clearAllIntervals');

      this.$router.push({
        name: routeName,
      }).catch(() => {});
    }
  }
};
</script>
