import gql from 'graphql-tag';

export const imageProcessor = gql`
  query imageProcessor($urls: [ImageProxy__ObjectInput!]!) {
    imageProcessor(urls: $urls) {
      params
      sourceUrl
      url
    }
  }
`;
