<template>
  <div class="tooltip-badge" :class="typeShow">
    <div class="tooltip-badge--title" @click="showModal">
      <slot></slot>
    </div>
    <div v-if="typeShow === 'hover' && (transactionAt || transactionNumber || countExpositionDays)" class="tooltip-badge--body">
      <SalesInformation
        v-bind:transactionAt="transactionAt"
        v-bind:transactionNumber="transactionNumber"
        v-bind:countExpositionDays="countExpositionDays"
      />
    </div>
  </div>
</template>

<script>
import SalesInformation from '@/components/common/SalesInformation.vue';
import { mapMutations } from 'vuex';
import isMobile from '@/mixins/isMobile';

export default {
  name: 'TooltipBadge',
  mixins: [isMobile],
  props: {
    typeShow: {
      type: String,
      default: 'hover'
    },
    transactionAt: {
      type: String,
      default: ''
    },
    transactionNumber: {
      type: String,
      default: ''
    },
    countExpositionDays: {
      type: Number,
      default: 0
    },
  },
  components: {
    SalesInformation,
  },
  methods: {
    ...mapMutations('favouriteMap', ['setTooltipModalVisible', 'setTooltipModalData']),
    showModal() {
      if (this.isMobile) {
        if (this.transactionAt || this.transactionNumber || this.countExpositionDays) {
          this.setTooltipModalVisible(true);
        }
        this.setTooltipModalData({
          transactionAt: this.transactionAt,
          transactionNumber: this.transactionNumber,
          countExpositionDays: this.countExpositionDays,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tooltip-badge {
  position: relative;
  &.hover {
    .tooltip-badge--body {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 100;
      background: #fff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 16px;
      transform: scale(0) translateY(0);
      transform-origin: top right;
      transition: transform .2s ease;
      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        height: 7px;
      }
      ::v-deep .params-list li {
        font-size: 12px;
        padding: 6px 0;
      }
    }
    &:hover {
      .tooltip-badge--body {
        transform: scale(1) translateY(7px);
      }
    }
  }
}
</style>
