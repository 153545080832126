export default {
  namespaced: true,
  state: {
    objectCurrentId: null,
    filters: null,
    filtersDefault: null,
    sliderHeight: null,
    modalPromoVisible: false,
    modalPromoObjectId: undefined,
    modalPromoSendPhone: false,
    objectStoryStopAnimated: false,
    tooltipModalVisible: false,
    tooltipModalData: {
      transactionAt: '',
      transactionNumber: '',
      countExpositionDays: 0,
    },
    chartModalVisible: false,
    chartModalData: [],
    paramsChartUrl: '',
    betaChartModalVisible: false,
    exchangeRate: 0,
    exchangeRateFilter: 0,
    selectCurrency: undefined,
    objectCurrency: undefined,
    selectArea: undefined,
    objectArea: undefined,
  },
  mutations: {
    setCurrentId(state, objectCurrentId) {
      state.objectCurrentId = objectCurrentId;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setFiltersDefault(state, filtersDefault) {
      state.filtersDefault = filtersDefault;
    },
    setSliderHeight(state, sliderHeight) {
      state.sliderHeight = sliderHeight;
    },
    setModalPromoVisible(state, value) {
      state.modalPromoVisible = value;
    },
    setModalPromoObjectId(state, value) {
      state.modalPromoObjectId = value;
    },
    setModalPromoSendPhone(state, value) {
      state.modalPromoSendPhone = value;
    },
    setObjectStoryStopAnimated(state, value) {
      state.objectStoryStopAnimated = value;
    },
    setTooltipModalVisible(state, value) {
      state.tooltipModalVisible = value;
    },
    setTooltipModalData(state, value = { transactionAt: '', transactionNumber: '', countExpositionDays: 0 }) {
      state.tooltipModalData = Object.assign({}, state.tooltipModalData, value);
    },
    setChartModalVisible(state, value) {
      state.chartModalVisible = value;
    },
    setParamsChartUrl(state, value) {
      state.paramsChartUrl = value;
    },
    setChartModalData(state, value) {
      state.chartModalData = value;
    },
    setBetaChartModalVisible(state, value) {
      state.betaChartModalVisible = value;
    },
    setExchangeRate(state, value) {
      state.exchangeRate = value;
    },
    setExchangeRateFilter(state, value) {
      state.exchangeRateFilter = value;
    },
    setSelectCurrency(state, value) {
      state.selectCurrency = value;
    },
    setObjectCurrency(state, value) {
      state.objectCurrency = value;
    },
    setSelectArea(state, value) {
      state.selectArea = value;
    },
    setObjectArea(state, value) {
      state.objectArea = value;
    },
  },
  getters: {
    getObjectCurrentId: (state) => state.objectCurrentId,
    getFilters: (state) => state.filters,
    getFiltersDefault: (state) => state.filtersDefault,
    getSliderHeight: (state) => state.sliderHeight
  },
};
