import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    propertyFields: {
      price: null,
      encumbrance: false,
      objectType: '',
      buildingPropertyType: 'condo',
      roomsCount: 1,
      square: null,
      squareLiving: null,
      squareKitchen: null,
      repairRating: 0,
      city: '',
      address: '',
      buildingBuildYear: null,
      redevelopment: false,
      viewFromWindows: [],
      roomsLayout: null,
      ceilingHeight: 2.65,
      floorNumber: null,
      buildingFloorsCount: null,
      balconiesCount: 0,
      loggiasCount: 0,
      bathroomShared: 0,
      bathroomCombined: 0,
      parkingType: null,
      buildingMaterialType: null,
      additionalOptions: [],
      elevatorFreightsCount: 0,
      elevatorPassengersCount: 0,
      contactPhone: '',
      lat: null,
      lng: null,
      comment: ''
    },
    headTitle: '',
    headSubTitle: '',
    apartmentNumber: null,
    claimType: '',
    canCalcForPoint: null,
    btnSubmitTitle: '',
    currentStep: 1,
    countSteps: 4,
    addressIsValid: true,
    calcWaitingSentences: null,
    calcWaitingMedia: null,
    calcClaimCounter: 0,
    prefilledFields: [],
    claimIsCalculated: false
  },
  mutations: {
    updatePropertyFields(state, res) {
      state.propertyFields = Object.assign(state.propertyFields, res);
    },
    setHeadTitle(state, title) {
      state.headTitle = title;
    },
    setHeadSubTitle(state, title) {
      state.headSubTitle = title;
    },
    setApartmentNumber(state, title) {
      state.apartmentNumber = title;
    },
    setClaimType(state, claimType) {
      state.claimType = claimType;
    },
    checkCanCalcForPoint(state, canCalcForPoint) {
      state.canCalcForPoint = canCalcForPoint;
    },
    setBtnSubmitTitle(state, btnSubmitTitle) {
      state.btnSubmitTitle = btnSubmitTitle;
    },
    setCurrentStep(state, currentStep) {
      state.currentStep = currentStep;
    },
    setAddressIsValid(state, addressIsValid) {
      state.addressIsValid = addressIsValid;
    },
    changeCalcWaitingSentences(state, calcWaitingSentences) {
      state.calcWaitingSentences = calcWaitingSentences;
    },
    changeCalcWaitingMedia(state, calcWaitingMedia) {
      state.calcWaitingMedia = calcWaitingMedia;
    },
    changeCalcClaimCounter(state, calcClaimCounter) {
      state.calcClaimCounter = calcClaimCounter;
    },
    setPrefilledFields(state, prefilledFields) {
      state.prefilledFields = prefilledFields;
    },
    setCalcClaimIsCalculated(state, claimIsCalculated) {
      state.claimIsCalculated = claimIsCalculated;
    }
  },
  getters: {
    getPropertyFields: (state) => state.propertyFields,
    getHeadTitle: (state) => state.headTitle,
    getHeadSubTitle: (state) => state.headSubTitle,
    getApartmentNumber: (state) => state.apartmentNumber,
    getClaimType: (state) => state.claimType,
    getCanCalcForPoint: (state) => state.canCalcForPoint,
    getBtnSubmitTitle: (state) => state.btnSubmitTitle,
    getCurrentStep: (state) => state.currentStep,
    getAddressIsValid: (state) => state.addressIsValid,
    getCalcWaitingSentences: (state) => state.calcWaitingSentences,
    getCalcWaitingMedia: (state) => state.calcWaitingMedia,
    getCalcClaimCounter: (state) => state.calcClaimCounter,
    getPrefilledFields: (state) => state.prefilledFields,
    claimIsCalculated: (state) => state.claimIsCalculated
  },
};
