const privacyPolicy = {
  title: 'Privacy Policy',
  textHtml: `<div field="text" class="t-text t-text_md ">
  <h3>General provisions</h3>
  <p>This policy has been drawn up in accordance with the requirements of the Federal Law of July 27, 2006. No. 152-FZ “On Personal Data” (hereinafter referred to as the Law on Personal Data) and determines the procedure for processing personal data and measures to ensure the security of personal data taken by Realiste JSC (address: Russian Federation, 123112, Moscow, Presnenskaya nab ., 12, floor 44, office 4403, part of room 1), Realiste Real Estate JSC (address: Russian Federation, 123112, Moscow, Presnenskaya nab., 12, floor 29, room A28, room 2 (hereinafter referred to as the Operator).</p>
  1.1. The operator sets as its most important goal and condition for the implementation of its activities the observance of the rights and freedoms of a person and a citizen in the processing of his personal data, including the protection of the rights to privacy, personal and family secrets.<br>
  1.2. This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator can receive about website visitors <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>.<br>
  1.3. The performance by the User of the site of any actions aimed at using the site, including viewing or searching for information posted on the site, registering on the site, sending messages through the contact form posted on the site, as well as each subsequent access to the site, is a full and unconditional acceptance of this Policy (by acceptance) in the editions that were in effect on the relevant date, within the meaning of Part 1 of Art. 438 of the Civil Code of the Russian Federation.<br><br>
  <h3>Basic concepts used in the Policy</h3>
  2.1. Automated processing of personal data - processing of personal data using computer technology.<br>
  2.2. Blocking of personal data is a temporary suspension of the processing of personal data (unless the processing is necessary to clarify personal data).<br>
  2.3. Website - a set of graphic and information materials, as well as computer programs and databases that ensure their availability on the Internet at a network address <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>.<br>
  2.4. Personal data information system - a set of personal data contained in databases, and information technologies and technical means that ensure their processing. <br>
  2.5. Depersonalization of personal data - actions as a result of which it is impossible to determine, without the use of additional information, the ownership of personal data by a specific User or other subject of personal data. <br>
  2.6. Processing of personal data - any action (operation) or a set of actions (operations) performed with or without the use of automation tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data. <br>
  2.7. Operator - a state body, municipal body, legal entity or individual, independently or jointly with other persons organizing and (or) carrying out the processing of personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, the actions (operations) performed with personal data. <br>
  2.8. Personal data - any information relating directly or indirectly to a specific or identifiable Website User <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>.<br>
  2.9. Personal data authorized by the subject of personal data for distribution - personal data, access to an unlimited number of persons to which is provided by the subject of personal data by giving consent to the processing of personal data permitted by the subject of personal data for distribution in the manner prescribed by the Law on Personal Data (hereinafter - personal data allowed for distribution). <br>
  2.10. User – any visitor to the website <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>.<br>
  2.11. Provision of personal data - actions aimed at disclosing personal data to a certain person or a certain circle of persons. <br>
  2.12. Dissemination of personal data - any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or familiarizing with personal data of an unlimited number of persons, including the disclosure of personal data in the media, placement in information and telecommunication networks or providing access to personal data in any other way. <br>
  2.13. Cross-border transfer of personal data is the transfer of personal data to the territory of a foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity. <br>
  2.14. Destruction of personal data - any actions as a result of which personal data is irretrievably destroyed with the impossibility of further restoration of the content of personal data in the information system of personal data and (or) material carriers of personal data are destroyed. <br><br>
  <h3>Basic rights and obligations of the Operator</h3>
  3.1. The operator has the right: <br>
  – receive from the subject of personal data reliable information and / or documents containing personal data; <br>
  – in the event that the subject of personal data withdraws consent to the processing of personal data, the Operator has the right to continue processing personal data without the consent of the subject of personal data if there are grounds specified in the Law on Personal Data; <br>
  – independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of the obligations provided for by the Law on Personal Data and the regulatory legal acts adopted in accordance with it, unless otherwise provided by the Law on Personal Data or other federal laws. <br>
  3.2. The operator is obliged: <br>
  – provide the subject of personal data, at his request, with information regarding the processing of his personal data; <br>
  – organize the processing of personal data in the manner prescribed by the current legislation of the Russian Federation; <br>
  – respond to requests and requests from personal data subjects and their legal representatives in accordance with the requirements of the Personal Data Law; <br>
  – report to the authorized body for the protection of the rights of subjects of personal data, at the request of this body, the necessary information within 30 days from the date of receipt of such a request; <br>
  – publish or otherwise provide unrestricted access to this Policy regarding the processing of personal data; <br>
  – take legal, organizational and technical measures to protect personal data from unauthorized or accidental access to them, destruction, modification, blocking, copying, provision, distribution of personal data, as well as from other illegal actions in relation to personal data; <br>
  – stop the transfer (distribution, provision, access) of personal data, stop processing and destroy personal data in the manner and in the cases provided for by the Personal Data Law; <br>
  – perform other duties stipulated by the Law on Personal Data. <br> <br>
  <h3>Basic rights and obligations of personal data subjects </h3>
  4.1. Subjects of personal data have the right to: <br>
  – receive information regarding the processing of his personal data, except as otherwise provided by federal laws. The information is provided to the subject of personal data by the Operator in an accessible form, and it should not contain personal data relating to other subjects of personal data, unless there are legal grounds for disclosing such personal data. The list of information and the procedure for obtaining it is established by the Law on Personal Data; <br>
  – require the operator to clarify his personal data, block or destroy them if the personal data is incomplete, outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing, as well as take legal measures to protect their rights; <br>
  – put forward a condition of prior consent when processing personal data in order to promote goods, works and services on the market; <br>
  – to withdraw consent to the processing of personal data; <br>
  – appeal to the authorized body for the protection of the rights of subjects of personal data or in court against illegal actions or inaction of the Operator when processing his personal data; <br>
  – to exercise other rights provided for by the legislation of the Russian Federation. <br>
  4.2. Subjects of personal data are obliged to: <br>
  – provide the Operator with reliable data about yourself; <br>
  – notify the Operator about the clarification (update, change) of their personal data. <br>
  4.3. Persons who have provided the Operator with inaccurate information about themselves or information about another subject of personal data without the consent of the latter are liable in accordance with the legislation of the Russian Federation. <br> <br>
  <h3>Processing of personal data</h3>
  5.1. The Operator may process the following personal data of the User: <br>
  Full Name. <br>
  Email address. <br>
  Phone numbers. <br>
  5.2. The user of the site is in accordance with clause 1.3. The Policy gives consent to the collection and processing of depersonalized data about Users (including cookies) using Internet statistics services (Yandex Metrika and Google Analytics and others). <br>
  5.3. The above data, as well as other data, to the processing of which the Site User agrees, hereinafter in the text of the Policy are united by the general concept of Personal data. <br>
  5.4. Processing of special categories of personal data relating to race, nationality, political views, religious or philosophical beliefs, intimate life is not carried out by the Operator. <br>
  5.5. Processing of personal data permitted for distribution from among the special categories of personal data specified in Part 1 of Art. 10 of the Law on Personal Data is allowed if the prohibitions and conditions provided for in Art. 10.1 of the Personal Data Law. <br>
  5.6. The User's consent to the processing of personal data permitted for distribution is issued separately from other consents to the processing of his personal data. At the same time, the conditions provided for, in particular, Art. 10.1 of the Personal Data Law. Requirements for the content of such consent are established by the authorized body for the protection of the rights of personal data subjects. <br>
  5.6.1 Consent to the processing of personal data permitted for distribution, the User provides the Operator directly. <br>
  5.6.2 The Operator is obliged, no later than three working days from the date of receipt of the specified consent of the User, to publish information on the conditions of processing, on the existence of prohibitions and conditions for the processing by an unlimited number of persons of personal data permitted for distribution.<br>
  5.6.3 The transfer (distribution, provision, access) of personal data authorized by the subject of personal data for distribution must be terminated at any time at the request of the subject of personal data. This requirement should include the last name, first name, patronymic (if any), contact information (telephone number, e-mail address or postal address) of the subject of personal data, as well as a list of personal data, the processing of which is subject to termination. The personal data specified in this request can be processed only by the Operator to whom it is sent.<br>
  5.6.4 Consent to the processing of personal data permitted for distribution terminates from the moment the Operator receives the request specified in clause 5.6.3 of this Policy regarding the processing of personal data.<br>
  5.7. The user is in the order of clause 1.3. The Policy gives consent to the Operator for the processing of his personal data: the number of the mobile radiotelephone subscriber device, by any necessary means, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), retrieval, use, transfer (distribution, provision, access ), depersonalization, blocking, deletion, destruction of personal data using automation tools or without using them at the discretion of the Operator. In order to receive the services of the Operator and / or partners of the Operator, as well as fill in the fields of my questionnaires / applications electronically on the website <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>, in case of my repeated and subsequent requests in order to receive the services of the Operator and / or partners of the Operator. <br>
  The user consents to the processing of personal data by the Operator for 5 years from the date of granting consent on the site. <br> <br>
  <h3>Principles of personal data processing</h3>
  6.1. The processing of personal data is carried out on a legal and fair basis. <br>
  6.2. The processing of personal data is limited to the achievement of specific, predetermined and legitimate purposes. It is not allowed to process personal data that is incompatible with the purposes of collecting personal data.<br>
  6.3. It is not allowed to combine databases containing personal data, the processing of which is carried out for purposes incompatible with each other.<br>
  6.4. Only personal data that meet the purposes of their processing are subject to processing.<br>
  6.5. The content and scope of the processed personal data correspond to the stated purposes of processing. The redundancy of the processed personal data in relation to the stated purposes of their processing is not allowed.<br>
  6.6. When processing personal data, the accuracy of personal data, their sufficiency, and, if necessary, relevance in relation to the purposes of processing personal data, is ensured. The operator takes the necessary measures and / or ensures their adoption to remove or clarify incomplete or inaccurate data.<br>
  6.7. The storage of personal data is carried out in a form that allows determining the subject of personal data, no longer than required by the purposes of processing personal data, if the period for storing personal data is not established by federal law, an agreement to which the subject of personal data is a party, beneficiary or guarantor. The processed personal data is destroyed or depersonalized upon reaching the goals of processing or in case of loss of the need to achieve these goals, unless otherwise provided by federal law. <br><br>
  <h3>Purposes of personal data processing</h3>
  7.1. The purpose of processing the User's personal data: <br>
  – informing the User by sending emails, SMS messages and messages to WhatsApp, Viber, Telegram messengers, etc.;<br>
  – conclusion, execution and termination of civil law contracts;<br>
  – granting access to the User to the services, information and/or materials contained on the website <a href="https://realiste.ai" target="_blank">https://realiste.ai</a>. <br>
  7.2. The user of the site is in accordance with clause 1.3. The Policy gives consent to the Operator to send notifications to the User about new products and services, special offers and various events. The User can always opt out of receiving informational messages by sending an email to the Operator at privacy@realiste.ai with the note "Opt out of notifications about new products and services and special offers". <br>
  7.3. Anonymized data of Users collected using Internet statistics services are used to collect information about the actions of Users on the site, improve the quality of the site and its content.<br> <br>
  <h3>Legal grounds for the processing of personal data </h3>
  8.1. The legal grounds for the processing of personal data by the Operator are: <br>
  – statutory (constituent) documents of the Operator;<br>
  – contracts concluded between the operator and the subject of personal data;<br>
  – federal laws, other regulatory legal acts in the field of personal data protection;<br>
  – consent of Users to the processing of their personal data, to the processing of personal data permitted for distribution.<br>
  8.2. The Operator processes the User's personal data only if they are filled in and / or sent by the User independently through special forms located on the site <a href="https://realiste.ai" target="_blank">https://realiste.ai</a> or sent to the Operator via e-mail. By filling out the relevant forms and / or sending their personal data to the Operator, the User expresses his consent to this Policy.<br>
  8.3. The user of the site is in accordance with clause 1.3. The Policy gives the Operator consent to the processing of anonymized data about the User if this is allowed in the User's browser settings (saving cookies and using JavaScript technology is enabled).<br>
  8.4. The subject of personal data independently decides on the provision of his personal data and gives consent freely, by his own will and in his own interest. <br> <br>
  <h3>Conditions for the processing of personal data </h3>
  9.1. The processing of personal data is carried out with the consent of the subject of personal data to the processing of his personal data.<br>
  9.2. The processing of personal data is necessary to achieve the goals provided for by an international treaty of the Russian Federation or the law, to carry out the functions, powers and obligations assigned to the operator by the legislation of the Russian Federation.<br>
  9.3. The processing of personal data is necessary for the performance of an agreement to which the personal data subject is the beneficiary, as well as to conclude an agreement on the initiative of the personal data subject or an agreement under which the personal data subject will be the beneficiary.<br>
  9.4. The processing of personal data is necessary to exercise the rights and legitimate interests of the operator or third parties, or to achieve socially significant goals, provided that the rights and freedoms of the subject of personal data are not violated.<br>
  9.5. The processing of personal data is carried out, access of an unlimited number of persons to which is provided by the subject of personal data or at his request (hereinafter referred to as publicly available personal data).<br>
  9.6. Processing of personal data subject to publication or mandatory disclosure in accordance with federal law is carried out.<br><br>
  <h3>The procedure for collecting, storing, transferring and other types of processing of personal data</h3>
  10.1. The procedure for collecting, storing, transferring and other types of processing of personal data The security of personal data processed by the Operator is ensured by implementing the legal, organizational and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.<br>
  10.2. The Operator ensures the safety of personal data and takes all possible measures to exclude access to personal data of unauthorized persons.<br>
  10.3. The User's personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of applicable law or if the subject of personal data has given consent to the Operator to transfer data to a third party to fulfill obligations under a civil law contract. <br>
  10.4. In case of detection of inaccuracies in personal data, the User can update them independently by sending a notification to the Operator at the Operator's email address privacy@realiste.ai marked "Updating personal data".<br>
  10.5. The term for the processing of personal data is determined by the achievement of the purposes for which the personal data were collected, unless a different period is provided by the contract or applicable law. <br>
  The User may at any time withdraw his consent to the processing of personal data by sending a notification to the Operator by e-mail to the Operator's email address privacy@realiste.ai marked "Withdrawal of consent to the processing of personal data". <br>
  10.6. All information that is collected by third-party services, including payment systems, means of communication and other service providers, is stored and processed by the indicated persons (Operators) in accordance with their User Agreement and Privacy and Personal Data Processing Policy. The subject of personal data and / or the User is obliged to independently familiarize themselves with the specified documents in a timely manner. The Operator is not responsible for the actions of third parties, including the service providers specified in this clause.<br>
  10.7. The prohibitions established by the subject of personal data on the transfer (except for granting access), as well as on the processing or processing conditions (except for obtaining access) of personal data permitted for distribution, do not apply in cases of processing personal data in state, public and other public interests determined by law RF.<br>
  10.8. The operator, when processing personal data, ensures the confidentiality of personal data. <br>
  10.9. The condition for terminating the processing of personal data may be the achievement of the purposes of processing personal data, the expiration of the consent of the subject of personal data or the withdrawal of consent by the subject of personal data, as well as the identification of unlawful processing of personal data. <br><br>
  <h3>List of actions performed by the Operator with the received personal data</h3>
  11.1. The operator collects, records, systematizes, accumulates, stores, clarifies (updates, changes), extracts, uses, transfers (distributes, provides, accesses), depersonalizes, blocks, deletes and destroys personal data.<br>
  11.2. The operator carries out automated processing of personal data with the receipt and / or transmission of the received information via information and telecommunication networks or without it. <br><br>
  <h3>Cross-border transfer of personal data</h3>
  12.1. Before the start of the cross-border transfer of personal data, the operator is obliged to make sure that the foreign state to whose territory the transfer of personal data is supposed to be carried out provides reliable protection of the rights of subjects of personal data.<br>
  12.2. Cross-border transfer of personal data on the territory of foreign states that do not meet the above requirements can be carried out only if there is a written consent of the subject of personal data to the cross-border transfer of his personal data and / or execution of an agreement to which the subject of personal data is a party. <br><br>
  <h3>Confidentiality of personal data</h3>
  13.1. The operator and other persons who have gained access to personal data are obliged not to disclose to third parties and not to distribute personal data without the consent of the subject of personal data, unless otherwise provided by federal law. <br><br>
  <h3>Final provisions</h3>
  14.1. The user can get any clarifications on issues of interest regarding the processing of his personal data by contacting the Operator via e-mail <a href="privacy@realiste.ai" target="_blank">privacy@realiste.ai</a>. <br>
  14.2. This document will reflect any changes in the personal data processing policy by the Operator. The policy is valid indefinitely until it is replaced by a new version.<br>
  14.3. The current version of the Policy in the public domain is located on the Internet at <a href="https://realiste.ai/privacypolicy" target="_blank">https://realiste.ai/privacypolicy</a>. <br>
</div>`
};

export default privacyPolicy;
