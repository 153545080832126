const obj = {
  ai: {
    title: 'Videos, training and instructions',
    btn: 'webinars. events and instructions',
    list: {
      title: 'On the AI ​​map, it highlights the most profitable deals for buying and selling apartments in Moscow:',
      options: [
        'apartments with a discount up to 5%',
        'apartments with a discount up to 9.99%',
        'apartments with a discount from 10%'
      ],
      optionsPublic: [
        'apartments with a discount up to 6.99%',
        'apartments with a discount from 7%',
      ]
    },
    content: `<p>How to use this information:</p>
                <ol class="list-ol">
                  <li>You can buy housing at a discount for yourself.</li>
                  <li>
                  You can buy apartments at a discount and quickly resell them. So you can earn up to 15% per annum.
                  </li>
                  <li>
                    The best deals selected by AI can be invested in the Realist Flagship Fund and earn up to 30% per annum. Details are {link}
                  </li>
                </ol>`,
    linkMore: 'here.'
  },
  chart: {
    title: 'Competitors chart',
    subTitle: 'There are two modes of displaying the list of objects on the map:',
    modes: [
      'according to the market price of the current object. The market price of the current object is shown as a green line;',
      'according to the market price for each object separately.'
    ],
    legend: {
      title: 'List of symbols on the chart:',
      list: [
        'sold object;',
        'the current object;',
        'the price of the object is higher than the market price;',
        'the price of the object is within the market price;',
        'the price of the object is below the market price;',
        'the system-calculated fast resale period;',
        'the currently selected fast resale period.'
      ]
    },
    help: {
      title: 'How to read a chart'
    }
  },
  userAgreement: {
    content: `<div class="text-agreement">
                <p>
                Giglob-Moscow LLC, hereinafter referred to as the Licensor, addresses this Agreement to any individual or legal entity (hereinafter referred to as the Licensee) who enters into the Agreement, fully and unconditionally agreeing to all of its terms specified in this document.
                </p>
                <p>
                This document is a public offer, acceptance (acceptance of conditions) of a public offer is the performance of the actions provided for by the Agreement (clause 2 of article 437 of the Civil Code of the Russian Federation).
                </p>
                <p>
                If the Licensee disagrees with any of the terms of the Agreement, the Agreement is not concluded, and the Licensee has no right to use the Intellectual Property Object.
                </p>
                <ol>
                  <li>
                    <h4>BASIC TERMS</h4>
                    <ol>
                      <li>
                        <strong>Licensor</strong> – LLC "Giglob-Moscow", 123112, Moscow, Presnenskaya nabierieznaja, building 8 / 1, floor 24, room 245 / 3.
                      </li>
                      <li>
                        <strong>Licensee</strong> – is any individual or legal entity who has the right to use the Program in his interest in accordance with the requirements of the current legislation of the Russian Federation and this Agreement.
                      </li>
                      <li>
                        <strong>Acceptance</strong> - is a full and unconditional acceptance of the Offer by performing the actions specified in clause 3.1. Agreements.
                      </li>
                      <li>
                        <strong>Content</strong> - all objects available on the website, including design elements, text, graphics, illustrations, videos, computer programs, databases and other objects.
                      </li>
                      <li>
                        <strong>Personal account</strong> - is a part of the website that is closed from public access, the access of the Licensee to which is carried out using the Account.
                      </li>
                      <li>
                        <strong>Offer</strong> - is a document posted on the Internet at: {link}
                      </li>
                      <li>
                        <strong>Website</strong> - is an automated information system available on the Internet at the address (including subdomains) {link}
                      </li>
                      <li>
                        <strong>Account</strong> - is a set of information data about the Licensee, recorded in digital format and necessary to identify the Licensee and provide access to his personal data, settings and content.
                      </li>
                      <li>
                        <strong>Technical support</strong> – activities carried out by the Licensor within the limits and volumes established by him to ensure the functioning of the Program, including information and consulting support of the Licensees on the use of the Program.
                      </li>
                      <li>
                        <strong>License type</strong> – is a tariff plan chosen by the Licensee in accordance with the Licensor's Price List, published on the Internet at the following address: {link}
                      </li>
                      <li>
                        <strong>API (Application Programming Interface)</strong> - is an interface of the system interaction with third-party applications by using public methods published by the Licensor.
                      </li>
                      <li>
                        <strong>Program</strong> - is a computer program "Magic.Realiste" (both as a whole and its components), which is an objectively presented set of data and commands, including source text, database, audiovisual works included by the Licensor in the said computer program, as well as any documentation on its use.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>SUBJECT OF THE AGREEMENT</h4>
                    <ol>
                      <li>
                        The Agreement defines the procedure and conditions for using the Website at: {link}. The subject of the Agreement includes all functions and services of the Site, as well as any development or addition of new ones that are explicitly available.
                      </li>
                      <li>
                        The licensor guarantees that he is the owner of the exclusive rights to the Site specified in clause 2.1. Agreements. All exclusive rights to the Site belong to the Licensor.
                      </li>
                      <li>
                        This Agreement is concluded before or immediately at the start of using the Program and is valid throughout the entire period of its lawful use by the Licensee within the term of the copyright to it, provided that the Licensee properly observes the terms of this Agreement.
                      </li>
                      <li>
                        The Licensor grants the Licensee the right to use the Program without restrictions on the territory in the manner and on the conditions provided for by the current legislation of the Russian Federation, the Agreement and this Agreement.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>COPYRIGHT AND TRADEMARKS</h4>
                    <ol>
                      <li>
                        The program is the result of intellectual activity and an object of copyright (Computer Program), which are regulated and protected by the legislation of the Russian Federation on intellectual property and the norms of international law.
                      </li>
                      <li>
                        The algorithms of the Program and its source codes (including their parts) are a trade secret of the Licensor. Any use or use of the Program in violation of the terms of this Agreement is considered as a violation of the Licensor's rights and is a sufficient reason to deprive the User of the rights granted under this Agreement.
                      </li>
                      <li>
                        The Licensor guarantees that it has all the necessary scope of rights to the Program to provide them to the Licensee, including the documentation for the Program.
                      </li>
                      <li>
                        Responsibility for copyright infringement occurs in accordance with the current legislation of the Russian Federation.
                      </li>
                      <li>
                        This Agreement does not grant the Licensee any rights to use the Trademarks and Service Marks of the Licensor and / or its partners.
                      </li>
                      <li>
                        The Licensee may not under any circumstances remove or make obscure the information and information about copyrights, trademark rights or patents specified in the Program.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>TERMS OF USE OF THE POGRAM AND RESTRICTIONS</h4>
                    <ol>
                      <li>
                        The Licensee is granted the right to Register under this License Agreement.
                      </li>
                      <li>
                        Registration is carried out by the Licensee independently by creating and specifying his account.
                      </li>
                      <li>
                        The Licensee has the right, within the framework of this License and in accordance with the selected type of License, to place in the Account the data belonging to him, if this does not violate this License Agreement and the Legislation of the Russian Federation.
                      </li>
                      <li>
                        The Licensee has the right to change, add or delete the Program files only in cases provided for by the copyright laws of the Russian Federation.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ASSIGNMENT (TRANSFER) OF RIGHTS</h4>
                    <ol>
                      <li>
                        The Licensee, with the exception of the cases established by this Agreement, has the right to assign completely its rights and obligations under this Agreement to another authorized user once.
                      </li>
                      <li>
                        The assignment (transfer) of rights and obligations is carried out only subject to the full and unconditional consent of the new Licensee with all the terms and conditions of this Agreement and the Agreement.
                      </li>
                      <li>
                        The Licensee is obliged to provide the Licensor with complete data about the new Licensee for re-registration of the Program to him in accordance with this Agreement.
                      </li>
                      <li>
                        The assignment (transfer) of rights under this Agreement cannot be carried out indirectly or through any third party.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>TYPES OF LICENSES</h4>
                    <ol>
                      <li>
                      The licensee has the right to independently choose the appropriate type of license, the list of which is posted on the {link}
                      </li>
                      <li>
                        The Licensee has the right to use the Program without payment of remuneration under the license for review (trial version) for a limited period of time, determined by the conditions in force at the time of registration.
                      </li>
                      <li>
                        The licensee has the right to change the type of license during the entire period of its validity, while the validity period of the purchased license will be increased by the period of the paid but unused current license, taking into account the tariff cost of the new license.
                      </li>
                      <li>
                        The beginning of the license validity period is the day the Account and / or Account was created (the day of Registration).
                      </li>
                      <li>
                        If the relevant license expires and the Licensee does not acquire a new license from the list specified in clause 6.1 within 15 (Fifteen) calendar days. of this Agreement, further use of the Program by the Licensee may be limited by the Licensor at its discretion.
                      </li>
                      <li>
                        The licensor has the right to unilaterally change the types of licenses by posting a new list of them on the Internet at {link} no later than fourteen days prior to the effective date of such changes.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>PROGRAM USE ORDER</h4>
                    <ol>
                      <li>
                        <h5>Registration</h5>
                        <ol>
                          <li>
                            In order to use the Program, the Licensee must go through the Registration procedure, as a result of which a unique Account and Account will be created for the Licensee. To add Authorized Users, the Licensee must independently create accounts for them using the Program and give them access to the Account. The number of Authorized Users within one Account is determined based on the type of license.
                          </li>
                          <li>
                            For registration, the Licensee undertakes to provide accurate and complete information about himself and the Authorized Users on the issues proposed in the registration form, and to keep this information up to date. If the Licensee provides incorrect information or the Licensor has reason to believe that the information provided by him is incomplete or inaccurate, the Licensor has the right, at its discretion, to block or delete the Licensee's account, as well as prohibit the use of the Program.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          Login and password to access the Licensee's or Authorized User's Account.
                        </h5>
                        <ol>
                          <li>
                            When registering, the Licensee independently chooses a login (a unique symbolic name for the Licensee's or Authorized User's account) and a password for accessing the Account. The licensor has the right to prohibit the use of certain logins, as well as establish requirements for the login and password (length, allowed characters, etc.).
                          </li>
                          <li>
                            The Licensee or Authorized User is solely responsible for the security (resistance to guessing) of the password chosen by him, and also independently ensures the confidentiality of his password. The Licensee is solely responsible for all actions / omissions (as well as their consequences) within or using the Program under his Account, including cases of voluntary transfer or non-observance of data confidentiality for access to his account to third parties on any conditions (including contracts or agreements). At the same time, all actions within or using the Program under the Licensee's account are considered to have been performed by him, except for cases that occur after the Licensor receives from the Licensee a notification of the unauthorized use of the Program under the Licensee's account or any violation (suspicion of violation) of the confidentiality of your password.
                          </li>
                          <li>
                            The Licensee is obliged to immediately notify the Licensor of any case of unauthorized access to the Program using his Account and / or any violation (suspicion of violation) of the confidentiality of his password. For security reasons, the Licensee is obliged to independently carry out a safe shutdown under his account (the "Exit" button) at the end of each session of work with the Program. The Licensor is not responsible for the possible loss of data, as well as other consequences of any nature that may occur due to the Licensee's violation of the provisions of this part of the Agreement.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          Deleting an Account. The Licensor has the right to block and delete the Licensee's Account, including all content without giving any reason, in case of violation of the terms of the Agreement. From this moment, the restoration of the account, any information related to it, as well as access to the Program using this Account, is impossible.
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Restricting access to the API. There cannot be more than 1 API request per second for all methods in total. In case of suspicious activity through the API, the Licensor has the right to block access to the API for the Licensee's account.
                        </h5>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>USE RESTRICTIONS</h4>
                    <ol>
                      <li>
                        The Licensee and / or Authorized Users are not entitled to take actions that may entail: a) disruption of the operation of the equipment and network of the Licensor; b) disruption of the Program or limiting the ability of other users to use the Program; c) unauthorized access to the Program, as well as information, computing and network resources of the Licensor; d) causing or threatening damage to third parties, including by posting information and links to network resources, the content of which contradicts the current legislation of the Russian Federation.
                      </li>
                      <li>
                        The Licensee is not provided with the opportunity and the right to modify the Program.
                      </li>
                      <li>
                        The Licensee independently ensures the availability of equipment that meets the technical requirements for using the Program and accessing the Internet.
                      </li>
                      <li>
                        The Licensee guarantees that he has all the necessary rights to all data, computer programs or services that he uses in connection with the use of the Program, and that such actions do not violate the rights of third parties.
                      </li>
                      <li>
                        The Licensee is not entitled to use the Program in any other way other than those specified in this Agreement, as well as to copy, sell and resell it or access to it, unless the User has received such permission from the Licensor.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>TECHNICAL SUPPORT</h4>
                    <ol>
                      <li>
                        The Licensor provides Technical support to the Licensee, including on issues related to the functionality of the Program, features of operation on standard configurations of supported operating, mail and other systems in the manner and on the terms specified in the technical documentation for it.
                      </li>
                      <li>
                        The Licensee has the right to contact the Technical Support Service of the Licensor without paying additional fees.
                      </li>
                      <li>
                        To provide Technical Support, the Licensor has the right to require the Licensee to provide information regarding account data, technical characteristics of equipment and other information necessary to provide Technical Support.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>LIMITED WARRANTY AND LIABILITY</h4>
                    <ol>
                      <li>
                        The Program is provided on an “as is” basis and the Licensor does not guarantee that all of its functionality will meet the expectations of the Licensee and will be applicable for its specific purpose.
                      </li>
                      <li>
                        The Licensor does not initiate or control the placement by the Licensee of any information in the process of using the Program, does not affect its content and integrity, and at the time of posting this information does not know and cannot know whether it violates the legally protected rights and interests of third parties, international treaties and the current legislation of the Russian Federation.
                      </li>
                      <li>
                        The Licensor is not liable to the Licensee for any damage, any loss of income, profit, information or savings associated with the use or inability to use the Program, including in the event of prior notification from the Licensee of the possibility of such damage, or any claim by a third party ...
                      </li>
                      <li>
                        If errors are found while using the Program, the Licensor will take measures to correct them as soon as possible. The parties agree that the exact definition of the time for elimination of the error cannot be established, since the Program closely interacts with other computer programs of third-party developers, operating systems and hardware resources of the Licensee's computer, and the operability and time of elimination of problems do not fully depend only on the Licensor.
                      </li>
                      <li>
                        In the event that the Licensee commits actions prohibited by the provisions of this Agreement, the Licensor has the right to take measures to identify and prevent these violations without explaining the reasons and giving any notification to the Licensee.
                      </li>
                      <li>
                        For violation of the terms of this Agreement, the Licensee is liable under the laws of the Russian Federation.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>TERMS OF PROCESSING AND USE OF PERSONAL DATA</h4>
                    <ol>
                      <li>
                        Accepting the terms of this Agreement, the Licensee in accordance with Federal Law No. 152-FZ "On Personal Data" dated July 27, 2006, as well as with the Decree of the Government of the Russian Federation dated November 1, 2012 No. 1119 "on approval of requirements for the protection of personal data when processing in information systems of personal data ”, other regulatory legal acts in force at a particular moment - acting freely, of his own free will and in his interest, expresses his consent to: provide his personal data, including Surname, Name, Patronymic, email address, contact number phone number, date of birth, region, city, organization, position for their processing by the Licensor.
                      </li>
                      <li>
                        The licensor undertakes to take all necessary measures to protect the above personal data from unauthorized access or disclosure.
                      </li>
                      <li>
                        The licensor takes the following actions in relation to the processing of personal data: collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, depersonalization, blocking, deletion, destruction.
                      </li>
                      <li>
                        This consent is valid for the entire term of the License Agreement and the use of the Program by the Licensee. The Licensee understands and agrees that if this consent is withdrawn, he is deprived of the opportunity to use part or all of the services of the Program.
                      </li>
                      <li>
                        The Licensee may revoke consent to the processing of personal data by contacting the Licensor's technical support at {email}.
                      </li>
                      <li>
                        The Licensee agrees to receive, via e-mail, the address of which he indicates during registration, advertising and information messages regarding the products and services of the Licensor and its partners.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>VALIDITY, AMENDMENT AND TERMINATION OF THE AGREEMENT</h4>
                    <ol>
                      <li>
                        For all issues not regulated by this Agreement, the Parties are guided by the current legislation of the Russian Federation.
                      </li>
                      <li>
                        The licensor has the right to change the terms of this Agreement unilaterally by posting the amended text on the Internet at {link}
                      </li>
                      <li>
                        The Licensor has the right, if the Licensee violates the terms of this Agreement on the use of the Program, unilaterally terminate this Agreement.
                      </li>
                      <li>
                        Upon termination of this Agreement by any party and for any reason, the Licensee and Authorized Users must stop using the Program completely.
                      </li>
                      <li>
                        In the event that a competent court declares any provisions of this Agreement to be invalid, the Agreement continues to operate in the rest of the Agreement.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>DISPUTE RESOLUTION</h4>
                    <ol>
                      <li>
                        The claim procedure for the pre-trial settlement of disputes from the Agreement is mandatory.
                      </li>
                      <li>
                        The letters of claim are sent by the Parties by registered mail with acknowledgment of receipt to the address of the Party's location.
                      </li>
                      <li>
                        The sending of letters of claim by the Parties in a different way than specified in clause 13.2. Agreement is not allowed.
                      </li>
                      <li>
                        The term for consideration of a claim letter is 30 working days from the date of receipt of the latter by the addressee.
                      </li>
                      <li>
                        Disputes under this Agreement are resolved in court in accordance with the law.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ADDITIONAL PROVISIONS</h4>
                    <ol>
                      <li>
                        The Licensee is prohibited from using the Program to send bulk electronic messages of a commercial, advertising or other nature that are not agreed (not requested) by the recipient of the information by email or to teleconference groups ("spam"). It is not considered "spam" to send information with the consent of the recipient, with the possibility of unsubscribing.
                      </li>
                      <li>
                        The use of the Program must be carried out by the Licensee only for lawful purposes and in legal ways, taking into account the laws of the Russian Federation, the European Union and the United States.
                      </li>
                      <li>
                        The Licensee is directly responsible for the content of the Account (copy of the Program), created and maintained by the Licensee. The Licensor does not exercise preliminary control over the content of information posted and / or distributed by the Licensee, however, when the placement and distribution of such information is contrary to the law, the Licensor has the right to block or delete the corresponding Account and data without warning.
                      </li>
                      <li>
                        The Licensor does not provide the Licensee with communication services, does not organize for him the possibility of access to information systems of information and telecommunication networks, including the Internet, and does not carry out activities for the reception, processing, storage, transmission, delivery of telecommunication messages.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>LICENSOR CONTACT INFORMATION</h4>
                    <p>
                      Inquiries regarding the terms of this Agreement and Technical Support are accepted by e-mail {email}.
                    </p>
                  </li>
                </ol>
                <h4>PERFORMER</h4>
                <p>
                  Legal address: 123112, Moscow, Presnenskaya nabierieznaja, building 8 / 1, floor 24, room 245 / 3 <br />
                  account No 40702810002370001790 in Alfa-Bank JSC, Moscow <br />
                  Cor. account No 3010181020000000593 <br />
                  BIK: 044525593 <br />
                  TIN: 7736282685 <br />
                  CEO - Galtsev Alexey Aleksandrovich
                </p>
              </div>`,
  },
  selectCity: {
    title: 'Select city',
    note: 'You can change the selected city by going to the section with filters.',
    btnTitle: 'OK'
  },
  externalUrl: {
    title: 'You\'re on the free version of Magic',
    btnPrimaryTitle: 'Go to external website',
    mainText: 'The data is updated every 7 days. If there is no object under the link, then it is 90% likely to be sold this week.',
    note: {
      proText: 'To view only up-to-date data',
      linkText: 'upgrade to PRO plan'
    }
  },
  companyAbout: {
    title: 'Realiste',
    subTitle: 'About our company',
    partnerSubTitle: {
      default: 'Based on',
      domclick: 'Based on'
    },
    htmlMainDescription: `
      <p>Realiste is a technology company that evaluates and buys real estate online.</p>
      <p>Realiste technologies help to understand the market value of real estate in 10 seconds, taking into account all factors (location, negative and positive factors in the location, real estate parameters, transaction history, competition and demand) for the purchase, sale and mortgage of real estate.</p>
    `,
    media: {
      title: 'Media about Realist',
      list: {
        rbc: 'How artificial intelligence helps to make money on real estate #Realiste',
        pierwyKanal: 'Apartments are the hottest product in 2021. Told in an interview with Channel One',
        hazeta: 'The mind cannot understand Russia: the neural network has found promising real estate outside the Moscow Ring Road #Realiste',
        rt: 'How the transition of Russians to remote work can affect the value of real estate in the country #Realiste',
        viedomosti: 'Investors use preferential mortgages to buy apartments for rent',
        rbc_2: 'What will happen to real estate in the second wave of COVID #Realiste',
        theVillage: 'The people do not believe in the ruble, but believe in the meter #Realiste',
        rg: 'Areas named where apartments will rise in price due to "remoteness" #Realiste',
        forbes: 'The cost of secondary housing will continue to grow in areas with poor transport accessibility #Realiste'
      }
    },
    htmlSidebarDescription: `
      <dl class="sidebar-list">
        <dt>Website</dt>
        <dd>
          <a href="{link}" target="_blank">{link}</a>
        </dd>
        <dt>Joint-stock company Realiste</dt>
        <dt>PSRN</dt>
        <dd>1207700420311</dd>
        <dt>TIN</dt>
        <dd>9703021240</dd>
        <dt></dt>
        <dd>Unit OT 16-36 , Level 16, Central Park Offices, Dubai International Financial Centre, Dubai, UAE</dd>
      </dl>
    `,
  },
  jkRating: {
    rating: 'Rating',
    distinctive_features: 'Distinctive features',
    photo: 'Photo'
  },
  propertyValuation: {
    title: 'Realiste property valuation',
    htmlDescription: `
      <p>Realist is not a valuation company, Realist valuation is the approximate market value calculated by the algorithm based on user-entered data.</p>
      <p>It should be used for informational purposes only.</p>
    `
  },
  contactPhone: {
    title: {
      default: 'Sign up',
      verifySmsCode: 'Enter sms-code',
      tradeUp: 'Sign up',
      register: 'Sign up'
    },
    subTitle: {
      tradeUp: 'We have evaluated your home and selected the best suited options.',
      ru: 'Экспресс-отчет со сравнительным анализом: 1500руб - бесплатно за первые 3 отчета',
      en: 'Express report with comparative analysis: 15$ - free of charge for the first 3 reports',
      gb: 'Express report with comparative analysis: 15£ - free of charge for the first 3 reports'
    },
    phoneLabel: '<strong>The cost of an apartment and competitors</strong> - after a free phone confirmation by SMS',
    noSpam: 'Please sign up with your phone number.<br/>All data is SSL encrypted',
    fields: {
      privacyPolicy: {
        label: 'I agree to the processing of ',
        link: 'personal data'
      }
    },
    btn: {
      sendRequest: 'Continue claim calculation',
      startCalc: 'Calculation is started',
      backToTheForm: 'Back to form'
    },
  },
  tradeUpHowItWorks: {
    htmlContent: `
      <p>With Realiste you can move to another apartment or exchange it for a new building. To do this, you do not need to look for a buyer for your apartment.</p>
      <p>Realiste selects for you only favorable offers with a fair market price and organizes an apartment exchange, including your move to a new apartment, obtaining a mortgage.</p>
    `,
    steps: {
      title: 'How It works:',
      list: [
        'You enter your apartment',
        'Realiste calculates the best combinations with the most favorable move scenario for you, including the selection of a mortgage',
        'You choose the option you like and leave a request to the Realiste managers',
        'Managers will organize everything, you will not need to waste months of effort and expectations',
        'Realiste will buy your apartment and arrange for you to buy a new one at a time. 15 days and you own a new apartment'
      ]
    }
  },
  wannaSell: {
    subTitle: [
      'Get an offer from us for a purchase at a price from',
      'to'
    ],
    messages: {
      success: 'The claim has been successfully sent<br>We will contact you shortly',
      error: 'The claim could not be sent. Try again'
    }
  },
  disclaimer: {
    title: 'Disclaimer',
    htmlContent: `
      <p>The information provided does not constitute an individual investment recommendation under any circumstances, including in the event of an external coincidence of its requirements of regulatory legal acts for an individual investment recommendation. Any similarity of the information presented to an individual investment recommendation is coincidental. Any of these financial instruments or transactions may not fit your investment profile. The operations and (or) financial instruments mentioned in the submitted message do not, under any circumstances, guarantee the income that you may be counting on, provided that the information provided is used to make investment decisions.</p>
      <p>The company is not responsible for possible losses of the investor in the event of transactions or investments in financial instruments mentioned in the information provided. In all cases, it is the investor's job to determine whether a financial instrument or transaction is appropriate for investment objectives, investment horizon and risk tolerance.</p>
      <p>The value of investment shares can increase and decrease, investment results in the past do not determine future income, investment income is not guaranteed. Information about real estate objects, economic indicators, as well as any analytical and informational data are provided by JSC Realiste TIN 9703021240 as the owner of investment shares of closed-end mutual investment funds. All information contained in marketing and other materials of JSC Realiste TIN 9703021240, including the future yield of CEREUIF (closed-end real estate unit investment fund) units, is assumed. For a more accurate assessment, before making a decision to buy investment shares, we strongly recommend that you independently study the investment object or contact a financial advisor. All financial calculations and models are only a forecast, for the accuracy of which JSC Realiste TIN 9703021240 is not responsible.</p>
      <p>In preparing the presented materials, information was used from sources that, in the opinion of the Company's specialists, are trustworthy. Despite the prudence with which the Company's specialists reacted to the preparation of the materials, the Company does not give any guarantees regarding the accuracy and completeness of the information contained herein. The information on the pages of this site does not constitute an offer / offer to conclude an agreement.</p>
    `
  },
  externalUrlClaim: {
    fillOutClaimPurchaseType: {
      title: 'Buy/Interested',
      options: {
        as_investment: 'as investment',
        on_mortgage: 'on mortgage',
        no_mortgage: 'no mortgage',
        for_information: 'just wondering'
      }
    },
    verifyPhone: {
      title: 'We ask you to enter your phone number and indicate the purpose of filling out the form in order to make sure that you are not a robot.'
    },
    formFields: {
      comment: 'Comment'
    }
  },
  modalPromo: {
    slide1: {
      title: 'Apartments with big discount',
      description: 'Apartments for investment with a discount from 7% to 12% are available for PRO accounts'
    },
    slide2: {
      title: '2X update rate',
      description: 'The speed of updating information from the market - no more than 1 hour + filter by the freshness of offers'
    },
    slide3: {
      title: 'Repair Forecast and Estimate',
      description: 'Repair estimate calculation and price forecast after repair'
    },
    slide4: {
      title: 'All countries, all cities',
      description: 'Access to 20 cities, 5 countries and more coming soon'
    },
    getRealistPRO: 'Get Realist PRO',
    moreAboutPRO: 'More about PRO',
    form: {
      title: 'Get Realist PRO',
      description: 'Submit a request for access to all system functionality',
      btn: 'Submit Request',
      privacyPolicyText: 'By clicking the button you agree to our terms',
      privacyPolicyLink: 'Policy',
      warningText: 'Sorry, an error occurred, please try again later'
    },
    success: {
      title: 'Application Sent',
      description: 'We`ll contact you soon',
      btn: 'Close'
    }
  }
};

export default obj;
