const obj = {
  btn: {
    cancel: 'Cancel',
    submit: 'Send',
    close: 'Close'
  },
  analysisError: {
    title: 'Analysis error?',
    telegramLink: 'Go to the Telegram support group',
  },
  propertyValuation: {
    title: {
      default: 'Free and accurate estimation of an apartment in 10 seconds. Step 1 of 2',
      lenstroytrest: 'Free and accurate estimation of an apartment in 10 seconds. Step 1 of 2'
    },
    subtitle: 'Find out how much an apartment costs if the sale period is 1 week, 3 months or half a year',
    claimsCounter: 'Already rated {counter} times since June 1, 2021.',
    claimFeatures: {
      title: 'You will find out in 10 seconds',
      list: [
        'The market value of the apartment and what affects it',
        'Monthly mortgage payment',
        'What credit can banks give on the security of an apartment'
      ]
    },
    fillOutClaimNote: 'Purpose of filling out the form:',
    btn: {
      submit: {
        default: 'Show result',
        nextStep: 'Next step',
        pending: 'Sending data',
        verifyPhone: {
          default: 'Send',
          tradeUp: 'Send'
        },
        smsCodeVerification: {
          default: 'Find out now the cost of the apartment',
          tradeUp: 'Open a map with options!'
        },
        resendPhone: {
          prependText: '',
          hasInterval: 'Resend SMS in {count} sec',
          default: 'Resend SMS'
        }
      },
      showThirdStep: 'Continue',
      showFourthStep: 'More'
    },
    fillAddressNote: 'Enter the address to go to the next step',
    canCalcForPoint: {
      errorLabel: 'It is impossible to calculate the claim for these coordinates. Try to enter a new address {telegramLink} to be aware of when we can make a calculation',
      linkText: 'join our community'
    },
    error: {
      manyCalcRequestsForAddress: 'Unfortunately, we are unable to complete the calculation. There may be too many requests coming from your network. <br>Please try again later.',
      default: 'Unfortunately, an error has occurred. Please try again later.'
    },
    prefilledDataMessage: 'Please check the pre-filled data',
    showPrice: 'Show price'
  },
  offerForm: {
    offerTitle: ['We will buy this property for', 'in 3 days'],
    formTitle: 'Confirm contact details:',
    note: ['If you consider the price to be non-market, ', 'please refer to the competitive analysis'],
    btn: {
      getOffer: 'Get an offer',
      notInterested: 'Not interested',
      submit: {
        default: 'Send request',
        pending: 'Sending data',
        error: 'Submit failed',
        success: 'Success!'
      }
    }
  },
  categoryTitle: {
    tellAboutApartment: 'Tell us about the apartment',
    mainObjectParameters: 'Your Home Information',
    location: 'Enter home address:',
    objectAbout: 'About the apartment',
    square: 'Square',
    floor: 'Floor',
    balconies: 'Number of balconies',
    bathrooms: 'Number of bathrooms',
    elevator: 'Number of lifts',
    contactInfo: 'Contact info'
  },
  inputGroup: {
    appendLabel: {
      squareMeter: {
        code: 'm2',
        html: 'sqft'
      }
    }
  },
  fields: {
    problemDescription: {
      placeholder: 'Describe the problem',
      note: 'You can request a detailed calculation of the cost of an apartment, where the accuracy of AI calculations will be checked by Realist experts and will send you their conclusion.'
    },
    desiredPrice: {
      label: 'Desired selling price (How much is your apartment worth?)',
      note: 'Enter the desired price or leave empty for automatic evaluation'
    },
    burden: {
      label: 'Do you have any burden?',
      note: 'Select if there is an encumbrance for the sale (pledge, court, etc.)'
    },
    objectType: {
      label: 'Property type',
      emptyText: '-- Choose --',
      options: [
        {
          code: 'flat',
          label: 'Flat'
        },
        {
          code: 'apartments',
          label: 'Apartment'
        }
      ]
    },
    buildingPropertyType: {
      emptyText: '-- Choose type --',
      options: [
        {
          code: 'condo',
          label: 'Condo'
        }
      ]
    },
    roomsCount: {
      label: 'Number of bedrooms',
      studio: 'studio'
    },
    square: {
      label: 'Square',
      note: {
        sqm: 'Minimum 8 m',
        sqft: 'Minimum 300 ft'
      }
    },
    repairRating: {
      title: 'Condition of your home',
      label: 'How do you rate repair quality of your apartment?',
      error: 'Please add repair rating (stars from 1 to 10)'
    },
    city: {
      label: 'State'
    },
    address: {
      label: '{city} and region',
      placeholder: 'Enter the address',
      note: {
        gmap: '',
        dadata: ''
      },
      error: 'Enter the street name and exact address',
      warning: 'Please check that the house has been entered',
      canChooseAddressLabel: {
        showSelect: 'Edit',
        hideSelect: 'Hide'
      }
    },
    roomNumber: {
      label: 'Apartment number'
    },
    buildingBuildYear: {
      label: 'Building build year'
    },
    timeWaltToMetro: {
      label: 'Time walk to metro'
    },
    kadNumber: {
      label: 'Cad number'
    },
    buildingMaterialType: {
      emptyText: '-- Choose type --',
      label: 'Building material type',
      options: [
        {
          code: 'brick',
          label: 'brick'
        },
        {
          code: 'monolith',
          label: 'monolith'
        },
        {
          code: 'panel',
          label: 'panel'
        },
        {
          code: 'block',
          label: 'block'
        },
        {
          code: 'wood',
          label: 'wood'
        },
        {
          code: 'stalin',
          label: 'stalin'
        },
        {
          code: 'monolithBrick',
          label: 'brick-monolithic'
        }
      ]
    },
    redevelopment: {
      label: 'Is there any illegal redevelopment?',
      options: [
        {
          code: true,
          label: 'Yes'
        },
        {
          code: false,
          label: 'No'
        }
      ]
    },
    viewFromWindow: {
      label: 'View from window',
      options: ['To the park', 'To the forest', 'On the highway', 'To the courtyard']
    },
    roomsLayout: {
      emptyText: '-- Choose value --',
      label: 'Rooms layout',
      options: [
        {
          code: 'adjoining',
          label: 'adjoining'
        },
        {
          code: 'isolated',
          label: 'isolated'
        },
        {
          code: 'adjoiningIsolated',
          label: 'adjoining-isolated'
        }
      ]
    },
    ceilingHeight: {
      label: 'Height of ceiling'
    },
    squareLiving: {
      label: 'Living'
    },
    squareKitchen: {
      label: 'Kitchen'
    },
    floorNumber: {
      label: 'Floor'
    },
    buildingFloorsCount: {
      label: 'Number of floors'
    },
    balconiesCount: {
      label: 'Balconies'
    },
    loggiasCount: {
      label: 'Loggias'
    },
    bathroomShared: {
      label: 'Full bath',
    },
    bathroomCombined: {
      label: 'Half bath',
    },
    parkingType: {
      emptyText: '-- Choose type --',
      label: 'Parking type',
      options: [
        {
          code: 'in_yard',
          label: 'in the yard'
        },
        {
          code: 'underground',
          label: 'Underground'
        },
        {
          code: 'chaos',
          label: 'Chaotic'
        },
        {
          code: 'barrier_used',
          label: 'Behind the barrier'
        }
      ]
    },
    additionalOptions: {
      label: 'More options',
      options: ['Playground', 'Concierge', 'Protected area']
    },
    elevatorFreightsCount: {
      label: 'Freight'
    },
    elevatorPassengersCount: {
      label: 'Passengers'
    },
    contactName: {
      label: 'Name'
    },
    contactPhone: {
      label: 'Phone',
      placeholder: 'Enter a phone number',
      error: {
        validateNumber: 'Please check your phone number',
        verificateNumber: 'Sorry, an error has occurred. Please try again later.'
      }
    },
    contactMailOrTelegram: {
      label: 'Email or Telegram'
    },
    contactSmsCode: {
      label: 'We have sent an SMS confirmation code to your phone. <br> Please enter the verification code.',
      placeholder: 'Confirmation code',
      error: 'Sorry, you entered the wrong code or an error occurred. Please check the code or request a new one.'
    },
    externalUrl: {
      label: 'Link to another site (if there is one)',
      error: 'No data found for this object',
      btnText: 'Fill with data'
    },
    correctPrice: {
      label: 'Correct price'
    },
    apartmentNumber: {
      label: 'Apt.'
    },
    comment: {
      label: 'Comment'
    }
  },
  messages: {
    error: {
      part1: 'Unfortunately, we cannot yet calculate the cost of an apartment at your address and characteristics. Perhaps you have specified incorrect data somewhere.<br>In any case, we received your application and started working on the financial calculation.<br> In the meantime, we invite you to follow the updates',
      part2: 'in our Telegram channel.'
    },
    bugReport: {
      success: 'Thanks for your comment! The system has accepted your comment and will take it into account in future calculations',
      error: 'Something went wrong. Try to send a comment later or write to <a href="https://t.me/joinchat/D9E-5xbj0w0nUgieILwaQg" target="_blank">the Telegram support group</a>',
      searchForErrors: 'For every 30 errors and inaccuracies found in the data or in the assessment, we give 1 month of using the PRO version for free. Detailed information on the <a href="{link}" target="_blank">site</a>.'
    },
    success: {
      default: 'Thank you, we will contact you as soon as possible',
    }
  },
  showMore: {
    title: {
      part1: 'Tell more about the apartment',
      part2: 'and make the estimate more accurate in 10 seconds'
    }
  },
  purposes: {
    wanna_sell: {
      label: 'Want to sell',
      labelBtnCta: 'Sell ​​this apartment in 10 days',
      labelModal: 'Leave a request and receive an offer from us for the purchase of this apartment in 1 day',
      subTitleModal: 'The sale period will be 10 days or earlier if you have the necessary package of documents for the sale',
      labelModalButton: 'Sell out'
    },
    wanna_buy: {
      label: 'Want to buy',
      labelBtnCta: 'Buy with us',
      labelModal: 'Buy with us',
      subTitleModal: '',
      labelModalButton: 'Buy'
    },
    get_apt_details: {
      label: 'Get information',
      labelBtnCta: 'Get information about this unit',
      labelModal: 'Leave a request and we will send you the details',
      subTitleModal: '',
      labelModalButton: 'Get information'
    },
    collateral_evaluation: {
      label: 'Collateral evaluation',
      labelBtnCta: 'Loan secured by an apartment',
      labelModal: 'Loan secured by an apartment',
      subTitleModal: '',
      labelModalButton: 'Leave a claim'
    },
    trade_in: {
      label: 'Trade in',
      labelBtnCta: 'Trade in',
      labelModal: 'Trade in',
      subTitleModal: '',
      labelModalButton: 'Trade in'
    },
    trade_up_map: {
      label: 'Trade up options'
    },
    wanna_view: {
      label: 'Wanna view'
    }
  },
  alert: {
    checkIfAllFieldsAreFilled: 'Check if all fields are filled'
  },
  canCalculateNo: 'Calculation of this property type is not yet supported',
};

export default obj;
