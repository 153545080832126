<template>
  <div v-html="$t('modal.userAgreement.content', {
                  'link': `<a target='_blank' href='${appDomain}'>${appDomain}</a>`,
                  'email': `<a href='mailto:a1@realiste.ai'>a1@realiste.ai</a>`})">
  </div>
</template>

<script>
export default {
  computed: {
    appDomain() {
      return process.env.VUE_APP_DOMAIN;
    }
  }
};
</script>
