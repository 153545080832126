<template>
  <span v-on:click.prevent="openModal"
        v-if="inNoiseArea">
    <span v-bind:class="`divider ${sizeClass}`"
          v-if="!hideDivider"></span>
    <span v-if="showComma"
          class="mr-1">,</span>
    <svg-icon name="noise"></svg-icon>
    <a class="info-wrap">
      <svg-icon name="info"></svg-icon>
    </a>
  </span>
</template>

<script>
import updatePanelData from '@/mixins/updatePanelData';

export default {
  props: ['inNoiseArea', 'sizeClass', 'hideDivider', 'showComma'],
  mixins: [updatePanelData],
  methods: {
    openModal() {
      this.showInfoPanel({ title: this.$t('property.noiseLevel'), category: 'objectProperties', code: 'NL' });
    }
  }
};
</script>

<style lang="scss" scoped>
.noise-area {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: baseline;

  .svg-icon--noise {
    width: 1.6rem;
    height: 1.6rem;
    color: $gray-600;
  }

  &:hover {
    color: #000;

    .svg-icon--noise {
      color: #000;
    }
  }
}
</style>
