import axios from 'axios';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    phone: null,
    status: 'unauth',
  },
  mutations: {
    setPhone(state, phone) {
      state.phone = phone;
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    signIn({ commit }, credentials) {
      axios.post('https://jsonplaceholder.typicode.com/posts', credentials,
        commit('setStatus', 'running')).then((response) => {
        commit('setPhone', response.data.phone);
        commit('setStatus', 'success');
        Vue.$cookies.set('phone', response.data.phone);
        Vue.$cookies.set('status', this.state.auth.status);
      }).catch((error) => {
        console.log(error);
        commit('setStatus', 'failed');
      });
    },
  },
  getters: {
    getPhone: (state) => ((Vue.$cookies.isKey('phone')) ? Vue.$cookies.get('phone') : state.phone),
    getStatus: (state) => ((Vue.$cookies.isKey('status')) ? Vue.$cookies.get('status') : state.status),
  },
};
