import gql from 'graphql-tag';

export const exchangeRate = gql`
  query exchangeRate(
    $currencyFrom: String!
    $currencyTo: String!
    $round: Int
  ) {
    exchangeRate(
      currencyFrom: $currencyFrom
      currencyTo: $currencyTo
      round: $round
    )
  }
`;
