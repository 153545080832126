import axios from 'axios';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    selectArea: 'sqft',
    locationCurrency: 'AED',
    selectCurrency: 'AED',
    selectCryptoCurrency: 'ETH',
    selectRate: 1,
    titleObject: '',
    regionObject: '',
    isListUnits: false,
    isLoadingUnit: false,
    modalFormVisible: false,
    isPayForm: false,
    isPayBooking: false,
    chartData: null,
    videosNewlineSeparated: [],
    imageUrls: [],
    filterUnits: {
      selectFilter: '',
      visibleSelection: false,
      projectOnSaleUnitAvailableBedroomsValue: [],
      queryFilter: {
        price: {
          min: null, max: null
        },
        areaChargeable: {
          min: null, max: null
        },
        floorNumber: {
          min: null, max: null
        },
        states: [],
        bedrooms: []
      },
      ordersFilter: [{
        direction: 'asc',
        field: 'availableForBook',
      },
      {
        direction: 'asc',
        field: 'price',
      }],
    },
    numberUnit: '',
    commissionForBroker: {
      min: null,
      max: null
    },
    unitsStockUpdatedAt: undefined,
    iid: undefined,
    hasInvestmentOpportunity: false,
    soldOut: false,
    keyEvents: null,
    coordinate: {
      lat: null,
      lng: null,
    },
    mainImageUrl: null,
  },
  getters: {
    isUseFilter: (state) => {
      const isPrice = state.filterUnits.queryFilter.price.min || state.filterUnits.queryFilter.price.max;
      const isAreaChargeable = state.filterUnits.queryFilter.areaChargeable.min || state.filterUnits.queryFilter.areaChargeable.max;
      const isFloorNumber = state.filterUnits.queryFilter.floorNumber.min || state.filterUnits.queryFilter.floorNumber.max;
      const isStates = state.filterUnits.queryFilter.states.length;
      const isBedrooms = state.filterUnits.queryFilter.bedrooms.length;
      return (isPrice || isAreaChargeable || isFloorNumber || isStates || isBedrooms);
    },
    getQuery: (state, getters, rootState) => {
      const priceMin = state.filterUnits.queryFilter.price.min;
      const priceMax = state.filterUnits.queryFilter.price.max;

      const areaMin = state.filterUnits.queryFilter.areaChargeable.min;
      const areaMax = state.filterUnits.queryFilter.areaChargeable.max;

      const floorMin = state.filterUnits.queryFilter.floorNumber.min;
      const floorMax = state.filterUnits.queryFilter.floorNumber.max;
      const query = {
        states: state.filterUnits.queryFilter.states.join(','),
        bedrooms: state.filterUnits.queryFilter.bedrooms.join(',')
      };
      query.price = priceMin || priceMax ? [priceMin, priceMax] : null;
      query.area = areaMin || areaMax ? [areaMin, areaMax] : null;
      query.floor = floorMin || floorMax ? [floorMin, floorMax] : null;
      for (const [name, value] of Object.entries(query)) {
        if (Array.isArray(value)) query[name] = value.join();
      }
      // Исключаем пустые параметры
      const pickQuery = _.pickBy(query);
      const selectUnit = {
        selectArea: state.selectArea,
        selectCurrency: document?.__partner?.useCryptoCurrencies ? state.selectCryptoCurrency : state.selectCurrency,
      };

      return {
        query: _.isEmpty(pickQuery) ? null : { ...pickQuery, ...selectUnit }
      };
    },
  },
  actions: {
    exchangeRate({ commit }, payload) {
      if (payload.base === 'USD' && payload.symbols === 'AED') {
        commit('setSelectRate', 3.67);
        return;
      }
      const crypto = payload.isCrypto ? '&source=crypto' : '';
      const urlApi = payload.isApiCryptocompare
        ? `https://min-api.cryptocompare.com/data/price?fsym=${payload.base}&tsyms=${payload.symbols}`
        : `https://api.exchangerate.host/latest?base=${payload.base}&symbols=${payload.symbols}${crypto}`;
      axios
        .get(urlApi)
        .then((response) => {
          const rate = payload.isApiCryptocompare ? response.data[payload.symbols] : response.data.rates[payload.symbols];
          const rateAED = payload.base === 'USD' && payload.symbols === 'AED' ? 3.67 : 1;
          commit('setSelectRate', rate || rateAED);
        })
        .catch(error => console.log('Error api.exchangerate.host:', error));
    },
    getChart({ commit }, payload) {
      const latLng = payload && payload.lat && payload.lng ? `&lat=${payload.lat}&lng=${payload.lng}` : '';
      axios
        .get(`${process.env.VUE_APP_DATA_CHART_URL}forecast_units_relative=true${latLng}`)
        .then((response) => {
          commit('setChartData', response.data);
        })
        .catch(error => {
          console.log('Error city_area_stats.json:', error);
        });
    },
  },
  mutations: {
    setSelectArea(state, value) {
      state.selectArea = value;
    },
    setLocationCurrency(state, value) {
      state.locationCurrency = value;
    },
    setSelectCurrency(state, value) {
      state.selectCurrency = value;
    },
    setSelectCryptoCurrency(state, value) {
      state.selectCryptoCurrency = value;
    },
    setSelectRate(state, value) {
      state.selectRate = value;
    },
    setTitleObject(state, value) {
      state.titleObject = value;
    },
    setRegionObject(state, value) {
      state.regionObject = value;
    },
    setIsListUnits(state, value) {
      state.isListUnits = value;
    },
    setIsLoadingUnit(state, value) {
      state.isLoadingUnit = value;
    },
    setModalFormVisible(state, value) {
      state.modalFormVisible = value;
    },
    setIsPayForm(state, value) {
      state.isPayForm = value;
    },
    setIsPayBooking(state, value) {
      state.isPayBooking = value;
    },
    setChartData(state, value) {
      state.chartData = value;
    },
    setVideosNewlineSeparated(state, value) {
      state.videosNewlineSeparated = value;
    },
    setImageUrls(state, value) {
      state.imageUrls = value;
    },
    setSelectFilter(state, value) {
      state.filterUnits.selectFilter = value;
    },
    setVisibleSelection(state, value) {
      state.filterUnits.visibleSelection = value;
    },
    setFilterPrice(state, value) {
      state.filterUnits.queryFilter.price = value;
    },
    setFilterArea(state, value) {
      state.filterUnits.queryFilter.areaChargeable = value;
    },
    setFilterFloor(state, value) {
      state.filterUnits.queryFilter.floorNumber = value;
    },
    setFilterStates(state, value) {
      state.filterUnits.queryFilter.states = value;
    },
    setBedrooms(state, value) {
      state.filterUnits.queryFilter.bedrooms = value;
    },
    setProjectOnSaleUnitAvailableBedroomsValue(state, value) {
      state.filterUnits.projectOnSaleUnitAvailableBedroomsValue = value;
    },
    setResetFilter(state) {
      state.filterUnits.queryFilter.price = { min: null, max: null };
      state.filterUnits.queryFilter.areaChargeable = { min: null, max: null };
      state.filterUnits.queryFilter.floorNumber = { min: null, max: null };
      state.filterUnits.queryFilter.states = [];
      state.filterUnits.queryFilter.bedrooms = [];
    },
    setFilterQuery(state, value) {
      const params = _.pickBy(value);
      if (params.states) {
        state.filterUnits.queryFilter.states = params.states;
      }
      if (params.bedrooms) {
        state.filterUnits.queryFilter.bedrooms = params.bedrooms;
      }
      if (params.price) {
        state.filterUnits.queryFilter.price = { min: params.price[0] ? Number(params.price[0]) : null, max: params.price[1] ? Number(params.price[1]) : null };
      }
      if (params.area) {
        state.filterUnits.queryFilter.areaChargeable = { min: params.area[0] ? Number(params.area[0]) : null, max: params.area[1] ? Number(params.area[1]) : null };
      }
      if (params.floor) {
        state.filterUnits.queryFilter.floorNumber = { min: params.floor[0] ? Number(params.floor[0]) : null, max: params.floor[1] ? Number(params.floor[1]) : null };
      }
    },
    setOrdersFilter(state, value) {
      state.filterUnits.ordersFilter = value;
    },
    setNumberUnit(state, value) {
      state.numberUnit = value;
    },
    setCommissionForBroker(state, value) {
      state.commissionForBroker.min = value.min;
      state.commissionForBroker.max = value.max;
    },
    setUnitsStockUpdatedAt(state, value) {
      state.unitsStockUpdatedAt = value;
    },
    setIid(state, value) {
      state.iid = value;
    },
    setHasInvestmentOpportunity(state, value) {
      state.hasInvestmentOpportunity = value;
    },
    setSoldOut(state, value) {
      state.soldOut = value;
    },
    setKeyEvents(state, value) {
      state.keyEvents = value;
    },
    setCoordinate(state, value) {
      state.coordinate.lat = value.lat;
      state.coordinate.lng = value.lng;
    },
    setMainImageUrl(state, value) {
      state.mainImageUrl = value;
    },
  },
};
