const bottomNavbar = [
  {
    pathName: {
      default: 'FavouriteObjectsMap'
    },
    icon: {
      en: 'map',
      ru: 'map'
    },
    key: 'map',
  },
  {
    beta: false,
    icon: {
      en: 'portfolio',
      ru: 'portfolio'
    },
    key: 'shivaAi',
    url: 'https://my.realiste.io',
    isPro: true,
    external: true
  },
  {
    pathName: {
      default: 'DealsAll'
    },
    beta: false,
    url: 'https://deals.realiste.ai',
    icon: {
      en: 'deals',
      ru: 'deals'
    },
    key: 'deals',
    external: true
  },
  {
    pathName: {
      default: 'ObjectEvaluation',
      hasCalcClaim: 'ObjectEvaluationResult'
    },
    icon: {
      en: 'coin',
      ru: 'ruble'
    },
    key: 'propertyValuation'
  },
  {
    pathName: {
      default: 'TradeUp',
      hasCalcClaim: 'TradeUpMap'
    },
    icon: {
      en: 'property-exchange',
      ru: 'property-exchange'
    },
    key: 'tradeUp'
  },
  {
    beta: false,
    pathName: {
      default: 'MapAnalytics'
    },
    icon: {
      en: 'analytics',
      ru: 'analytics'
    },
    key: 'mapAnalytics'
  }
];

export default {
  computed: {
    bottomBarOrder() {
      return this.$domainConfig.bottomBarButtons;
    },

    navbarList() {
      let arr = bottomNavbar.filter(el => this.bottomBarOrder.includes(el.key));

      if (this.showNotAvailableProducts) {
        arr = bottomNavbar;
        bottomNavbar.forEach(el => {
          if (!this.bottomBarOrder.includes(el.key)) el.showProLabel = true;
        });
      }

      return this.sortArray(this.bottomBarOrder, arr);
    },

    isPro() {
      return this.$store.getters.isPro;
    },

    showNotAvailableProducts() {
      return this.$domainConfig.showNotAvailableProdutsAsAvailableInPROVersion;
    },

    shivaLink() {
      const objId = this.$route.path.includes('object/') ? this.$route.params.id : '';
      const path = 'https://admin.realiste.ai/';
      const proLink = objId ? `${path}calculation-log/${objId}` : path;
      return this.isPro ? proLink : this.$domainConfig.realisteLandingUrl;
    },
  },

  methods: {
    sortArray(arrOrder, arr) {
      return arr.sort((a, b) => {
        let indexOfA = 100;
        let indexOfB = 100;
        arrOrder.forEach((el, index) => {
          if (el === a.key) indexOfA = index;
          if (el === b.key) indexOfB = index;
        });

        return indexOfA - indexOfB;
      });
    }
  }
};
