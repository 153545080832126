<template>
  <div class="d-inline-flex align-items-baseline"
       v-if="price">
    <span v-bind:class="`divider ${sizeClass}`"
          v-if="!hideDivider"></span>
    <amount-currency v-bind:code="currency"
                     v-bind:val="price"
                     v-bind:parentTypename="parentTypename"
                     v-bind:btnCustomClassName="btnCustomClassName"></amount-currency>
  </div>
</template>

<script>
export default {
  props: {
    currency: String,
    price: Number,
    sizeClass: String,
    hideDivider: {
      default: false,
      type: Boolean
    },
    parentTypename: String,
    btnCustomClassName: String
  },
};
</script>
