export const parseTrackRecord = (entries) => {
  const obj = {
    commonStatistic: [],
    deals: []
  };

  const commonData = [
    {
      row: 3,
      col: 13,
      fieldName: 'boughtTotal',
      unit: 'RUB'
    },
    {
      row: 3,
      col: 17,
      fieldName: 'totalDeals',
    },
    {
      row: 4,
      col: 13,
      fieldName: 'operationProfit',
      unit: 'RUB'
    },
    {
      row: 5,
      col: 13,
      fieldName: 'netProfitPaidToInvestors',
      unit: 'RUB'
    },
    {
      row: 7,
      col: 13,
      fieldName: 'notPaidNetProfitToInvestors',
      unit: 'RUB'
    },
    {
      row: 7,
      col: 17,
      fieldName: 'countProjects',
    },
    {
      row: 9,
      col: 13,
      fieldName: 'soldTotal',
      unit: 'RUB'
    },
    {
      row: 9,
      col: 17,
      fieldName: 'countSuccesfulProjects',
    },
    {
      row: 11,
      col: 13,
      fieldName: 'IRR',
      unit: '%'
    },
    {
      row: 11,
      col: 13,
      fieldName: 'portfolioAnnualReturn',
      unit: '%'
    },
    {
      row: 11,
      col: 17,
      fieldName: 'countUnprofitableProjects',
    },
    {
      row: 13,
      col: 17,
      fieldName: 'countActiveProjects',
    },
    {
      row: 15,
      col: 13,
      fieldName: 'averageProjectDuration',
      unit: 'day'
    },
    {
      row: 16,
      col: 13,
      fieldName: 'portfolioVolalityRatio',
      unit: '%'
    },
    {
      row: 17,
      col: 13,
      fieldName: 'countHighYieldDeals',
      unit: '%'
    },
    {
      row: 19,
      col: 13,
      fieldName: 'modifiedSharpeRatio',
    },
    {
      row: 21,
      col: 13,
      fieldName: 'averageRiskFreeReturnOnDeposits',
      unit: '%'
    }
  ];

  const parseDate = (str) => {
    const arr = str.split('.');
    const modifiedDate = `${arr[2]}/${arr[1]}/${arr[0]}`;
    return new Date(modifiedDate) || null;
  };

  const getDate = (val) => {
    const isDate = val instanceof Date;
    const v = isDate ? val : parseDate(val);
    return v.getFullYear();
  };

  obj.actualDate = entries[1][7];

  commonData.forEach(el => obj.commonStatistic.push({ name: el.fieldName, val: entries[el.row][el.col], unit: el.unit }));

  const createDealObj = ((el) => {
    const dealObj = {};

    const realtorNameCustom = el[0] < 46 || el[0] == 48 ? 'Алексей Гальцев' : 'Реалист';

    dealObj.id = el[0];
    dealObj.realtorName = el[1] || realtorNameCustom;
    dealObj.projectName = el[7];
    dealObj.address = el[8];
    dealObj.status = el[6].toLowerCase();
    dealObj.cadNumber = el[9];
    dealObj.investmentAmount = el[10];
    dealObj.netProfitPortfolio = el[11];
    dealObj.forecastNetProfit = el[12];
    dealObj.pricePortfolio = el[13];
    dealObj.IRR = el[14];
    dealObj.propertyType = el[15];
    dealObj.dateSale = el[16];
    dealObj.datePurchase = el[17];
    dealObj.projectDuration = el[19];
    dealObj.investorProfit = el[21];
    dealObj.sheetName = el[23];
    dealObj.dateSaleYear = getDate(dealObj.dateSale);
    dealObj.datePurchaseYear = getDate(dealObj.datePurchase);
    dealObj.imageAvatar = el[2];

    obj.deals.push(dealObj);
  });

  entries.forEach((el, index) => {
    return index > 24 && el[0] != '' && el.length > 0 ? createDealObj(el) : null;
  });

  const resultObj = obj.deals.reduce((r, a) => {
    if (a.propertyType.toLowerCase() == 'земельный участок') {
      r[a.projectName] = r[a.projectName] || [];
      r[a.projectName].push(a);
    } else {
      r[a.address] = a;
    }

    return r;
  }, {});

  Object.entries(resultObj).forEach(([key, value]) => {
    if (value.length > 1) {
      return value.map((el, index) => el.landIndex = index);
    }
  });

  const resultArr = [];

  Object.values(resultObj).forEach(el => {
    if (el.length) {
      el.forEach(innerEl => resultArr.push(innerEl));
    } else {
      resultArr.push(el);
    }

    return false;
  });

  obj.deals = resultArr;

  return obj;
};

export const parseSpreadsheets = (entries) => {
  const res = [];
  entries.forEach((el, i) => {
    res.push({ id: i, name: el.properties.title });
  });
  return res;
};

export const parseDealData = (entries, propertyType, landIndex) => {
  const res = {
    demand: {},
    legalCheck: {}
  };

  const demandItems = [];
  const demandRowStart = entries.findIndex(el => el[0] == 'Спрос');
  const legalCheckRowStart = entries.findIndex(el => el[0] == 'Юр. проверка');
  const projectStatusRowStart = entries.findIndex(el => el[4] == 'Текущий статус проекта');
  const demandTotalCountersRow = entries.findIndex(el => el[0] == 'ИТОГО' || el[0] == 'ИТОГО ') != -1 ? entries.findIndex(el => el[0] == 'ИТОГО' || el[0] == 'ИТОГО ') : '';
  const projectStages = [];
  const isApartment = propertyType == 'apartment';
  const isLand = propertyType == 'land';

  let projectStageIndex = 0;

  if (isApartment) {
    res.area = entries[6][1];
    res.projectProfitYearRate = entries[16][13];
    res.expectedSaleDate = entries[16][11];
    res.netProfit = entries[16][12];
    res.price = entries[16][2];
    res.pricePurchase = entries[9][1];
    res.additionalInvestments = entries[10][1];
  }

  if (isLand) {
    res.area = entries[16 + landIndex][1];
    res.projectProfitYearRate = entries[16 + landIndex][13];
    res.expectedSaleDate = entries[16 + landIndex][11];
    res.netProfit = entries[16 + landIndex][12];
    res.price = entries[16 + landIndex][2];
    res.pricePurchase = entries[9][1] / (entries[6][1] - entries[7][1]) * entries[16 + landIndex][1];
    res.additionalInvestments = entries[10][1] / (entries[6][1] - entries[7][1]) * entries[16 + landIndex][1];
  }

  entries.some((el, index) => {
    if (index > +demandRowStart + 1) {
      demandItems.push({
        date: el[0],
        comment: el[1],
        price: el[2],
        countAdViews: el[3],
        countCalls: el[4],
        countAdRotated: el[5]
      });
    }

    return index == demandTotalCountersRow;
  });

  const legalCheckTitleList = [];
  const legalCheckValList = [];

  entries.some((el, index) => {
    if (index > +legalCheckRowStart + 2 && el[0] != '') {
      legalCheckTitleList.push({
        title: el[0],
        rowNumber: index,
      });
    }

    if (index > +legalCheckRowStart + 2 && el[1] != '') {
      legalCheckValList.push({
        val: el[1],
        rowNumber: index,
      });
    }

    return index == entries.length;
  });

  const getLegalCheckOption = (rowIndexFirst, rowIndexLast, code) => {
    return legalCheckTitleList.slice(rowIndexFirst, rowIndexLast).map((el, i) => {
      const legalCheckNameCell = legalCheckValList.filter(elInner => el.rowNumber == elInner.rowNumber)[0];
      const val = legalCheckNameCell ? legalCheckNameCell.val : '-';
      return {
        code: `${code}_${i + 1}`,
        val
      };
    });
  };

  entries.some((el, index) => {
    if (index > +projectStatusRowStart + 2) {
      projectStageIndex += 1;

      projectStages.push({
        link: el[4].includes('http') ? el[4].substring(el[4].indexOf('https')) : null,
        status: el[10],
        estimatedFinishDate: el[11],
        actualFinishDate: el[12],
        code: `B${projectStageIndex}`
      });
    }
    return index == +projectStatusRowStart + 30;
  });

  res.demand.items = demandItems;
  res.projectStages = projectStages;
  res.legalCheck.mainData = isApartment ? getLegalCheckOption(1, 3, 'A1') : getLegalCheckOption(5, 7, 'A1');
  res.legalCheck.primaryRisks = isApartment ? getLegalCheckOption(5, 11, 'A2') : getLegalCheckOption(9, 16, 'A2');
  res.legalCheck.objData = isApartment ? getLegalCheckOption(12, 19, 'A3') : getLegalCheckOption(17, 25, 'A3');
  res.legalCheck.lossRightsRisks = isApartment ? getLegalCheckOption(20, 29, 'A4') : getLegalCheckOption(26, 38, 'A4');

  return res;
};
