const obj = {
  ai: {
    title: 'Видео, обучение и инструкция',
    btn: 'вебинары. ивенты и инструкции',
    list: {
      title: 'На карте AI подсвечивает самые выгодные сделки для купли-продажи квартир в Москве:',
      options: [
        'квартиры с дисконтом до 5%',
        'квартиры с дисконтом до 9.99%',
        'квартиры с дисконтом от 10%'
      ],
      optionsPublic: [
        'квартиры с дисконтом до 6.99%',
        'квартиры с дисконтом от 7%',
      ]
    },
    content: `<p>Как использовать эту информацию:</p>
                <ol class="list-ol">
                  <li>Можно приобрести жилье со скидкой для себя.</li>
                  <li>
                    Можно покупать квартиры с дисконтом и быстро перепродавать. Так можно
                    заработать до 15% годовых.
                  </li>
                  <li>
                    В лучшие сделки, отобранные AI, можно инвестировать в рамках
                    Флагманского Фонда Реалист и зарабатывать до 30% годовых. Подробности
                    {link}
                  </li>
                </ol>`,
    linkMore: 'здесь.'
  },
  chart: {
    title: 'График конкурентов',
    subTitle: 'На карте есть два режима отображения списка объектов:',
    modes: [
      'относительно рыночной цены текущего объекта. Рыночная цена текущего объекта представлена в виде зеленой прямой;',
      'относительно рыночной цены по каждому объекту в отдельности.'
    ],
    legend: {
      title: 'Список обозначений на графике:',
      list: [
        'объект продан',
        'текущий объект',
        'объект выше рыночной цены, <span class="legend-max-sm-br">{legend} - сила спроса</span>',
        'объект в пределах рыночной цены, <span class="legend-max-sm-br">{legend} - сила спроса</span>',
        'объект ниже рыночной цены, <span class="legend-max-sm-br">{legend} - сила спроса</span>',
        'рассчитанный системой срок быстрой перепродажи',
        'выбранный в данный момент срок быстрой перепродажи'
      ]
    },
    help: {
      title: 'Как читать график'
    }
  },
  userAgreement: {
    content: `<div class="text-agreement">
                <p>
                  ООО “Гиглоб-Москва”, именуемое в дальнейшем Лицензиар, адресует настоящее
                  Соглашение любому физическому или юридическому лицу (далее Лицензиат),
                  который заключает Соглашение, полностью и безоговорочно соглашаясь со
                  всеми его условиями , указанными в настоящем документе.
                </p>
                <p>
                  Настоящий документ является публичной офертой (публичным предложением),
                  акцептом (принятием условий) публичной оферты является совершение действий
                  , предусмотренных Соглашением (п.2 ст.437 Гражданского кодекса Российской
                  Федерации).
                </p>
                <p>
                  В случае несогласия Лицензиата с какими-либо условиями Соглашения,
                  Соглашение не заключается, и Лицензиат не имеет права использовать Объект
                  интеллектуальной собственности.
                </p>
                <ol>
                  <li>
                    <h4>ОСНОВНЫЕ ТЕРМИНЫ</h4>
                    <ol>
                      <li>
                        <strong>Лицензиар</strong> – ООО «Гиглоб-Москва», 123112, город
                        Москва, Пресненская набережная, дом 8 строение 1, эт 24 пом 245м ком
                        3.
                      </li>
                      <li>
                        <strong>Лицензиат</strong> – любое физическое или юридическое лицо,
                        которое имеет право на использование Программы в своем интересе в
                        соответствии с требованиями действующего законодательства РФ и
                        настоящего Соглашения.
                      </li>
                      <li>
                        <strong>Акцепт</strong> - полное и безоговорочное принятие Оферты
                        путем осуществления действий, указанных в пункте 3.1. Соглашения.
                      </li>
                      <li>
                        <strong>Контент</strong> - все объекты, доступные на Сайте, в том
                        числе элементы дизайна, текст, графические изображения, иллюстрации,
                        видео, программы для ЭВМ, базы данных и другие объекты.
                      </li>
                      <li>
                        <strong>Личный кабинет</strong> - закрытая от публичного доступа
                        часть Сайта, доступ Лицензиата к которой осуществляется с
                        использованием Учетной записи.
                      </li>
                      <li>
                        <strong>Оферта</strong> - настоящий документ, размещенный в сети
                        Интернет по адресу:
                        {link}
                      </li>
                      <li>
                        <strong>Сайт</strong> - автоматизированная информационная система,
                        доступная в сети Интернет по адресу (включая поддомены) :
                        {link}
                      </li>
                      <li>
                        <strong>Учетная запись</strong> - совокупность информационных данных
                        о Лицензиате, зафиксированная в цифровом формате и необходимая для
                        опознания Лицензиата и предоставления доступа к его личным данным,
                        настройкам и контенту.
                      </li>
                      <li>
                        <strong>Техническая поддержка</strong> – мероприятия, осуществляемые
                        Лицензиаром в установленных им пределах и объемах для обеспечения
                        функционирования Программы, включая информационно-консультационную
                        поддержку Лицензиатов по вопросам использования Программы.
                      </li>
                      <li>
                        <strong>Тип лицензии</strong> – тарифный план, избираемый
                        Лицензиатом, согласно Прайс-листу Лицензиара, публикуемый в сети
                        интернет по следующему адресу:
                        {link}
                      </li>
                      <li>
                        <strong>API (Application Programming Interface)</strong> - интерфейс
                        взаимодействия системы со сторонними приложениями путем
                        использования публичных методов, опубликованных Лицензиаром.
                      </li>
                      <li>
                        <strong>Программа</strong> - Программа для ЭВМ «Маgic.Realiste» (как
                        в целом, так и ее компоненты), являющаяся представленной в
                        объективной форме совокупностью данных и команд, в том числе
                        исходного текста, базы данных, аудиовизуальных произведений,
                        включенных Лицензиаром в состав указанной программы для ЭВМ, а также
                        любая документация по ее использованию.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ПРЕДМЕТ СОГЛАШЕНИЯ</h4>
                    <ol>
                      <li>
                        Соглашение определяет порядок и условия использования Сайта по
                        адресу:
                        {link} В предмет Соглашения входят все функции и сервисы Сайта, а также
                        любое их развитие или добавление новых , доступные в явном виде.
                      </li>
                      <li>
                        Лицензиар гарантирует, что он является правообладателем
                        исключительных прав на Сайт, указанный в пункте 2.1. Соглашения. Все
                        исключительные права на Сайт принадлежат Лицензиару.
                      </li>
                      <li>
                        Настоящее Соглашение заключается до или непосредственно в момент
                        начала использования Программы и действует на протяжении всего срока
                        ее правомерного использования Лицензиатом в пределах срока действия
                        авторского права на нее при условии надлежащего соблюдения
                        Лицензиатом условий настоящего Соглашения.
                      </li>
                      <li>
                        Лицензиар предоставляет Лицензиату право использования Программы без
                        ограничения по территории в порядке и на условиях, предусмотренных
                        действующим законодательством Российской Федерации, Договором и
                        настоящим Соглашением.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>АВТОРСКИЕ ПРАВА И ТОВАРНЫЕ ЗНАКИ</h4>
                    <ol>
                      <li>
                        Программа является результатом интеллектуальной деятельности и
                        объектом авторских прав (Программа для ЭВМ), которые регулируются и
                        защищены законодательством Российской Федерации об интеллектуальной
                        собственности и нормами международного права.
                      </li>
                      <li>
                        Алгоритмы работы Программы и ее исходные коды (в том числе их части)
                        являются коммерческой тайной Лицензиара. Любое их использование или
                        использование Программы в нарушение условий настоящего Соглашения
                        рассматривается как нарушение прав Лицензиара и является достаточным
                        основанием для лишения Пользователя предоставленных по настоящему
                        Соглашению прав.
                      </li>
                      <li>
                        Лицензиар гарантирует, что обладает всем необходимым объемом прав на
                        Программу для предоставления их Лицензиату, включая документацию к
                        Программе.
                      </li>
                      <li>
                        Ответственность за нарушение авторских прав наступает в соответствии
                        с действующим законодательством Российской Федерации.
                      </li>
                      <li>
                        Настоящим Соглашением Лицензиату не предоставляются никакие права на
                        использование Товарных Знаков и Знаков Обслуживания Лицензиара и/или
                        его партнеров.
                      </li>
                      <li>
                        Лицензиат не может ни при каких условиях удалять или делать
                        малозаметными информацию и сведения об авторских правах, правах на
                        товарные знаки или патенты, указанные в Программе.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ И ОГРАНИЧЕНИЯ</h4>
                    <ol>
                      <li>
                        Лицензиату предоставляется право Регистрации в рамках настоящего
                        Лицензионного соглашения.
                      </li>
                      <li>
                        Регистрация, осуществляется Лицензиатом самостоятельно путем
                        создания и указания своей учетной записи.
                      </li>
                      <li>
                        Лицензиат имеет право в рамках настоящей Лицензии и в соответствии с
                        выбранным типом Лицензии размещать в Аккаунте принадлежащие ему
                        данные, если это не нарушает настоящее Лицензионное соглашение и
                        Законодательство РФ.
                      </li>
                      <li>
                        Лицензиат вправе изменять, добавлять или удалять файлы Программы
                        только в случаях, предусмотренных законодательством Российской
                        Федерации об авторском праве.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>УСТУПКА (ПЕРЕДАЧА) ПРАВ</h4>
                    <ol>
                      <li>
                        Лицензиат за исключением случаев, установленных настоящим
                        Соглашением, имеет право однократно уступить полностью свои права и
                        обязанности по настоящему Соглашению другому авторизованному
                        пользователю.
                      </li>
                      <li>
                        Уступка (передача) прав и обязанностей осуществляется только при
                        условии полного и безоговорочного согласия нового Лицензиата со
                        всеми положениями и условиями настоящего Соглашения и Договора.
                      </li>
                      <li>
                        Лицензиат обязан предоставить Лицензиару полные данные о новом
                        Лицензиате для перерегистрации на него Программы в соответствии с
                        настоящим Соглашением.
                      </li>
                      <li>
                        Уступка (передача) прав по настоящему Соглашению не может быть
                        осуществлена косвенно или через какое-либо третье лицо.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ТИПЫ ЛИЦЕНЗИЙ</h4>
                    <ol>
                      <li>
                        Лицензиат вправе самостоятельно выбирать соответствующий тип
                        лицензии, перечень которых размещен в сети Интернет по адресу:
                        {link}
                      </li>
                      <li>
                        Лицензиат вправе без выплаты вознаграждения использовать Программу в
                        рамках лицензии для ознакомления (триальной версии) в ограниченный
                        период времени, определяемый действующими на момент регистрации
                        условиями.
                      </li>
                      <li>
                        Лицензиат имеет право изменить тип лицензии в течение всего срока ее
                        действия, при этом срок действия приобретаемой лицензии будет
                        увеличен на срок оплаченной, но неизрасходованной текущей лицензии с
                        учетом тарифной стоимости новой лицензии.
                      </li>
                      <li>
                        Начало срока действия лицензии - день создания Учетной записи и/или
                        Аккаунта (день Регистрации).
                      </li>
                      <li>
                        В случае окончания срока действия соответствующей лицензии и
                        неприобретения Лицензиатом в течение 15 (Пятнадцати) календарных
                        дней новой лицензии из перечня, указанного в п. 6.1. настоящего
                        Соглашения, дальнейшее использование Программы Лицензиатом может
                        быть ограничено Лицензиаром на его усмотрение.
                      </li>
                      <li>
                        Лицензиар вправе изменять в одностороннем порядке типы лицензий,
                        путем размещения нового их перечня в сети Интернет по адресу
                        {link}
                        не позднее 14 (Четырнадцати) дней до даты введения в действие таких
                        изменений.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ПОРЯДОК ИСПОЛЬЗОВАНИЯ ПРОГРАММЫ</h4>
                    <ol>
                      <li>
                        <h5>Регистрация</h5>
                        <ol>
                          <li>
                            Для того чтобы воспользоваться Программой Лицензиату необходимо
                            пройти процедуру Регистрации, в результате которой для
                            Лицензиата будет создана уникальная Учетная запись и Аккаунт.
                            Для добавления Авторизованных пользователей Лицензиату
                            необходимо самостоятельно средствами Программы создать им
                            учетные записи и дать доступ к Аккаунту. Количество
                            Авторизованных пользователей в рамках одного Аккаунта
                            определяется исходя из типа лицензии.
                          </li>
                          <li>
                            Для регистрации Лицензиат обязуется предоставить достоверную и
                            полную информацию о себе и Авторизованных пользователях по
                            вопросам, предлагаемым в форме регистрации, и поддерживать эту
                            информацию в актуальном состоянии. Если Лицензиат предоставляет
                            неверную информацию или у Лицензиара есть основания полагать,
                            что предоставленная им информация неполна или недостоверна,
                            Лицензиар имеет право по своему усмотрению заблокировать либо
                            удалить учетную запись Лицензиата, а также запретить
                            использование Программы.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          Логин и пароль для доступа к Учетной записи Лицензиата или
                          Авторизованного пользователя.
                        </h5>
                        <ol>
                          <li>
                            При регистрации Лицензиат самостоятельно выбирает себе логин
                            (уникальное символьное имя учетной записи Лицензиата или
                            Авторизованного пользователя) и пароль для доступа к Учетной
                            записи. Лицензиар вправе запретить использование определенных
                            логинов, а также устанавливать требования к логину и паролю
                            (длина, допустимые символы и т.д.).
                          </li>
                          <li>
                            Лицензиат или Авторизованный пользователь самостоятельно несут
                            ответственность за безопасность (устойчивость к угадыванию)
                            выбранного им пароля, а также самостоятельно обеспечивает
                            конфиденциальность своего пароля. Лицензиат самостоятельно несет
                            ответственность за все действия/бездействие (а также их
                            последствия) в рамках или с использованием Программы под своей
                            Учетной записью, включая случаи добровольной передачи или
                            несоблюдения конфиденциальности данных для доступа к его учетной
                            записи третьим лицам на любых условиях (в том числе по договорам
                            или соглашениям). При этом все действия в рамках или с
                            использованием Программы под учетной записью Лицензиата
                            считаются произведенными им самим, за исключением случаев,
                            имеющих место после получения Лицензиаром от Лицензиата
                            направленного в порядке, предусмотренном настоящим Соглашением,
                            уведомления о несанкционированном использовании Программы под
                            учетной записью Лицензиата или о любом нарушении (подозрениях о
                            нарушении) конфиденциальности своего пароля.
                          </li>
                          <li>
                            Лицензиат обязан немедленно уведомить Лицензиара о любом случае
                            несанкционированного доступа к Программе с использованием его
                            Учетной записи и/или о любом нарушении (подозрениях о нарушении)
                            конфиденциальности своего пароля. В целях безопасности,
                            Лицензиат обязан самостоятельно осуществлять безопасное
                            завершение работы под своей учетной записью (кнопка «Выход») по
                            окончании каждой сессии работы с Программой. Лицензиар не
                            отвечает за возможную потерю данных, а также другие последствия
                            любого характера, которые могут произойти из-за нарушения
                            Лицензиатом положений этой части Соглашения.
                          </li>
                        </ol>
                      </li>
                      <li>
                        <h5>
                          Удаление Учетной записи. Лицензиар вправе заблокировать и удалить
                          Учетную запись Лицензиата, включая весь контент без объяснения
                          причин, в случае нарушения условий Соглашения. С этого момента
                          восстановление учетной записи, какой-либо информации, относящейся
                          к ней, а равно доступов к Программе с использованием этой Учетной
                          записи - невозможны.
                        </h5>
                      </li>
                      <li>
                        <h5>
                          Ограничение доступа к API. Не может быть выполнено более 1 запроса
                          к API в секунду суммарно ко всем методам. В случае подозрительной
                          активности через API, Лицензиар вправе заблокировать доступ к API
                          для аккаунта Лицензиата.
                        </h5>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ОГРАНИЧЕНИЯ ИСПОЛЬЗОВАНИЯ</h4>
                    <ol>
                      <li>
                        Лицензиат и/или Авторизованные пользователи не вправе совершать
                        действия, которые могут повлечь: а) нарушение функционирования
                        оборудования и сети Лицензиара; б) нарушение работы Программы или
                        ограничение возможностей других пользователей в использовании
                        Программы; в) несанкционированный доступ к Программе, а также
                        информационно-вычислительным и сетевым ресурсам Лицензиара; г)
                        причинение либо угрозу причинения ущерба третьим лицам, в том числе
                        путем размещения информации и ссылок на ресурсы сети, содержание
                        которых противоречит действующему законодательству РФ.
                      </li>
                      <li>
                        Лицензиату не предоставляется возможность и право модификации
                        Программы.
                      </li>
                      <li>
                        Лицензиат самостоятельно обеспечивает наличие оборудования,
                        соответствующего техническим требованиям для использования Программы
                        и доступа к сети Интернет.
                      </li>
                      <li>
                        Лицензиат гарантирует, что он имеет все необходимые права на все
                        данные, компьютерные программы или сервисы, которые используются им
                        в связи с использованием Программы, и что такие действия не нарушают
                        прав третьих лиц.
                      </li>
                      <li>
                        Лицензиат не вправе использовать Программу иными способами, кроме
                        указанных в настоящем Соглашении, а также копировать, продавать и
                        перепродавать ее или доступ к ней, кроме тех случаев, когда
                        Пользователь получил такое разрешение от Лицензиара.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ТЕХНИЧЕСКАЯ ПОДДЕРЖКА</h4>
                    <ol>
                      <li>
                        Лицензиар осуществляет Техническую поддержку Лицензиата, в том числе
                        по вопросам, связанным с функциональностью Программы, особенностями
                        эксплуатации на стандартных конфигурациях поддерживаемых
                        операционных, почтовых и иных систем в порядке и на условиях,
                        указанных в технической документации к ней.
                      </li>
                      <li>
                        Лицензиат вправе обращаться в службу Технической поддержки
                        Лицензиара без выплаты дополнительного вознаграждения.
                      </li>
                      <li>
                        Для осуществления Технической поддержки Лицензиар вправе потребовать
                        от Лицензиата предоставления информации, касающейся данных учетной
                        записи, технических характеристик оборудования и другую необходимую
                        для оказания Технической поддержки информацию.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ОГРАНИЧЕННАЯ ГАРАНТИЯ И ОТВЕТСТВЕННОСТЬ</h4>
                    <ol>
                      <li>
                        Программа предоставляется по принципу «как есть» и Лицензиар не
                        гарантирует, что все ее функциональные возможности будут отвечать
                        ожиданиям Лицензиата и смогут быть применимы для конкретной его
                        цели.
                      </li>
                      <li>
                        Лицензиар не инициирует и не контролирует размещение Лицензиатом
                        любой информации в процессе использования Программы, не влияет на ее
                        содержание и целостность, а также в момент размещения указанной
                        информации не знает и не может знать - нарушает ли она охраняемые
                        законом права и интересы третьих лиц, международные договоры и
                        действующее законодательство Российской Федерации.
                      </li>
                      <li>
                        Лицензиар не несет ответственности перед Лицензиатом за любой ущерб,
                        любую потерю доходов, прибыли, информации или сбережений, связанных
                        с использованием или с невозможностью использования Программы, в том
                        числе в случае предварительного уведомления со стороны Лицензиата о
                        возможности такого ущерба, или по любому иску третьей стороны.
                      </li>
                      <li>
                        Если при использовании Программы будут обнаружены ошибки, Лицензиар
                        предпримет меры для их исправления в максимально короткие сроки.
                        Стороны соглашаются, что точное определение срока устранения ошибки
                        не может быть установлено, так как Программа тесно взаимодействует с
                        другими программами для ЭВМ сторонних разработчиков, операционными
                        системами и аппаратными ресурсами компьютера Лицензиата и
                        работоспособность и время устранения проблем в полной мере не
                        зависят только от Лицензиара.
                      </li>
                      <li>
                        В случае совершения Лицензиатом действий, запрещенных нормами
                        настоящего Соглашения, Лицензиар вправе без объяснения причин и
                        какого-либо уведомления Лицензиата предпринять меры, выявляющие и
                        предотвращающие указанные нарушения.
                      </li>
                      <li>
                        За нарушение условий настоящего Соглашения Лицензиатом наступает
                        ответственность, предусмотренная законодательством Российской
                        Федерации.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>УСЛОВИЕ ОБРАБОТКИ И ИСПОЛЬЗОВАНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</h4>
                    <ol>
                      <li>
                        Принимая условия настоящего Соглашения, Лицензиат в соответствии с
                        Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006
                        года, а также с Постановлением Правительства РФ от 1 ноября 2012
                        года № 1119 “об утверждении требований к защите персональных данных
                        при обработке в информационных системах персональных данных”, других
                        нормативно-правовых актов, действующих в конкретный момент -
                        действуя свободно, своей волей и в своем интересе, выражает свое
                        согласие на: предоставление своих персональных данных, включающих
                        Фамилию, Имя, Отчество, адрес электронной почты, номер контактного
                        телефона, дату рождения, регион, город, организацию, должность для
                        их обработки Лицензиаром.
                      </li>
                      <li>
                        Лицензиар обязуется принимать все необходимые меры для защиты
                        указанных выше персональных данных от неправомерного доступа или
                        раскрытия.
                      </li>
                      <li>
                        Лицензиар осуществляет следующие действия в отношении обработки
                        персональных данных: сбор, запись, систематизация, накопление,
                        хранение, уточнение (обновление, изменение), извлечение,
                        использование, обезличивание, блокирование, удаление, уничтожение.
                      </li>
                      <li>
                        Настоящее согласие действует в течение всего срока действия
                        Лицензионного соглашения и использование Лицензиатом Программы.
                        Лицензиат понимает и соглашается с тем, что в случае отзыва данного
                        согласия он лишается возможности использовать часть или все сервисы
                        Программы.
                      </li>
                      <li>
                        Лицензиат может отозвать согласие на обработку персональных данных,
                        обратившись в техническую поддержку Лицензиара по адресу
                        {email}.
                      </li>
                      <li>
                        Лицензиат соглашается на получение посредством электронной почты,
                        адрес которой он указывает при регистрации, рекламно-информационных
                        сообщений, касающихся продукции и услуг Лицензиара и его партнеров.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ДЕЙСТВИЕ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ СОГЛАШЕНИЯ</h4>
                    <ol>
                      <li>
                        По всем вопросам, не урегулированным настоящим Соглашением, Стороны
                        руководствуются действующим законодательством Российской Федерации.
                      </li>
                      <li>
                        Лицензиар имеет право изменять условия настоящего Соглашения в
                        одностороннем порядке путем размещения измененного текста в сети
                        Интернет по адресу
                        {link}
                      </li>
                      <li>
                        Лицензиар имеет право в случае нарушения Лицензиатом условий
                        настоящего Соглашения по использованию Программы, в одностороннем
                        порядке расторгнуть настоящее Соглашение.
                      </li>
                      <li>
                        При расторжении настоящего Соглашения любой стороной и по любым
                        основаниям Лицензиат и Авторизованные пользователи обязаны
                        прекратить использование Программы полностью.
                      </li>
                      <li>
                        В случае если компетентный суд признает какие-либо положения
                        настоящего Соглашения недействительными, Соглашение продолжает
                        действовать в остальной части.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>РАЗРЕШЕНИЕ СПОРОВ</h4>
                    <ol>
                      <li>
                        Претензионный порядок досудебного урегулирования споров из
                        Соглашения является обязательным.
                      </li>
                      <li>
                        Претензионные письма направляются Сторонами заказным почтовым
                        отправлением с уведомлением о вручении по адресу местонахождения
                        Стороны.
                      </li>
                      <li>
                        Направление Сторонами претензионных писем иным способом, чем указано
                        в п.13.2. Соглашения, не допускается.
                      </li>
                      <li>
                        Срок рассмотрения претензионного письма составляет 30 рабочих дней
                        со дня получения последнего адресатом.
                      </li>
                      <li>
                        Споры по настоящему Соглашению разрешаются в судебном порядке в
                        соответствии с законодательством.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4>
                    <ol>
                      <li>
                        Лицензиату запрещается использование Программы для осуществления
                        массовых рассылок электронных сообщений коммерческого, рекламного и
                        иного характера, не согласованных (не запрошенных) получателем
                        информации по электронной почте или в группы телеконференций
                        ("спам"). Не рассматривается как "спам" рассылка информации с
                        согласия получателя, при возможности отказа от подписки.
                      </li>
                      <li>
                        Использование Программы должно осуществляться Лицензиатом только для
                        законных целей и законными способами с учетом законодательства РФ,
                        Европейского союза и США.
                      </li>
                      <li>
                        За содержание Аккаунта (копии Программы), создаваемых и
                        поддерживаемых Лицензиатом данных, несет ответственность
                        непосредственно сам Лицензиат. Лицензиар не осуществляет
                        предварительного контроля за содержанием размещаемой и/или
                        распространяемой Лицензиатом информации, однако когда размещение и
                        распространение такой информации противоречит законодательству,
                        Лицензиар вправе заблокировать или удалить соответствующий Аккаунт и
                        данные без предупреждения.
                      </li>
                      <li>
                        Лицензиар не предоставляет Лицензиату услуги связи, не организует
                        для него возможность доступа к информационным системам
                        информационно-телекоммуникационных сетей, в том числе к сети
                        Интернет, и не осуществляет деятельность по приему, обработке,
                        хранению, передаче, доставки сообщений электросвязи.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>КОНТАКТНАЯ ИНФОРМАЦИЯ ЛИЦЕНЗИАРА</h4>
                    <p>
                      Обращения по вопросам условий настоящего Соглашения и Технической
                      поддержки принимаются по адресу электронной почты
                      {email}.
                    </p>
                  </li>
                </ol>
                <h4>ИСПОЛНИТЕЛЬ</h4>
                <p>
                  Юр.адрес: 123112, город Москва, Пресненская набережная, дом 8 строение 1,
                  эт 24 пом 245м ком 3 <br />
                  р/c No 40702810002370001790 в АО “Альфа-банк”, г. Москва <br />
                  Кор. счет No 3010181020000000593 <br />
                  БИК: 044525593 <br />
                  ИНН: 7736282685 / КПП: 773601001 <br />
                  Генеральный директор -Гальцев Алексей Александрович
                </p>
              </div>`,
  },
  selectCity: {
    title: 'Выберите город',
    note: 'Изменить выбранный город можно перейдя в раздел с фильтрами.',
    btnTitle: 'Выбрать'
  },
  externalUrl: {
    title: 'Вы на бесплатной версии Magic',
    btnPrimaryTitle: 'Перейти на другой сайт',
    mainText: 'Данные обновляются раз с 7 дней. Если объекта по ссылке уже нет, то с 90% вероятностью он продан на этой неделе.',
    note: {
      proText: 'Для просмотра только актуальных данных',
      linkText: 'перейдите на план PRO'
    }
  },
  companyAbout: {
    title: 'Реалист',
    subTitle: 'О компании',
    partnerSubTitle: {
      default: 'На технологии',
      domclick: 'Усилено'
    },
    experience: 'Опыт работы: с 2006 года',
    htmlMainDescription: `
      <p>Реалист – это технологическая компания, которая оценивает и покупает недвижимость онлайн.</p>
      <p>Технологии Реалист помогают понять рыночную стоимость недвижимости за 10 секунд с учетом всех факторов (локации, негативных и позитивных факторов в локации, параметров недвижимости, истории сделок, конкуренции и спроса) для покупки, продажи и залога недвижимости.</p>
    `,
    media: {
      title: 'СМИ о Реалист',
      list: {
        rbc: 'Как искусственный интеллект помогает зарабатывать на недвижимости  #Realiste',
        pierwyKanal: 'Апартаменты - самый горячий товар 2021. Рассказали в интервью "Первому каналу"',
        hazeta: 'Умом Россию не понять: нейросеть нашла перспективную недвижимость за МКАД #Realiste',
        rt: 'Как переход россиян на удалённую работу может повлиять на стоимость недвижимости в стране #Realiste',
        viedomosti: 'Инвесторы используют льготную ипотеку, чтобы покупать квартиры для сдачи в аренду',
        rbc_2: 'Что будет с недвижимостью при второй волне COVID #Realiste',
        theVillage: 'Народ не верит в рубль, а верит в метр #Realiste',
        rg: 'Названы районы, квартиры в которых подорожают из-за "удаленки" #Realiste',
        forbes: 'Стоимость вторичного жилья будет и дальше расти в районах с плохой транспортной доступностью #Realiste'
      }
    },
    htmlSidebarDescription: `
      <dl class="sidebar-list">
        <dt>Сайт</dt>
        <dd>
          <a href="{link}" target="_blank">{link}</a>
        </dd>
        <dt>Акционерное Общество Реалист</dt>
        <dt>ОГРН</dt>
        <dd>1207700420311</dd>
        <dt>ИНН</dt>
        <dd>9703021240</dd>
        <dt></dt>
        <dd>Unit OT 16-36 , Level 16, Central Park Offices, Dubai International Financial Centre, Dubai, UAE</dd>
      </dl>
    `,
  },
  jkRating: {
    rating: 'Рейтинг',
    distinctive_features: 'Отличительные черты',
    photo: 'Фото'
  },
  propertyValuation: {
    title: 'Оценка Реалист',
    htmlDescription: `
      <p>Реалист не является оценочной компанией, оценка Реалист - это примерная рыночная стоимость, рассчитанная алгоритмом на основе введенных пользователем данных.</p>
      <p>Ее следует использовать для ознакомительных целей.</p>
    `
  },
  contactPhone: {
    title: {
      default: 'Регистрация по номеру телефона',
      verifySmsCode: 'Введите код',
      tradeUp: 'Регистрация по номеру телефона',
      register: 'Регистрация по номеру телефона'
    },
    phoneLabel: '<strong>Стоимость квартиры и конкуренты</strong> – после бесплатного подтверждения телефона по СМС',
    noSpam: 'Введите номер телефона для регистрации в системе.<br/>Данные зашифрованы с SSL',
    fields: {
      privacyPolicy: {
        label: 'Нажимая кнопку «{btnName}», вы соглашаетесь с нашей',
        link: 'Политикой'
      }
    },
    btn: {
      sendRequest: 'Продолжить расчёт заявки',
      startCalc: 'Мы уже начали расчёт для вас',
      backToTheForm: 'Вернуться назад к форме'
    },
    subTitle: {
      ru: 'Экспресс-отчет со сравнительным анализом: 1500руб - бесплатно за первые 3 отчета',
      en: 'Express report with comparative analysis: 15$ - free of charge for the first 3 reports',
      gb: 'Express report with comparative analysis: 15£ - free of charge for the first 3 reports'
    }
  },
  tradeUpHowItWorks: {
    htmlContent: `
      <h4>(обмен квартиры с выгодой)</h4>
      <p>С Реалист вы можете переехать в другую квартиру или обменять на новостройку. Для этого не нужно искать покупателя на свою квартиру.</p>
      <p>Реалист подбирает для вас только выгодные предложения с справедливой рыночной ценой и организует обмен квартиры, включая ваш переезд в новую квартиру, получение ипотеки.</p>
    `,
    steps: {
      title: 'Как работает:',
      list: [
        'Вы указываете вашу квартиру',
        'Система находит квартиры для обмена у девелоперов и на вторичном рынке по самым низким ценам и сразу подбирает лучшую ипотечную ставку',
        'Вы сразу видите какие есть сейчас квартиры без доплаты для обмена. Используйте фильтры и найдёте топ 10 квартир на всем рынке для вас за 5 минут.',
      ]
    }
  },
  wannaSell: {
    subTitle: [
      'Получите от нас предложение о покупке по цене от',
      'до'
    ],
    messages: {
      success: 'Заявка успешно отправлена<br>Мы свяжемся с вами в ближайшее время',
      error: 'Заявку не удалось отправить. Попробуйте еще раз'
    }
  },
  disclaimer: {
    title: 'Дисклеймер',
    htmlContent: `
      <p>Представленная информация не является индивидуальной инвестиционной рекомендацией ни при каких условиях, в том числе при внешнем совпадении её содержания с требованиями нормативно-правовых актов, предъявляемых к индивидуальной инвестиционной рекомендации. Любое сходство представленной информации с индивидуальной инвестиционной рекомендацией является случайным. Какие-либо из указанных финансовых инструментов или операций могут не соответствовать вашему инвестиционному профилю. Упомянутые в представленном сообщении операции и (или) финансовые инструменты ни при каких обстоятельствах не гарантируют доход, на который вы, возможно, рассчитываете, при условии использования предоставленной информации для принятия инвестиционных решений.</p>
      <p>Компания не несёт ответственности за возможные убытки инвестора в случае совершения операций либо инвестирования в финансовые инструменты, упомянутые в представленной информации. Во всех случаях определение соответствия финансового инструмента либо операции инвестиционным целям, инвестиционному горизонту и толерантности к риску является задачей инвестора.</p>
      <p>Стоимость инвестиционных паев может увеличиваться и уменьшаться, результаты инвестирования в прошлом не определяют доходы в будущем, доход от инвестиций не гарантирован. Информация об объектах недвижимости, экономические показатели, а также любые аналитические и информационные данные представлены АО "Реалист" ИНН 9703021240 на правах владельца инвестиционных паев закрытых паевых инвестиционных фондов. Вся информация, содержащаяся в маркетинговых и иных материалах АО "Реалист" ИНН 9703021240, в том числе о будущей доходности паев ЗПИФН, является предположительной. Для более точной оценки перед принятием решения о покупке инвестиционных паев мы настоятельно рекомендуем самостоятельно изучить объект инвестирования или обратиться к финансовому советнику. Все финансовые расчеты и модели являются только прогнозом, за достоверность которого АО "Реалист" ИНН 9703021240 не несет ответственности.</p>
      <p>При подготовке представленных материалов была использована информация из источников, которые, по мнению специалистов Компании, заслуживают доверия. Невзирая на осмотрительность, с которой специалисты Компании отнеслись к подготовке материалов, Компания не дает никаких гарантий в отношении достоверности и полноты содержащейся здесь информации. Информация на страницах данного сайта не является офертой/предложением заключить договор.</p>
    `
  },
  externalUrlClaim: {
    fillOutClaimPurchaseType: {
      title: 'Купить/Интересно',
      options: {
        as_investment: 'в качестве инвестиции',
        on_mortgage: 'в ипотеку',
        no_mortgage: 'без ипотеки',
        for_information: 'интересно'
      }
    },
    verifyPhone: {
      title: 'Мы просим вас ввести свой номер телефона и&nbsp;указать цель заполнения формы для того, чтобы убедиться что вы не робот'
    },
    formFields: {
      comment: 'Комментарий'
    }
  },
  modalPromo: {
    slide1: {
      title: 'Квартиры с большим дисконтом',
      description: 'Квартиры для инвестиций с дисконтом от 7% до 12% доступны PRO аккаунтам'
    },
    slide2: {
      title: '2X скорость обновления',
      description: 'Скорость обновления информации с рынка - не более 1 часа + фильтр по свежести предложений'
    },
    slide3: {
      title: 'Прогноз и смета ремонта',
      description: 'Расчет сметы ремонта и прогноз цены после ремонта'
    },
    slide4: {
      title: 'Все страны, все города',
      description: 'Доступ к 20 городам, 5 странам и скоро больше'
    },
    getRealistPRO: 'Получить Реалист PRO',
    moreAboutPRO: 'Подробнее о PRO',
    form: {
      title: 'Получить Реалист PRO',
      description: 'Оставьте заявку на доступ ко всему функционалу системы',
      btn: 'Оставить заявку',
      privacyPolicyText: 'Нажимая на кнопку, вы соглашаетесь с нашей',
      privacyPolicyLink: 'Политикой',
      warningText: 'К сожалению, произошла ошибка, пожалуйста попробуйте позже'
    },
    success: {
      title: 'Заявка отправлена',
      description: 'Скоро мы свяжемся с вами',
      btn: 'Закрыть'
    }
  }
};

export default obj;
