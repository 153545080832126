const obj = {
  inTheOfferList: '在報價列表中',
  inFavoriteList: '在最喜歡的列表中',
  emptyList: '您的報價列表為空',
  backToIndex: '返回索引',
  objectsInOffer: '提供的對象',
  objectsInFavorite: '最喜歡的對象',
  download: '下載',
  createLink: '與Realiste的聯繫',
  remove: '消除',
  editProfit: '編輯利潤',
  totalInvestment: '總投資額',
  totalProfit: '利潤總額',
  totalEquityBefore: '之前的總股本',
  totalEquityAfter: '之後的總股本',
  roiTotal: '總投資回報率',
  potentialRentAfterHandover: '交房後的潛在租金',
  potentialRoiAfterHandover: '移交後一年的潛在投資回報率租金',
  removeAllOffers: '刪除所有優惠？',
  deleteFromList: '從列表中刪除？',
  cancel: '取消',
  delete: '刪除',
  linkCreated: '已創建鏈接',
  copyLink: '複製鏈接',
  linkToOffer: '優惠鏈接',
  offers: '優惠',
  personalOffer: '個人報價',
  pleaseReview: '尊敬的客戶，請查看此優惠',
  personalManager: '您的私人管家',
  apply: '申請',
  profileForm: {
    title: '創建您的鏈接',
    nameAndLastName: {
      placeholder: '名字和姓氏',
      errorMsg: '請輸入你的名字',
    },
    phone: {
      placeholder: '電話號碼',
      errorMsg: '請檢查您的電話號碼',
    },
    email: {
      placeholder: '電子郵件',
      errorMsg: '請查看你的郵箱',
    },
    telegram: {
      placeholder: '電報帳戶（可選）',
      errorMsg: '請檢查您的電報帳戶',
    },
    whatsApp: {
      placeholder: 'WhatsApp 帳戶（可選）',
      errorMsg: '請檢查您的 WhatsApp 帳戶',
    },
    clientName: {
      placeholder: '客戶名稱',
      errorMsg: '請檢查客戶名稱'
    },
    messageForClient: {
      placeholder: '給客戶的留言',
      errorMsg: '請查看客戶留言',
    },
    create: '創造',
    cancel: '取消',
    next: '下一個',
  },
  createLinkRequestError: '發生意外的錯誤。 請稍後再試',
  saveChanges: '保存更改',
  totalProfitFromProject: '項目利潤總額',
  creatingLink: '正在創建鏈接...',
  copied: '鏈接已復制',
  poweredOnRealiste: '採用 Realiste 技術',
  personalOfferError: '發生意外的錯誤。 請稍後再試',
  favorites: '收藏夾',
  hello: '你好',
  pdfLoading: '加載中...',
  openUnit: '查看單位信息',
  openProject: '查看項目信息',
  whiteLabelLink: '沒有現實主義的鏈接',
  downloadPdf: '下載PDF文件',
};

export default obj;
