const table = {
  thead: {
    sellToRealiste: 'Sell to Realiste',
    sellByYourOwn: 'Sell by your own'
  },
  saleTerm: 'Sale term, days',
  serviceFee: 'Service fee 3%',
  urgentSaleCommission: 'Urgent sale commission 9%',
  bargain: 'Bargain 2.5%',
  rent: 'Rent 0.2%',
  instantAmountOfMoney: 'Instant amount of money',
  amountOfMoneyIn120days: 'Amount of money in 120 days',
  totalPrice: 'TOTAL price:',
  in3Days: '(in 3 days)',
  in90Days: '(in 90 days)',
  aboutRealiste: 'About Realiste'
};

export default table;
