<template>
  <div>
    <div class="user-agreement-wrap">
      <div class="center-holder">
        <p class="text-not-investment-advice"
           v-if="estimationResultsIsVisible">
          {{ $t('disclaimer.info') }}
          <a v-on:click.prevent="showInfoPanel(disclaimerInfo)"
             class="style-link">{{ $t('disclaimer.linkText') }}</a>
        </p>
        <a v-on:click.prevent="showInfoPanel({title, customComponent: 'UserAgreement'})"
           class="user-agreement">{{ $t('userAgreement.linkTitle') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import updatePanelData from '@/mixins/updatePanelData';
import { mapGetters } from 'vuex';

export default {
  mixins: [updatePanelData],
  computed: {
    ...mapGetters({
      isDeveloper: 'isDeveloper',
    }),
    title() {
      return this.$t('userAgreement.title');
    },
    disclaimerInfo() {
      return {
        title: this.$t('modal.disclaimer.title'),
        htmlDescription: this.$t('modal.disclaimer.htmlContent')
      };
    },
    estimationResultsIsVisible() {
      return !this.isDeveloper || this.$route.name != 'TradeUpCard';
    }
  },
};
</script>
