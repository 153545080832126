export default {
  namespaced: true,
  state: {
    calculatorReady: false,
    favorites: {
      items: []
    },
    brokerProfile: {
      name: null,
      phone: null,
      email: null,
      avatar: null,
      telegram: null,
      whatsApp: null,
      image: null, // оригинальная картинка
      avatarCoordinates: null, // координаты аватарки
    },
    currentCalcInputsOutputs: null,
  },
  mutations: {
    setCalculatorReady(state, value) {
      state.calculatorReady = value;
    },
    setFavorites(state, value) {
      if (value === null) {
        state.favorites.items = [];
      } else {
        state.favorites.items = [...value.items];
      }
    },
    setBrokerProfile(state, value) {
      if (value === null) return null;
      state.brokerProfile.avatar = value.avatar;
      state.brokerProfile.name = value.name;
      state.brokerProfile.phone = value.phone;
      state.brokerProfile.email = value.email;
      state.brokerProfile.telegram = value.telegram;
      state.brokerProfile.whatsApp = value.whatsApp;
      state.brokerProfile.image = value.image;
      state.brokerProfile.avatarCoordinates = value.avatarCoordinates;
    },
    setCurrentCalcInputsOutputs(state, value) {
      state.currentCalcInputsOutputs = value;
    }
  }
};
