/* eslint-disable */
import Vue from 'vue';

let theme = 'default';

const loadTheme = (theme) => {
  if (theme != 'default') {
    import('@/assets/scss/main.global.' + theme + ".scss")
  } else {
    import('@/assets/scss/main.global.scss');
  }
}

if (Vue.prototype.$domainConfig.ownTheme.name) {
  theme = Vue.prototype.$domainConfig.ownTheme.name;
}
else {
  theme = window.__realisteStorage.getItem('theme') == 'dark' ? 'dark' : 'default';
}

loadTheme(theme);
