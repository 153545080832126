<template>
  <span v-if="buildingRating"
        class="building-rating-wrap"
        v-on:click.prevent="openModal">
    <span v-bind:class="`divider ${sizeClass}`" v-if="!hideDivider"></span>
    <span v-if="showComma"
          class="mr-1">,</span>
    <span class="mr-1"
          v-if="!short">{{ $t('property.buildingRating') }}</span>
    <span v-bind:class="buildingRatingClass"
          v-if="!short">
      {{ buildingRating }}
    </span>
    <span v-else>{{ buildingRating }}</span>
    <a v-bind:class="`info-wrap ${sizeClass}`"
       v-if="!short">
      <svg-icon name="info"></svg-icon>
    </a>
  </span>
</template>

<script>
import updatePanelData from '@/mixins/updatePanelData';

export default {
  props: ['buildingRating', 'sizeClass', 'short', 'hideDivider', 'showComma', 'coordinate'],
  mixins: [updatePanelData],
  computed: {
    buildingRatingClass() {
      return [
        `building-rating ${this.sizeClass}`,
        {
          sm: this.buildingRating.length == 2 && this.sizeClass == 'sm',
          xs: this.buildingRating.length == 3,
        }
      ];
    }
  },
  methods: {
    openModal() {
      this.showInfoPanel({ title: this.$t('property.buildingRating'), customComponent: 'JKRating', coordinate: this.coordinate });
    }
  }
};
</script>
