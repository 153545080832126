<template>
  <div class="table-wrap jk-rating" v-if="ratingList">
    <div class="jk-rating-head">
      <div class="jk-rating-child">{{ $t('modal.jkRating.rating') }}</div>
      <div class="jk-rating-child">{{ $t('modal.jkRating.distinctive_features') }}</div>
      <div class="jk-rating-child">{{ $t('modal.jkRating.photo') }}</div>
    </div>
    <div class="jk-rating-body">
      <div v-for="el in ratingList"
           v-bind:key="el.code"
           class="jk-rating-row">
        <div class="jk-rating-child">
          <h3 class="mb-0">{{ el.code }}</h3>
        </div>
        <div class="jk-rating-child">
          {{ el.description[$i18n.locale] }}
        </div>
        <div class="jk-rating-child">
          <div class="images-list">
            <img v-bind:src="img"
                 v-for="(img, i) in el.image_urls"
                 v-bind:key="`${img}_${i}`"
                 class="rating-img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataFactory from '@/services/DataFactory';
import pointIsInsidePolygon from '@/lib/point_inside_polygon';

export default {
  data() {
    return {
      ratingList: null
    };
  },

  props: ['coordinate'],

  computed: {
    city() {
      if (!this.coordinate) return 'default';

      let res = '';

      this.$constants.AVAILABLE_CITIES.forEach(el => {
        const coordinate = this.coordinate;

        if (pointIsInsidePolygon([coordinate.lat, coordinate.lng], el.poly)) {
          res = el.code;
          return;
        }

        return 'default';
      });

      return res;
    }
  },

  mounted() {
    dataFactory.getCityRating(this.city).then(response => {
      this.ratingList = response.ratings.filter(el => el.description.en);
    });
  }
};
</script>
