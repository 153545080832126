<template>
  <span class="price-val">
    <a v-bind:class="btnClassName"
       v-on:click="showPrice"
       v-on:click.stop="clickStop"
       v-show="showPriceBtnIsVisible">{{ $t('form.propertyValuation.showPrice') }}</a>
    <span v-if="!showPriceBtnIsVisible">
      <span v-bind:class="currencyClassName" v-show="symbol && hasValue" v-if="currencyCodeIsBeforeAmount">
        {{ symbol }}<template v-if="forSq">/<span v-html="$t(`${selectArea}Sup`)"></span></template>
      </span>

      <div v-bind:class="valClass" :id="id" v-show="hasValue" class="d-inline price-val-amount">{{ value }}</div>

      <span v-bind:class="currencyClassName" v-show="symbol && hasValue" v-if="!currencyCodeIsBeforeAmount">
        {{ symbol }}<template v-if="forSq">/<span v-html="$t(`${selectArea}Sup`)"></span></template>
      </span>
      <span v-show="!hasValue">–</span>
    </span>
  </span>
</template>

<script>
import { EventBus } from '@/patterns/event-bus';
import { CountUp } from 'countup.js';
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: [String, Number]
    },
    code: {
      type: [String, Number],
    },
    val: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: 'xs',
    },
    valClass: {
      type: String,
    },
    precision: {
      type: [Number, String],
      default: 0
    },
    btnCustomClassName: String,
    parentTypename: String,
    formatValue: {
      type: Boolean,
      default: true
    },
    isShare: {
      type: Boolean,
      default: false
    },
    // показывать цену за квадрат
    forSq: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasContactPhone: null,
      currencyCodePosition: 'right'
    };
  },
  watch: {
    selectCurrency() {
      if (this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap') {
        this.getCurrencyCodePosition();
      }
    },
    selectUnitCurrency() {
      this.getCurrencyCodePosition();
    },
    'dealsCurrency.rate'() {
      this.getCurrencyCodePosition();
    },
  },
  computed: {
    ...mapState(['dealsCurrency']),
    ...mapState('favouriteMap', ['selectCurrency']),
    ...mapState('indexMap', { selectUnitCurrency: 'selectCurrency' }),
    ...mapState('indexMap', ['selectArea']),
    hasValue() {
      return this.val !== null;
    },
    symbol() {
      if (this.code == null || this.val == null) {
        return false;
      }
      const currency = (this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap') && this.selectCurrency && this.code !== '%' ? this.selectCurrency : this.code;
      const v = this.$constants.CURRENCIES.find((el) => el.currency === currency);

      if (v) {
        return v.symbol;
      }

      if (this.code == 'day') {
        return this.$tc('timePeriod.dayWithoutVal', 212);
      }

      return this.code;
    },
    value() {
      // TODO: условие нужно, чтобы анимация числа получала Number, props id переименовать к примеру на initAnimated
      // TODO: и при данном условии props precision не будет учитываться, при вкл. анимации, это надо учитывать, по этому отрефакторить нужно
      if (this.id && !this.phoneNumber) {
        return this.val;
      }
      if (this.precision == 0) {
        return this.isShare ? this.getPriceCrypto(this.val) : this.formatNumber(parseFloat(this.val).toFixed(0), this.code);
      }
      if (this.val == null) {
        return '-';
      }

      return this.isShare ? this.getPriceCrypto(this.val) : this.formatNumber(+(Math.round(this.val + 'e+2') + 'e-2'), this.code);
    },
    calcClaimPrepareResultAnimationHasBeenShown() {
      return window.__realisteStorage.getItem('calcClaimPrepareResultHasAnimation');
    },
    hasAnimation() {
      return this.$domainConfig.calcClaimPrepareResultAnimation && this.$domainConfig.calcClaimPrepareResultTimeout && this.$domainConfig.calcClaimPrepareResultTimeout > 0;
    },
    showPriceBtnIsVisible() {
      if (this.$route.name == 'TradeUpObjectEvaluationResult' || this.$route.name == 'ObjectEvaluationResult') return this.parentTypename == 'CalcClaimPrepareResult' && (!this.hasAnimation || this.calcClaimPrepareResultAnimationHasBeenShown);
      return this.parentTypename == 'CalcClaimPrepareResult';
    },
    btnClassName() {
      return `btn-show-price btn btn-outline-secondary-black-font ${this.btnCustomClassName || ''}`;
    },
    currencyCodeIsBeforeAmount() {
      return this.currencyCodePosition == 'left';
    },
    currencyClassName() {
      if (!this.code) return;
      if (this.code == 'RUB') return 'currency-font-fix text-xs';

      return [
        {
          'text-three-letters-curency-code': this.code.length > 2,
          'mr-1': this.currencyCodePosition == 'left',
          'ml-1': this.currencyCodePosition == 'right'
        }
      ];
    },
    animationTimeout() {
      return this.$domainConfig.calcClaimPrepareResultTimeout;
    },
    isRequiredPhoneVerification() {
      return this.$domainConfig.isRequiredPhoneVerification;
    },
    phoneNumber() {
      return window.__realisteStorage.getItem('contactPhone');
    }
  },
  methods: {
    showPrice(e) {
      e.preventDefault();

      EventBus.$emit('showContactForm');
    },
    clickStop() {
      console.log('stop click');
    },
    getCurrencyCodePosition() {
      if (!this.$constants.CURRENCIES) return 'right';
      const currency = (this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap') && this.selectCurrency && this.code !== '%' ? this.selectCurrency : this.code;
      const obj = this.$constants.CURRENCIES.filter(el => el.currency == currency);

      if (obj.length == 0) return 'right';

      this.currencyCodePosition = obj[0].currencyCodePosition;
    },
    countUp(target) {
      const countSeconds = this.animationTimeout * 2;
      const startVal = this.value * (1 - 0.01);
      const duration = +this.animationTimeout + countSeconds;

      const countUp = new CountUp(target, this.value, { startVal, duration, separator: ' ' });
      countUp.start();
    },
    getPriceCrypto(price) {
      if (price < 1) {
        return price.toFixed(4);
      } else if (price >= 1 && price < 100) {
        return price.toFixed(2).replace('.00', '');
      } else if (price >= 100) {
        return this.formatNumber(Math.floor(price));
      }
    },
  },

  mounted() {
    this.getCurrencyCodePosition();
    if (this.id && !this.phoneNumber) {
      this.countUp(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.price-val {
  display: inline-flex;
  align-items: baseline;

  .currency-font-fix {
    margin: 0 0 0 0.3em;
  }
}
</style>
