const table = {
  thead: {
    sellToRealiste: 'Продать Realiste',
    sellByYourOwn: 'Продать самостоятельно'
  },
  saleTerm: 'Срок продажи, дн.',
  serviceFee: 'Комиссия за услуги руб. 3%',
  urgentSaleCommission: 'Комиссия за срочную продажу руб.',
  bargain: 'Торг руб. 2.5%',
  rent: 'Квартплата руб. 0.2%',
  instantAmountOfMoney: 'Сумма на руки сейчас руб.',
  amountOfMoneyIn120days: 'Сумма на руки через 90 дней руб.',
  totalPrice: 'ИТОГО цена на руки:',
  in3Days: '(за 3 дня)',
  in90Days: '(за 90 дней)',
  aboutRealiste: 'О Realiste'
};

export default table;
