const tradeUp = {
  object: '{count} property | {count} properties',
  form: {
    title: {
      default: 'Free and accurate estimation of an apartment in 10 seconds. Step 1 of 2',
      lenstroytrest: 'Free and accurate estimation of an apartment in 10 seconds. Step 1 of 2'
    },
    subtitle: 'Estimate the value of your home in seconds. </br>See the price difference between your current home and your future home.',
    ownApartment: {
      title: 'Enter the information about your apartment'
    },
    exchangeApartment: {
      title: 'Where would you like to move?'
    },
    searchDistrictsWithin: {
      title: 'Search within a city'
    }
  },
  options: {
    spacious_apartment: 'Upgrade',
    new_apartment: 'New building',
    modest_apartment: 'Downsize',
    similar: 'Similar'
  },
  optionsTitle: 'Apartment characteristics',
  showObjectsBtn: 'Show results',
  shortInfo: {
    yourApartment: 'Your apartment',
    payExtra: 'Pay extra',
    mortgage: 'Mortgage from',
    getPriceDifference: 'Get price difference',
    sendRequest: 'Trade Up'
  },
  countObjects: {
    default: 'Found {count}',
    more250: 'Found more than {count}',
  },
  actionModal: {
    title: 'Get an offer in 1 minute',
    btnText: 'Get an offer'
  },
  propertySlide: {
    analysis: 'Analysis'
  },
  btn: {
    howItWorks: 'Trade-Up',
    sendRequest: 'Trade Up',
    wannaView: 'Wanna view'
  },
  slider: {
    title: 'Exchange options'
  },
  modal: {
    wannaView: {
      title: 'Wanna view',
      btnText: 'Send'
    }
  }
};

export default tradeUp;
