import Vue from 'vue';
import Router from 'vue-router';
import VueRouterBackButton from 'vue-router-back-button';
import store from '../store';
import saveGetParams from './controllers/saveGetParams';
import saveGetParamsInStorage from './controllers/saveGetParamsInStorage';

Vue.use(Router);

const BOTTOM_BAR_BUTTONS_ROUTE_NAME = {
  propertyValuation: 'ObjectEvaluation',
  tradeUp: 'TradeUp',
  mapAnalytics: 'MapAnalytics',
  map: 'FavouriteObjectsMap',
  deals: 'Deals'
};

// temporary fix for partners links
if (location.href.includes('//?')) {
  window.location.replace(location.href.replace('//?', '/?'));
}

if (location.href.includes('//#')) {
  window.location.replace(location.href.replace('//#', '/#'));
}

const url = window.location;
if (!url.host.includes('localhost') && url.origin.includes('brokers.properties') && location.pathname === '/') {
  if (url.host.match(/offer-/) && !url.pathname.match(/\/offer\//)) {
    let hostParts = url.host.replace('www.', '');
    hostParts = hostParts.split('.');
    const domainParts = hostParts[0].split('-');
    window.location.replace(`${url.origin}/offer/${domainParts[domainParts.length - 1]}${url.search}`);
  }
}

const paramClientTokenPart = ':clientToken([a-zA-Z0-9]{24})?';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: `/${paramClientTokenPart}/onboarding`,
      name: 'OnBoarding',
      component: () => import('../views/OnBoarding.vue'),
      props: true,
      meta: {
        emptyLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/`,
      name: 'FavouriteObjectsMap',
      component: () => import('../views/FavouriteObjectsMap.vue'),
      props: true,
      meta: {
        emptyLayout: true,
        hasMap: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object-evaluation`,
      name: 'ObjectEvaluation',
      component: () => import('../views/ObjectEvaluation.vue'),
    },
    {
      path: `/${paramClientTokenPart}/object-evaluation/:id`,
      name: 'ObjectEvaluationResult',
      component: () => import('../views/Card.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCalcClaim: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object/:id`,
      name: 'Card',
      component: () => import('../views/Card.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        hasPublicObjectTargetAction: true
      }
    },
    {
      path: `/${paramClientTokenPart}/booking-index/:id`,
      component: () => import('../views/BookingIndex.vue'),
      name: 'BookingIndex'
    },
    {
      path: `/${paramClientTokenPart}/booking-index/:id/detail`,
      component: () => import('../views/BookingIndexObjects.vue'),
      name: 'BookingIndexObjects',
      children: [{
        path: `/${paramClientTokenPart}/booking-index/:id/detail/:object`,
        component: () => import('../components/booking-index-object/Detail/index.vue'),
        name: 'BookingIndexDetail'
      }]
    },
    {
      path: `/${paramClientTokenPart}/favorites`,
      component: () => import('../views/Favorites.vue'),
      name: 'Favorites'
    },
    {
      path: `/${paramClientTokenPart}/offer/:uid`,
      component: () => import('../views/PersonalOffer.vue'),
      name: 'PersonalOffer',
      props: true,
    },
    {
      path: `/${paramClientTokenPart}/object/:id/profit-forecast`,
      name: 'ProfitForecast',
      component: () => import('../views/ProfitForecast.vue'),
      props: true,
      meta: {
        isObjectDetail: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object/:id/competitors`,
      name: 'Competitors',
      component: () => import('../views/Competitors.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCompetitorsList: true,
        hasPublicObjectTargetAction: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object/:id/competitors/map`,
      name: 'CompetitorsMap',
      component: () => import('../views/CompetitorsMap.vue'),
      props: true,
      meta: {
        emptyLayout: true,
        isObjectDetail: true,
        hasMap: true,
        isCompetitorsMap: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object/:id/potential-competitors`,
      name: 'PotentialCompetitors',
      component: () => import('../views/Competitors.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCompetitorsList: true,
        hasPublicObjectTargetAction: true,
        hasPotentials: true
      }
    },
    {
      path: `/${paramClientTokenPart}/object/:id/potential-competitors/map`,
      name: 'PotentialCompetitorsMap',
      component: () => import('../views/CompetitorsMap.vue'),
      props: true,
      meta: {
        emptyLayout: true,
        isObjectDetail: true,
        hasMap: true,
        isCompetitorsMap: true,
        hasPotentials: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/`,
      name: 'Deals',
      component: () => import('../views/deals/Deals.vue'),
      beforeEnter(to, from, next) {
        window.location.href = 'https://deals.realiste.ai';
      },
      meta: {
        dealsLayout: true
      },
      children: [
        {
          path: 'deals-all',
          name: 'DealsAll',
          component: () => import('../views/deals/deals-all/Common.vue'),
          meta: {
            dealsLayout: true
          }
        },
        {
          path: 'funds',
          name: 'DealsFunds',
          component: () => import('../views/deals/funds/Common.vue'),
          meta: {
            dealsLayout: true
          }
        },
        {
          path: 'flippers',
          name: 'DealsFlippers',
          component: () => import('../views/deals/flippers/Common.vue'),
          meta: {
            dealsLayout: true
          }
        },
        {
          path: 'persons',
          name: 'DealsPersons',
          component: () => import('../views/deals/persons/Common.vue'),
          meta: {
            dealsLayout: true
          }
        },
      ]
    },
    {
      path: `/${paramClientTokenPart}/deals/all-deals/statistic`,
      name: 'DealsStatistic',
      component: () => import('../views/deals/deals-all/Statistic.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/inner-deal/:fund_id`,
      name: 'Fund',
      component: () => import('../views/deals/Fund.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/funds/fund_entry_instructions`,
      name: 'FundEntryInstructions',
      component: () => import('../views/deals/FundEntryInstructions.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/funds/fund_legal_information`,
      name: 'FundLegalInformation',
      component: () => import('../views/deals/FundLegalInformation.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/deal/:deal_id/`,
      name: 'Deal',
      component: () => import('../views/deals/deal-inner/Deal.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/deal/:deal_id/demand`,
      name: 'DealDemand',
      component: () => import('../views/deals/deal-inner/DealDemand.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/deal/:deal_id/legal_review`,
      name: 'LegalReview',
      component: () => import('../views/deals/deal-inner/LegalReview.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/deal/:deal_id/project_stages`,
      name: 'ProjectStages',
      component: () => import('../views/deals/deal-inner/ProjectStages.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/list`,
      name: 'SourceList',
      component: () => import('../views/deals/SourceList.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/deals/:deal_realtor/statistic`,
      name: 'SourceStatistic',
      component: () => import('../views/deals/SourceStatistic.vue'),
      props: true,
      meta: {
        dealsLayout: true
      }
    },
    {
      path: `/${paramClientTokenPart}/map-analytics/`,
      name: 'MapAnalytics',
      component: () => import('../views/MapAnalytics.vue'),
      meta: {
        emptyLayout: true,
        hasMap: true
      },
      props: true
    },
    {
      path: '/docs/freedom',
      name: 'DocsFreedom',
      component: () => import('../views/DocsFreedom.vue'),
      props: true
    },
    {
      path: `/${paramClientTokenPart}/trade-up`,
      name: 'TradeUp',
      component: () => import('../views/TradeUp.vue'),
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:id`,
      name: 'TradeUpMap',
      component: () => import('../views/TradeUpMap.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCalcClaim: true,
        hasMap: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id`,
      name: 'TradeUpCard',
      component: () => import('../views/Card.vue'),
      props: true,
      meta: {
        isObjectDetail: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object-evaluation/:id`,
      name: 'TradeUpObjectEvaluationResult',
      component: () => import('../views/Card.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCalcClaim: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id/profit-forecast`,
      name: 'TradeUpProfitForecast',
      component: () => import('../views/ProfitForecast.vue'),
      props: true,
      meta: {
        isObjectDetail: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id/competitors`,
      name: 'TradeUpCompetitors',
      component: () => import('../views/Competitors.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCompetitorsList: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id/competitors/map`,
      name: 'TradeUpCompetitorsMap',
      component: () => import('../views/CompetitorsMap.vue'),
      props: true,
      meta: {
        emptyLayout: true,
        isObjectDetail: true,
        hasMap: true,
        isCompetitorsMap: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id/potential-competitors`,
      name: 'TradeUpPotentialCompetitors',
      component: () => import('../views/Competitors.vue'),
      props: true,
      meta: {
        isObjectDetail: true,
        isCompetitorsList: true,
        hasPotentials: true
      }
    },
    {
      path: `/${paramClientTokenPart}/trade-up/:tradeUpObjectId/object/:id/potential-competitors/map`,
      name: 'TradeUpPotentialCompetitorsMap',
      component: () => import('../views/CompetitorsMap.vue'),
      props: true,
      meta: {
        emptyLayout: true,
        isObjectDetail: true,
        hasMap: true,
        isCompetitorsMap: true,
        hasPotentials: true
      }
    },
    {
      path: `/${paramClientTokenPart}/fa/:feedbackAnswerUuid`,
      name: 'FeedbackAnswer',
      component: () => import('../views/FeedbackAnswer.vue'),
      props: true,
    },
    {
      path: `/${paramClientTokenPart}/loading-debug`,
      name: 'LoadingDebug',
      component: () => import('../views/LoadingDebug.vue'),
      props: true,
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('../views/NotFound.vue'),
      name: 'NotFound',
      meta: {
        emptyLayout: true
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    const fromHistory = Boolean(savedPosition);

    store.commit('changeRouterHistory', {
      fromHistory: fromHistory,
      from: from
    });

    return savedPosition || { x: 0, y: 0 };
  },
});

Vue.use(VueRouterBackButton, {
  router,
  ignoreRoutesWithSameName: true
});

router.beforeEach((to, from, next) => {
  saveGetParams.beforeEach(to, from, next);
  saveGetParamsInStorage.beforeEach(to, from, next);

  Vue.$cookies.set('currentPage', to.name);
  Vue.$cookies.set('prevPage', from.name);

  next();

  const noOnboardingRoutes = ['OnBoarding'];

  if (window.__realisteStorage.getItem('onboardingHasViewed') == null && !noOnboardingRoutes.includes(to.name) && Vue.prototype.$domainConfig.hasOnboarding && Vue.prototype.$domainConfig.propertyEstimationFormHasOnboarding) {
    store.commit('setOnboardingTargetPage', {
      path: to.path,
      query: to.query,
      params: to.params
    });

    router.push({
      name: 'OnBoarding',
      query: to.query,
      params: to.params
    }).catch(() => {});

    next();
  }

  let toBottomRouteKey = Vue.prototype.$domainConfig.initialPage || Vue.prototype.$domainConfig.bottomBarButtons[0];
  if (!Vue.prototype.$domainConfig.bottomBarButtons.includes(toBottomRouteKey)) {
    toBottomRouteKey = Vue.prototype.$domainConfig.bottomBarButtons[0];
  }
  const toBottomRouteName = BOTTOM_BAR_BUTTONS_ROUTE_NAME[toBottomRouteKey];
  if (to.name === BOTTOM_BAR_BUTTONS_ROUTE_NAME.map && toBottomRouteName !== BOTTOM_BAR_BUTTONS_ROUTE_NAME.map) {
    // if (window.__realisteStorage.getItem('city') != null) next();
    const params = {};
    if (window.sessionStorage.clientToken) {
      params.clientToken = window.sessionStorage.clientToken;
    }
    router.push({
      name: toBottomRouteName,
      query: to.query,
      params
    }).catch(() => {});

    next();
  }

  if (to.name == 'FavouriteObjectsMap' && Vue.prototype.$domainConfig.initialPage != 'map' && Vue.prototype.$domainConfig.bottomBarButtons.filter(el => el == 'map').length != 0 && window.__realisteStorage.getItem('city') == null) {
    // if (window.__realisteStorage.getItem('city') != null) next();
    const v = Vue.prototype.$domainConfig.initialPage || Vue.prototype.$domainConfig.bottomBarButtons[0];
    router.push({
      name: BOTTOM_BAR_BUTTONS_ROUTE_NAME[v],
      query: to.query,
      params: to.params
    }).catch(() => {});

    next();
  }
});

export default router;
