const obj = {
  inTheOfferList: 'в списке предложений',
  inFavoriteList: 'в избранном',
  emptyList: 'Ваш список предложений пуст',
  backToIndex: 'Назад к Индексу',
  objectsInOffer: 'Объекты в предложении',
  objectsInFavorite: 'Объекты в избранном',
  download: 'Загрузить',
  createLink: 'Ссылка с Realiste',
  remove: 'Удалить',
  editProfit: 'Изменить прибыль',
  totalInvestment: 'Всего инвестиций',
  totalProfit: 'Общая прибыль',
  totalEquityBefore: 'Общий капитал до',
  totalEquityAfter: 'Общий капитал после',
  roiTotal: 'ROI итого',
  potentialRentAfterHandover: 'Потенциальная аренда после передачи ключей',
  potentialRoiAfterHandover: 'Потенциальная ROI аренды в год после передачи ключей',
  removeAllOffers: 'Удалить все предложения?',
  deleteFromList: 'Удалить из списка?',
  cancel: 'Отмена',
  delete: 'Удалить',
  linkCreated: 'Ссылка создана',
  copyLink: 'Скопировать ссылку',
  linkToOffer: 'Ссылка на предложение',
  offers: 'Предложение',
  personalOffer: 'Персональное предложение',
  pleaseReview: 'Уважаемый клиент, пожалуйста, ознакомьтесь с этим предложением',
  personalManager: 'Ваш персональный менеджер',
  apply: 'Применить',
  profileForm: {
    title: 'Создайте свою ссылку',
    nameAndLastName: {
      placeholder: 'Имя и фамилия',
      errorMsg: 'Пожалуйста, введите ваше имя',
    },
    phone: {
      placeholder: 'Номер телефона',
      errorMsg: 'Пожалуйста, проверьте ваш номер телефона',
    },
    email: {
      placeholder: 'Email',
      errorMsg: 'Пожалуйста, проверьте ваш email',
    },
    telegram: {
      placeholder: 'Telegram (необязательно)',
      errorMsg: 'Пожалуйста, проверьте ваш Telegram',
    },
    whatsApp: {
      placeholder: 'WhatsApp (необязательно)',
      errorMsg: 'Пожалуйста, проверьте ваш WhatsApp',
    },
    clientName: {
      placeholder: 'Имя клиента',
      errorMsg: 'Пожалуйста, проверьте имя клиента',
    },
    messageForClient: {
      placeholder: 'Сообщение для клиента',
      errorMsg: 'Пожалуйста, проверьте сообщение для клиента',
    },
    create: 'Создать',
    cancel: 'Отменить',
    next: 'Дальше',
  },
  createLinkRequestError: 'Произошла непредвиденная ошибка. Пожалуйста, повторите попытку позже',
  saveChanges: 'Сохранить изменения',
  totalProfitFromProject: 'Общая прибыль от проекта',
  creatingLink: 'Создаём ссылку...',
  copied: 'Ссылка скопирована',
  poweredOnRealiste: 'Работает на технологии Realiste',
  personalOfferError: 'Произошла непредвиденная ошибка. Пожалуйста, повторите попытку позже',
  favorites: 'Избранное',
  hello: 'Здравствуйте',
  pdfLoading: 'Загрузка...',
  openUnit: 'Информация о лоте',
  openProject: 'Информация о проекте',
  whiteLabelLink: 'Ссылка без Realiste',
  downloadPdf: 'Скачать PDF-файл',
};

export default obj;
