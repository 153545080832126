import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import constants from '@/components/constants';
import { parseTrackRecord, parseSpreadsheets, parseDealData } from '@/lib/google_sheet';
import { apolloAnalgeoClient } from '@/lib/apolloAnalgeo';
import { exchangeRate } from '@/models/analgeo/exchangeRate';
import auth from './modules/auth';
import modal from './modules/modal';
import tradeUp from './modules/trade-up';
import favouriteMap from './modules/favourite-map';
import indexMap from './modules/index-map';
import competitors from './modules/competitors';
import propertyForm from './modules/property-valuation-form';
import favorites from './modules/favorites';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasToken: null,
    isInternal: null,
    isPublic: null,
    isDeveloper: null,
    deals: {},
    realtors: [],
    sheetTranslations: [],
    spreadsheets: [],
    deal: null,
    dealActiveTab: 'active',
    dealsOrder: 'all',
    dealsYear: null,
    selectedCity: '',
    currentLayer: 'color_index_demand-0',
    activeLayerOptions: null,
    ratingDescription: null,
    roomDescription: null,
    closestObjectsAreVisible: false,
    realtorType: null,
    routerHistory: [],
    contactPhone: null,
    calcClaimState: '',
    onboardingTargetPage: null,
    dealsCurrency: {
      currency: 'USD',
      rate: 1,
      symbol: '$',
      currencyCodePosition: 'left'
    },
    dealsImagesRealtor: [],
  },
  mutations: {
    updateTokenStatus(state, hasToken) {
      state.hasToken = hasToken;
    },
    updateInternalStatus(state, isInternal) {
      state.isInternal = isInternal;
    },
    updatePublicStatus(state, isPublic) {
      state.isPublic = isPublic;
    },
    setIsDeveloper(state, partnerInfo) {
      state.isDeveloper = partnerInfo.isDeveloper;
    },
    setDeals(state, deals) {
      state.deals = deals;
    },
    setRealtors(state, realtors) {
      state.realtors = realtors;
    },
    setSheetTranslations(state, sheetTranslations) {
      state.sheetTranslations = sheetTranslations;
    },
    setSpreadsheets(state, spreadsheets) {
      state.spreadsheets = spreadsheets;
    },
    setDeal(state, deal) {
      state.deal = deal;
    },
    setDealActiveTab(state, dealActiveTab) {
      state.dealActiveTab = dealActiveTab;
    },
    setSelectedCity(state, selectedCity) {
      state.selectedCity = selectedCity;
    },
    setDealsOrder(state, dealsOrder) {
      state.dealsOrder = dealsOrder;
    },
    setDealsYear(state, dealsYear) {
      state.dealsYear = dealsYear;
    },
    changeLayer(state, currentLayer) {
      state.currentLayer = currentLayer;
    },
    setRatingDescription(state, ratingDescription) {
      state.ratingDescription = ratingDescription;
    },
    setRoomDescription(state, roomDescription) {
      state.roomDescription = roomDescription;
    },
    updateClosestObjectVisibility(state, payload) {
      state.closestObjectsAreVisible = payload;
    },
    setRealtorType(state, realtorType) {
      state.realtorType = realtorType;
    },
    changeRouterHistory(state, args) {
      if (args.fromHistory && state.routerHistory.length > 0) {
        state.routerHistory.splice(-1, 1);
      } else {
        state.routerHistory.push(args.from);
      }
    },
    setContactPhone(state, contactPhone) {
      state.contactPhone = contactPhone;
    },
    changeCalcClaimState(state, calcClaimState) {
      state.calcClaimState = calcClaimState;
    },
    setOnboardingTargetPage(state, onboardingTargetPage) {
      state.onboardingTargetPage = { ...onboardingTargetPage };
    },
    setActiveLayerOptions(state, payload) {
      state.activeLayerOptions = payload;
    },
    setDealsCurrencyVal(state, value) {
      state.dealsCurrency.currency = value;
    },
    setDealsCurrencyRate(state, value) {
      state.dealsCurrency.rate = value;
    },
    setDealsCurrencySymbol(state, value) {
      state.dealsCurrency.symbol = value;
    },
    setDealsCurrencyPosition(state, value) {
      state.dealsCurrency.currencyCodePosition = value;
    },
    setDealsImagesRealtor(state, value) {
      state.dealsImagesRealtor = value;
    },
  },
  actions: {
    async getDealsFormula({ commit }) {
      await Axios
        .get(constants.TRACK_RECORD_URL_RENDER_FORMULA)
        .then((response) => {
          const dataRealtor = parseTrackRecord(response.data.values);
          commit('setDealsImagesRealtor', dataRealtor?.deals.map(item => item?.imageAvatar) || []);
        })
        .catch(err => console.log('getDealsFormula', err));
    },
    async getDeals({ commit }, payload) {
      await Axios
        .get(constants.TRACK_RECORD_URL)
        .then((response) => {
          const responseData = parseTrackRecord(response.data.values);
          responseData?.deals.forEach((item, index) => {
            item.imageAvatar = payload && payload[index] ? payload[index] : '';
          });
          commit('setDeals', responseData);
        });
    },
    async getRealtors({ commit }) {
      await Axios
        .get(`https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}/values/${constants.TRACK_RECORD_SHEET_NAME_REALTORS}?majorDimension=ROWS&valueRenderOption=UNFORMATTED_VALUE&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`)
        .then((response) => {
          if (!response.data) return;
          const resKeys = response.data.values[0];

          const res = response.data.values.map((el, index) => {
            if (index == 0) return;
            const obj = {};

            resKeys.forEach((headEl, headIndex) => {
              obj[headEl] = response.data.values[index][headIndex];
            });

            return obj;
          }).filter(el => el);

          commit('setRealtors', res);
        })
        .catch((err) => {
          console.log('err');
          console.log(err);
        });
    },
    async getSheetTranslations({ commit }) {
      await Axios
        .get(`https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}/values/${constants.TRACK_RECORD_SHEET_NAME_SHEET_TRANSLATIONS}?majorDimension=ROWS&valueRenderOption=UNFORMATTED_VALUE&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`)
        .then((response) => {
          if (!response.data) return;

          const resKeys = response.data.values[0];

          const res = response.data.values.map((el, index) => {
            if (index == 0) return;
            const obj = {};

            resKeys.forEach((headEl, headIndex) => {
              obj[headEl] = response.data.values[index][headIndex];
            });

            return obj;
          }).filter(el => el);

          commit('setSheetTranslations', res);
        })
        .catch((err) => {
          console.log('err');
          console.log(err);
        });
    },
    async getSpreadsheets({ commit }) {
      await Axios
        .get(constants.SPREADSHEETS_URL)
        .then((response) => {
          commit('setSpreadsheets', parseSpreadsheets(response.data.sheets));
        });
    },
    async getDeal({ commit }, sheetName1) {
      await Axios
        .get(`https://sheets.googleapis.com/v4/spreadsheets/${process.env.VUE_APP_GOOGLE_SPREADSHEETS_SHEET_ID}/values/${sheetName1}?majorDimension=ROWS&valueRenderOption=UNFORMATTED_VALUE&dateTimeRenderOption=FORMATTED_STRING&key=${process.env.VUE_APP_GOOGLE_SPREADSHEETS_API_KEY}`)
        .then((response) => {
          commit('setDeal', parseDealData(response.data.values));
        })
        .catch((err) => {
          console.log('err');
          console.log(err);
        });
    },
    async exchangeRateDeals({ commit }, payload) {
      await apolloAnalgeoClient
        .query({
          query: exchangeRate,
          variables: {
            currencyFrom: payload,
            currencyTo: 'RUB',
            round: 6,
          },
          fetchPolicy: 'no-cache',
        })
        .then((data, loading) => {
          if (!loading) {
            commit('setDealsCurrencyRate', data.data.exchangeRate);
          }
        })
        .catch((error) => {
          console.error('exchangeRate error: ', error);
          commit('setDealsCurrencyRate', 1);
        });
    },
  },
  getters: {
    isPro: (state) => {
      if (state == null || state.isPublic == true) {
        return false;
      }
      return state.hasToken;
    },
    isInternal: (state) => {
      if (state == null || state.isPublic == true) {
        return false;
      }
      return state.isInternal;
    },
    isDeveloper: (state) => state.isDeveloper,
    isPublic: (state) => state.isPublic,
    getDealActiveTab: (state) => state == null ? false : state.dealActiveTab,
    getSelectedCity: (state) => state.selectedCity,
    getDealsOrder: (state) => state.dealsOrder,
    getDealsYear: (state) => state.dealsYear,
    getCurrentLayer: (state) => state.currentLayer,
    getRatingDescription: (state) => state.ratingDescription,
    getRoomDescription: (state) => state.roomDescription,
    areClosestObjectVisible: (state) => state.closestObjectsAreVisible,
    getRealtorsList: (state) => state.realtors,
    getSheetTranslations: (state) => state.sheetTranslations,
    getRealtorType: (state) => state.realtorType,
    getPreviousRoute: (state) => {
      const historyLen = state.routerHistory.length;
      if (historyLen == 0) return null;
      return state.routerHistory[historyLen - 1];
    },
    getContactPhone: (state) => state.contactPhone,
    getCalcClaimState: (state) => state.calcClaimState,
    getOnboardingTargetPage: (state) => state.onboardingTargetPage,
    getActiveLayerOptions: (state) => state.activeLayerOptions
  },
  modules: {
    auth,
    modal,
    tradeUp,
    favouriteMap,
    competitors,
    propertyForm,
    indexMap,
    favorites,
  },
});
