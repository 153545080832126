const obj = {
  title: '项目成本估算',
  estimateCosts: {
    estimateBuyFields: {
      title: '买房',
      CQ2: {
        title: '法律风险的专家评估',
        description: '出发对该物体进行检查，核对检查清单，与邻居交谈，向当地当局收集有关该物体的信息。',
      },
      CR2: {
        title: '房地产状况的专家评估',
        description: '由专业承包商组织的专家对改进进行评估。'
      },
      CS2: {
        title: '收藏证书'
      },
      CT2: {
        title: '律师的费用',
        description: '律师就当事人之间的合同条款、签订合同的地点、时间、款项过户的条件等达成一致，向国家机关提交文书，接收文书，并协调移转行为的签署。'
      },
      CU2: {
        title: '公证员的付款',
        description: '由公证人付款(在经公证的购买交易中)，在简单的书面形式中——费用为0卢布。'
      },
      CW2: {
        title: '交易产权保险',
        description: '产权保险是由俄罗斯三大保险公司之一提供的。(应投资者要求)。不包括在预算中。'
      },
      CX2: {
        title: '信用证或银行电话的银行费用',
        description: '银行对交易的交易费用(信用证或手机)。'
      },
      CY2: {
        title: '购买价格',
        description: '在交易登记后给卖方的交易价值(不考虑可能的出价)。'
      },
      CZ2: {
        title: '目标交易/折扣以快速流动资金',
      },
      DB2: {
        title: '房地产经纪人为找到这笔交易而提供的服务的成本',
      },
    },
    estimateOwnFields: {
      title: '房地产价值的增加',
      DC2: {
        title: '收集遗失文件的法律服务',
        description: '收集丢失的文件(如果所有的文件都在那里，就会增加改善房产的预算)。'
      },
      DD2: {
        title: '第三方工头的服务，以控制工作',
        description: '技术项目经理监督实体改进的费用。'
      },
      DE2: {
        title: '(三)第三方律师为买卖文件准备的服务',
        description: '项目经理服务监控截止日期和解决有关文件，章节，拒绝在国家机构，通信，当地市政，当地合作伙伴关系，邻居的问题。'
      },
      DF2: {
        title: '第三方销售控制经理的服务',
        description: '对所有项目点的实际控制，策略调整。'
      },
      DG2: {
        title: '清理，拆除所有看起来超过3年的东西。',
        description: '清洁、拆解旧家具、内嵌家具、厨房、水槽、厕所、门、窗台。'
      },
      DH2: {
        title: '设置和安装WI-FI',
        description: '连接Wi-Fi以协调其他服务和系统并发送信息。'
      },
      DI2: {
        title: '安装一个摄像头',
        description: '公寓的遥控器。'
      },
      DJ2: {
        title: '安装智能锁',
        description: '在没有房地产经纪人(公寓或商业楼宇)的情况下，全天候显示房地产，使用智能锁(进入楼宇的电子代码)。'
      },
      DK2: {
        title: '居住区举办',
        description: '估计值由a)工程的精确平方面积(重新计算成立方体)组成——地板、墙壁、天花板、窗户。b) 5个承包商提供的工程和材料价格c)本房屋(地区)销售所需的质量。'
      },
      DL2: {
        title: '走廊分期',
        description: '估计值由a)工程的精确平方面积(重新计算成立方体)组成——地板、墙壁、天花板、窗户。b) 5个承包商提供的工程和材料价格c)本房屋(地区)销售所需的质量。'
      },
      DM2: {
        title: '厨房里举办',
        description: '估计值由a)工程的精确平方面积(重新计算成立方体)组成——地板、墙壁、天花板、窗户。b) 5个承包商提供的工程和材料价格c)本房屋(地区)销售所需的质量。'
      },
      DN2: {
        title: '举办浴/浴室',
        description: '估计值由a)工程的精确平方面积(重新计算成立方体)组成——地板、墙壁、天花板、窗户。b) 5个承包商提供的工程和材料价格c)本房屋(地区)销售所需的质量。'
      },
      DO2: {
        title: '公众地方的安排',
        description: '改善场所(公共区域)或在与邻居共用的门上安装智能锁。'
      },
      DP2: {
        title: '评估公司为抵押贷款买家进行的评估',
        description: '由专业估价师进行房地产估价(银行、抵押贷款等)。这将出售期限从10天减少到14天，以出售给有抵押的买家。'
      },
      DQ2: {
        title: '3D模型公寓专业摄影',
        description: '创建一个公寓的3D模型，专业照片和渲染4张照片。'
      },
      DR2: {
        title: '公寓在Cyan, Avito, Yandex, Comclick',
        description: '它是在目标销售期间，以网站上最昂贵的服务(前3名，Avito的溢价，等等)为代价设计的。'
      },
      DS2: {
        title: '在Facebook / Instagram上的本地广告',
        description: '超过60%的买家是当地居民。他们不会每天盯着电子公告板。激活潜在买家和扩大覆盖面缩短了销售周期，通常显示投资回报率为>400%。'
      },
      DT2: {
        title: '线下落地广告(传单、贴广告)',
        description: '超过60%的买家是当地居民。他们不会每天盯着电子公告板。激活潜在买家和扩大覆盖面缩短了销售周期，通常显示投资回报率为>200%。'
      },
      DU2: {
        title: '印制及安装广告条幅(窗户/阳台)',
        description: '安装和印刷横幅。'
      },
      DV2: {
        title: '负责接听和记录电话的呼叫中心接线员。CRM系统',
        description: '接听广告电话，回答客户问题，送客户参观。为销售的目标日期而设计。'
      },
    },
    estimateOwn2Fields: {
      title: '出售房地产赚钱',
      DW2: {
        title: '销售佣金的支付',
        description: '代理寻找买家的佣金(默认为售价的1.5%至2.5%)。如果买主没有代理人，佣金就会加到项目的纯利润中。'
      },
      DX2: {
        title: '房产税',
      },
      DY2: {
        title: '支持销售交易的法律服务',
      },
      DZ2: {
        title: '每笔交易的银行手续费',
        description: '银行对交易的交易费用(信用证或手机)。'
      },
      taxes: {
        title: '所得税',
        description: '个人所得税被认为是默认的(13%的个人所得税)。请记住，您可能无法将您的维修费用、广告费用和帮助购买kvaritra的服务费用作为税收减免。扣除额只适用于您出售公寓所产生的费用(给房地产经纪人的佣金、广告、法律服务)。'
      },
      net_profit: {
        title: '所得税',
        description: '在扣除所有费用和费用后的项目利润，但在所得税之前。'
      },
    }
  },
  investmentObjectParameters: {
    APY: {
      description: '年收益率(APY)是指考虑存款或投资产品复利应用后计算出的回报率。允许您简化与不同收入间隔的年度复利回报的比较(当利息按年度复利计息数次时)。返回的年利率(年)显示了返回好像每年的利率复利计算每年一次,给了相同的应计值(未来(现在的)值)在年度复利的计算问题,这是支付一年要更新好几次。'
    },
    NP: {
      description: '在扣除所有费用和费用后的项目利润，但在所得税之前。'
    },
    PD: {
      description: '这是项目的目标期，系统保守地认为在此期间可以以快速销售的价格出售。'
    },
    SP: {
      description: '这是卖方在当期的报价。'
    },
    FSP: {
      description: '该公寓在项目目标期内或更早售出的价格(需在附加附件中注明所需投资以供快速销售)附加附件及类别均在项目概算中注明。'
    },
    PP: {
      description: '这是购买价格，你不会有损失风险的投资，你购买的远远低于这个财产的流动价值。(不考虑可能存在的法律风险或其他风险，只考虑估值风险)'
    },
    AI: {
      description: '这些费用对于增加购房者的受众和增加购房者对公寓的兴趣是必要的，这样才能快速将公寓出售。这些费用包括:改善公寓的外观和大量的广告宣传。在90%的情况下，如果公寓以市场价卖了3个月以上，那么a)拍了糟糕的照片b)广告覆盖不足c)房地产经纪人是个业余爱好者。'
    },
    priceShownObviousAd: {
      description: '根據同類優惠計算價格，但無法計算銷售日期。'
    }
  },
  competitiveObjectsParameters: {
    BC: {
      description: '作为竞争对手，我们只接受市场上流动性最好、价格最好的公寓(通常，这些公寓的价格也被低估了)。'
    }
  },
  objectProperties: {
    NL: {
      description: '位於高噪聲水平的區域'
    },
    SP: {
      description: ''
    },
  }
};

export default obj;
