<template>
  <transition name="fade" mode="out-in">
    <div class="page-loader-wrap"
         v-bind:style="containerStyle"
         v-if="isLoading">
      <picture v-if="bgImg && bgImg.length != 0"
               class="loader-bg-img">
        <source media="(max-width: 767px)"
                v-bind:srcset="bgImg[1]">
        <source media="(min-width: 768px)"
                v-bind:srcset="bgImg[0]">
        <img src="images/img-default.png"  alt="Backcground image">
      </picture>
      <div v-bind:class="pageLoaderClass">
        <img v-if="customSpinnerIsVisible"
             v-bind:style="imgStyle"
             v-bind:src="require('@/assets/images/dna-loader.svg')"
             width="200"
             height="100" alt />
        <div class="loader"
             v-if="loaderIsVisible"
             v-bind:style="imgStyle"></div>
        <p v-html="innerText"
           v-else-if="innerText"
           class="mb-5"></p>
        <calc-claim-counter v-if="calcClaimCounterIsVisible"
                            v-on:clearAllIntervals="clearAllIntervals"></calc-claim-counter>
      </div>
      <calc-claim-loader-text v-bind="loaderTextBind"
                              v-if="calcClaimLoaderIsVisible"></calc-claim-loader-text>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import CalcClaimLoaderText from '@/components/common/loader/CalcClaimLoaderText.vue';
import CalcClaimCounter from '@/components/common/loader/CalcClaimCounter.vue';
import constants from '@/components/constants';

export default {
  props: {
    isLoading: Boolean,
    isSpinner: {
      default: true,
      type: Boolean,
    },
    innerText: {
      type: [String, Boolean, Array],
    },
    overlayColor: {
      default: 'transparent',
      type: String,
    },
    textColor: {
      default: '$dark',
      type: String,
    },
    customSpinner: {
      default: false,
      type: Boolean
    },
    bgImg: {
      type: Array
    },
    marginBottom: {
      default: 40
    },
    loaderWithMediaText: {
      type: Boolean
    },
    calcClaimCounterIsVisible: {
      type: Boolean
    }
  },
  components: {
    CalcClaimLoaderText,
    CalcClaimCounter
  },
  data() {
    return {
      mdResolution: 1024,
    };
  },
  computed: {
    ...mapGetters({
      calcClaimState: 'getCalcClaimState',
      calcClaimCounter: 'propertyForm/getCalcClaimCounter',
    }),
    isTradeUpMap() {
      return this.$route.name == 'TradeUpMap';
    },
    isObjectEvaluationResult() {
      return this.$route.name == 'ObjectEvaluationResult';
    },
    containerStyle() {
      const result = {};

      if (this.overlayColor) {
        result['background-color'] = this.overlayColor;
      }

      if (!this.isSpinner) {
        result['z-index'] = 2000;
      }

      if (((this.calcClaimState != 'finish' && this.calcClaimState != '') && (this.isTradeUpMap || this.isObjectEvaluationResult)) || this.$route.name == 'LoadingDebug') {
        result['z-index'] = 9;
      }

      return result;
    },
    marginBottomInner() {
      return this.customSpinner ? -4 : this.marginBottom;
    },
    isMobile() {
      return document.body.clientWidth < this.mdResolution;
    },
    imgStyle() {
      return { 'margin-bottom': this.marginBottomInner + 'px' };
    },
    loaderTextBind() {
      return {
        textColor: this.textColor,
      };
    },
    isDebug() {
      return this.$route.name == 'LoadingDebug';
    },
    pageLoaderClass() {
      return [
        'page-loader',
        {
          'calc-claim': this.loaderWithMediaText,
          'more-than-60-sec': this.calcClaimCounter >= constants.CALC_CLAIM_TIMER_MAX_VALUE
        }
      ];
    },
    calcClaimLoaderIsVisible() {
      return (this.calcClaimState == 'start' || this.isDebug) && this.calcClaimCounter < constants.CALC_CLAIM_TIMER_MAX_VALUE;
    },
    customSpinnerIsVisible() {
      return this.isSpinner && this.customSpinner && this.calcClaimCounter < constants.CALC_CLAIM_TIMER_MAX_VALUE;
    },
    loaderIsVisible() {
      return (!this.isSpinner || !this.customSpinner) && this.calcClaimCounter < constants.CALC_CLAIM_TIMER_MAX_VALUE;
    },
  },
  methods: {
    clearAllIntervals() {
      this.$emit('clearAllIntervals');
    }
  }
};
</script>
