import gql from 'graphql-tag';

const COMMON_FIELDS = `
  address
  comment
  projectProfitYearRate(asPercent: true, roundingAccuracy: 0)
  netProfit
  duration {
    duration
    unit
    unitLabel
  }
  price
  pricePurchase(roundingAccuracy:0, absolute: true)
  priceMarket(roundingAccuracy: 0)
  investmentAmount(roundingAccuracy: 0)
  improvementAmount(roundingAccuracy: 0, absolute: true)
  coordinate {lat, lng}
  id
  externalUrl
  calculationDate
  tags
  buildingInfo {
    constructionYear
    floorsCount
    materialType
    rating
    inNoiseArea
  }
  flatInfo {
    floorNumber
    roomsCount
    repairRating
    repairType
    area
  }
  discountFromPriceMarket(asPercent: true, roundingAccuracy: 2)
  exposition {
    days
  }
  currency
  scorePowerOfDemand(roundingAccuracy: 0)
  priceShownObviousAd
  commentChecks
  commentChecksAt
  isCommentChecks
`;

const COMPETITORS = `
  competitors {
    items {
      address
      price
      priceMarket
      coordinate {lat, lng}
      id
      externalUrl
      tags
      currency
      buildingInfo {
        constructionYear
        floorsCount
        materialType
        rating
        inNoiseArea
      }
      flatInfo {
        floorNumber
        roomsCount
        repairRating
        repairType
        area
      }
      closestCompetitor
      unpublishedAt
      scorePowerOfDemand(roundingAccuracy: 0)
      exposition {
        days
      }
      metaInfo {
        ... on InvestmentObject__MetaInfo__AeSold {
          transactionAt
          transactionNumber
        }
        __typename
      }
    }
  }
`;

const POTENTIALS = (hasDetailedInformation) => {
  if (!hasDetailedInformation) {
    return `
      potentialStrategies {
        name
        price
        roi

        ... on InvestmentObject__PotentialStrategy__Rent {
          period
        }
      }
      potentials {
        currency
        potentialType
        variantInfo{
          changes
        }
        flatInfo {
          floorNumber
          roomsCount
          repairRating
          repairType
          area
          repairRatingChangesTo
          repairTypeChangesTo
        }
      }
    `;
  }

  return `
    potentials {
      potentialType
      active
      address
      comment
      projectProfitYearRate(asPercent: true, roundingAccuracy: 0)
      netProfit
      duration {
        duration
        unit
        unitLabel
      }
      price
      pricePurchase(roundingAccuracy:0, absolute: true)
      priceMarket(roundingAccuracy: 0)
      investmentAmount(roundingAccuracy: 0)
      improvementAmount(roundingAccuracy: 0, absolute: true)
      coordinate {lat, lng}
      id
      externalUrl
      calculationDate
      tags
      buildingInfo {
        constructionYear
        floorsCount
        materialType
        rating
        inNoiseArea
      }
      variantInfo{
        changes
      }
      flatInfo {
        floorNumber
        roomsCount
        repairRating
        repairType
        area
        repairRatingChangesTo
        repairTypeChangesTo
      }
      discountFromPriceMarket(asPercent: true, roundingAccuracy: 2)
      exposition {
        days
      }
      currency
      scorePowerOfDemand(roundingAccuracy: 0)
      priceShownObviousAd
      commentChecks
      commentChecksAt
      isCommentChecks
      ${COMPETITORS}
    }
  `;
};

const INVESTMENT_OBJECT_FIELDS = (potentialsDetailedInformation = false) => {
  return `
  {
    ${COMMON_FIELDS}
    ${COMPETITORS}
    ${POTENTIALS(potentialsDetailedInformation)}
    potentialVariants {
      id
      changes
    }
    ...on ClientObject {
      phones
    }
  }`;
};

const PREPARE_RESULT = `
  discountForFastSell(roundingAccuracy: 0)
  expositionDays
  priceAreaMedian
  similarApartmentCount
  soldApartmentsLastMonthsCount
`;

export const INVESTMENT_OBJECT_CALC_CLAIM_RESULTS_FIELDS = (potentialsDetailedInformation = false) => {
  return `
  {
    __typename
    ... on CalcClaim {
      ${COMMON_FIELDS}
      ${COMPETITORS}
      ${POTENTIALS(potentialsDetailedInformation)}
      contactInfo {
        mailOrTelegram
        name
        phone
      }
      prepareResult {
        ${PREPARE_RESULT}
      }
    }
    ... on CalcClaimPrepareResult {
      ${PREPARE_RESULT}
      fields {
        city
      }
      claimResult {
        __typename
        ... on CalcClaim {
          ${COMMON_FIELDS}
          ${COMPETITORS}
          ${POTENTIALS(potentialsDetailedInformation)}
          contactInfo {
            mailOrTelegram
            name
            phone
          }
          prepareResult {
            ${PREPARE_RESULT}
          }
        }
      }
    }
    ... on CalcClaimError {
      message
    }
  }`;
};

export default function buildInvestmentObjectQuery(args) {
  const hasPotentialsDetailedInformation = args && args.hasPotentialsDetailedInformation || false;
  const isSubscription = args && args.isSubscription || false;
  const type = args && args.type || '';

  if (type == 'CalcClaim' || type == 'CalcClaimPrepareResult') {
    if (isSubscription) {
      return gql`subscription investmentObjectCalcClaimDone($id: ID!) {
                investmentObjectCalcClaimDone(id: $id) {
                  investmentObject ${INVESTMENT_OBJECT_CALC_CLAIM_RESULTS_FIELDS(hasPotentialsDetailedInformation)}
                }
              }`;
    } else {
      return gql`query investmentObjectCalcClaimResult($id: ID!) {
                userInfo {
                  auth
                  isInternal
                  isPublic
                }
                investmentObjectCalcClaimResult(id: $id) ${INVESTMENT_OBJECT_CALC_CLAIM_RESULTS_FIELDS(hasPotentialsDetailedInformation)}
              }`;
    }
  } else {
    return gql`query app($id: ID!) {
      userInfo {
        auth
        isInternal
        isPublic
      }
      investmentObject(id: $id) ${INVESTMENT_OBJECT_FIELDS(hasPotentialsDetailedInformation)}
    }`;
  }
}

export function investmentObjectWithCompetitors() {
  return gql`query investmentObjectPotential(
    $id: ID!,
    $variantId: ID!) {
    investmentObject(id: $id) {
      id
      potential(variantId: $variantId) {
        variantInfo {
          id
          changes
        }
        ${COMMON_FIELDS}
        ${COMPETITORS}
      }
      ...on ClientObject {
        phones
      }
    }
  }`;
}
