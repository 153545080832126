import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_ANALGEO_GRAPHQL_URL,
});

export const apolloAnalgeoClient = new ApolloClient({
  link: ApolloLink.from([
    httpLink,
  ]),
  cache: new InMemoryCache(),
});
