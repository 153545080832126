import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations({
      changePanelStatus: 'modal/changePanelStatus',
      updateModalContent: 'modal/updateContent',
    }),
    showInfoPanel(data) {
      this.changePanelStatus(true);
      this.updateModalContent(data);
      document.querySelector('body').classList.add('info-panel-visible');
    }
  },
};
