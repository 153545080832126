<template>
  <header v-bind:class="`header top-header ${headerClass}`">
    <slot></slot>
  </header>
</template>

<script>
export default {
  props: {
    sticky: {
      type: Boolean,
    },
  },
  name: 'Header',
  data() {
    return {
      scrollPosition: null,
    };
  },
  computed: {
    headerClass() {
      return this.scrollPosition > 10 && this.sticky ? 'sticky' : '';
    }
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    this.updateScroll();
    window.addEventListener('scroll', this.updateScroll);
  },
};
</script>
