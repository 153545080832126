const obj = {
  chart: {
    priceRadioGroupTitle: 'Цены за',
    saleRadioGroupTitle: 'Продажа за',
    lot: 'лот',
  },
  noCompetitors: 'Конкурентов нет',
  currentProperty: 'Ваша квартира',
  btnList: 'Показать детали'
};

export default obj;
