<template>
  <div class="object-address">
    <span v-if="truncatedAddress">{{ truncatedAddress }}</span>
    <span v-if="address && flatInfo">, </span>
    <span v-if="flatInfo">{{ flatInfo }}</span>
    <span v-if="constructionYear">, {{ constructionYear }}</span>
    <span v-if="materialType">, {{ materialType }}</span>
    <span v-if="area">, {{ formatNumber(area) }}
    <span v-html="$t(`property.${units}`)"></span>
    </span>
  </div>
</template>

<script>
export default {
  props: ['address', 'flatInfo', 'constructionYear', 'materialType', 'area', 'units'],
  computed: {
    truncatedAddress() {
      /* eslint-disable */
      if (!this.address) return false;
      return this.$route.name.includes('Map') ? this.address.replace(/[\(\)]/g, '').trim().split(' ').slice(-4).join(' ') : this.address;
    }
  }
};
</script>

<style lang="scss" scoped>
.object-address {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
