const funds = {
  deals: {
    title: 'Сделки'
  },
  mainNavbar: {
    all: 'Все',
    funds: 'Фонды',
    flippers: 'Компании',
    persons: 'Частные лица'
  },
  dealsNavbar: {
    apartment: 'Квартиры',
    land: 'Земельные участки',
    all: 'Все',
    allTheTime: 'За все время'
  },
  chart: {
    actualDate: 'Отчет по сделкам на',
    tradingProfit: 'Прибыль по сделкам',
    irrAverage: 'Средний % годовой доходности',
    netProfit: 'Полученная прибыль',
    forecastNetProfit: 'Предполагаемая прибыль'
  }
};

export default funds;
