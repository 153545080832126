<template>
  <div>
    <a v-if="hasPreviousStep"
       v-bind:class="buttonClass"
       v-on:click.prevent="goToPreviousStep">
      <span class="back icon-in-circle">
        <svg-icon name="chevron-left" class="text-white"></svg-icon>
      </span>
      <span class="back-text ml-2" v-if="showText">{{ btnText }}</span>
    </a>
    <router-link v-bind:class="buttonClass"
                 v-bind:to="routeTo()"
                 @click.native="closePage"
                 v-else
                 replace>
      <span class="back icon-in-circle">
        <svg-icon name="chevron-left" class="text-white"></svg-icon>
      </span>
      <span class="back-text ml-2" v-if="showText">{{ btnText }}</span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ButtonBack',
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'static'
    },
    targetPath: String,
    hasPreviousStep: Boolean
  },
  computed: {
    ...mapGetters({
      getObjectCurrentId: 'favouriteMap/getObjectCurrentId',
      getPreviousRoute: 'getPreviousRoute'
    }),
    historyIsEmpty() {
      return this.$cookies.get('prevPage') === 'null';
    },
    isFavouriteObjectsMap() {
      return this.$cookies.get('currentPage') === 'FavouriteObjectsMap';
    },
    isCard() {
      return this.$cookies.get('currentPage') === 'Card';
    },
    isTradeUpCard() {
      return this.$cookies.get('currentPage') === 'TradeUpCard' || this.$cookies.get('currentPage') === 'TradeUpObjectEvaluationResult';
    },
    isCardInner() {
      const cardInnerPages = ['ProfitForecast', 'Competitors', 'CompetitorsMap', 'PotentialCompetitors', 'PotentialCompetitorsMap'];

      return cardInnerPages.some(el => this.$cookies.get('currentPage') == el);
    },
    isTradeUpCardInner() {
      const tradeUpCardInnerPages = ['TradeUpCardMap', 'TradeUpProfitForecast', 'TradeUpCompetitors', 'TradeUpCompetitorsMap'];

      return tradeUpCardInnerPages.some(el => this.$cookies.get('currentPage') == el);
    },
    prevPageIsForm() {
      return this.$cookies.get('prevPage') === 'ObjectEvaluation';
    },
    btnText() {
      return this.$t('btn.back');
    },
    objectId() {
      return this.$route.params.id || this.getObjectCurrentId;
    },
    isDisabledForPartners() {
      return this.$domainConfig.isPartner && this.$domainConfig.bottomBarButtons[0] != 'map' && !this.hasPrevious;
    },
    hasPrevious() {
      return this.$route ? this.$routerHistory.hasPrevious() : false;
    },
    buttonClass() {
      return [
        'back-wrap',
        this.position,
        {
          disabled: ((this.isFavouriteObjectsMap && !this.hasPrevious && !this.$route.fullPath.includes(this.$routerHistory.previous().path)) || this.isDisabledForPartners) && !this.hasPreviousStep || (this.$routerHistory.previous().path && this.$routerHistory.previous().path.includes('/onboarding') && !this.hasPreviousStep)
        }
      ];
    },
    hasTradeUpSelectedOption() {
      return this.$route ? this.$route.query.tradeUpSelectedOption : false;
    },
  },
  methods: {
    goToPreviousStep() {
      this.$emit('goToPreviousStep');
    },

    closePage() {
      this.$emit('closePage');
    },

    routeTo() {
      document.querySelector('body').classList.remove('modal-visible');
      let path = this.$routerHistory.previous().path;
      const id = this.$route.params.id;
      const tradeUpObjectId = this.$route.params.tradeUpObjectId;
      const clientToken = window.__realisteStorage.getItem('clientToken', 'sessionStorage');
      const initialPath = clientToken ? `/${clientToken}` : '/';
      const history = this.$routerHistory._history;
      if (!this.hasPrevious && !this.isFavouriteObjectsMap) {
        if (this.isCardInner) {
          if (id.length > 10) {
            path = clientToken ? `/${clientToken}/object-evaluation/${id}` : `/object-evaluation/${id}`;
          } else {
            path = clientToken ? `/${clientToken}/object/${id}` : `/object/${id}`;
          }
        } else if (this.isTradeUpCardInner) {
          if (id.length > 10) {
            path = clientToken ? `/${clientToken}/trade-up/${tradeUpObjectId}/object-evaluation/${id}` : `/trade-up/${tradeUpObjectId}/object-evaluation/${id}`;
          } else {
            path = clientToken ? `/${clientToken}/trade-up/${tradeUpObjectId}/object/${id}` : `/trade-up/${tradeUpObjectId}/object/${id}`;
          }
        } else {
          path = clientToken ? `/${clientToken}/` : '/';
        }
      }
      if (this.isCard && history[0] != initialPath.split('?')[0] && !this.hasTradeUpSelectedOption) {
        path = clientToken ? `/${clientToken}/` : '/';
      }
      if (this.isTradeUpCard && history[0] != initialPath.split('?')[0]) {
        path = clientToken ? `/${clientToken}/trade-up/${tradeUpObjectId}` : `/trade-up/${tradeUpObjectId}`;
      }
      if (this.$route.fullPath.split('?')[0] == this.$routerHistory.previous().path) {
        path = history[history.length - 3];
      }

      return {
        path
      };
    }
  },
};
</script>
