const obj = {
  title: 'Project estimate',
  estimateCosts: {
    estimateBuyFields: {
      title: 'Buying a property',
      CQ2: {
        title: 'Expert assessment of legal risks',
        description: 'Departure to inspect the object, check on the checklist, interviewing neighbors, collecting information about the object from local self-government bodies.',
      },
      CR2: {
        title: 'Expert assessment of real estate condition',
        description: 'Formation of estimates for improvements by an expert from a specialized contractor organization.'
      },
      CS2: {
        title: 'Collection of certificates'
      },
      CT2: {
        title: 'Lawyer\'s fee',
        description: 'The lawyer agrees between the parties to the terms of the contract, the place and time of signing the contract, the conditions for transferring money, submits documents to the state. authorities, receives documents, coordinates the signing of the deed of transfer.'
      },
      CU2: {
        title: 'Notary payment',
        description: 'Payment of a notary (for a notarized purchase transaction), for simple writing - costs 0 rubles.'
      },
      CW2: {
        title: 'Title insurance of the transaction',
        description: 'Title insurance is carried out by one of the TOP 3 insurance companies in Russia. (at the request of the investor). Not included in the estimate.'
      },
      CX2: {
        title: 'Banks commissions for letter of credit or safety deposit box',
        description: 'Bank commission for transaction under the transaction (letter of credit or cell).'
      },
      CY2: {
        title: 'Purchase cost',
        description: 'The cost of the transaction that goes to the seller after the registration of the transaction (excluding possible bargaining).'
      },
      CZ2: {
        title: 'Targeted bargaining / discount for quick liquidity',
      },
      DB2: {
        title: 'The cost of a realtor\'s services to find this deal',
      },
    },
    estimateOwnFields: {
      title: 'Increase in property value',
      DC2: {
        title: 'Legal services for the collection of missing documents',
        description: 'Collection of missing documents (if all documents are available - add up to the budget for real estate improvement).'
      },
      DD2: {
        title: 'Services of a third-party supervisor for work control',
        description: 'The cost of the supervision of the technical project manager to control the physical improvements.'
      },
      DE2: {
        title: 'Services of a third-party lawyer for the preparation of documents for sale',
        description: 'Services of a project manager to control the timing and solution of problems related to documents, section, refusals in state bodies, communications, local municipality, local partnership, neighbors.'
      },
      DF2: {
        title: 'Services of a third-party sales control manager',
        description: 'Realiste control of all points of the project, strategy adjustment.'
      },
      DG2: {
        title: 'Cleaning, dismantling of everything that looks older than 3 years.',
        description: 'Cleaning, dismantling of old furniture, built-in furniture, kitchens, sinks, toilets, doors, window sills.'
      },
      DH2: {
        title: 'Setting up and installing WI-FI',
        description: 'Connecting Wi-Fi to coordinate other services and systems and send information.'
      },
      DI2: {
        title: 'Installing a Webcam',
        description: 'Remote control over the apartment.'
      },
      DJ2: {
        title: 'Installing a smart lock',
        description: 'To display real estate 24/7 without the presence of a realtor (apartments or commercial premises), a smart lock is used (entrance to the premises by an electronic code).'
      },
      DK2: {
        title: 'Living area staging',
        description: 'The estimate is made from a) the exact footage of the work (counted in cubes) - floor, walls, ceiling, windows. b) prices for work and materials from 5 contractors c) the required quality for sale in this house (area).'
      },
      DL2: {
        title: 'Corridor staging',
        description: 'The estimate is made from a) the exact footage of the work (counted in cubes) - floor, walls, ceiling, windows. b) prices for work and materials from 5 contractors c) the required quality for sale in this house (area).'
      },
      DM2: {
        title: 'Staging cuisine',
        description: 'The estimate is made from a) the exact footage of the work (counted in cubes) - floor, walls, ceiling, windows. b) prices for work and materials from 5 contractors c) the required quality for sale in this house (area).'
      },
      DN2: {
        title: 'Staging bath / WC',
        description: 'The estimate is made from a) the exact footage of the work (counted in cubes) - floor, walls, ceiling, windows. b) prices for work and materials from 5 contractors c) the required quality for sale in this house (area).'
      },
      DO2: {
        title: 'Arrangement of common areas',
        description: 'Improving the site (common areas) or installing a smart lock on common doors with neighbors.'
      },
      DP2: {
        title: 'Valuation from an estimation company for a buyer with a mortgage',
        description: 'Real estate estimation by a professional appraiser (for banks, mortgages, etc.). This shortens the sale period from 10 to 14 days for a sale to a mortgage buyer.'
      },
      DQ2: {
        title: '3D model of the apartment + Professional photography',
        description: 'Creation of a 3D model of an apartment, professional photos and rendering of 4 photos.'
      },
      DR2: {
        title: 'Advertising of an apartment on Cyan, Avito, Yandex, DomClick',
        description: 'Calculated for the period of target sale at the cost of the most expensive services of the sites (TOP 3, Premium on Avito, etc.).'
      },
      DS2: {
        title: 'Local advertising on Facebook / Instagram',
        description: 'More than 60% of buyers are locals. They do not monitor daily bulletin boards. Activating leads and increasing reach will shorten the lead time and generally show an ROI> 400%.'
      },
      DT2: {
        title: 'Offline advertising in the area (leaflets, posting ads)',
        description: 'More than 60% of buyers are locals. They do not monitor daily bulletin boards. Activating leads and increasing reach will shorten the lead time and generally show an ROI> 200%.'
      },
      DU2: {
        title: 'Printing and installation of advertising banners (windows / balcony)',
        description: 'Installation and printing of banners.'
      },
      DV2: {
        title: 'Call center operators for receiving and fixing calls. CRM system',
        description: 'Receiving calls from advertising, answering customer questions, sending customers for views. Calculated for the target sale period.'
      },
    },
    estimateOwn2Fields: {
      title: 'Selling real estate - making a profit',
      DW2: {
        title: 'Payment of sales commission',
        description: 'Commission to agents for finding a buyer (by default from 1.5% to 2.5% of the sale value). If the buyer came without an agent, then the commission is added to the project\'s net profit.'
      },
      DX2: {
        title: 'Property tax',
      },
      DY2: {
        title: 'Legal service for support of the sale transaction',
      },
      DZ2: {
        title: 'Banks commissions per transaction',
        description: 'Bank commission for transaction under the transaction (letter of credit or cell).'
      },
      taxes: {
        title: 'Income tax',
        description: 'Income tax is considered by default for a private person (13% personal income tax). Remember that you will most likely not be able to use your repair, advertising, and service costs as a tax deduction to help you buy an apartment. Only expenses that you incurred to sell an apartment (commission to a realtor, advertising, legal services) are accepted as deductions.'
      },
      net_profit: {
        title: 'Profit',
        description: 'Profit on the project after deducting all costs and fees, but before paying income taxes.'
      },
    }
  },
  investmentObjectParameters: {
    APY: {
      description: 'Annual Percentage Yield (APY) is the rate of return calculated taking into account the application of compound interest to deposits or investment products. Allows you to simplify the comparison of the yield for annual compound interest with different income calculation intervals (when interest is calculated several times a year at the annual compound interest rate). Annual Percentage Yield (APY) shows the percentage rate of return as if the annual compounding interest was calculated once a year and would give the same accrued value (future (present) value) as when calculating the annual compound interest in question, which is paid several times in year.'
    },
    NP: {
      description: 'Profit on the project after deducting all costs and fees, but before paying income taxes.'
    },
    PD: {
      description: 'This is the target period for the project, for which the system conservatively believes that it can be sold at the quick sale price.'
    },
    SP: {
      description: 'This is the seller\'s bid price as of the current date.'
    },
    FSP: {
      description: 'The price for which the apartment will be sold within the target period of the project or earlier (with the necessary investments indicated in the additional investments for quick sale) Additional investments and categories are indicated in the project estimate.'
    },
    PP: {
      description: 'This is the purchase price at which you do not risk taking a loss on this investment, you are buying well below the liquidity value of this property. (does not take into account possible legal risks or other risks, only the risks of valuation).'
    },
    AI: {
      description: 'These expenses are necessary to increase the audience of buyers and increase interest in the apartment in order to sell the apartment quickly. These expenses include: improving the appearance of the apartment and an abundant advertising campaign. In 90 cases out of 100, if the apartment is sold for more than 3 months at the price of the market price, then a) bad photos were taken b) insufficient advertising coverage c) an amateur realtor.'
    },
    priceShownObviousAd: {
      description: 'Price calculated based on similar offers, but the sale date cannot be calculated.'
    }
  },
  competitiveObjectsParameters: {
    BC: {
      description: 'As competitors, we take only the most liquid and best offers on the market (as a rule, these apartments are also underestimated).'
    }
  },
  objectProperties: {
    NL: {
      description: 'Located in an area of ​​high noise levels'
    },
    SP: {
      description: ''
    },
  }
};

export default obj;
