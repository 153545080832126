<template>
  <div class="d-inline-flex align-items-baseline"
       v-if="area">
    <span v-bind:class="`divider ${sizeClass}`"
          v-if="roomsCount != null && !hideDivider"></span>
    <span v-if="roomsCount != null && showComma"
          class="mr-1">,</span>
    {{ area }}
    <span v-html="$t(`property.${units}`)"></span>
  </div>
</template>

<script>
export default {
  props: {
    area: Number,
    sizeClass: String,
    units: String,
    roomsCount: Number,
    hideDivider: {
      type: Boolean,
      default: false
    },
    showComma: {
      type: Boolean,
      default: false
    },
  }
};
</script>
