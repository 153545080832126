<template>
  <div v-bind:class="iconClass"
       v-if="icon">
    <svg-icon v-if="icon === 'star'"
              v-bind:name="icon"></svg-icon>
    <span v-else>{{ icon }}</span>
  </div>
</template>

<script>
export default {
  props: ['icon', 'iconClass']
};
</script>

<style lang="scss" scoped>
.icon {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  font-size: 1rem;
  line-height: 1.8rem;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  border: 0.1rem solid $white;
  box-shadow: 0px 4px 4px rgba(203, 203, 203, 0.3);
  color: $white;
  font-weight: 800;
  text-align: center;
  margin-right: 0.5em;

  .svg-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: 0.1rem;
    vertical-align: baseline;
  }
}
</style>
