const obj = {
  btn: {
    cancel: '取消',
    submit: '发送',
    close: '关闭'
  },
  analysisError: {
    title: '分析中的错误?',
    telegramLink: '去电报技术支持组',
  },
  propertyValuation: {
    title: {
      default: 'Free and accurate estimation of an apartment in 10 seconds',
      lenstroytrest: 'Free and accurate estimation of an apartment in 10 seconds'
    },
    subtitle: '如果銷售期為 1 週、3 個月或半年，請找出公寓的成本',
    claimsCounter: '自 2021 年 6 月 1 日起已獲得 {counter} 次評級。',
    claimFeatures: {
      title: '你會在10秒內發現',
      list: [
        '公寓的市場價值及其影響因素',
        '每月按揭付款',
        '銀行可以為公寓的安全提供什麼信用'
      ]
    },
    fillOutClaimNote: '填寫申請的目的：',
    btn: {
      submit: {
        default: 'Show result',
        nextStep: 'Next step',
        pending: '数据被发送',
        verifyPhone: {
          default: 'Send',
          tradeUp: 'Send'
        },
        smsCodeVerification: {
          default: 'Find out now the cost of the apartment',
          tradeUp: 'Open a map with options!'
        },
        resendPhone: {
          prependText: '',
          hasInterval: 'Resend SMS in {count} sec',
          default: 'Resend SMS'
        }
      },
      showThirdStep: '估計',
      showFourthStep: 'More'
    },
    fillAddressNote: 'Enter the address to go to the next step',
    canCalcForPoint: {
      errorLabel: 'It is impossible to calculate the claim for these coordinates. Try to enter a new address {telegramLink} to be aware of when we can make a calculation',
      linkText: 'join our community'
    },
    error: {
      manyCalcRequestsForAddress: 'Unfortunately, we are unable to complete the calculation. There may be too many requests coming from your network. <br>Please try again later.',
      default: 'Unfortunately, an error has occurred. Please try again later.'
    },
    prefilledDataMessage: 'Please check the pre-filled data',
    showPrice: 'Show price'
  },
  offerForm: {
    offerTitle: ['我們將購買該物品', '3天內'],
    formTitle: '確認聯繫方式：',
    note: ['如果您認為價格不屬於市場，請', '查看競爭分析'],
    btn: {
      getOffer: '獲得報價',
      notInterested: '沒興趣',
      submit: {
        default: '發送請求',
        pending: '數據已發送',
        error: '無法發送數據',
        success: '數據發送成功'
      }
    }
  },
  categoryTitle: {
    tellAboutApartment: '跟我们说说公寓的情况',
    mainObjectParameters: '房屋的主要参数',
    location: '輸入您的公寓地址',
    objectAbout: '关于公寓',
    square: '面积',
    floor: '层数',
    balconies: '数量的阳台',
    bathrooms: '数量的浴室',
    elevator: '电梯的数量',
    contactInfo: '联系信息'
  },
  inputGroup: {
    appendLabel: {
      squareMeter: {
        code: 'm2',
        html: '平米'
      }
    }
  },
  fields: {
    problemDescription: {
      placeholder: '描述的问题',
      note: '您可以要求詳細計算公寓的成本，其中 AI 計算的準確性將由 Realist 專家檢查並將他們的結論發送給您。'
    },
    desiredPrice: {
      label: '期望销售价格(你自己估计多少)',
      note: '输入想要的价格或留空自动评估'
    },
    burden: {
      label: '是否存在累赘?',
      note: '选择是否存在待售产权负担(质押、法庭等)'
    },
    objectType: {
      emptyText: '-- Choose --',
      label: '类型的住房',
      options: [
        {
          code: 'flat',
          label: '平'
        },
        {
          code: 'apartments',
          label: '公寓'
        }
      ]
    },
    buildingPropertyType: {
      emptyText: '-- Choose type --',
      options: [
        {
          code: 'condo',
          label: '平'
        }
      ]
    },
    roomsCount: {
      label: '房间的数量',
      studio: 'studio'
    },
    square: {
      label: '区域',
      note: {
        sqm: 'Minimum 8 m',
        sqft: 'Minimum 300 ft'
      }
    },
    repairRating: {
      title: '維修質量',
      label: '您如何評價公寓裝修的質量',
      error: '請註明裝修水平（1至10星）'
    },
    city: {
      label: '地區或城市'
    },
    address: {
      label: '{city} and region',
      placeholder: '输入的地址',
      note: {
        gmap: '',
        dadata: ''
      },
      error: '进入街道，找到确切的地址和门牌号',
      warning: '請檢查房屋或公寓是否已進入',
      canChooseAddressLabel: {
        showSelect: 'Edit',
        hideSelect: 'Hide'
      }
    },
    roomNumber: {
      label: '公寓号码'
    },
    buildingBuildYear: {
      label: '年的建设'
    },
    timeWaltToMetro: {
      label: '步行至地铁'
    },
    kadNumber: {
      label: '地籍号'
    },
    buildingMaterialType: {
      emptyText: '-- Choose type --',
      label: '类型的房子',
      options: [
        {
          code: 'brick',
          label: '砖'
        },
        {
          code: 'monolith',
          label: '单片'
        },
        {
          code: 'panel',
          label: '三合板'
        },
        {
          code: 'block',
          label: '块'
        },
        {
          code: 'wood',
          label: '木'
        },
        {
          code: 'stalin',
          label: '斯大林'
        },
        {
          code: 'monolithBrick',
          label: '-单片砖墙'
        }
      ]
    },
    redevelopment: {
      label: '有違法重建嗎？',
      options: [
        {
          code: true,
          label: '是'
        },
        {
          code: false,
          label: '不是'
        }
      ]
    },
    viewFromWindow: {
      label: '从窗户看出去',
      options: ['去公园', '到森林', '在高速公路上', '到院子里']
    },
    roomsLayout: {
      emptyText: '-- Choose value --',
      label: '房间的布局',
      options: [
        {
          code: 'adjoining',
          label: '相邻'
        },
        {
          code: 'isolated',
          label: '孤立的'
        },
        {
          code: 'adjoiningIsolated',
          label: '鄰接隔離'
        }
      ]
    },
    ceilingHeight: {
      label: '天花板的高度'
    },
    squareLiving: {
      label: '住宅'
    },
    squareKitchen: {
      label: '厨房'
    },
    floorNumber: {
      label: '层'
    },
    buildingFloorsCount: {
      label: '房屋的楼层数'
    },
    balconiesCount: {
      label: '阳台'
    },
    loggiasCount: {
      label: '从事'
    },
    bathroomShared: {
      label: '相邻'
    },
    bathroomCombined: {
      label: '单独的房间'
    },
    parkingType: {
      emptyText: '-- Choose type --',
      label: '停车场',
      options: [
        {
          code: 'in_yard',
          label: '在院子里'
        },
        {
          code: 'underground',
          label: '地下'
        },
        {
          code: 'chaos',
          label: '混乱的'
        },
        {
          code: 'barrier_used',
          label: '背后的障碍'
        }
      ]
    },
    additionalOptions: {
      label: '另外',
      options: ['操场上', '礼宾服务', '保护区']
    },
    elevatorFreightsCount: {
      label: '货物'
    },
    elevatorPassengersCount: {
      label: '客运服务'
    },
    contactName: {
      label: '你的名字'
    },
    contactPhone: {
      label: '联系电话号码',
      placeholder: '輸入你的電話號碼',
      error: {
        validateNumber: '請檢查您的電話號碼',
        verificateNumber: '抱歉，發生錯誤了。請稍後再試。'
      }
    },
    contactMailOrTelegram: {
      label: '电子邮件或电报'
    },
    contactSmsCode: {
      label: 'We have sent an SMS confirmation code to your phone. <br> Please enter the verification code.',
      placeholder: 'Confirmation code',
      error: 'Sorry, you entered the wrong code or an error occurred. Please check the code or request a new one.'
    },
    externalUrl: {
      label: '鏈接到另一個站點（如果有）',
      error: '找不到該對象的數據',
      btnText: '填寫數據'
    },
    correctPrice: {
      label: '正確的價格'
    },
    apartmentNumber: {
      label: '公寓號'
    },
    comment: {
      label: 'Comment'
    }
  },
  messages: {
    error: {
      part1: '不幸的是，我們仍無法根據您的住址和特徵來計算公寓的費用。也許您在某處指定了錯誤的數據。<br>無論如何，我們已經收到您的申請，並且將在您的申請計算可用後立即寫信給您。<br>同時，我們邀請您關注更新',
      part2: '在我們的電報頻道'
    },
    bugReport: {
      success: '謝謝你的評論！系統已接受您的評論，並將在以後的計算中予以考慮',
      error: '出問題了。嘗試稍後發送評論或寫信 <a href="https://t.me/joinchat/D9E-5xbj0w0nUgieILwaQg" target="_blank">給 Telegram 支持組</a>',
      searchForErrors: '對於在數據或評估中發現的每 30 個錯誤和不准確之處，我們將免費提供 1 個月的 PRO 版本使用時間。 詳細信息 <a href="{link}" target="_blank">網站</a>'
    },
    success: {
      default: 'Thank you, we will contact you as soon as possible',
    }
  },
  showMore: {
    title: {
      part1: '告訴更多關於公寓的信息',
      part2: '並在10秒內使估算更加準確'
    }
  },
  purposes: {
    wanna_sell: {
      label: '我賣',
      labelBtnCta: '10天內賣掉這間公寓',
      labelModal: '留下請求並收到我們在 1 天內購買此公寓的報價',
      subTitleModal: '如果您有必要的銷售文件包，銷售期將為 10 天或更早',
      labelModalButton: '賣'
    },
    wanna_buy: {
      label: '買',
      labelBtnCta: '與我們一起購買',
      labelModal: '與我們一起購買',
      subTitleModal: '',
      labelModalButton: '買'
    },
    get_apt_details: {
      label: 'Get information',
      labelBtnCta: 'Get information about this unit',
      labelModal: 'Leave a request and we will send you the details',
      subTitleModal: '',
      labelModalButton: 'Get information'
    },
    collateral_evaluation: {
      label: '抵押品評估',
      labelBtnCta: '由公寓擔保的貸款',
      labelModal: '由公寓擔保的貸款',
      subTitleModal: '',
      labelModalButton: '提交您的申請'
    },
    trade_in: {
      label: '交換',
      labelBtnCta: '交換',
      labelModal: '交換',
      subTitleModal: '',
      labelModalButton: '交換'
    },
    trade_up_map: {
      label: 'Trade up options'
    },
    wanna_view: {
      label: 'Wanna view'
    }
  },
  alert: {
    checkIfAllFieldsAreFilled: 'Check if all fields are filled'
  },
  canCalculateNo: '尚不支持計算此屬性類型',
};

export default obj;
