<template>
  <div id="app" v-bind:class="appClass">
    <data-loader v-bind:isLoading="isLoading"
                  v-bind:isSpinner="true"
                  overlayColor="rgba(0,0,0,0.4)"
                  textColor="white" />
    <div v-if="!isLoading">
      <svg-sprite v-if="styleSheetIsLoaded" />
      <transition name="fade" mode="out-in">
        <div>
          <locale-flags v-if="canSwitchLocale"></locale-flags>
          <div class="fixed-btn-wrap"
              v-if="hasOnboardingLinks && styleSheetIsLoaded">
            <a
              class="fixed-btn"
              v-if="educationBtnIsVisible"
              v-on:click="showInfoPanel({title: 'Видео, обучение и инструкция', customComponent: 'AIHowItWorks'})">
              <svg-icon name="innovation"></svg-icon>
              <p class="fixed-btn-text">
                {{ $t('btn.education') }}
              </p>
            </a>
          </div>
          <div class="fixed-btn-wrap"
              v-if="hasOnboardingLinks && styleSheetIsLoaded">
            <a v-bind:href="mediaAboutRealisteUrl"
              target="_blank"
              class="fixed-btn"
              v-if="$route.name === 'FavouriteObjectsMap'">
              <svg-icon name="newspaper"></svg-icon>
              <p class="fixed-btn-text">
                {{ $t('btn.mediaAboutRealiste') }}
              </p>
            </a>
          </div>
          <component v-bind:is="layout">
            <slot />
          </component>
        </div>
      </transition>
      <info-panel
        v-bind:code="panelContentInner.code"
        v-bind:category="panelContentInner.category"
        v-bind:subCategory="panelContentInner.subCategory"
        v-bind:title="panelContentInner.title"
        v-bind:customComponent="panelContentInner.customComponent"
        v-bind:objectId="panelContentInner.objectId"
        v-bind:zIndex="panelContentInner.zIndex"
        v-bind:htmlDescription="panelContentInner.htmlDescription"
        v-bind:currency="panelContentInner.currency"
        v-bind:coordinate="panelContentInner.coordinate" />
      <bottom-bar></bottom-bar>
      <theme-switcher v-bind:class="themeSwitcherClass"
                      v-if="canSwitchTheme"></theme-switcher>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/patterns/event-bus';
import buildRealisteUrl from '@/lib/build_realiste_url';
import fbqTrack from '@/lib/fbq_track';
import { userInfo } from '@/models/userInfo';
import updatePanelData from '@/mixins/updatePanelData';
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';
import SvgSprite from '@/components/common/SvgSprite.vue';
import calcVhMixin from './mixins/calcVhMixin';

export default {
  name: 'app',
  mixins: [calcVhMixin, updatePanelData],
  components: {
    SvgSprite,
    LocaleFlags: () => import('@/components/common/LocaleFlags.vue'),
    ThemeSwitcher: () => import('@/components/common/ThemeSwitcher.vue')
  },
  data() {
    return {
      currentLayout: 'empty-layout',
      styleSheetIsLoaded: false,
      isLoading: true,
      isAuth: false,
    };
  },
  computed: {
    ...mapState(['dealsCurrency']),
    urlRealisteKnowledge() {
      return buildRealisteUrl('platform/knowledge');
    },
    appClass() {
      return {
        'ovh-visible': this.$route.meta.isCompetitorsList,
        'map pb-0': this.$route.meta.hasMap,
        'bottom-bar-wrap': this.$domainConfig.bottomBarButtons.length > 1,
        'fixed-btn-visible': this.educationBtnIsVisible
      };
    },
    ...mapGetters({
      panelContent: 'modal/content',
      getSelectedCity: 'getSelectedCity'
    }),
    getSelectedCityFromLS() {
      return window.__realisteStorage.getItem('selectedCity') != null;
    },
    panelContentInner() {
      return this.panelContent;
    },
    educationBtnIsVisible() {
      return this.$route.name == 'FavouriteObjectsMap';
    },
    layout() {
      if (this.$route.meta.emptyLayout) {
        return 'empty-layout';
      }

      if (this.$route.meta.dealsLayout) {
        return 'deals-layout';
      }

      return 'default-layout';
    },
    hasOnboardingLinks() {
      return this.$domainConfig.onboardingLinks;
    },
    phoneIsCorrect() {
      return this.$store.getters.getContactPhone || window.__realisteStorage.getItem('contactPhone') != undefined;
    },
    mediaAboutRealisteUrl() {
      return this.$constants.MEDIA_ABOUT_REALISTE_URL;
    },
    themeSwitcherClass() {
      const routeNames = ['TradeUp', 'ObjectEvaluation'];

      if (!this.$route.name) return ['theme-switch-wrap'];

      return [
        'theme-switch-wrap',
        {
          'position-under-locale': routeNames.includes(this.$route.name),
          'no-onboarding-links': !this.hasOnboardingLinks
        }
      ];
    },
    canSwitchTheme() {
      return this.$domainConfig.canSwitchTheme && this.styleSheetIsLoaded;
    },
    canSwitchLocale() {
      return this.$domainConfig.canSwitchLocale && this.styleSheetIsLoaded;
    }
  },
  methods: {
    ...mapActions(['exchangeRateDeals']),
    ...mapMutations({
      updateTokenStatus: 'updateTokenStatus',
      updateInternalStatus: 'updateInternalStatus',
      updatePublicStatus: 'updatePublicStatus',
      setIsDeveloper: 'setIsDeveloper',
    }),

    updateLoadingState(v) {
      this.isLoading = !v;
    },
    checkIsAuth() {
      if (this.$route.params?.clientToken && !this.isAuth) {
        alert(this.$t('tokenExpired'));
      }
    }
  },
  apollo: {
    userInfo: {
      query: userInfo,
      update: () => [],
      result({ data, loading }) {
        if (!loading) {
          this.$nextTick(() => {
            if (data.userInfo && data.userInfo != null) {
              this.updateTokenStatus(data.userInfo.auth);
              this.updateInternalStatus(data.userInfo.isInternal);
              this.updatePublicStatus(data.userInfo.isPublic);
            } else {
              this.updateTokenStatus(false);
            }
            if (data.partnerInfo) {
              this.setIsDeveloper(data.partnerInfo);
            }
            this.isAuth = data?.userInfo?.auth;
            setTimeout(this.checkIsAuth, 1000);
          });
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },

  mounted() {
    if (location.pathname.includes('deals')) {
      this.exchangeRateDeals('USD');
    }
    if (window.__realisteStorage.getItem('selectedCity') == null && (this.$domainConfig.bottomBarButtons.length == 1 || this.$domainConfig.initialPage)) {
      window.__realisteStorage.setItem('selectedCity', this.$domainConfig.selectedCity);
    }

    this.styleSheetIsLoaded = true;

    EventBus.$on('constantsAreLoaded', this.updateLoadingState);
  },

  updated() {
    document.body.classList.add('app-loaded');
  },

  watch: {
    $route(to, from) {
      if (to.fullPath.includes('deals') && this.dealsCurrency.rate === 1 && this.dealsCurrency.currency === 'USD') {
        this.exchangeRateDeals('USD');
      }
      fbqTrack(to, this.$domainConfig.name);
    }
  }
};
</script>
