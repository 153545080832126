export default {
  methods: {
    routingTo(pageName, id, properties) {
      this.$router.push({
        name: pageName,
        params: {
          id
        },
        query: {
          ...properties
        }
      }).catch(() => {});
    }
  }
};
