<template>
  <svg v-bind:class="className">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    className() {
      return `svg-icon svg-icon--${this.name}`;
    },
  },
};
</script>

<style>
.svg-icon {
  fill: currentColor;
}
</style>
