const obj = {
  title: 'Смета проекта',
  estimateCosts: {
    estimateBuyFields: {
      title: 'Покупка недвижимости',
      CQ2: {
        title: 'Экспертная оценка юридических рисков',
        description: 'Выезд на осмотр объекта, проверка по чек-листу, опрос соседей, сбор информации об объекте из местных органов самоуправления.',
      },
      CR2: {
        title: 'Экспертная оценка состояния недвижимости',
        description: 'Формирование сметы на улучшения экспертом из профильной организации-подрядчика.'
      },
      CS2: {
        title: 'Сбор справок'
      },
      CT2: {
        title: 'Гонорар юриста',
        description: 'Юрист согласовывает между сторонами условия договора, место и время подписания договора, условия передачи денег, сдает документы в гос. органы, получает документы, согласовывает подписание передаточного акта.'
      },
      CU2: {
        title: 'Оплата нотариуса',
        description: 'Оплата  нотариуса (при нотариальной сделке покупки ), при простой письменной форме - расходы 0 рублей.'
      },
      CW2: {
        title: 'Титульное страхование сделки',
        description: 'Титульное страхование осуществляется одним из ТОП 3 страховых компаний по России. ( по желанию инвестора ). Не учитывается в смете.'
      },
      CX2: {
        title: 'Комиссии банков за акредитив или банковскую ячейку',
        description: 'Комиссия банка за транзакцию по сделке ( аккредитив или ячейка ).'
      },
      CY2: {
        title: 'Стоимость покупки',
        description: 'Стоимость сделки, которая уходит продавцу после регистрации сделки ( без учета возможного торга ).'
      },
      CZ2: {
        title: 'Целевой торг / скидка за быструю ликвидность',
      },
      DB2: {
        title: 'Стоимость услуг риелтора по поиску этой сделки',
      },
    },
    estimateOwnFields: {
      title: 'Увеличение стоимости недвижимости',
      DC2: {
        title: 'Услуги юриста по сбору недостающих документов',
        description: 'Сбор недостающих документов ( если все документы есть - складывается с бюджетом на улучшение недвижимости ).'
      },
      DD2: {
        title: 'Услуги стороннего прораба по контролю за работами',
        description: 'Стоимость услуг надзора технического менеджера проекта по контролю за физическими  улучшениями.'
      },
      DE2: {
        title: 'Услуги стороннего юриста по подготовке документов для продажи',
        description: 'Услуги менеджера проекта по контролю за сроками и решением проблем связанных с документами, разделом, отказами в гос.органах, коммуникаций, местным муниципалитетом, местным товариществом, соседями.'
      },
      DF2: {
        title: 'Услуги стороннего менеджера по контролю за продажей',
        description: 'Контроль всех точек проекта со стороны Realiste, корректировка стратегии.'
      },
      DG2: {
        title: 'Уборка, демонтаж всего что выглядит старше 3-ех лет.',
        description: 'Уборка, демонтаж старой мебели, встроенной мебели, кухни, раковин, унитазов, дверей, подоконников.'
      },
      DH2: {
        title: 'Настройка и установка WI-FI',
        description: 'Подключение вай фай для координации других сервисов и систем и отсылки информации.'
      },
      DI2: {
        title: 'Установка Веб камеры',
        description: 'Удаленный контроль за квартирой.'
      },
      DJ2: {
        title: 'Установка смарт-лока',
        description: 'Для показа недвижимость 24/7 без присутствия риелтора ( квартиры или коммерческое помещение ) используется смарт-лок ( вход в помещение по электронному коду ).'
      },
      DK2: {
        title: 'Стэйджинг жилой зоны',
        description: 'Смета составляется из а) точного метража по работам ( пересчитанным в кубах ) - пол, стены, потолок, окна. б) цен на работы и материалы от 5 подрядчиков в) необходимого качества для продажи в этом доме ( районе ).'
      },
      DL2: {
        title: 'Стэйджинг коридоров',
        description: 'Смета составляется из а) точного метража по работам ( пересчитанным в кубах ) - пол, стены, потолок, окна. б) цен на работы и материалы от 5 подрядчиков в) необходимого качества для продажи в этом доме ( районе ).'
      },
      DM2: {
        title: 'Стэйджинг кухни',
        description: 'Смета составляется из а) точного метража по работам ( пересчитанным в кубах ) - пол, стены, потолок, окна. б) цен на работы и материалы от 5 подрядчиков в) необходимого качества для продажи в этом доме ( районе ).'
      },
      DN2: {
        title: 'Стэйджинг ванна/санузел',
        description: 'Смета составляется из а) точного метража по работам ( пересчитанным в кубах ) - пол, стены, потолок, окна. б) цен на работы и материалы от 5 подрядчиков в) необходимого качества для продажи в этом доме ( районе ).'
      },
      DO2: {
        title: 'Обустройство площадок общего пользования',
        description: 'Улучшения площадки ( общих территорий ) или установка смарт замка на общие двери с соседями.'
      },
      DP2: {
        title: 'Оценка стоимости от оценочной компании для покупателя с ипотекой',
        description: 'Оценка недвижимости профессиональным оценщиком ( для банков, ипотеки и тд). Это сокращает срок продажи от 10 до 14 дней на продажу покупателю с ипотекой.'
      },
      DQ2: {
        title: '3D модель квартиры+ Профессиональная фотосъемка',
        description: 'Создание 3D модели квартиры, профессиональных фото и рендеринг 4-ех фотографий.'
      },
      DR2: {
        title: 'Реклама квартиры на Циан, Авито, Яндекс, ДомКлик',
        description: 'Рассчитано на срок целевой продажи по стоимости самым дорогим услугам площадок ( ТОП 3, Премиум на Авито и тд).'
      },
      DS2: {
        title: 'Локальная реклама в Фэйсбуке / инстаграмм',
        description: 'Более 60% покупателей - местные жители. Они не мониторят ежедневно доски объявлений. Активация потенциальных покупателей и увеличение охвата сокращает срок продажи и в целом показывает ROI >400%.'
      },
      DT2: {
        title: 'Оффлайн реклама на местности (листовки , расклейка объявлений)',
        description: 'Более 60% покупателей - местные жители. Они не мониторят ежедневно доски объявлений. Активация потенциальных покупателей и увеличение охвата сокращает срок продажи и в целом показывает ROI >200%.'
      },
      DU2: {
        title: 'Распечатка и установка рекламных баннеров (окна/балкон)',
        description: 'Установка и распечатка баннеров.'
      },
      DV2: {
        title: 'Операторы колцентра по приему и фиксации звонков. CRM система',
        description: 'Прием звонков с рекламы, ответы на вопросы покупателей, отправка покупателей на просмотры.Рассчитано на целевой срок продажи.'
      },
    },
    estimateOwn2Fields: {
      title: 'Продажа недвижимости - получение прибыли',
      DW2: {
        title: 'Оплата комиссии за продажу',
        description: 'Комиссия агентам за поиск покупателя ( по умолчанию от 1.5% до 2.5% от стоимости продажи ). Если покупатель пришел без агента, то комиссия добавляется к чистой прибыли проекта.'
      },
      DX2: {
        title: 'Налог на имущество',
      },
      DY2: {
        title: 'Юридический сервис по сопровождению сделки продажи',
      },
      DZ2: {
        title: 'Комиссии банков за сделку',
        description: 'Комиссия банка за транзакцию по сделке ( аккредитив или ячейка ).'
      },
      taxes: {
        title: 'Налог на прибыль',
        description: 'Налог на прибыль считается по умолчанию для частного лица ( 13% НДФЛ ). Помните, что вы скорее всего не сможете использовать в качестве налогового вычета ваши расходы на ремонт, рекламу и оплату услуг в помощи по приобретению кваритры. К вычетам принимаются только расходы, которые вы понесли чтобы продать квартиру ( комиссия риелтору, реклама, услуги юриста ).'
      },
      net_profit: {
        title: 'Прибыль ',
        description: 'Прибыль по проекту после вычета всех расходов и сборов, но до уплаты налогов на прибыль.'
      },
    }
  },
  investmentObjectParameters: {
    APY: {
      description: 'Годовая процентная доходность (англ. Annual Percentage Yield, APY) — ставка дохода, вычисленная с учётом применения к депозитам или инвестиционным продуктам сложных процентов. Позволяет упростить сравнение доходности для годовых сложных процентов с различающимися интервалами начисления дохода (когда проценты начисляются несколько раз в году по годовой сложной процентной ставке). Годовая процентная доходность (APY) показывает такую процентную ставку доходности, как если бы годовой сложный процент начислялся один раз в год и давал бы такую же наращенную стоимость (будущая (приведенная) стоимость) как при начислении рассматриваемого годового сложного процента, который выплачивается несколько раз в год.'
    },
    NP: {
      description: 'Прибыль по проекту после вычета всех расходов и сборов, но до уплаты налогов на прибыль.'
    },
    PD: {
      description: 'Это целевой срок проекта, за который система консервативно считает, что можно продать по цене быстрой продажи.'
    },
    SP: {
      description: 'Это цена предложения продавца на актуальную дату.'
    },
    FSP: {
      description: 'Цена, за которую квартира будет продана за целевой срок проекта или раньше ( при необходимых вложениях,указанных в доп.вложения для быстрой продажи) Доп.вложения и категории указаны в смете проекта.'
    },
    PP: {
      description: 'Это цена покупки, при которой вы не рискуете получить убыток по этой инвестиции, вы покупаете намного ниже ликвидной стоимости этой недвижимости. (не учитывает возможные юридические риски или другие риски, исключительно риски оценки стоимости).'
    },
    AI: {
      description: 'Эти расходы необходимы чтобы увеличить аудиторию покупателей и увеличить интерес к квартире чтобы продать квартиру быстро. В эти расходы входят: улучшение внешнего вида квартиры и обильная рекламная компания. В 90 случаев из 100, если квартира продается более 3 мес по цене рыночной цены- то а) сделаны плохие фотографии б) недостаточный охват рекламой в) риелтор-дилетант.'
    },
    priceShownObviousAd: {
      description: 'Цена, рассчитанная алгоритмом Shiva.AI на основе истории сделок и выявленных рыночных трендов в данном районе для данного класса недвижимости.'
    }
  },
  competitiveObjectsParameters: {
    BC: {
      description: 'В качестве конкурентов мы берем только самые ликвидные и лучшие предложения на рынке (как правило, эти квартиры тоже недооценены).'
    }
  },
  objectProperties: {
    NL: {
      description: 'Находится в зоне повышенного уровня шума.'
    },
    SP: {
      description: ''
    },
  }
};

export default obj;
