import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ru from '@/langs/ru';
import zh from '@/langs/zh';
import en from '@/langs/en';

Vue.use(VueI18n);

const getHashParams = () => {
  const hash = window.location.search;
  const params = {};
  if (hash.split('?').length == 1) return;
  hash.split('?')[1].split('&').map(hk => {
    const temp = hk.split('=');
    params[temp[0]] = temp[1];
    return temp;
  });

  return params;
};

const getLocale = () => {
  const localeFromDomain = Vue.prototype.$domainConfig.locale;

  const hashParams = getHashParams();
  const localeFromHash = hashParams ? hashParams.locale : '';

  if (localeFromHash) window.__realisteStorage.setItem('locale', localeFromHash);

  return localeFromHash || window.__realisteStorage.getItem('locale') || localeFromDomain;
};

const messages = {
  ru,
  zh,
  en
};

const numberFormats = {
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  zh: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }
};

const dateTimeFormats = {
  ru: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    }
  },
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  zh: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
};

export const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: getLocale(),
  messages,
  numberFormats,
  dateTimeFormats,
  pluralizationRules: {
    ru(choice) {
      const n = Math.abs(choice) % 100;

      const n1 = n % 10;

      if (n > 10 && n < 20) {
        return 2;
      }

      if (n1 > 1 && n1 < 5) {
        return 1;
      }

      if (n1 == 1) {
        return 0;
      }

      return 2;
    }
  }
});
