import constants from '@/components/constants';

class SaveGetParams {
  #params = [...constants.GLOBAL_GET_PARAMS, ...constants.OBJECT_GET_PARAMS];

  beforeEach(to, from, next) {
    if (!this.#params.some(el => from.query[el])) {
      return;
    }

    if (this.#params.every(el => !to.query[el])) {
      this.#params.forEach(el => {
        if (!from.query[el]) return;
        if (!to.meta.isObjectDetail) {
          constants.OBJECT_GET_PARAMS.forEach(v => {
            delete from.query[v];
          });
        }
        to.query[el] = from.query[el];
      });

      next({ path: to.path, query: to.query });
    } else {
      next();
    }
  }
}

const saveGetParams = new SaveGetParams();

export default saveGetParams;
