const rent = {
  card: {
    btnCompetitors: 'Рынок аренды',
    label: {
      longterm_rent: 'Долгосрочная аренда',
      during_repair: 'при ремонте',
      with: ''
    }
  }
};

export default rent;
