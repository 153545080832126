const rent = {
  card: {
    btnCompetitors: 'Rental market',
    label: {
      longterm_rent: 'Long term rental',
      during_repair: 'repairs',
      with: 'with'
    }
  }
};

export default rent;
