const obj = {
  inTheOfferList: 'in the offer list',
  inFavoriteList: 'in favorite list',
  emptyList: 'Your offer list is empty',
  backToIndex: 'Back to Index',
  objectsInOffer: 'Objects in offer',
  objectsInFavorite: 'Objects in favorite',
  download: 'Download',
  createLink: 'Link with Realiste',
  remove: 'Remove',
  editProfit: 'Edit Profit',
  totalInvestment: 'Total investment',
  totalProfit: 'Total profit',
  totalEquityBefore: 'Total equity before',
  totalEquityAfter: 'Total equity after',
  roiTotal: 'ROI total',
  potentialRentAfterHandover: 'Potential rent after handover',
  potentialRoiAfterHandover: 'Potential rent ROI/year after handover',
  removeAllOffers: 'Remove all offers?',
  deleteFromList: 'Delete from list?',
  cancel: 'Cancel',
  delete: 'Delete',
  linkCreated: 'Link created',
  copyLink: 'Copy link',
  linkToOffer: 'Link to offer',
  offers: 'Offers',
  personalOffer: 'Personal offer',
  pleaseReview: 'Dear customer, please review this offer',
  personalManager: 'Your Personal manager',
  apply: 'Apply',
  profileForm: {
    title: 'Create your link',
    nameAndLastName: {
      placeholder: 'Name & Last name',
      errorMsg: 'Please enter your name',
    },
    phone: {
      placeholder: 'Phone number',
      errorMsg: 'Please check your phone number',
    },
    email: {
      placeholder: 'Email',
      errorMsg: 'Please check your email',
    },
    telegram: {
      placeholder: 'Telegram account (optional)',
      errorMsg: 'Please check your Telegram account',
    },
    whatsApp: {
      placeholder: 'WhatsApp account (optional)',
      errorMsg: 'Please check your WhatsApp account',
    },
    clientName: {
      placeholder: 'Client Name',
      errorMsg: 'Please check client name',
    },
    messageForClient: {
      placeholder: 'Message for client',
      errorMsg: 'Please check message for client',
    },
    create: 'Create',
    cancel: 'Cancel',
    next: 'Next',
  },
  createLinkRequestError: 'An unexpected error has occurred. Please try again later',
  saveChanges: 'Save changes',
  totalProfitFromProject: 'Total profit from the project',
  email: 'Email',
  creatingLink: 'Creating link...',
  copied: 'Link copied',
  poweredOnRealiste: 'Powered on Realiste techology',
  personalOfferError: 'An unexpected error has occurred. Please try again later',
  favorites: 'Favorites',
  hello: 'Hello',
  pdfLoading: 'Loading...',
  openUnit: 'View unit information',
  openProject: 'View project information',
  whiteLabelLink: 'Link without Realiste',
  downloadPdf: 'Download PDF file',
};

export default obj;
