import costs from '@/langs/forecast/zh';
import form from '@/langs/form_fields/zh';
import modal from '@/langs/modal/zh';
import filters from '@/langs/filters/zh';
import deals from '@/langs/deals/zh';
import propertyTable from '@/langs/property_table/zh';
import analytics from '@/langs/analytics/zh';
import tradeUp from '@/langs/trade_up/zh';
import competitors from '@/langs/competitors/zh';
import funds from '@/langs/funds/zh';
import poi from '@/langs/poi/zh';
import feedback from '@/langs/feedback/zh';
import privacyPolicy from '@/langs/privacy_policy/zh';
import rent from '@/langs/rent/zh';
import favorites from '@/langs/favorites/zh';

const obj = {
  timePeriod: {
    year: '{count} 年',
    yearNoCount: 'year',
    month: '{count} 月',
    monthNoCount: '月',
    day: '{count} 天',
    dayWithoutVal: '天',
    monthShort: '月',
    weekShort: '周',
    minShort: '分鐘'
  },
  amount: {
    thousandShort: '千',
    millionShort: '百萬'
  },
  units: {
    meter: '儀表'
  },
  btn: {
    education: '培训',
    mediaAboutRealiste: '媒体约 Realiste',
    '30PerAnnum': '每年30%',
    proLink: '被動投資',
    analysisError: '分析中的错误?',
    invest: '在10秒內為您的公寓評分',
    investRequest: '{count}, 如果您在3天內賣出並以每年15％的價格向我們投資',
    telegramSubscribe: '订阅该频道',
    back: '回来',
    reset: '摆脱',
    backToList: '进入名单',
    openTheMap: '看图',
    realisteLink: '转到基金会页面',
    cianLink: '看网站的下一页',
    sell: '7天內賣出',
    complaintReceived: '收到投訴',
    getMoreFeatures: 'Realiste 的股東和合作夥伴可以獲得詳細的分析',
    slideCompetitorsMap: '在地圖上顯示競爭對手',
    showClosestCompetitors: '僅顯示直接類似物',
    showAllCompetitors: '顯示所有競爭對手',
    onlyClosestCompetitors: '僅相似對象',
    howItWasCalculated: '據信',
    getOffer: '獲得購買優惠',
    competitorsList: 'List',
    more: 'More',
    areaInfrastructure: 'District infrastructure',
    analyticsAndForecast: 'Statistics and forecast'
  },
  property: {
    numberRoomsShort: '居室',
    sqm: 'sqm',
    sqft: 'sqft',
    year: '年',
    floor: '层',
    discount: {
      textBefore: '',
      label: {
        undervalued: 'cheaper',
        overvalued: 'more expensive'
      },
      badgeOptions: {
        fairPrice: 'fair price',
        overValued: 'over valued',
        goodPrice: 'good price',
        greatPrice: 'great price',
        bestPrice: 'best price'
      }
    },
    discountText: '折扣',
    profitText: '年收入',
    applicationSent: '已發送申請',
    discountShortV: '折扣',
    externalUrl: {
      pro: '另一个网站',
      default: '要求查看',
      unpublished: 'View full analysis with links to ads',
      defaultDetailed: '獲取更多信息'
    },
    actualDate: {
      default: ['到', '有效'],
      notPro: ['數據每7天免費更新一次。每天更新一次', '去PRO']
    },
    repairQuality: '修质量',
    priceMarketDiscount: '低於市場價',
    priceMarketDiscountResultsPage: '2-10天銷售時有折扣',
    netProfit: '利润',
    projectProfitYearRate: '项目年利润',
    projectProfitYearRateNote: '如果轉售',
    duration: '项目的期限',
    priceDetail: '销售价格',
    priceMarket: 'Price after 3 months',
    pricePurchase: '推荐购买价格',
    improvementAmount: '出售其他附件',
    profitPageLink: '每月计算及预算',
    competitorsPageLink: '與競爭對手比較',
    price: '价',
    square: '面积',
    pricePer: {
      sqm: '每平方米',
      sqft: '每平方英尺',
    },
    discountMoreThanPriceMarket: '打入更贵。价格',
    potentialBenefit: '潛在的轉售利益',
    statedPrice: '申報價格',
    marketPrice: '市場價',
    marketPriceResultsPage: '出售時的市場價格 {count}',
    investInRenovation: '您需要投資出售 {count}',
    unitLabel: {
      month: '{count} 月',
      day: '{count} 天',
    },
    buildingRating: '房屋評級',
    noiseLevel: '噪音水平',
    repairType: {
      killed: '未經修理',
      cosmetics: '化妝品',
      euro: '歐元',
      super_euro: '設計'
    },
    repairType2: {
      no: '無裝修',
      cosmetic: '化妝品',
      euro: '歐式裝修',
      design: '設計師裝修'
    },
    repairTypeChange: {
      label: '顯示計算',
      noRepair: '未經修理',
      renovated: '裝修 {repair}'
    },
    sold: '賣',
    not_sold: '賣不出去',
    unlisted: '租用',
    demand: '要求',
    betaValuationAlert: '此位置的評估正在改進中，可能非常不准確。您可以通過在按鈕上發送反饋來幫助我們改進我們的服務',
    valuationAlert: {
      default: 'The evaluation is made by the Shiva AI algorithm automatically. In this area, Shiva AI accurately estimates the price when selling for 3 months for 95 apartments out of 100. If you think the estimate is inadequate, please let us know. We will conduct additional research and inform you of the results.',
    },
    seelerContactsMessage: '留下您的電話號碼以獲取更多信息',
    exposition: {
      default: 'Exposition',
      unpublished: 'Rented in {count}',
      competitorsMapObject: 'exposition {count}',
      sold: 'exposition {count}'
    },
    metaInfo: {
      transactionAt: '銷售日期',
      transactionNumber: 'DLD 交易'
    },
    countExpositionDays: '接觸時間',
    tooltipTitle: '信息',
    priceShownObviousAd: 'Price after 12 mos.',
    noData: 'No data',
    salePriceForPeriod: {
      title: {
        default: 'Selling price for',
        tradeUpCard: 'Apartment price'
      },
      modalTitle: 'Selling price',
      period: {
        '12months': '12 months',
        '3months': '3 months',
        dealTomorrow: 'deal tomorrow'
      }
    },
    verifiedObject: {
      btnText: 'Apartment check results',
      badgeText: 'Verified by Realiste'
    }
  },
  district: {
    details: [
      {
        key: 'similarApartmentCount',
        text: ['<h3>{count}</h3> similar apartment | <h3>{count}</h3> similar apartments | <h3>{count}</h3> similar apartments', 'for sale'],
      },
      {
        key: 'soldApartmentsLastMonthsCount',
        text: ['<h3>{count}<h3> similar apartment | <h3>{count}</h3> similar apartments | <h3>{count}</h3> similar apartments', 'was sold for the last month | were sold for the last month | were sold for the last month'],
      },
      {
        key: 'expositionDays',
        text: ['<h3>{count}</h3> day | <h3>{count}</h3> days | <h3>{count}</h3> days', ' - average exposition days'],
      },
      {
        key: 'priceAreaMedian',
        text: ['currency', 'average selling price of similar apartments in the area'],
      },
      {
        key: 'discountForFastSell',
        text: ['<h3>{count}%</h3>', ' - necessary discount for a quick sale']
      }
    ],
    showDetailsLabel: {
      text: 'Details - on our',
      textLink: 'price index map'
    }
  },
  chart: {
    legend: {
      priceMarket: ['Market price', 'for current apartment'],
      priceMarketForEachObject: '每個物業的市場價格'
    },
  },
  loader: {
    text: '请等待数据加载',
    textCalcClaimTop: '<p class="mb-1">Please wait, data is loading.</p>In highly competitive areas, claim calculation can take up to 30 seconds',
    textCalcClaimTradeUpTop: '<p class="mb-1">We remove all unfavorable offers from the screen.</p>In highly competitive areas, claim calculation can take up to 30 seconds',
    textCalcClaim: [
      'I highlight the location',
      'I analyze the factors affecting the&nbsp;area',
      'Collecting data on transactions made for&nbsp;3 months',
      'I determine the market price for each apartment in the &nbsp;area',
      'Appreciating the current apartment',
      'Almost ready',
      'It looks like there are a lot of apartments for sale in your area. The analysis will take a little longer'
    ],
    textCalcClaimTradeUp: [
      'I highlight the location',
      'Appreciating the current apartment',
      'I determine the market price for each apartment',
      'I select offers with a market price or lower',
      'I remove unprofitable options from the map',
      'I analyze what good and bad factors affect the area',
      'I calculate the difference and possible mortgage payments',
      'Almost ready',
      'It looks like there are a lot of apartments for sale in your area. The analysis will take a little longer'
    ],
    textShort: '等待',
    counter: {
      default: 'The calculation takes <mark>{count}</mark> s',
      moreThan60sec: 'Unfortunately, we were unable to quickly calculate the cost according to your parameters. Please try again later or chat with us'
    },
    isReady: 'The calculation is ready'
  },
  costs,
  profitPage: {
    title: '预估产量',
    pricePurchase: '购买价格',
    priceSelling: '销售价格',
    projectDuration: '项目的期限',
    improvements: '改进',
    projectProfitYearRate: '年回报率',
    netProfit: '净利润'
  },
  competitorsPage: {
    title: '最强对手'
  },
  disclaimer: {
    info: '非投資建議，數據僅供參考',
    linkText: '作為信息'
  },
  userAgreement: {
    title: '与网站用户的许可协议',
    linkTitle: '用户协议'
  },
  bubble: {
    bubbleAbout: {
      title: '转到'
    }
  },
  share: {
    default: {
      title: '将Realiste !从你的投资中得到50%+。',
      description: 'Realiste是一家投资公司，以人工智能(AI)为基础进行房地产投资决策。'
    },
    form: {
      title: '从现实生活中烧掉公寓',
      description: '免费接受AI的房地产分析。在你投资之前，先了解房地产，然后后悔你的错误。'
    }
  },
  form,
  bottomNav: {
    map: '折扣公寓',
    propertyValuation: {
      default: '評價公寓',
      isCalcClaim: {
        priceEstimation: 'Estimate another apartment',
        tradeUp: 'Estimate another apartment'
      }
    },
    tradeUp: {
      default: 'Trade Up',
      tradeUp: 'Trade Up',
      isCalcClaim: 'Trade Up'
    },
    deals: '投資歷程',
    shivaAi: '文件夾',
    magicPro: '對於投資者',
    mapAnalytics: '地圖'
  },
  bottomNavProLabelTitle: '提供專業版',
  startScreen: {
    btn: {
      map: 'View properties at a good price',
      propertyValuation: 'Price estimation',
      tradeUp: 'Trade Up',
      deals: 'Investment history',
      shivaAi: 'Shiva AI',
      magicPro: 'Information for investors',
      mapAnalytics: 'View price index',
    },
    hint: 'All actions will be available later in the bottom menu',
    phoneVerification: {
      title: 'To view apartments with a discount, please enter your phone number',
      btn: {
        submit: 'Confirm'
      }
    },
    smsCodeVerification: {
      btn: {
        submit: 'Confirm'
      },
      tradeUpBtn: {
        submit: 'Open trade up options',
      }
    }
  },
  onboarding: {
    slides: {
      propertyValuation: 'Assessment of any apartment in 10 seconds',
      map: 'Selected top 1% of the most profitable apartments at the best prices',
      poi: 'See what factors affect the cost of apartments in the area',
      competitors: 'Find out what apartments similar to yours are for sale nearby',
      tradeUp: 'Find out the best options for exchanging your apartment for any new build',
      investment: 'Own apartments and earn income from&nbsp;10%&nbsp;to&nbsp;20% per year',
      mapAnalytics: 'Find out which areas in&nbsp;your city are growing in price and which are not'
    },
    buttons: {
      skip: 'Skip',
      next: 'Next'
    }
  },
  modal,
  filters,
  mapTopMessage: '前1000套公寓在120000個報價中',
  internalTokenMessage: '內部令牌',
  proText: '提供給Realiste的股東和合作夥伴',
  deals,
  propertyTable,
  analytics,
  tradeUp,
  competitors,
  funds,
  poi,
  feedback,
  privacyPolicy,
  rent,
  favorites,
  roiDesk: '淨投資回報率，包括維護場地的成本.',
  linkHowItWorks: '<strong>怎麼運行的？</strong><br>找出一個現實主義者以及他如何成為房地產評估',
  sqftSup: '每平方英尺',
  sqmSup: '每平方米',
  tokenExpired: '鏈接已過期或被阻止'
};

export default obj;
