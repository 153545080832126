export default {
  namespaced: true,
  state: {
    objectCurrentId: null,
    filters: null,
    filtersDefault: null,
    sliderHeight: null,
    payExtraGetCash: null
  },
  mutations: {
    setCurrentId(state, objectCurrentId) {
      state.objectCurrentId = objectCurrentId;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setFiltersDefault(state, filtersDefault) {
      state.filtersDefault = filtersDefault;
    },
    setSliderHeight(state, sliderHeight) {
      state.sliderHeight = sliderHeight;
    },
    setPayExtraGetCash(state, payExtraGetCash) {
      state.payExtraGetCash = payExtraGetCash;
    }
  },
  getters: {
    getObjectCurrentId: (state) => state.objectCurrentId,
    getFilters: (state) => state.filters,
    getFiltersDefault: (state) => state.filtersDefault,
    getSliderHeight: (state) => state.sliderHeight,
    getPayExtraCash: (state) => state.payExtraGetCash
  },
};
