<template>
  <div :class="getClassName" @mouseenter="addHoverClass" @mouseleave="removeHoverClass">
    <slot></slot>
    <div class="tooltip-base--body" :class="position">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import isMobile from '@/mixins/isMobile';

export default {
  name: 'TooltipBase',
  mixins: [isMobile],
  props: {
    position: {
      type: String,
      default: ''
    },
    isInline: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: 'block'
    }
  },
  computed: {
    getClassName() {
      let className = `tooltip-base tooltip-base-${this.display}`;
      if (this.isInline) className += 'tooltip-inline';
      return className;
    },
  },
  methods: {
    addHoverClass(event) {
      const tooltip = event.currentTarget;

      tooltip.classList.add('hover');

      if (this.isMobile) {
        setTimeout(() => {
          tooltip.classList.remove('hover');
        }, 3000);
      }
    },
    removeHoverClass(event) {
      event.target.classList.remove('hover');
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-base {
  position: relative;
  &.tooltip-inline {
    display: inline-block;
  }
  &-flex {
    display: flex;
  }
  &--body {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px;
    transform: scale(0) translateY(0);
    transform-origin: top right;
    transition: transform .2s ease;
    &.left {
      left: 0;
      right: auto;
      transform-origin: top left;
    }
    &.top {
      top: -100%;
      bottom: auto;
      transform-origin: bottom right;
    }
    &.center {
      left: 50%;
      right: auto;
      transform-origin: bottom left;
    }
    &.bottom-center {
      right: auto;
      left: 50%;
      transform-origin: top left;
    }
    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      height: 7px;
    }
  }
  &.hover {
    .tooltip-base--body {
      transform: scale(1) translateY(7px);
    }

    .tooltip-base--body.top {
      transform: scale(1) translateY(-15px);
    }

    .tooltip-base--body.center.top {
      transform: scale(1) translateY(-15px) translateX(-50%);
    }

    .tooltip-base--body.bottom-center {
      transform: scale(1) translateY(7px) translateX(-50%);
    }
  }
}
</style>
