<template>
  <div v-if="$route.meta.dealsLayout" class="main-wrap">
    <data-loader v-bind:isLoading="Object.keys(deals).length == 0 || Object.keys(spreadsheets).length == 0" />
    <main>
      <div v-if="Object.keys(deals).length != 0 && Object.keys(spreadsheets).length != 0">
        <router-view />
      </div>
    </main>
    <user-agreement-link />
  </div>
</template>

<script>
import UserAgreementLink from '@/components/common/UserAgreementLink.vue';
import { imageProcessor } from '@/models/imageProcessor';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'Default',
  data() {
    return {
      imagesRealtor: [],
    };
  },
  components: {
    UserAgreementLink
  },
  computed: {
    deals() {
      return this.$store.state.deals;
    },
    spreadsheets() {
      return this.$store.state.spreadsheets;
    },
    dealsImagesRealtor() {
      return this.$store.state.dealsImagesRealtor;
    },
  },
  mounted() {
    this.initDeals();
    this.getRealtors();
    this.getSheetTranslations();
    this.getSpreadsheets();
  },
  methods: {
    ...mapActions(['getDealsFormula', 'getDeals', 'getRealtors', 'getSheetTranslations', 'getSpreadsheets']),
    async initDeals() {
      await this.getDealsFormula();
      await this.getImageProcessor();
      await this.getDeals(this.imagesRealtor);
    },
    getImageProcessor() {
      return new Promise((resolve, reject) => {
        let imagesRealtor = [];
        imagesRealtor = _.uniq(this.dealsImagesRealtor).map(item => {
          // eslint-disable-next-line no-useless-escape
          const image = item.replace(/ /g, '').split('=IMAGE(\"');
          // eslint-disable-next-line no-useless-escape
          const imageAvatar = image.length == 2 ? image[1].replace('\";1)', '') : '';
          return {
            url: imageAvatar,
            params: 'resize:auto:100:100'
          };
        });
        this.$apollo.query({
          query: imageProcessor,
          variables: { urls: imagesRealtor },
          fetchPolicy: 'no-cache',
        }).then((data) => {
          const compressedImages = data.data.imageProcessor;
          this.dealsImagesRealtor.forEach(item => {
            // eslint-disable-next-line no-useless-escape
            const image = compressedImages.find(img => `=IMAGE(\"${img.sourceUrl}\";1)` === item.replace(/ /g, ''));
            this.imagesRealtor.push(image?.url || '');
          });
          resolve();
        }).catch((error) => {
          console.log('getImageProcessor' - error);
          reject(error);
        });
      });
    },
  }
};
</script>
